/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from 'react-query'
import { Layout, Menu } from 'antd'
import { Redirect } from 'react-router-dom'
import { Route } from 'react-router-dom'
import {
    isAuthenticated,
    decodeToken,
    getToken,
    decodeUserInfo,
    decodeUserNextStage,
    logsOutUser,
} from '../../utils/auth'
import './authenticatedroute.scss'

import AuthenticatedNavBar from '../AuthenticatedNavBar'
import FloatingBox from '../FloatingBox'
import logo2 from '../../assets/images/logo2.svg'
import FloatIcon from '../../assets/images/float-icon.svg'

import { onboardingStage, stageNumber } from '../../pages/Login/stages'
import { getProfileInformationApi } from '../../api/profile'
import { checkConsumerPages } from '../../utils/checkConsumerPages'
import { encrypt } from 'src/utils/cryptography'
import {
    applyDynamicPermissionsToAdminUser,
    applyDynamicPermissionsToProviderUser,
    encrptUserPermissions,
} from 'src/utils/permissionsControl'
import { checkRepWorkspacePages } from 'src/utils/checkClusterRepPages'
import { useClearPersistedFilter } from 'src/utils/clearPersistedFilter'
import {
    useCheckSelection,
    usePermittedRoutes,
} from '../AuthenticatedNavBar/utils'

/**
 * Renders the component if the user is authenticated
 *
 * @param {Component} Component
 *
 * @returns {JSX}
 */
const renderComponent = (Component, onboardingStageHandler) => props => {
    const userInfo = decodeUserInfo()
    return (
        <Component
            onboardingStageHandler={onboardingStageHandler}
            {...props}
            userInfo={userInfo}
        />
    )
}

/**
 * This Component returns a new
 * route based on authenticated status
 *
 * @returns {JSX}
 */
const AuthenticatedRoute = props => {
    const { Header, Sider, Content } = Layout

    const history = useHistory()
    const { clearPersistedFilter } = useClearPersistedFilter()

    const [showOnboardingBox, setShowOnboardingBox] = useState(false)
    const [userOnboardingStage, setUserOnboardingStage] = useState('')
    const [getProfilePermission, setGetProfilePermission] = useState(false)

    const { component: Component, path, ...rest } = props

    const checkisAuthenticated = isAuthenticated()
    const token = getToken()
    const user = decodeToken(token)
    const userInfo = decodeUserInfo()
    const getStage = decodeUserNextStage()
    const isSSEConsumer = JSON.parse(localStorage.getItem('isSSEConsumer'))
    const isClusterRep = JSON.parse(localStorage.getItem('isClusterRep'))
    const isConsumerOrRepWorkspace =
        checkConsumerPages(path) || checkRepWorkspacePages(path)

    useEffect(() => {
        const currentUrlIsPaymentPlan =
            history?.location?.pathname.includes('/app/payment-plans')
        if (
            userInfo?.isAdminWorkspaceUser === 'true' &&
            currentUrlIsPaymentPlan
        ) {
            history.push('/app/403-page')
        }
    }, [history, userInfo.isAdminWorkspaceUser])

    useEffect(() => {
        const currentUrlIsOverview =
            history?.location?.pathname.includes('/app/overview')
        if (userInfo?.isAdminWorkspaceUser === 'true' && currentUrlIsOverview) {
            history.push('/app/403-page')
        }
    }, [history, userInfo.isAdminWorkspaceUser])

    useEffect(() => {
        if (getStage && !checkConsumerPages(path)) {
            setUserOnboardingStage(getStage)
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // const currentUrl = history?.location?.pathname

        if (userOnboardingStage === 'ongoing') {
            refetch()
        } else if (
            (applyDynamicPermissionsToProviderUser ||
                applyDynamicPermissionsToAdminUser) &&
            !checkConsumerPages(path) &&
            !checkRepWorkspacePages(path)
        ) {
            setGetProfilePermission(true)
            refetch()
        }
        // eslint-disable-next-line
    }, [userOnboardingStage])

    const { refetch, status: getProfileStatus } = useQuery(
        'getProfile',
        () => getProfileInformationApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                if (getProfilePermission) {
                    createPermissionStream(data?.data?.data?.id)
                    encrptUserPermissions(data)
                } else {
                    const encodedNextStage = encrypt(
                        data?.data?.data?.next_stage === null ||
                            data?.data?.data?.is_system_admin
                            ? 'completed'
                            : 'ongoing',
                    )
                    localStorage.setItem('sunfiUserStage', encodedNextStage)
                    if (
                        data?.data?.data?.next_stage === null ||
                        data?.data?.data?.is_system_admin
                    ) {
                        history.push(
                            data?.data?.data?.is_system_admin
                                ? 'admin/overview'
                                : '/app/products',
                        )
                    } else {
                        const hasOnboardingStarted =
                            data?.data?.data?.last_completed_stage ===
                            onboardingStage['COMPLETED_SIGN_UP']
                                ? 'notstarted'
                                : 'started'

                        history.push({
                            pathname: `/onboarding`,
                            state: {
                                onboardingState: hasOnboardingStarted,
                                nextOnboardingStage:
                                    stageNumber[data?.data?.data?.next_stage],
                            },
                        })
                    }
                }
            },
            onError: () => {
                logsOutUser()
            },
        },
    )

    const permittedRoutes = usePermittedRoutes(getProfileStatus)
    const checkSelection = useCheckSelection({
        isOnboardingActive: userOnboardingStage === 'ongoing',
    })

    const updateOnboardingStage = (value = false) => {
        setShowOnboardingBox(value)
    }

    //change this after integrating repayment schedule endpoint
    const exceptionUrls = [
        '/consumer/repayment-schedule',
        '/consumer/update-application',
        '/consumer/workspace',
    ]

    const exceptionRepUrls = ['/rep/workspace/:id']

    if (!exceptionUrls?.includes(path) && !exceptionRepUrls.includes(path)) {
        if (
            !checkisAuthenticated ||
            (isSSEConsumer !== true && isClusterRep !== true && !getStage)
        ) {
            const exceptionUrls = ['/app/403-page', '/app/not-found']
            const nextPage = window.location.pathname + window?.location?.search
            return (
                <Redirect
                    to={
                        exceptionUrls.includes(nextPage)
                            ? '/login'
                            : `/login?next=${nextPage}`
                    }
                />
            )
        }
    }

    if (exceptionUrls?.includes(path)) {
        if (!checkisAuthenticated) {
            return <Redirect to="/consumer/login" />
        }
    }

    if (exceptionRepUrls?.includes(path)) {
        if (!checkisAuthenticated) {
            return <Redirect to="/rep/login" />
        }
    }

    const currentUrl = props?.path
    const additionalContentBoxClassName =
        props?.additionalContentBoxClassName ?? ''

    function createPermissionStream(user_id) {
        const permissionSource = new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET_URL}/user-permission-watch/stream/${user_id}/`,
        )

        permissionSource.onmessage = event => {
            if (event.data === 'READY') {
                refetch()
            }
        }
    }
    return (
        <Layout
            className={
                isConsumerOrRepWorkspace
                    ? 'ConsumerLayoutWrapper'
                    : 'LayoutWrapper'
            }
        >
            <Sider
                trigger={null}
                collapsible
                collapsed={false}
                className="VerticalSider"
                style={{
                    display: isConsumerOrRepWorkspace ? 'none' : 'block',
                }}
            >
                <div
                    className="LogoBox"
                    style={{
                        display: isConsumerOrRepWorkspace ? 'block' : 'none',
                    }}
                >
                    <img src={logo2} alt="logo" />
                </div>
                <Menu
                    theme="light"
                    defaultSelectedKeys={checkSelection()}
                    selectedKeys={checkSelection()}
                    mode="inline"
                    className="MenuItemBox"
                >
                    <>
                        <div className="MenuItemBox_Logo">
                            <img
                                src={
                                    'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Blue.png'
                                }
                                alt="SunFi"
                            />
                        </div>
                        {permittedRoutes.map(navRoute => {
                            return (
                                <Menu.Item
                                    key={navRoute?.key}
                                    icon={
                                        <div style={{ width: '30px' }}>
                                            <img
                                                src={
                                                    parseInt(
                                                        checkSelection(),
                                                        10,
                                                    ) === navRoute?.key
                                                        ? navRoute?.onSelectIcon
                                                        : navRoute?.icon
                                                }
                                                alt="icon"
                                            />
                                        </div>
                                    }
                                    className="MenuItem"
                                    disabled={
                                        navRoute?.key === 1 ||
                                        navRoute?.key === 2 ||
                                        navRoute?.key === 3 ||
                                        navRoute?.key === 4 ||
                                        navRoute?.key === 5 ||
                                        navRoute?.key === 6 ||
                                        navRoute?.key === 7 ||
                                        navRoute?.key === 8 ||
                                        navRoute?.key === 9 ||
                                        navRoute?.key === 10 ||
                                        navRoute?.key === 11 ||
                                        navRoute?.key === 12 ||
                                        navRoute?.key === 13
                                            ? false
                                            : true
                                    }
                                    onClick={
                                        userOnboardingStage !== 'ongoing'
                                            ? () => {
                                                  clearPersistedFilter(
                                                      navRoute.url,
                                                  )
                                                  history.push(navRoute.url)
                                              }
                                            : () => {}
                                    }
                                >
                                    <span className="MenuTitle">
                                        {navRoute?.title}
                                    </span>
                                </Menu.Item>
                            )
                        })}
                    </>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header
                    className={`${
                        props.hideNavOnMobile ? 'hideNav' : 'HeaderBox'
                    } ${isConsumerOrRepWorkspace ? 'HeaderBoxV2' : ''}`}
                    style={{
                        backgroundColor: isConsumerOrRepWorkspace
                            ? '#fff'
                            : props.bgColor
                            ? props.bgColor
                            : '',
                    }}
                >
                    <AuthenticatedNavBar userInfo={userInfo} path={path} />
                </Header>
                <Content
                    className={`ContentBox ${additionalContentBoxClassName}`}
                    style={{
                        backgroundColor: isConsumerOrRepWorkspace
                            ? '#fff'
                            : props.bgColor
                            ? props.bgColor
                            : '',
                    }}
                >
                    {currentUrl.includes('products') ||
                    currentUrl.includes('estimations') ||
                    currentUrl.includes('payment-plans') ||
                    currentUrl.includes('admin') ||
                    currentUrl.includes('profile') ||
                    currentUrl.includes('not-found') ||
                    currentUrl.includes('overview') ||
                    currentUrl.includes('requests') ||
                    currentUrl.includes('/consumer/repayment-schedule') ||
                    currentUrl.includes('/consumer/update-application') ||
                    currentUrl.includes('/consumer/workspace') ||
                    currentUrl.includes('/rep/workspace/:id') ||
                    currentUrl.includes('/rep/overview') ||
                    currentUrl.includes('/app/customers') ||
                    currentUrl.includes('user') ||
                    currentUrl.includes('group') ? (
                        ''
                    ) : (
                        <>
                            <FloatingBox
                                type="onboarding"
                                floatIcon={FloatIcon}
                                showOnboardingBox={showOnboardingBox}
                                userInfo={userInfo}
                            />
                        </>
                    )}

                    <div user={user}>
                        <Route
                            {...rest}
                            render={renderComponent(
                                Component,
                                updateOnboardingStage,
                            )}
                        />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default AuthenticatedRoute
