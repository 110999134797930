import axios from '../../../config/axios'

export const sseAuthValidateOtp = async payload => {
    const request = axios.post('/sse-auth/auth-validate-otp', payload)
    return request
}

export const sseAuthInitiateOtp = async payload => {
    const request = axios.post(`/sse-auth/initiate-otp`, payload)
    return request
}

export const sseAuthCheckExistingUser = async payload => {
    const request = axios.post(`/sse-auth/check-user-exists`, payload)
    return request
}
