import PropTypes from 'prop-types'
import styles from 'src/components/ActionModalV2/actionmodalv2.module.scss'
import { getIconByActionType } from 'src/components/ActionModalV2/utils'

const ActionContent = ({
    actionModalHeaderText,
    actionModalSubtitle,
    actionModalActionType,
    actionModalOpen = true,
}) => {
    const { ActionHeader, ActionContainer, ActionText } = styles
    const iconType = getIconByActionType(
        actionModalActionType,
        10,
        () => {},
        actionModalOpen,
    )
    return (
        <div className={ActionContainer}>
            {iconType}
            <h2 className={ActionHeader}>{actionModalHeaderText}</h2>
            <p className={ActionText}>{actionModalSubtitle}</p>
        </div>
    )
}

ActionContent.propTypes = {
    actionModalHeaderText: PropTypes.string,
    actionModalSubtitle: PropTypes.string,
    actionModalActionType: PropTypes.string,
    actionModalOpen: PropTypes.bool,
}

export default ActionContent
