import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { SelectField, InputFields } from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import FormWrapper from '../../PersonalInformation/_components/FormWrapper'
import Label from '../../PersonalInformation/_components/Label'
import styles from './financeinfo.module.scss'
import mono from 'src/assets/images/mono.svg'
import okra from 'src/assets/images/okra.svg'
import lockIcon from 'src/assets/images/Lock1.svg'
import attachIcon from 'src/assets/images/clip.svg'
import { useEffect, useRef, useState } from 'react'
import UploadModal from 'src/components/UploadModal'
import UploadField from 'src/components/InputFields/UploadField'
import ModalContainer from '../ModalContainer'
import ButtonComponent from 'src/components/Button'
import { useMono } from 'src/utils/Hooks/useMono'
import { isMobile } from 'src/utils/mediaQueries'

import { useOkra } from 'src/utils/Hooks/useOkra'
import { useUploadBankStatement, useGetBanks } from './api'
import { useGetKycInfo } from 'src/api/shoppingExperience/account-overview'
import BankStatement from '../_components/BankStatement'
import { checkPDFPassword } from 'src/utils/checkPDFPassword'
import { InlineLoader } from 'src/components/Loader'
import { useAppContext } from 'src/appContext/AppContext'
import {
    SET_AUDIT_FILE,
    SET_AUDIT_REPORT,
    SET_AUDIT_REPORT_PASSWORD,
    SET_AUDIT_REPORT_SUCCESS,
    SET_DOC_URL,
    SET_MONO_CODE,
    SET_MONTHLY_REVENUE_ERROR,
    SHOW_AUDITREPORT_PASSWORD_MODAL,
    SHOW_AUDIT_MODAL,
    SHOW_BANKSTATEMENT_MODAL,
    SHOW_BANKSTATEMENT_PASSWORD_MODAL,
    SHOW_THIRD_PARTY,
    SHOW_UPLOAD_BANK_STATEMENT,
    SET_BVN,
    SET_BVN_VERIFIED,
    SET_BVN_ERROR,
    SET_UPDATE_REQUEST_ERRORS,
    SET_BANK_STATEMENT_ERROR,
    SET_AUDIT_REPORT_ERROR,
    SET_FIRST_NAME,
    SET_LAST_NAME,
    SET_EMAIL,
    SET_PHONE_NUMBER,
    SET_BANK_STATEMENT_NAME_MISMATCH,
} from 'src/appContext/appReducer'
import { formatErrorMessages } from '../../utils/utils'
import { extractFileNameFromUrl, toTitleCase } from 'src/utils/formatting'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import VerifyBVNModal from '../../Verification/components/VerifyBVNModal'
import { decodeUserInfo } from 'src/utils/auth'
import BvnData from './BvnData'
import OverrideCheckModal from '../../_components/OverrideCheckModal'
import useMappedErrors from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/hooks/useMappedErrors'
import {
    BUSINESS_FINANCIAL_INFO_UPDATE_REQUESTS_MAP,
    RESIDENTIAL_FINANCIAL_INFO_UPDATE_REQUESTS_MAP,
} from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/utils'
import useScrollToError from '../../hooks/useScrollToError'
import FormSkeleton from '../../_components/FormSkeleton'
import RemoveFileModal from '../../_components/RemoveFileModal'
import BankStatementDisplay from './BankStatement/BankStatementDisplay'
import { useParams } from 'react-router-dom'
import useGetBankStatement from './BankStatement/useGetBankStatement'
import { useKYCCompletionStatus } from 'src/utils/Hooks/useKYCCompletionStatus'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { ReactComponent as DocumentIcon } from 'src/assets/images/document-icon.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { bankStatementFileTypes } from 'src/utils/supportedFileTypes'
import { useMutation } from 'react-query'
import { updatePrimaryStatement } from 'src/api/create-account'
import ActionModalV2 from 'src/components/ActionModalV2'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useKYCFormCompletionGuard from '../../hooks/useKYCFormCompletionGuard'
import AccountTypeModal from '../_components/AccountTypeModal'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import CSSEVideoPlayer from 'src/components/CSSEVideoPlayer'
import SaveInfo from '../../Verification/components/saveInfo'

const FinancialForm = ({ userType }) => {
    const [bvnError, setBvnError] = useState('')
    const [saveAndContinueModal, setSaveAndContinueModal] = useState(false)
    const pageTitle =
        'KYC - Financial Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const {
        state,
        dispatch,
        estimationId,
        handleSubmitBusinessFinancialInfo: handleFinancialInfoSubmission,
        postDocumentToS3,
        isUploadingFileUrl,
        isAuditReportUploading,
        errorAlert,
        successAlert,
        finanicalInfoFormik,
        handleUploadAuditStatement,
        isCustomerApproved,
        customerType,
        showBankAnalysisModal,
        setShowBankAnalysisModal,
        verificationInProgress: isVerificationInProgress,
        setVerificationInProgress: setIsVerificationProgress,
    } = useAppContext()
    const { BVNAlert } = useCustomToast()

    const {
        values: financialInfoValues,
        errors: financialInfoError,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        touched,
    } = finanicalInfoFormik
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const errors = useMappedErrors(
        userType === 'business'
            ? BUSINESS_FINANCIAL_INFO_UPDATE_REQUESTS_MAP
            : RESIDENTIAL_FINANCIAL_INFO_UPDATE_REQUESTS_MAP,
    )
    const { data: bankLists } = useGetBanks()
    const bankNames = bankLists?.data?.map(el => ({ value: el.name }))

    const { kycStatus } = useKYCCompletionStatus(estimationId, customerType)
    const bvnCompleted = kycStatus?.bvn_completed
    const { id } = useParams()
    const {
        consumerBankStatements,
        ConsumerBankStatementIsLoading,
        notAuthorized,
    } = useGetBankStatement(id, isAdminUser)
    useEffect(() => {
        if (state.errors.bvn) {
            setBvnError(state.errors.bvn)
        } else if (state.bvn.length && state.bvn.length < 11) {
            setBvnError('Please enter a valid bvn')
        } else if (state.bvn.length && state.bvn.length > 11) {
            setBvnError('Please enter a valid bvn')
        } else {
            setBvnError('')
        }
    }, [state.bvn, state.errors.bvn])

    const {
        mutate: postBankStatement,
        isLoading: isBankStatementUploading,
        isSuccess: isBankStateUploadSuccess,
    } = useUploadBankStatement()
    const {
        data: customerKycInfo,
        refetch: refetchKycInfo,
        isFetching: kycInfoFetching,
    } = useGetKycInfo(isAdminUser, estimationId || id, {
        onSuccess: res => {
            const consumerData = res?.data?.data
            if (
                res?.data?.data?.financial_verification?.bank_statements
                    ?.length > 0
            ) {
                dispatch({
                    type: SHOW_UPLOAD_BANK_STATEMENT,
                    payload: false,
                })
            }

            if (userType === 'business') {
                const formatedMonthlyRevenue = consumerData?.consumer?.business
                    ?.monthly_revenue
                    ? String(consumerData?.consumer?.business?.monthly_revenue)
                    : ''
                setFieldValue(
                    'monthlyRevenue',
                    formatedMonthlyRevenue.toLocaleString() ||
                        financialInfoValues.monthlyRevenue,
                )
            } else if (userType === 'residential') {
                dispatch({
                    type: SET_BVN,
                    payload:
                        res?.data?.data?.financial_verification?.bvn?.number ||
                        state.bvn,
                })

                dispatch({
                    type: SET_BVN_VERIFIED,
                    payload:
                        res?.data?.data?.financial_verification?.bvn
                            ?.verified || false,
                })

                dispatch({
                    type: SET_FIRST_NAME,
                    payload: res?.data?.data?.consumer?.user?.first_name,
                })

                dispatch({
                    type: SET_LAST_NAME,
                    payload: res?.data?.data?.consumer?.user?.last_name,
                })

                dispatch({
                    type: SET_EMAIL,
                    payload: res?.data?.data?.consumer?.user?.email,
                })

                dispatch({
                    type: SET_PHONE_NUMBER,
                    payload: res?.data?.data?.consumer?.user?.phone_number,
                })
            }
            const fileName = extractFileNameFromUrl(
                res?.data?.data?.consumer?.audited_financial_reports[0]?.url,
            )
            dispatch({
                type: SET_AUDIT_FILE,
                payload: {
                    name: fileName,
                    url: res?.data?.data?.consumer?.audited_financial_reports[0]
                        ?.url,
                },
            })
            const initialPaymentMethod = res?.data?.data?.consumer
                ?.repayment_method
                ? toTitleCase(
                      res?.data?.data?.consumer?.repayment_method?.replace(
                          '_',
                          ' ',
                      ),
                  )
                : financialInfoValues.paymentMethod
            setFieldValue('paymentMethod', initialPaymentMethod)
        },
    })

    const customerData = customerKycInfo?.data?.data
    const customerFinancialData = customerData?.financial_verification

    const [bankStatementUpload, setBankStatmentSuccessUpload] = useState([])
    const [bankStatement, setBankStatement] = useState([])
    const [showBVNModal, setShowBVNModal] = useState(false)

    const [isAuditPasswordProtected, setIsAuditPasswordProtected] =
        useState(false)
    const [
        isBankstatementPasswordProtected,
        setIsBankstatementPasswordProtected,
    ] = useState(false)

    const [auditCallbackFunction, setAuditCallbackFunction] = useState(null)
    const [bankCallbackFunction, setBankCallbackFunction] = useState(null)

    const [errorMessage, setErrorMessage] = useState('')
    const [bankStatementPassword, setBankStatementPassword] = useState('')
    const [showRemoveFileModal, setShowRemoveFileModal] = useState(false)
    const [fileToBeRemoved, setFileToBeRemoved] = useState({
        key: '',
        title: '',
        url: '',
        bankStatementId: '',
        fileType: '',
    })
    const bankStatementPasswordCorrectRef = useRef(false)
    const auditReportPasswordCorrectRef = useRef(false)

    const [checkedStatementId, setCheckedStatementId] = useState('')
    const [bankDetails, setBankDetails] = useState({
        id: '',
        name: '',
    })
    const [showPrimaryStatementModal, setShowPrimaryStatementModal] =
        useState(false)

    const [showAccountTypeModal, setShowAccountTypeModal] = useState(false)
    const [accountType, setAccountType] = useState('')
    const [statementUploadMethod, setStatementUploadMethod] = useState('')

    const { monoConnect, successCode: monoResp } = useMono({
        onSuccess: data => {
            const { code } = data
            dispatch({
                type: SET_MONO_CODE,
                payload: code,
            })
            postBankStatement(
                {
                    mono_code: code,
                    upload_method: 'MONO',
                    estimationId,
                    ...(userType === 'residential' && {
                        is_salary_account: accountType === 'salary-account',
                    }),
                },
                {
                    onSuccess: () => {
                        successAlert('Successfully connected to Mono')
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            'SAVE_MONO',
                            eventTrackers['sseSaveFinancialInfo'].label,
                            eventTrackers['sseSaveFinancialInfo'].category,
                        )
                        setAccountType('')
                        setStatementUploadMethod('')
                    },
                    onError: err => {
                        errorAlert(err?.response?.data?.message)
                    },
                },
            )
        },
        data: customerData?.consumer?.user,
    })

    // check if user has completed previous forms
    useKYCFormCompletionGuard(
        kycStatus,
        3,
        userType === 'business' ? 'BUSINESS' : 'RESIDENTIAL',
    )

    useEffect(() => {
        // if (isBankStateUploadSuccess) {
        refetchKycInfo()
        // }
    }, [isBankStateUploadSuccess, refetchKycInfo, monoResp])

    const { openOkraWidget } = useOkra({
        setCustomerId: id => {
            postBankStatement(
                {
                    customer_id: id,
                    upload_method: 'OKRA',
                    estimationId,
                    ...(userType === 'residential' && {
                        is_salary_account: accountType === 'salary-account',
                    }),
                },
                {
                    onSuccess: () => {
                        successAlert('Successfully connected to Okra')
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            'SAVE_OKRA',
                            eventTrackers['sseSaveFinancialInfo'].label,
                            eventTrackers['sseSaveFinancialInfo'].category,
                        )
                        setAccountType('')
                        setStatementUploadMethod('')
                    },
                },
            )
        },
        onClose: () => {},
        page: !isAdminUser && 'financialInfo',
    })

    const handleShowUploadAuditReportModal = () => {
        dispatch({
            type: SHOW_AUDIT_MODAL,
            payload: true,
        })
    }

    const handleShowBankStatementModal = () => {
        dispatch({
            type: SHOW_BANKSTATEMENT_MODAL,
            payload: true,
        })
    }
    const handleCloseUploadAuditModal = () => {
        dispatch({
            type: SHOW_AUDIT_MODAL,
            payload: false,
        })
        dispatch({
            type: SHOW_AUDITREPORT_PASSWORD_MODAL,
            payload: true,
        })
    }
    const handleCloseBankStatementModal = () => {
        dispatch({
            type: SHOW_BANKSTATEMENT_MODAL,
            payload: false,
        })

        dispatch({
            type: SHOW_BANKSTATEMENT_PASSWORD_MODAL,
            payload: true,
        })
    }

    const openBVNModal = () => {
        setShowBVNModal(true)
    }

    const closeBVNModal = () => {
        setShowBVNModal(false)
    }

    const bvnVerifiedSuccessfully = () => {
        successAlert('BVN verified successfully')
        dispatch({
            type: SET_BVN_ERROR,
            payload: '',
        })
        setShowBVNModal(false)
        refetchKycInfo()
    }

    const handleBvnVerification = () => {
        openBVNModal()
    }
    const [tooltipVisible, setToolTipVisible] = useState('')
    const [verificationInProgress, setVerificationInProgress] = useState(false)

    const handleBvnChange = e => {
        setToolTipVisible('revert-layer')
        dispatch({
            type: SET_BVN_ERROR,
            payload: '',
        })
        const value = e.target.value.trim()
        dispatch({
            type: SET_BVN,
            payload: value,
        })
    }

    const handleBvnFocus = () => {
        setToolTipVisible('visible')
    }

    const handleBvnVerifyLater = () => {
        closeBVNModal()
        BVNAlert('Please, go ahead to provide your other details', '511px')
    }

    const handleUploadBankStatementUrl = () => {
        postDocumentToS3(
            {
                category: 'BANK_STATEMENT',
                file: bankStatementUpload[0],
            },
            {
                onSuccess: res => {
                    const docUrl = res?.url + res?.fields?.key || ''
                    const bankStatementPayload =
                        isBankstatementPasswordProtected
                            ? {
                                  upload_method: 'MANUAL_UPLOAD',
                                  document_url: docUrl,
                                  estimationId,
                                  password: bankStatementPassword,
                                  bank_name: financialInfoValues.bankName,
                                  ...(userType === 'residential' && {
                                      is_salary_account:
                                          accountType === 'salary-account',
                                  }),
                              }
                            : {
                                  upload_method: 'MANUAL_UPLOAD',
                                  document_url: docUrl,
                                  estimationId,
                                  bank_name: financialInfoValues.bankName,
                                  ...(userType === 'residential' && {
                                      is_salary_account:
                                          accountType === 'salary-account',
                                  }),
                              }
                    postBankStatement(bankStatementPayload, {
                        onSuccess: () => {
                            successAlert('Bank statement uploaded successfully')
                            dispatch({
                                type: SHOW_BANKSTATEMENT_PASSWORD_MODAL,
                                payload: false,
                            })
                            setBankStatmentSuccessUpload([])
                            setBankStatement([])
                            dispatch({
                                type: SHOW_BANKSTATEMENT_MODAL,
                                payload: false,
                            })
                            dispatch({
                                type: SHOW_THIRD_PARTY,
                                payload: true,
                            })
                            setBankStatementPassword('')
                            setFieldValue('bankName', '')
                            setAccountType('')
                            setStatementUploadMethod('')
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                'SAVE_MANUAL_BANK_STATEMENT_UPLOAD',
                                eventTrackers['sseSaveFinancialInfo'].label,
                                eventTrackers['sseSaveFinancialInfo'].category,
                            )
                        },
                        onError: err => {
                            errorAlert(
                                err?.response?.data?.message ||
                                    'Error occurred',
                            )
                        },
                    })
                    dispatch({
                        type: SET_DOC_URL,
                        payload: docUrl,
                    })
                },
                onError: err => {
                    errorAlert(formatErrorMessages(err?.response?.data?.errors))
                },
            },
        )
    }

    const paymentMethods = [{ value: 'Card Debit' }, { value: 'Direct Debit' }]

    useEffect(() => {
        if (state.auditReportSuccessUpload[0]) {
            checkPDFPassword(
                state.auditReportSuccessUpload[0],
                setIsAuditPasswordProtected,
                auditReportPasswordCorrectRef,
                setAuditCallbackFunction,
                setErrorMessage,
                () => handleFinancialInfoSubmission(userType),
                state.auditReportPassword,
            )
        }
        if (bankStatementUpload[0]) {
            checkPDFPassword(
                bankStatementUpload[0],
                setIsBankstatementPasswordProtected,
                bankStatementPasswordCorrectRef,
                setBankCallbackFunction,
                setErrorMessage,
                handleUploadBankStatementUrl,
                bankStatementPassword,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.auditReportSuccessUpload, bankStatementUpload])

    const handleBankStatementPasswordSubmit = () => {
        if (bankCallbackFunction) {
            if (!bankStatementPassword) {
                return setErrorMessage('Password is required')
            }
            bankCallbackFunction(bankStatementPassword)
            checkPDFPassword(
                bankStatementUpload[0],
                setIsBankstatementPasswordProtected,
                bankStatementPasswordCorrectRef,
                setAuditCallbackFunction,
                setErrorMessage,
                handleUploadBankStatementUrl,
                bankStatementPassword,
            )
        }
    }
    const handleAuditStatementPasswordSubmit = () => {
        if (auditCallbackFunction) {
            if (!state.auditReportPassword) {
                return setErrorMessage('Password is required')
            }
            auditCallbackFunction(state.auditReportPassword)
            checkPDFPassword(
                state.auditReportSuccessUpload[0],
                setIsAuditPasswordProtected,
                auditReportPasswordCorrectRef,
                setBankCallbackFunction,
                setErrorMessage,
                () => handleFinancialInfoSubmission(userType),
                state.auditReportPassword,
            )
        }
    }

    const handleRemoveFile = (
        key,
        title,
        url = null,
        bankStatementId = null,
    ) => {
        setFileToBeRemoved({
            key: key,
            title: title,
            url: url,
            bankStatementId: bankStatementId,
            fileType:
                key === 'audit-report' ? 'AUDIT_REPORT' : 'BANK_STATEMENT',
        })
        setShowRemoveFileModal(true)
    }

    const closeRemoveFileModal = () => {
        setFileToBeRemoved({
            key: '',
            title: '',
            url: '',
            bankStatementId: '',
            fileType: '',
        })
        setShowRemoveFileModal(false)
    }

    const closeRemoveFileModalAfterSuccess = () => {
        if (fileToBeRemoved.key === 'audit-report') {
            dispatch({
                type: SET_AUDIT_REPORT_SUCCESS,
                payload: [],
            })
            dispatch({
                type: SET_AUDIT_FILE,
                payload: { ...state.auditFile, name: '', url: '' },
            })
        }

        if (fileToBeRemoved.key === 'bank-statement') {
            // timeout to make up for backend delay
            setTimeout(() => {
                refetchKycInfo()
                dispatch({
                    type: SHOW_THIRD_PARTY,
                    payload: true,
                })
                dispatch({
                    type: SHOW_UPLOAD_BANK_STATEMENT,
                    payload: true,
                })
                dispatch({
                    type: SET_BANK_STATEMENT_ERROR,
                    payload: '',
                })
            }, 3000)
        }
        setShowRemoveFileModal(false)
    }

    useEffect(() => {
        if (isNaN(Number(state.monthly_revenue))) {
            dispatch({
                type: SET_MONTHLY_REVENUE_ERROR,
                payload: 'Monthly revenue must be a valid number',
            })
        } else {
            dispatch({
                type: SET_MONTHLY_REVENUE_ERROR,
                payload: '',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.monthly_revenue])
    const handleCancelBankStatementPasswordModal = () => {
        dispatch({
            type: SHOW_BANKSTATEMENT_PASSWORD_MODAL,
            payload: false,
        })
        setBankStatmentSuccessUpload([])
        setBankStatementPassword('')
        setErrorMessage('')
    }
    const [showOverrideModal, setShowOverrideModal] = useState(false)

    const handleOverride = () => {
        setShowOverrideModal(true)
    }

    useEffect(() => {
        dispatch({
            type: SET_UPDATE_REQUEST_ERRORS,
            payload: errors,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])

    useScrollToError(errors)
    const showOkraBtn =
        process.env.REACT_APP_FLAG_SHOW_OKRA_INTEGRATION === 'true'
            ? true
            : false

    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_FINANCIAL_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    const {
        mutate: updatePrimaryStatementMutation,
        isLoading: isPrimaryBankStatementLoading,
    } = useMutation({
        mutationFn: ({ id }) =>
            updatePrimaryStatement(estimationId, { bank_statement_id: id }),
        onSuccess: (_, variables) => {
            setCheckedStatementId(variables?.id)
            setShowPrimaryStatementModal(false)
        },
        onError: err => {
            setShowPrimaryStatementModal(false)
            errorAlert(err?.response?.data?.message || 'Error occurred')
        },
    })

    const handleCheck = (e, name, id) => {
        e.preventDefault()

        if (id === checkedStatementId) return
        setBankDetails(prev => {
            return { ...prev, id, name }
        })
        setShowPrimaryStatementModal(true)
    }

    const setIsBankStatementMismatch = payload => {
        dispatch({
            type: SET_BANK_STATEMENT_NAME_MISMATCH,
            payload,
        })
    }

    useEffect(() => {
        const primaryStatement = customerFinancialData?.bank_statements.find(
            statement => statement?.primary_statement === true,
        )
        if (primaryStatement) {
            setCheckedStatementId(primaryStatement?.id)
        }
    }, [customerFinancialData?.bank_statements])

    const history = useHistory()
    const latestBankStatement = customerFinancialData?.bank_statements[0]
    const banksStatementAnalysisInReview =
        latestBankStatement?.analysis_context?.status === 'IN_PROGRESS' ||
        latestBankStatement?.analysis_context?.status === 'RETRYING' ||
        latestBankStatement?.analysis_context?.status === 'EMPTY' ||
        latestBankStatement?.analysis_context?.status === 'FAILED'
    const bankStatementAnalysisIncomplete =
        latestBankStatement?.analysis_context?.status === 'DONE' &&
        latestBankStatement?.analysis_context?.statement_complete === false
    const bankStatementAnalysisInvalid =
        latestBankStatement?.document_meta_data?.invalid_statement === true

    const bankStatementAnalysisTexts = {
        'in-review': {
            heading: 'Your bank statement is currently being analyzed',
            paragraph:
                'We were not able to analyse your bank statement immediately, we are currently working on this and will reach out to you via email once its completed',
        },
        incomplete: {
            heading: 'Your previous uploaded bank statement is incomplete',
            paragraph: `We require ${
                userType === 'business' ? '12-24' : '6-12'
            } up-to-date months Verified  Bank Statement. Please upload more to continue`,
        },
        invalid: {
            heading: 'Your previous uploaded bank statement is Invalid',
            paragraph:
                'Your previous uploaded bank statement is Invalid, please upload a new bank statement',
        },
    }

    const openAccountTypeModal = method => {
        if (userType === 'residential') {
            setShowAccountTypeModal(true)
            if (method === 'manual-upload') {
                setStatementUploadMethod('manual-upload')
            }
            if (method === 'mono') {
                setStatementUploadMethod('mono')
            }
            if (method === 'okra') {
                setStatementUploadMethod('okra')
            }
        } else {
            handleAccountTypeModalClick(method)
        }
    }

    const handleAccountTypeModalClick = method => {
        const methodStrToUse =
            userType === 'residential' ? statementUploadMethod : method
        setShowAccountTypeModal(false)
        if (methodStrToUse === 'manual-upload') {
            handleShowBankStatementModal()
        }

        if (methodStrToUse === 'mono') {
            monoConnect.open()
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                `SELECTED MONO STATEMENT UPLOAD`,
                'SMART_SHOPPING_EXPERIENCE_KYC',
                'KYC_FINANCIAL_INFO',
            )
        }

        if (methodStrToUse === 'okra') {
            openOkraWidget()
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                `SELECTED OKRA STATEMENT UPLOAD`,
                'SMART_SHOPPING_EXPERIENCE_KYC',
                'KYC_FINANCIAL_INFO',
            )
        }

        dispatch({
            type: SET_BANK_STATEMENT_ERROR,
            payload: '',
        })
    }

    const closeAccountTypeModal = () => {
        setShowAccountTypeModal(false)
        setAccountType('')
        setStatementUploadMethod('')
    }

    useEffect(() => {
        if (isVerificationInProgress) {
            setSaveAndContinueModal(true)
        }
    }, [isVerificationInProgress])

    useEffect(() => {
        if (bankStatementAnalysisIncomplete || bankStatementAnalysisInvalid) {
            setShowBankAnalysisModal(true)
        }
        // eslint-disable-next-line
    }, [customerFinancialData])
    const [showVideoModal, setShowVideoModal] = useState(!isAdminUser)
    const [showVideo, setShowVideo] = useState(false)

    return (
        <>
            <ActionModalV2
                modalWidth="475px"
                onCancel={() => setShowPrimaryStatementModal(false)}
                actionModalOpen={showPrimaryStatementModal}
                handlePrimaryBtnClick={() => {
                    updatePrimaryStatementMutation({ id: bankDetails.id })
                }}
                primaryBtnLoading={isPrimaryBankStatementLoading}
                title="Primary bank Statement"
                primaryBtnText="Confirm"
                headerText="Set as primary bank statement"
                subTitle={`Please confirm that you would like to set ${
                    bankDetails?.name ?? 'this'
                } as your primary bank statement.`}
                actionType={'warning'}
                v2
                showFooter
            />
            <OverrideCheckModal
                showOverrideModal={showOverrideModal}
                type="BVN"
                onCancel={() => {
                    setShowOverrideModal(false)
                }}
            />
            {isBankstatementPasswordProtected && (
                <ModalContainer
                    title="Enter your bank statement password"
                    onClose={handleCancelBankStatementPasswordModal}
                    isOpen={state.showBankStatementPasswordModal}
                    footer={
                        <ButtonComponent
                            disabled={isUploadingFileUrl}
                            handleClick={handleBankStatementPasswordSubmit}
                            marginLeft="auto"
                            type="primary"
                        >
                            {isUploadingFileUrl || isBankStatementUploading ? (
                                <InlineLoader />
                            ) : (
                                'Confirm'
                            )}
                        </ButtonComponent>
                    }
                >
                    <div className={styles.modal_content}>
                        <div className={styles.password_section}>
                            <section className={styles.file_preview}>
                                <img
                                    src={lockIcon}
                                    alt="password"
                                    width="28px"
                                    height="28px"
                                />
                            </section>
                            <section className={styles.file_section_preview}>
                                <div className={styles.file_section}>
                                    <div className={styles.file_name}>
                                        <img src={attachIcon} />
                                        <p>{bankStatementUpload[0]?.name}</p>
                                    </div>
                                    <div className={styles.file_datestamp}>
                                        <p>
                                            {new Date().toDateString()} at{' '}
                                            {new Date().toLocaleTimeString()}
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <InputFields
                            title="Password"
                            type="password"
                            name="bankPassword"
                            value={bankStatementPassword}
                            handleChange={e =>
                                setBankStatementPassword(e.target.value)
                            }
                            onBlur={e => {
                                handleTracking(e)
                            }}
                        />
                        <span className={styles.errorMessage}>
                            {errorMessage}
                        </span>
                    </div>
                </ModalContainer>
            )}

            {isAuditPasswordProtected && (
                <ModalContainer
                    title="Enter your audit report password"
                    onClose={() => {
                        dispatch({
                            type: SHOW_AUDITREPORT_PASSWORD_MODAL,
                            payload: false,
                        })
                        dispatch({
                            type: SET_AUDIT_REPORT_SUCCESS,
                            payload: [],
                        })
                        setErrorMessage('')
                    }}
                    isOpen={state.showAuditReportPasswordModal}
                    footer={
                        <ButtonComponent
                            handleClick={handleAuditStatementPasswordSubmit}
                            marginLeft="auto"
                            type="primary"
                        >
                            {isAuditReportUploading || isUploadingFileUrl ? (
                                <InlineLoader />
                            ) : (
                                'Confirm'
                            )}
                        </ButtonComponent>
                    }
                >
                    <div className={styles.modal_content}>
                        <div className={styles.password_section}>
                            <section className={styles.file_preview}>
                                <img
                                    src={lockIcon}
                                    alt="password"
                                    width="28px"
                                    height="28px"
                                />
                            </section>
                            <section className={styles.file_section_preview}>
                                <div className={styles.file_section}>
                                    <div className={styles.file_name}>
                                        <img src={attachIcon} />
                                        <p>
                                            {
                                                state
                                                    .auditReportSuccessUpload[0]
                                                    ?.name
                                            }
                                        </p>
                                    </div>
                                    <div className={styles.file_datestamp}>
                                        <p>
                                            {new Date().toDateString()} at{' '}
                                            {new Date().toLocaleTimeString()}
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <InputFields
                            title="Password"
                            type="password"
                            name="auditPassword"
                            value={state.auditReportPassword}
                            handleChange={e =>
                                dispatch({
                                    type: SET_AUDIT_REPORT_PASSWORD,
                                    payload: e.target.value,
                                })
                            }
                            onBlur={e => {
                                handleTracking(e)
                            }}
                        />
                        <span className={styles.errorMessage}>
                            {errorMessage}
                        </span>
                    </div>
                </ModalContainer>
            )}

            <AccountTypeModal
                showModal={showAccountTypeModal}
                onCancel={() => closeAccountTypeModal()}
                handleClick={() => {
                    handleAccountTypeModalClick()
                }}
                accountType={accountType}
                setAccountType={setAccountType}
            />

            <UploadModal
                acceptableFileTypes={bankStatementFileTypes}
                primaryBtnText={
                    isBankStatementUploading || isUploadingFileUrl ? (
                        <InlineLoader />
                    ) : (
                        'Confirm'
                    )
                }
                isLoading={isBankStatementUploading || isUploadingFileUrl}
                title="Upload Bank Statement"
                showModal={state.isBankStatementOpen}
                onCancel={handleCloseBankStatementModal}
                successUpload={bankStatementUpload}
                setSuccessUpload={setBankStatmentSuccessUpload}
                setFileToState={setBankStatement}
                handlePrimaryBtnClick={() => {
                    if (
                        bankStatementUpload.length > 0 &&
                        isBankstatementPasswordProtected === false
                    ) {
                        handleUploadBankStatementUrl()
                    } else {
                        handleCloseBankStatementModal()
                    }
                }}
            />

            {userType === 'business' && (
                <UploadModal
                    acceptableFileTypes={bankStatementFileTypes}
                    primaryBtnText={
                        isUploadingFileUrl ? <InlineLoader /> : 'Confirm'
                    }
                    title="Upload Financial Report"
                    showModal={state.isAuditReportOpen}
                    onCancel={handleCloseUploadAuditModal}
                    successUpload={state.auditReportSuccessUpload}
                    setSuccessUpload={e => {
                        dispatch({
                            type: SET_AUDIT_REPORT_SUCCESS,
                            payload: e,
                        })
                        setFieldValue('auditReport', {
                            fileName: e?.[0]?.name,
                            fileOriginObj: e?.[0]?.fileOriginObj,
                        })
                    }}
                    setFileToState={e =>
                        dispatch({ type: SET_AUDIT_REPORT, payload: e })
                    }
                    handlePrimaryBtnClick={() => {
                        if (
                            state.auditReportSuccessUpload.length > 0 &&
                            isAuditPasswordProtected === false
                        ) {
                            handleUploadAuditStatement()
                        } else {
                            handleCloseUploadAuditModal()
                        }
                    }}
                />
            )}
            <VerifyBVNModal
                showModal={showBVNModal}
                closeBVNModal={closeBVNModal}
                storedBvn={state.bvn}
                bvnVerifiedSuccessfully={bvnVerifiedSuccessfully}
                userType={userType}
                storedFirstName={state.firstName}
                storedLastName={state.lastName}
                storedEmail={state.email}
                storedPhoneNumber={state.phoneNumber}
                handleBvnVerifyLater={handleBvnVerifyLater}
                setVerificationInProgress={setVerificationInProgress}
            />
            <RemoveFileModal
                showModal={showRemoveFileModal}
                fileToBeRemoved={fileToBeRemoved}
                closeRemoveFileModal={closeRemoveFileModal}
                closeRemoveFileModalAfterSuccess={
                    closeRemoveFileModalAfterSuccess
                }
            />
            {/* Verification in progress Modal */}
            <SecondaryModal
                v2
                modalWidth={'475px'}
                showModal={saveAndContinueModal}
                title="Save and Continue Later"
                content={<SaveInfo />}
                showFooter
                onCancel={() => setSaveAndContinueModal(false)}
                footerLeftContent="chat"
                primaryBtnText="Save for later"
                handlePrimaryBtnClick={() => {
                    setSaveAndContinueModal(false)
                    setIsVerificationProgress(false)
                    history.push({
                        pathname: '/consumer/account-setup/overview',
                        state: { justCompletedKYC: false, estimationId },
                    })
                }}
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            />

            {/* Bank Analysis modal */}

            <SecondaryModal
                showModal={showBankAnalysisModal}
                onCancel={() => setShowBankAnalysisModal(false)}
                modalWidth={'475px'}
                title={
                    banksStatementAnalysisInReview
                        ? 'Bank statement in review'
                        : 'Update required on bank statement'
                }
                v2
                content={
                    <div style={{ position: 'relative' }}>
                        <div className={styles.BankAnalysisModalStyle}>
                            <DocumentIcon />
                            <h2>
                                {
                                    bankStatementAnalysisTexts[
                                        bankStatementAnalysisInvalid
                                            ? 'invalid'
                                            : bankStatementAnalysisIncomplete
                                            ? 'incomplete'
                                            : 'in-review'
                                    ].heading
                                }
                            </h2>
                            <p>
                                {
                                    bankStatementAnalysisTexts[
                                        bankStatementAnalysisInvalid
                                            ? 'invalid'
                                            : bankStatementAnalysisIncomplete
                                            ? 'incomplete'
                                            : 'in-review'
                                    ].paragraph
                                }
                            </p>
                        </div>
                    </div>
                }
                showFooter
                footerLeftContent="chat"
                primaryBtnText="Continue"
                handlePrimaryBtnClick={() => {
                    if (banksStatementAnalysisInReview) {
                        history.push({
                            pathname: '/consumer/account-setup/overview',
                            state: { justCompletedKYC: true, estimationId },
                        })
                        setShowBankAnalysisModal(false)
                    }
                    setShowBankAnalysisModal(false)
                }}
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            />

            {(showVideoModal || showVideo) && (
                <CSSEVideoPlayer
                    showVideoModal={showVideoModal}
                    setShowVideoModal={setShowVideoModal}
                    showVideo={showVideo}
                    setShowVideo={setShowVideo}
                    modalTitle="Next step"
                    header="Submit your financial infomation"
                    videoInfo="Here’s a video to guide you on how you submit your financial infomation"
                    videoUrl={
                        userType === 'business'
                            ? 'https://www.youtube.com/watch?v=iiDpoTvw4mk'
                            : 'https://www.youtube.com/watch?v=nmkqKnnyFEo'
                    }
                    videoGIF={
                        userType === 'business'
                            ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/How_to_Enter_Your_Financial_Details_as_a_Registered_Business.gif'
                            : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/How_Individuals_or_Unregistered_Businesses_Can_Submit_Their_.gif'
                    }
                    description={
                        userType === 'business'
                            ? 'Please submit your monthly revenue, 12 months of up-to-date primary bank statements, and audited financial report. Ensure bank statements are clear, legible, complete, and display all key details for quick analysis.'
                            : 'Please submit a 6-month up-to-date bank statement and BVN for credit check. Ensure clear, complete, and legible bank statements for quick analysis.'
                    }
                />
            )}

            <FormWrapper>
                {kycInfoFetching || ConsumerBankStatementIsLoading ? (
                    <FormSkeleton />
                ) : (
                    <div>
                        {!isAdminUser && (
                            <div
                                className="FinancialFormVideoWrapper"
                                onClick={() => setShowVideoModal(true)}
                            >
                                <PlayIcon />
                                <p>Watch how to submit your financial info</p>
                            </div>
                        )}
                        {userType === 'business' ? (
                            <>
                                <Label
                                    title="Add your Monthly revenue"
                                    subTitle="How much is your business revenue in a month"
                                />
                                <IconInputField
                                    icon={NairaIcon}
                                    title="Monthly Revenue"
                                    type="text"
                                    name="monthlyRevenue"
                                    value={financialInfoValues.monthlyRevenue.toLocaleString()}
                                    handleChange={e => {
                                        const newValue = parseFloat(
                                            e.target.value.replace(/,/g, ''),
                                        )
                                        if (!isNaN(newValue)) {
                                            setFieldValue(
                                                'monthlyRevenue',
                                                newValue,
                                            )
                                        } else {
                                            setFieldValue('monthlyRevenue', '')
                                        }
                                    }}
                                    onBlur={e => {
                                        setFieldTouched('monthlyRevenue', true)
                                        handleTracking(e)
                                    }}
                                    errorMessage={
                                        touched.monthlyRevenue &&
                                        financialInfoError.monthlyRevenue
                                    }
                                    width="100%"
                                    disabled={isAdminUser || isCustomerApproved}
                                />
                            </>
                        ) : (
                            <>
                                <Label
                                    title={
                                        isAdminUser
                                            ? `Customer's Bank Verification Number`
                                            : 'Add your Bank Verification Number'
                                    }
                                    subTitle={
                                        isAdminUser
                                            ? ''
                                            : 'Please provide your bank verification number. Your BVN does not give us access to your bank account. We use it to verify your credit history'
                                    }
                                    showHelpIcon={true}
                                    tooltipVisible={tooltipVisible}
                                    tooltipText={
                                        'With your BVN, we can validate your name, date of birth and also credit history through credit bureau.'
                                    }
                                />

                                <div
                                    className={`${styles.bvn__field} ${
                                        !state.isBvnVerified &&
                                        !bvnError &&
                                        styles.bvn__button
                                    }`}
                                >
                                    <InputFields
                                        title="Bank Verification Number"
                                        type={
                                            state.isBvnVerified
                                                ? 'text'
                                                : 'number'
                                        }
                                        name="bvn"
                                        marginBottom="10px"
                                        value={state.bvn}
                                        handleChange={handleBvnChange}
                                        verified={state.isBvnVerified}
                                        disabled={
                                            state.isBvnVerified ||
                                            isAdminUser ||
                                            isCustomerApproved ||
                                            verificationInProgress
                                        }
                                        onBlur={e => handleTracking(e)}
                                        errorMessage={
                                            state.errors.bvnError || bvnError
                                        }
                                        inprogress={verificationInProgress}
                                        onFocus={handleBvnFocus}
                                    />
                                    {verificationInProgress && (
                                        <span
                                            className={
                                                styles.bvn__verification__inProgress
                                            }
                                        >
                                            Verification In Progress
                                        </span>
                                    )}
                                    {!state.isBvnVerified &&
                                    !isAdminUser &&
                                    !verificationInProgress ? (
                                        <button
                                            disabled={
                                                state?.bvn === undefined ||
                                                state?.bvn?.length === 0 ||
                                                state?.bvn?.length < 11 ||
                                                state?.bvn?.length > 11 ||
                                                isAdminUser ||
                                                isCustomerApproved
                                            }
                                            onClick={handleBvnVerification}
                                        >
                                            <span>
                                                {isMobile
                                                    ? 'Verify'
                                                    : 'Verify your BVN'}
                                            </span>
                                        </button>
                                    ) : null}
                                    {isAdminUser && bvnCompleted && (
                                        <BvnData
                                            title="BVN DATA"
                                            handleOverride={handleOverride}
                                        />
                                    )}
                                </div>
                            </>
                        )}

                        {!isAdminUser && (
                            <Label
                                title={`Bank Statement ${
                                    userType === 'business'
                                        ? '& Financial report'
                                        : ''
                                }`}
                                subTitle="To help us confirm your transaction history, kindly provide your bank statement covering at least 6 to 12 months. Please ensure that the name on the statement matches the one provided during your KYC."
                            />
                        )}

                        {!isAdminUser && state.errors.bankStatement && (
                            <p className={styles.bank_statement_error}>
                                {state.errors.bankStatement}
                            </p>
                        )}
                        {!isAdminUser &&
                        customerFinancialData?.bank_statements?.length > 0 ? (
                            <div className={styles.uploaded_bank_statements}>
                                {customerFinancialData?.bank_statements?.map(
                                    (el, i, arr) => {
                                        return (
                                            <BankStatement
                                                meta_data={el}
                                                key={el.id}
                                                handleRemoveFile={
                                                    handleRemoveFile
                                                }
                                                showPrimaryStatement={
                                                    arr.length > 1
                                                }
                                                checkedStatementId={
                                                    checkedStatementId
                                                }
                                                handleCheck={(event, name) =>
                                                    handleCheck(
                                                        event,
                                                        name,
                                                        el.id,
                                                    )
                                                }
                                                disableCheck={isAdminUser}
                                                setIsBankStatementMismatch={
                                                    setIsBankStatementMismatch
                                                }
                                            />
                                        )
                                    },
                                )}
                                <section className={styles.bank_statement}>
                                    {!state.showThirdParty ||
                                        (!state.showUploadBankStatement && (
                                            <>
                                                <p>
                                                    Want to add another bank
                                                    statement?
                                                </p>
                                                <button
                                                    onClick={() => {
                                                        dispatch({
                                                            type: SHOW_THIRD_PARTY,
                                                            payload: true,
                                                        })
                                                        dispatch({
                                                            type: SHOW_UPLOAD_BANK_STATEMENT,
                                                            payload: true,
                                                        })
                                                        dispatch({
                                                            type: SET_BANK_STATEMENT_ERROR,
                                                            payload: '',
                                                        })
                                                    }}
                                                    disabled={
                                                        isAdminUser ||
                                                        isCustomerApproved
                                                    }
                                                >
                                                    Add another bank statement
                                                </button>
                                            </>
                                        ))}
                                </section>
                            </div>
                        ) : (
                            <></>
                        )}
                        {!isAdminUser &&
                            state.showThirdParty &&
                            state.showUploadBankStatement && (
                                <>
                                    {
                                        <section className={styles.thirdparty}>
                                            <p>
                                                By clicking on “connect to Mono
                                                {showOkraBtn && ' & Okra'}” we
                                                help you securely retrieve your
                                                account statement faster to
                                                enable us complete your KYC
                                                process. However, please note
                                                that this process does NOT give
                                                us any access to your bank
                                                username, password, nor can we
                                                make payments without your
                                                permission.
                                            </p>
                                            <div
                                                className={styles.button_group}
                                            >
                                                <button
                                                    onClick={() => {
                                                        openAccountTypeModal(
                                                            'mono',
                                                        )
                                                    }}
                                                    disabled={
                                                        isAdminUser ||
                                                        isCustomerApproved
                                                    }
                                                >
                                                    <img src={mono} />
                                                    {isMobile
                                                        ? 'Use Mono'
                                                        : 'Connect to Mono'}
                                                </button>
                                                {process.env
                                                    .REACT_APP_FLAG_SHOW_OKRA_INTEGRATION ===
                                                    'true' && (
                                                    <button
                                                        onClick={() => {
                                                            openAccountTypeModal(
                                                                'okra',
                                                            )
                                                        }}
                                                        disabled={
                                                            isAdminUser ||
                                                            isCustomerApproved
                                                        }
                                                    >
                                                        <img src={okra} />
                                                        {isMobile
                                                            ? 'Use Okra'
                                                            : 'Connect to Okra'}
                                                    </button>
                                                )}
                                            </div>
                                            <p className={styles.note}>
                                                We do not support dollar
                                                accounts. We accept bank
                                                statements from Opay, Alat,
                                                Moniepoint, Palmpay and Kuda.
                                            </p>
                                        </section>
                                    }

                                    <section className={styles.bank_statement}>
                                        <p>
                                            Do you have your bank statement in
                                            PDF?
                                        </p>
                                        <button
                                            onClick={() => {
                                                dispatch({
                                                    type: SHOW_THIRD_PARTY,
                                                    payload: false,
                                                })
                                                dispatch({
                                                    type: SHOW_UPLOAD_BANK_STATEMENT,
                                                    payload: false,
                                                })
                                                appTracking(
                                                    pageUrlName,
                                                    pageTracker,
                                                    pageTitle,
                                                    `SELECTED MANUAL STATEMENT UPLOAD`,
                                                    'SMART_SHOPPING_EXPERIENCE_KYC',
                                                    'KYC_FINANCIAL_INFO',
                                                )
                                            }}
                                            disabled={
                                                isAdminUser ||
                                                isCustomerApproved
                                            }
                                        >
                                            Upload here
                                        </button>
                                    </section>
                                </>
                            )}
                        {!isAdminUser &&
                            state.showThirdParty === false &&
                            state.showUploadBankStatement === false && (
                                <>
                                    <SelectField
                                        value={financialInfoValues.bankName}
                                        currentSelected={
                                            financialInfoValues.bankName
                                        }
                                        handleChange={(_, value) => {
                                            setFieldValue('bankName', value)
                                            appTracking(
                                                pageUrlName,
                                                pageTracker,
                                                pageTitle,
                                                `FILLED_${_?.toUpperCase()}`,
                                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                                'KYC_FINANCIAL_INFO',
                                                ['GA', 'MP'],
                                                'event',
                                                { value },
                                            )
                                        }}
                                        onBlur={() => {
                                            setFieldTouched('bankName', true)
                                            if (!financialInfoValues.bankName)
                                                setFieldError(
                                                    'bankName',
                                                    financialInfoError.bankName,
                                                )
                                        }}
                                        values={bankNames}
                                        dropdownPositionRelative
                                        name="bankName"
                                        floatingLabel={
                                            financialInfoValues.bankName
                                                ? 'Select the bank you want to upload from.'
                                                : ''
                                        }
                                        initialOption="Select the bank you want to upload from."
                                        selectWidth="100%"
                                        errorMessage={
                                            touched.bankName &&
                                            financialInfoError.bankName
                                        }
                                        disabled={
                                            isAdminUser || isCustomerApproved
                                        }
                                        withCheckBox
                                        marginBottom="16px"
                                        showSearch
                                    />
                                    <UploadField
                                        fileUploaded={bankStatement.length > 0}
                                        btnText={'Upload Bank Statement (PDF)'}
                                        handleUploadClick={() => {
                                            if (!financialInfoValues.bankName) {
                                                setFieldError(
                                                    'bankName',
                                                    'Please select your bank name',
                                                )
                                                return
                                            }
                                            openAccountTypeModal(
                                                'manual-upload',
                                            )
                                        }}
                                        handleRemoveClick={() =>
                                            setBankStatement([])
                                        }
                                        fileName={bankStatement?.[0]?.name}
                                        disabled={
                                            isAdminUser || isCustomerApproved
                                        }
                                    />
                                    <section className={styles.bank_statement}>
                                        <p>
                                            Don&apos;t have your bank statement?
                                        </p>
                                        <button
                                            onClick={() => {
                                                dispatch({
                                                    type: SHOW_THIRD_PARTY,
                                                    payload: true,
                                                })
                                                dispatch({
                                                    type: SHOW_UPLOAD_BANK_STATEMENT,
                                                    payload: true,
                                                })
                                                dispatch({
                                                    type: SET_BANK_STATEMENT_ERROR,
                                                    payload: '',
                                                })
                                            }}
                                            disabled={
                                                isAdminUser ||
                                                isCustomerApproved
                                            }
                                        >
                                            Use our third party partner
                                        </button>
                                    </section>
                                </>
                            )}

                        {!isAdminUser && userType === 'business' && (
                            <UploadField
                                fileUploaded={
                                    state.auditReportSuccessUpload.length > 0 ||
                                    state.auditFile.name?.length > 0
                                }
                                handleRemoveClick={() => {
                                    handleRemoveFile(
                                        'audit-report',
                                        'audit report',
                                        state.auditFile.url,
                                        null,
                                    )
                                }}
                                handleUploadClick={() => {
                                    handleShowUploadAuditReportModal()
                                    dispatch({
                                        type: SET_AUDIT_REPORT_ERROR,
                                        payload: '',
                                    })
                                }}
                                btnText="Audited financial report"
                                fileName={
                                    state.auditFile.name ||
                                    state.auditReportSuccessUpload?.[0]?.name
                                }
                                disabled={isAdminUser || isCustomerApproved}
                                errorMessage={state.errors.audit_report}
                            />
                        )}

                        <div className={styles.bankStatementWrapper}>
                            {isAdminUser &&
                            notAuthorized ? null : isAdminUser &&
                              consumerBankStatements?.length <= 0 ? (
                                <>
                                    <h3>Customer&apos;s Bank Statement</h3>
                                    <BankStatementDisplay
                                        bankStatement={{}}
                                        isEmpty={true}
                                    />
                                </>
                            ) : (
                                consumerBankStatements.map(
                                    (statement, index, statements) => (
                                        <BankStatementDisplay
                                            key={index}
                                            bankStatement={statement}
                                            isEmpty={false}
                                            showPrimaryTag={
                                                statements.length > 1
                                            }
                                        />
                                    ),
                                )
                            )}
                        </div>

                        {process.env
                            .REACT_APP_FLAG_SHOW_CUSTOMER_PREFERRED_PAYMENT_METHOD ===
                            'true' && (
                            <>
                                <div className={styles.preferred_payment_label}>
                                    <Label
                                        title={
                                            isAdminUser
                                                ? `Customer's Preferred Repayment Method`
                                                : 'Preferred Payment method'
                                        }
                                        subTitle={
                                            !isAdminUser &&
                                            'Select how best you’ll like to make your payment'
                                        }
                                    />
                                </div>
                                <SelectField
                                    handleChange={(_, value) => {
                                        setFieldValue('paymentMethod', value)
                                        appTracking(
                                            pageUrlName,
                                            pageTracker,
                                            pageTitle,
                                            `FILLED_${_?.toUpperCase()}`,
                                            'SMART_SHOPPING_EXPERIENCE_KYC',
                                            'KYC_FINANCIAL_INFO',
                                            ['GA', 'MP'],
                                            'event',
                                            { value },
                                        )
                                    }}
                                    onBlur={() => {
                                        setFieldTouched('paymentMethod', true)
                                        if (!financialInfoValues.paymentMethod)
                                            setFieldError(
                                                'paymentMethod',
                                                financialInfoError.paymentMethod,
                                            )
                                    }}
                                    values={paymentMethods}
                                    value={financialInfoValues.paymentMethod}
                                    dropdownPositionRelative
                                    name="paymentMethod"
                                    floatingLabel={
                                        financialInfoValues.paymentMethod
                                            ? 'Select payment method'
                                            : ''
                                    }
                                    initialOption="Select payment method"
                                    selectWidth="100%"
                                    errorMessage={
                                        touched.paymentMethod &&
                                        financialInfoError.paymentMethod
                                    }
                                    disabled={isAdminUser || isCustomerApproved}
                                    withCheckBox
                                    selectedValue={
                                        financialInfoValues.paymentMethod
                                    }
                                    currentSelected={
                                        financialInfoValues.paymentMethod
                                    }
                                />
                            </>
                        )}
                    </div>
                )}
            </FormWrapper>
        </>
    )
}

FinancialForm.propTypes = {
    userType: PropTypes.string,
}

export default FinancialForm
