import { createSlice } from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'

const initialState = {
    profile_type: '',
    max_cluster_members: '',
    recently_updated: '',
    recently_created: '',
    name: '',
    page: '1',
}

const clusterListing = createSlice({
    name: 'clusterListingFilter',
    initialState,
    reducers: {
        updateClusterFilter(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetClusterFilter(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateClusterFilter, resetClusterFilter } =
    clusterListing.actions

export const updatedClusterFilters = state => {
    state.clusterListingFilter
}

const persistConfig = {
    key: 'clusterListingFilter',
    storage: storageSession,
}

export const persistedClusterListingReducer = persistReducer(
    persistConfig,
    clusterListing.reducer,
)

export default clusterListing.reducer
