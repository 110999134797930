import { useState } from 'react'

export const useBuyout = () => {
    const [actionModal, setActionModal] = useState({
        open: false,
        type: '',
    }) // confirm | successful

    const openActionModal = () => {
        setActionModal(prev => ({
            open: prev.type === 'successful' ? false : true,
            type:
                prev.type === 'successful'
                    ? ''
                    : prev.type === 'confirm'
                    ? 'successful'
                    : 'confirm',
        }))
    }
    const closeActionModal = () => {
        setActionModal({
            open: false,
            type: '',
        })
    }

    return {
        actionModal,
        openActionModal,
        closeActionModal,
    }
}
