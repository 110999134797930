export const actionBuyoutModalData = {
    confirm: {
        title: 'Confirm Lease Buyout Request',
        header: 'Confirm customer lease buyout request',
        subTitle:
            'By clicking “Send request”, an email will be sent to the customer with the remaining balance and a payment request. Once the payment is completed, their Lease-to-Own plan will be finalized, the customer will have full ownership of the system.',
        actionType: 'warning',
        btnText: 'Send request',
    },
    successful: {
        title: 'Buyout request sent successfully!',
        header: 'Request sent successfully!',
        subTitle:
            'The buyout request has been sent to the customer. They will receive an email with the payment details and a link to complete the process. You’ll be notified once the payment is made.',
        actionType: 'success',
        btnText: 'Okay, got it.',
    },
}
