import { getOnboardingInfo } from 'src/utils/userOnboardingInfoManager'

export const sortByData = ['Price: low to high', 'Price: high to low', 'All']

export const productFit = {
    TECHNICAL_FIT: 'Technical Fit',
    BUDGET_FIT: 'Budget Fit',
    ECONOMY_FIT: 'Economy Fit',
}

export const sortByParam = {
    All: 'all',
    'Price: low to high': 'ascending',
    'Price: high to low': 'descending',
}

export const showClearFilter = (data, serverFilter) => {
    const systemSizeIsSame =
        data?.system_size[0] === serverFilter?.min_system_size &&
        data?.system_size[1] === serverFilter?.max_system_size
    const result =
        systemSizeIsSame &&
        data?.backup_hours[1] <= serverFilter?.min_backup_hour &&
        data?.cost[1] <= serverFilter?.min_cost &&
        data?.battery_type?.length === 0 &&
        data?.system_type?.length === 0 &&
        data?.payment_plan?.length === 0
    return result === true ? false : true
}

export const formatEntryFormDataForMixpanel = (
    data = {},
    action = 'shared-profile',
) => {
    const payload = {
        'First name': data.firstName || '',
        'Email address': data.email || '',
        'Phone number': data.phone || '',
        'Appliance count': data.applianceCount || '',
    }

    switch (action) {
        case 'shared-profile':
            return {
                ...payload,
                'Profile type': data.customerType || '',
                'Profile name': data.profileName || '',
            }
        case 'shared-package':
            return {
                ...payload,
                'Profile type': data.customerType || '',
                'Profile name': data.profileName || '',
                'Package name': data.packageName || '',
                'Package size': data.packageSize || '',
                'Package cost': data.packageCost || '',
            }
        case 'shared-cluster':
            return {
                ...payload,
                'Cluster type': data.customerType || '',
                'Cluster name': data.clusterName || '',
                'Package name': data.packageName || '',
                'Package size': data.packageSize || '',
                'Package cost': data.packageCost || '',
            }
        default:
            return payload
    }
}

export const formatProfileManagementDataForMixpanel = (
    data = {},
    action = 'view-package-list',
) => {
    const isAllSystems = location.pathname.includes('/all-systems')
    const isPromo = location.pathname.includes('/promo')

    const onboardingInfo = getOnboardingInfo()

    let packageSource = onboardingInfo?.type || ''

    if (packageSource === 'DTC') {
        packageSource = 'Profile'

        if (isAllSystems) {
            packageSource = 'View all available system'
        }

        if (isPromo) {
            packageSource = 'Promo cluster'
        }
    }

    switch (action) {
        case 'view-package-list':
            return {
                'Profile name': data.profileName || '',
                'Profile payment plan type(s)': data.planTypes || '',
                'Use type': data.customerType || '',
                'Sort by': data.sortBy || '',
                'Filter by cost': data.filterCost || '',
                'Filter by payment plan type': data.filterPaymentPlanType || '',
                'Filter by System size': data.filterSystemSize || '',
                'Filter by Battery type': data.filterBatteryType || '',
                'Filter by System type': data.filterSystemType || '',
                'Filter by System backup hours':
                    data.filterSystemBackupHours || '',
                'Number of Package lists viewed': data.visitCount || '',
                'Package sourcePackage source': packageSource || '',
            }
        case 'package-quick-view':
            return {
                'Package name': data.packageName || '',
                'Package capacity': data.packageCapacity || '',
                'Package plan type': data.planType || '',
                'Upfront deposit': data.upfrontDeposit || '',
                'Total amount': data.totalAmount || '',
                'Package sourcePackage source': packageSource || '',
            }
        case 'package-details-view':
            return {
                'Package name': data.packageName || '',
                'Package type': data.planType || '',
                'Can last up to': data.canLastUpTo,
                'Monthly payment': data.monthlyPayment,
                'Appliance count': data.applianceCount,
                'Number of available plans': data.availablePlansCount,
                'Upfront deposit': data.upfrontDeposit || '',
                'Total package amount': data.totalAmount || '',
                'Package sourcePackage source':
                    data.packageSource || packageSource || '',
            }
        default:
            return {}
    }
}
