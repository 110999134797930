import { convertToSentenceCase } from 'src/utils/formatting'

export const filterOptions = data => {
    return [
        {
            title: 'Filter by system size',
            name: 'system_size',
            type: 'range',
            min: data?.min_system_size,
            max: data?.max_system_size,
            isActive:
                data?.max_system_size > 0 &&
                data?.min_system_size !== data?.max_system_size,
        },
        {
            title: 'Filter by cost',
            name: 'cost',
            type: 'range',
            min: data?.min_cost,
            max: data?.max_cost,
            isActive: data?.max_cost > 0 && data?.min_cost !== data?.max_cost,
        },
        {
            title: 'Filter by payment plan type',
            name: 'payment_plan',
            options: data?.payment_plan?.map(item =>
                convertToSentenceCase(item.replaceAll('_', ' ').toLowerCase()),
            ),
            type: 'radio',
            isActive: data?.payment_plan?.length > 1 ? true : false,
        },
        {
            title: 'Filter by system type',
            name: 'system_type',
            options: data?.system_types?.map(item =>
                convertToSentenceCase(item.replaceAll('_', ' ')),
            ),
            type: 'radio',
            isActive: data?.system_types?.length > 1 ? true : false,
        },
        {
            title: 'Filter by battery type',
            name: 'battery_type',
            options: data?.battery_types?.map(item =>
                convertToSentenceCase(item.replaceAll('_', ' ')),
            ),
            type: 'radio',
            isActive: data?.battery_types?.length > 1 ? true : false,
        },
        {
            // TODO: this would be reverted if the DT & BE conflicts on the title here
            // title: 'Filter by system daily backup hours',
            title: 'Filter by system backup hours',
            name: 'backup_hours',
            // TODO: this would be reverted if the PM & BE conflicts on the type this filter should be
            // options: ['9-10 hours daily', '12-14 hours daily'],
            // type: 'radio',
            type: 'range',
            min: data?.min_backup_hour,
            max: data?.max_backup_hour,
            isActive:
                data?.max_backup_hour > 0 &&
                data?.min_backup_hour !== data?.max_backup_hour,
        },
        // TODO: remove this from based on pm requirement
        // {
        //     title: 'Filter by backup system options',
        //     name: 'backup_system',
        //     options: ['Battery', 'Solar panels'],
        //     type: 'radio',
        // },
    ]
}

export const actionModalValues = action => {
    switch (action) {
        case 'customer-exists':
            return {
                actionModalTitle: 'You already have an account',
                actionModalHeaderText:
                    'Welcome back, you have previously created an account',
                actionModalSubtitle:
                    "Sign in into your account to continue to view this system, once you're signed you’ll be able to view this system.",
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Sign in',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'confirm-continue-to-package':
            return {
                actionModalTitle: 'You already have a selected package',
                actionModalHeaderText: (
                    <>
                        Welcome back, would you like
                        <br />
                        to continue with your selected package?
                    </>
                ),
                actionModalSubtitle:
                    'You have previously selected a package with your created account. Your old package will be discarded if you will like to change to this new package, please confirm.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm new package',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Use old package',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}

export const mixpanelDataMap = {
    sseStartSharedProfileEntryForm: 'shared-profile',
    sseCompleteSharedProfileEntryForm: 'shared-profile',
    sseStartSharedClusterEntryForm: 'shared-cluster',
    sseCompleteSharedClusterEntryForm: 'shared-cluster',
    sseStartSharedPackageEntryForm: 'shared-package',
    sseCompleteSharedPackageEntryForm: 'shared-package',
}
