import { ReactComponent as WarningIcon } from 'src/assets/images/warning-icon.svg'
// TODO:Remove appliance option for now till api is ready
// import { ReactComponent as LayerIcon } from 'src/assets/images/Layer.svg'
// import { ReactComponent as LayerWhiteIcon } from 'src/assets/images/Layer-white.svg'
import { ReactComponent as ArrowReloadWhiteIcon } from 'src/assets/images/arrow-reload-white.svg'
import { ReactComponent as ArrowReloadBlueIcon } from 'src/assets/images/arrow-reload-blue.svg'
import { ReactComponent as QuestionMarkIcon } from 'src/assets/images/question-mark-circle.svg'
// import GroupSystem from 'src/assets/images/group-system.svg'

import BackNav from 'src/components/BackNav'
import NairaSymbol from 'src/components/NairaSymbol'

export const step2OptionsMap = {
    CHANGE: 'change',
    TAKEOUT: 'takeout',
}

export function maybeStepMapping(
    styles,
    active,
    setActive,
    data,
    toAvailablePlans,
) {
    const isChange = active === step2OptionsMap.CHANGE
    // TODO:Remove appliance option for now till api is ready
    // const isTakeout = active === step2OptionsMap.TAKEOUT
    return {
        0: {
            title: 'Close available offers',
            primaryBtnText: 'Confirm',
            secondaryBtnText: 'Go back',
            footerLeftContent: 'button',
            content: (
                <div className={styles.maybe1}>
                    <div className={styles.maybe1__content}>
                        <WarningIcon />
                        <h2>
                            Please confirm you want to close available offers
                        </h2>
                        <p>
                            Closing your available offers will not save your
                            current progress and you’ll be redirected to the
                            landing page
                        </p>
                    </div>
                </div>
            ),
        },
        1: {
            title: 'Explore more options',
            primaryBtnText: 'See other options',
            content: (
                <div className={styles.maybe1}>
                    <div className={styles.maybe1__content}>
                        <WarningIcon />
                        <h2>We can&apos;t finance your selected package</h2>
                        <p>
                            Based on our credit decision result, we regret to
                            inform you that we are unable to provide financing
                            for your selected package.{' '}
                            <strong>
                                We recommend exploring other alternative options
                                with more affordable pricing.
                            </strong>
                        </p>
                    </div>
                </div>
            ),
        },
        2: {
            title: 'Available offers for you',
            primaryBtnText: 'Confirm',
            content: (
                <div className={styles.maybe2}>
                    <div className={styles.maybe2__content}>
                        <div>
                            <BackNav
                                title={'Go back to available plans'}
                                onClick={() => {
                                    toAvailablePlans()
                                }}
                                v2
                            />
                        </div>
                        {/** TODO: Remove appliance option for now till api is ready  **/}
                        {/*<div
                            className={`${styles.maybe2__content__item} ${
                                isTakeout && styles.maybe2__active
                            }`}
                            onClick={() => setActive('takeout')}
                        >
                            {isTakeout ? (
                                <span>
                                    <LayerWhiteIcon />
                                </span>
                            ) : (
                                <span>
                                    <LayerIcon />
                                </span>
                            )}
                            <div>
                                <h4>Take out some appliances added</h4>
                                <p>
                                    Have access to new packages by removing some
                                    appliances that you added initially
                                </p>
                            </div>
                            </div>*/}
                        <div
                            className={`${styles.maybe2__content__item} ${
                                isChange && styles.maybe2__active
                            }`}
                            onClick={() => setActive('change')}
                        >
                            {isChange ? (
                                <span>
                                    <ArrowReloadWhiteIcon />
                                </span>
                            ) : (
                                <span>
                                    <ArrowReloadBlueIcon />
                                </span>
                            )}
                            <div>
                                <h4>Change to a rental plan</h4>
                                <p>
                                    Get access to the exact system you’ve
                                    selected, with the payment plan changed to a
                                    rental plan
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        3: {
            title: 'We have a new deal for you',
            primaryBtnText: 'Get this system',
            secondaryBtnText: 'Go back',
            footerLeftContent: 'button',
            content: (
                <div className={styles.maybe3}>
                    <div className={styles.maybe3__content}>
                        <div className={styles.maybe3__content__image}>
                            <img
                                src={data?.packageImageUrl}
                                alt="Group System"
                            />
                        </div>

                        <div className={styles.maybe3__content__text}>
                            <h4>
                                Hi {data?.firstName}, We have a new deal for you{' '}
                            </h4>
                            <p>
                                The same system you initially selected also has
                                a Rental plan type, you can pay{' '}
                                <strong>
                                    <NairaSymbol />
                                    {data?.monthlyPayment} monthly
                                </strong>{' '}
                                to use this exact system
                            </p>
                        </div>
                        <p className={styles.maybe3__content__question}>
                            <span>
                                <QuestionMarkIcon />
                            </span>
                            Whats the difference between Lease to own and
                            Subscription?
                        </p>
                    </div>
                </div>
            ),
        },
    }
}

export const getTimeAgo = date => {
    const givenDate = new Date(date)
    const currentDate = new Date()

    const differenceInMilliseconds = currentDate.getTime() - givenDate.getTime()

    const daysAndHours = differenceInMilliseconds / (1000 * 60 * 60 * 60)
    const days = Math.floor(daysAndHours)
    const hoursAndMinutes = (daysAndHours - days) * 24
    const hours = Math.floor(hoursAndMinutes)
    const minutes = Math.floor((hoursAndMinutes - hours) * 60)

    return {
        minutes,
        hours,
        days,
    }
}
