import residenceIcon from 'src/assets/images/residence-icon.svg'
import businessIcon from 'src/assets/images/business-icon.svg'
import { offerStatusMap } from 'src/utils/Data/status'

export const requiredResidentialInfo = [
    'Up-to-date, verified 6-12 months Bank statement',
    'Your Name',
    'Monthly Income',
    'BVN/NIN',
]

export const requiredBusinessInfo = [
    'Up-to-date, verified 12-24 months Bank statement',
    'Business Name',
    'Business Location',
    'RC number',
    'Monthly Business Revenue',
]

export const useTypeDetails = [
    {
        icon: residenceIcon,
        title: 'Get for home or unregistered business',
        description:
            'Getting the system for home or unregistered business e.g self-employed etc.',
        value: 'individual',
    },
    {
        icon: businessIcon,
        title: 'Get for Registered Business',
        description:
            'Getting the system for registered business e.g LLC, NGOs, etc.',
        value: 'business',
    },
]

export const applicationStatus = () => {
    return Object.entries(offerStatusMap).map(([status, title]) => ({
        id: status,
        payment: title,
    }))
}

export const customerTypeMapping = {
    business: 'BUSINESS',
    individual: 'RESIDENTIAL',
}

export const getActionModalData = action => {
    switch (action) {
        case 'wrong-credentials':
            return {
                title: 'Incorrect Customer Details',
                actionType: 'warning',
                headerText: 'Confirm customer details',
                subTitle: `Please be informed that this consumer has previously started creating a plan, it’s important to use the exact phone number and email address to create a new plan for them.`,
                primaryBtnText: 'Try Again',
                closable: true,
                secondaryBtnText: '',
                footerLeftContent: 'chat',
            }
        case 'prefilled-info':
            return {
                title: 'Customer information',
                actionType: 'solar-mills',
                headerText: 'We pre-filled some information for you!',
                subTitle: `This customer has started an application previously. Some of their information has been pre-filled into the form for convenience.`,
                primaryBtnText: 'Okay, got it!',
                closable: true,
                secondaryBtnText: '',
                footerLeftContent: 'chat',
            }

        default:
            return {
                title: '',
                actionType: 'warning',
                headerText: '',
                subTitle: '',
                primaryBtnText: '',
                closable: true,
                secondaryBtnText: '',
                footerLeftContent: 'chat',
            }
    }
}
