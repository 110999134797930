import { useHistory, useParams, useLocation } from 'react-router-dom'
import { errorHandler } from 'src/utils/errorHandler'
import { decode, decrypt, encrypt } from 'src/utils/cryptography'
import { useMutation } from 'react-query'
import {
    getReturningCustomerStatusApi,
    registerDropOffApi,
} from 'src/api/shoppingExperience/landingPage'
import { formatPhoneNumber } from 'src/utils/formatting'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useInitiateLoginMutation from 'src/components/ConsumerLandingPageBody/hooks/useInitiateLoginMutation'
import useValidateExistingCustomer from 'src/components/ConsumerLandingPageBody/hooks/useValidateExistingCustomer'
import useDiscardDeal from 'src/components/ConsumerLandingPageBody/hooks/useDiscardDeal'
import { useEffect, useState } from 'react'
import { pageTitle, pageTracker, pageUrlName } from '../utils'
import { decodeUserInfo } from 'src/utils/auth'

const useViewSystem = ({ energyNeeds, activePaymentModel }) => {
    const [modalAction, setModalAction] = useState('') // customer-exists | confirm-continue-to-package
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [loginModalContent, setLoginModalContent] = useState('') // select-otp-method | enter-otp
    const [otpMethod, setOtpMethod] = useState('') // email | phone
    const [loginInputs, setLoginInputs] = useState({})
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [actionModalToast, setActionModalToast] = useState(toastData)
    const [returningCustomerId, setReturningCustomerId] = useState('')
    const [initialDetailsNotProvided, setInitialDetailsNotProvided] =
        useState(false)
    const [showSharedPackageModal, setShowSharedPackageModal] = useState(true)
    const [signUpInputs, setSignUpInputs] = useState({
        firstName: '',
        phone: '',
        email: '',
        useType: '',
    })
    const [hasDiscardedDeal, setHasDiscardedDeal] = useState(false)
    const { errorAlert } = useCustomToast()
    const history = useHistory()
    const location = useLocation()

    const { id } = useParams()
    const userInfo = decodeUserInfo()
    const { validateUserIdMutation, validateUserIdLoading } =
        useValidateExistingCustomer({})
    const queryParams = new URLSearchParams(window.location?.search)

    const returningActiveOrClosedCustomer = JSON.parse(
        sessionStorage.getItem('returningActiveOrClosedCustomer'),
    )
    const clusterCode = history?.location?.state?.clusterCode ?? ''
    const isPromoCluster = location?.state?.isPromoCluster
    const isSharedCluster = location?.state?.isSharedCluster
    const isNotSharedPromoCluster = isPromoCluster && !isSharedCluster
    const customerType =
        location?.state?.customer_type ||
        (queryParams.get('customerType')?.length > 0
            ? decode(queryParams.get('customerType'))
            : '')
    const energyProfileId =
        location.state?.profile_id ||
        (queryParams.get('profile_id')?.length > 0
            ? decode(queryParams.get('profile_id'))
            : '')
    const payment_model =
        location.state?.payment_model ||
        (queryParams.get('payment_model')?.length > 0
            ? decode(queryParams.get('payment_model'))
            : null) ||
        activePaymentModel
    const isAllSystemsString =
        queryParams.get('isAllSystems')?.length > 0
            ? decode(queryParams.get('isAllSystems'))
            : null
    const isAllSystems =
        location.state?.isAllSystems || isAllSystemsString === 'true'
    const onboardingType =
        location?.state?.onboardingType ||
        (queryParams.get('onboardingType')?.length > 0
            ? decode(queryParams.get('onboardingType'))
            : null)
    const isProfilePackage =
        onboardingType === 'profiles-or-all-systems' && !isAllSystems

    const decryptedData =
        localStorage?.getItem('sunfiUserSSEGettingStartedInfo') &&
        JSON.parse(
            decrypt(localStorage?.getItem('sunfiUserSSEGettingStartedInfo')),
        )
    const useType = decryptedData?.useType
    const isPromotional = location?.state?.isPromotional
    const showReturningCustomerV2 =
        process.env.REACT_APP_FLAG_RETURNING_CUSTOMERS_V2 === 'true'
    const customerTypeMapping = {
        'Business use': 'BUSINESS',
        'Residential use': 'RESIDENTIAL',
    }

    const customerTypeRemapping = {
        BUSINESS: 'Business use',
        RESIDENTIAL: 'Residential use',
    }

    const resetToast = () => {
        setTimeout(() => {
            setActionModalToast(toastData)
        }, 2000)
    }

    const discardDealOnSuccess = () => {
        history.push(
            location.pathname.replace(
                '/shared',
                '' + `${window.location.search}`,
            ),
            {
                shared: true,
            },
        )
        window.location.reload()
    }

    const discardDealOnError = errorMsg => {
        setActionModalToast({
            showToast: true,
            toastMessage: errorMsg,
            messageType: 'error-secondary',
        })
        resetToast()
    }

    const openLoginNoDetailsProvided = () => {
        setInitialDetailsNotProvided(true)
        setLoginModalContent('select-otp-method')
        setShowLoginModal(true)
        setShowSharedPackageModal(false)
    }

    const { discardDealMutate, discardDealLoading } = useDiscardDeal({
        id: returningCustomerId,
        onSuccess: discardDealOnSuccess,
        onError: discardDealOnError,
    })

    const { mutateAsync: registerDropOffMutateAsync } = useMutation({
        mutationFn: ({ email, phone, firstName }) =>
            registerDropOffApi({
                email,
                phone_number: formatPhoneNumber(phone),
                first_name: firstName,
            }),
        onSuccess: () => {
            localStorage.setItem(
                'sunfiUserSSEGettingStartedInfo',
                encrypt(
                    JSON.stringify({
                        firstName: loginInputs.firstName,
                        email: loginInputs.email,
                        ...(loginInputs?.phone !== '' && {
                            phone: formatPhoneNumber(loginInputs?.phone),
                        }),
                    }),
                ),
            )
        },
        onError: error => {
            errorAlert(errorHandler(error?.response?.data))
        },
    })

    const openActionModal = action => {
        setModalAction(action)
        setActionModalOpen(true)
    }

    const closeActionModal = () => {
        setModalAction('')
        setActionModalOpen(false)
    }

    const closeLoginModal = () => {
        setShowLoginModal(false)
        setLoginModalContent('')
    }

    const { mutate: initiateLogin, isLoading: initiateLoginLoading } =
        useInitiateLoginMutation(
            loginInputs,
            () => {
                setLoginModalContent('enter-otp')
                setShowLoginModal(true)
                closeActionModal()
            },
            err => {
                setActionModalToast({
                    showToast: true,
                    toastMessage: errorHandler(err),
                    messageType: 'error-secondary',
                })
                resetToast()
            },
            'consumer',
            pageUrlName,
            pageTracker,
            pageTitle,
        )

    const validateReturningUserContact = async () => {
        const [emailResult, phoneResult] = await Promise.all([
            validateUserIdMutation.mutateAsync({ email: loginInputs?.email }),
            validateUserIdMutation.mutateAsync({
                phone_number: formatPhoneNumber(loginInputs?.phone),
            }),
        ])

        const emailExists = emailResult?.data?.data
        const phoneExists = phoneResult?.data?.data

        const otpMethod = emailExists ? 'email' : 'phone'
        setOtpMethod(otpMethod)

        if (emailExists && phoneExists) {
            setLoginModalContent('select-otp-method')
            setActionModalOpen(false)
            setShowLoginModal(true)
        } else {
            initiateLogin(otpMethod)
        }
    }

    const {
        mutate: getReturningCustomerStatus,
        isLoading: getReturningCustomerStatusLoading,
    } = useMutation(
        ({ email, phone }) => getReturningCustomerStatusApi(email, phone),
        {
            onSuccess: response => {
                const data = response?.data?.data
                if (data?.id) {
                    setReturningCustomerId(data.id)
                }
            },
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        },
    )

    const onLoginSuccess = async data => {
        const userFirstName = data?.user?.first_name
        const userEmail = data?.user?.email
        const userPhone = data?.user?.phone_number

        const email = initialDetailsNotProvided ? userEmail : loginInputs?.email
        const phone = initialDetailsNotProvided
            ? userPhone
            : formatPhoneNumber(loginInputs?.phone)

        if (initialDetailsNotProvided) {
            setLoginInputs({
                email: userEmail,
                phone: userPhone,
                firstName: userFirstName,
            })
        }
        await registerDropOffMutateAsync({
            email: email,
            phone: phone,
            firstName: initialDetailsNotProvided
                ? userFirstName
                : loginInputs?.firstName,
        })

        closeLoginModal()
        if (showReturningCustomerV2) {
            if (!hasDiscardedDeal) {
                openActionModal('confirm-continue-to-package')
            } else {
                history.push(
                    location.pathname.replace(
                        '/shared',
                        '' + `${window.location.search}`,
                    ),
                    {
                        shared: true,
                    },
                )
                window.location.reload()
            }
        } else {
            openActionModal('confirm-continue-to-package')
            getReturningCustomerStatus({ email, phone })
        }
    }

    const actionModalHandler = () => {
        switch (modalAction) {
            case 'customer-exists':
                validateReturningUserContact()
                break
            case 'confirm-continue-to-package':
                discardDealMutate()
                break
        }
    }

    const actionModalSecondaryHandler = () => {
        switch (modalAction) {
            case 'confirm-continue-to-package':
                history.push(userInfo?.consumerKYCHome)
                break
        }
    }

    const actionModalPrimaryBtnLoading =
        initiateLoginLoading ||
        validateUserIdLoading ||
        discardDealLoading ||
        getReturningCustomerStatusLoading
    const canCloseActionModal =
        modalAction !== 'customer-exists' &&
        modalAction !== 'confirm-continue-to-package'

    const signupPayload = {
        customer_type:
            customerTypeMapping[signUpInputs?.useType] || customerType,
        selected_solution_id: id,
        ...(clusterCode !== '' && { cluster_code: clusterCode }),
        ...(energyProfileId !== '' && { energy_profile_id: energyProfileId }),
        payment_model,
        flow: isAllSystems
            ? 'ALL_SOLUTIONS'
            : isProfilePackage
            ? 'ENERGY_PROFILE'
            : 'ENERGY_ASSESSMENT',
        ...(signUpInputs?.firstName !== '' && {
            first_name: signUpInputs.firstName,
        }),
        appliances: energyNeeds,
        email: signUpInputs?.email,
        phone_number: formatPhoneNumber(signUpInputs?.phone),
    }

    useEffect(() => {
        try {
            const data = localStorage.getItem('sunfiUserSSEGettingStartedInfo')
            if (returningActiveOrClosedCustomer?.status) {
                setSignUpInputs(prev => ({
                    ...prev,
                    email: userInfo?.email,
                    phone: userInfo?.phoneNumber?.replace('+234', 0),
                    useType: returningActiveOrClosedCustomer?.useType,
                }))
                return
            } else if (data !== null && !isNotSharedPromoCluster) {
                const decryptedData = JSON.parse(decrypt(data))
                if (decryptedData) {
                    setSignUpInputs(prev => ({
                        ...prev,
                        firstName: decryptedData?.firstName,
                        email: decryptedData?.email,
                        ...(decryptedData?.phone && {
                            phone: decryptedData?.phone?.replace('+234', 0),
                        }),
                        ...(decryptedData?.useType
                            ? {
                                  useType:
                                      customerTypeRemapping[
                                          decryptedData?.useType
                                      ],
                              }
                            : {}),
                    }))
                    return
                }
            } else {
                setSignUpInputs({
                    useType: useType,
                    email: '',
                    phone: '',
                    firstName: '',
                })
            }
        } catch (error) {
            setSignUpInputs(prev => ({
                ...prev,
                email: '',
                phone: '',
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNotSharedPromoCluster])

    return {
        modalAction,
        actionModalOpen,
        openActionModal,
        closeActionModal,
        showLoginModal,
        loginModalContent,
        setLoginModalContent,
        loginInputs,
        setLoginInputs,
        otpMethod,
        setOtpMethod,
        actionModalHandler,
        actionModalToast,
        actionModalPrimaryBtnLoading,
        actionModalSecondaryHandler,
        setReturningCustomerId,
        canCloseActionModal,
        onLoginSuccess,
        initialDetailsNotProvided,
        showSharedPackageModal,
        setShowSharedPackageModal,
        openLoginNoDetailsProvided,
        signupPayload,
        signUpInputs,
        customerType,
        isPromotional,
        hasDiscardedDeal,
        setHasDiscardedDeal,
    }
}

export default useViewSystem
