export const statusOptions = [
    {
        status: 'Active',
        filterType: 'member_status',
    },
    {
        status: 'Inactive',
        filterType: 'member_status',
    },
    {
        status: 'Due payment',
        filterType: 'due_payment',
    },
]

export const overviewFilterOptions = [
    {
        status: 'Business Profile Type',
        filterType: 'profile_type',
    },
    {
        status: 'Residential Profile Type',
        filterType: 'profile_type',
    },
    {
        status: 'Highest cluster member',
        filterType: 'max_cluster_member',
    },
    {
        status: 'Recently Updated',
        filterType: 'recently_updated',
    },
    {
        status: 'Recently Added',
        filterType: 'recently_created',
    },
]

export const paymentTypeOptions = [
    'Lease to Own',
    'Subscription',
    'Outright Sale',
]

export const profileType = {
    'Business Profile Type': 'BUSINESS',
    'Residential Profile Type': 'RESIDENTIAL',
}
