import { useMutation } from 'react-query'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { adminRequestBuyoutApi, calculateBuyoutAmountApi } from './api'

export const useCalculateBuyoutAmount = ({ onSuccess, onError, user }) => {
    const { errorAlert } = useCustomToast()
    const { data, mutate, isLoading } = useMutation({
        mutationFn: ({ estimation_id, user_id }) =>
            calculateBuyoutAmountApi({ estimation_id, user_id, user }),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
            errorAlert(errorHandler(err?.response?.data))
        },
    })

    return {
        calculateBuyoutAmountMutate: mutate,
        calculateBuyoutAmountLoading: isLoading,
        calculateBuyoutAmountData: data?.data?.data,
    }
}
export const useAdminRequestBuyout = ({ onSuccess, onError }) => {
    const { errorAlert } = useCustomToast()
    const { data, mutate, isLoading } = useMutation({
        mutationFn: ({ estimation_id, user_id }) =>
            adminRequestBuyoutApi({ estimation_id, user_id }),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
            errorAlert(errorHandler(err?.response?.data))
        },
    })

    return {
        adminRequestBuyoutMutate: mutate,
        adminRequestBuyoutLoading: isLoading,
        adminRequestBuyoutData: data?.data?.data,
    }
}
