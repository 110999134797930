import { AppButton } from 'src/components/Button'
import NairaSymbol from 'src/components/NairaSymbol'
import Pill from 'src/components/Pill'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { denyPermission } from 'src/utils/permissionFramework'
import {
    applyDynamicPermissionsToAdminUser,
    permissionsControl,
} from 'src/utils/permissionsControl'
import { reasonForSolarFormatter } from 'src/utils/reasonsForSolarFormatter'

export const canUploadSignedAgreement = permissionsControl([
    'can_upload_signed_agreement_evidence',
])

export const canUploadProviderPaymentEvidence = permissionsControl([
    'can_upload_provider_payment_evidence',
])

export const canUploadInstallationEvidence = permissionsControl([
    'can_upload_installation_evidence',
])

export const canUpdatePlanTenure = permissionsControl([
    'can_update_plan_tenure',
])

export const canCloseRequest = permissionsControl(['can_close_estimation'])

export const canUploadUpfrontDeposit = permissionsControl([
    'can_upload_upfront_deposit_evidence',
])

export const canStartRepaymentSchedule = permissionsControl([
    'can_start_repayment_schedule',
])

export const canUpdateRepaymentMethod = permissionsControl([])

export const canSetupRepaymentMethod = permissionsControl([
    'can_setup_repayment_method',
])

export const handlePermission = ({ permission, permissionKey }) => {
    return applyDynamicPermissionsToAdminUser && permission !== undefined
        ? !permission
        : denyPermission('admin', 'approved_requests', permissionKey)
}

export const planDetails = data => {
    const approvedRequest = data?.approval_status === 'APPROVED'
    const selectedPlan = data?.repayment_plans?.find(
        plans => plans.selected === true,
    )
    const approvedPlan = data?.repayment_plans?.find(
        plans => plans.admin_approved === true,
    )
    // display approved plan if its an approved request else display selected plan
    let plan
    if (approvedRequest) {
        plan = approvedPlan
    } else {
        plan = selectedPlan
    }
    const arr = [
        {
            label: 'Payment Plan',
            value: data?.payment_plan,
        },
        {
            label: 'Solution Amount',
            value: plan?.solution_amount
                ? ` ₦ ${currencyFormatter(plan?.solution_amount)}`
                : 'N/A',
        },
        {
            label: 'Initial Deposit',
            value: plan?.initial_deposit.length
                ? `₦ ${plan?.initial_deposit}`
                : 'N/A',
        },
        {
            label: 'Financing Amount',
            value: plan?.amount_to_be_financed.length
                ? `₦ ${plan?.amount_to_be_financed}`
                : 'N/A',
        },
        {
            label: 'Monthly Repayment',
            value: plan?.monthly_payment.length
                ? `₦ ${plan?.monthly_payment}`
                : 'N/A',
        },
        {
            label: 'Tenure',
            value: plan?.tenure ? `${plan?.tenure} Months` : 'N/A',
        },
        {
            label: 'Declining Balance Rate',
            value: plan?.investment_meta_data?.annual_interest ?? 'N/A',
        },
        {
            label: 'Finance Charge',
            value: plan?.investment_meta_data?.actual_ann_lzd ?? 'N/A',
        },
    ]

    if (data.payment_plan === 'Subscription') {
        return arr.filter(a => !['Tenure'].includes(a.label))
    }

    return arr
}
export const MoreActions = data => {
    const actionsData = [
        {
            keyID: 'SUNFI_AGREEMENT',
            label: 'SunFi Agreement',
            moreActionsLabel: 'Signed Agreement',
            disabled:
                Object.entries(data?.signed_agreement).length === 0
                    ? false
                    : true,
            statusBox:
                Object.entries(data?.signed_agreement).length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                Object.entries(data?.signed_agreement).length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                Object.entries(data?.signed_agreement).length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.agreement_signed_at,
            btnText:
                Object.entries(data?.signed_agreement).length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle: 'Upload Signed Agreement',
            modalSubTitle: 'Enter the details of the signed agreement',
            uploadText: 'Signed Agreement',
            actionEvidence: data?.signed_agreement?.secure_url,
            permission: {
                ...(Object.entries(data?.signed_agreement).length === 0 && {
                    permission: canUploadSignedAgreement,
                }),

                permissionKey:
                    Object.entries(data?.signed_agreement).length === 0
                        ? 'signed_agreement:can_upload'
                        : 'signed_agreement:can_view',
            },
        },
        {
            keyID: 'REPAYMENT_METHOD_SETUP',
            label: 'Repayment Method Setup',
            moreActionsLabel: 'Repayment Method Setup',
            disabled:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? false
                    : true,
            statusBox:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? null
                    : data?.repayment_method_setup?.created_at,
            btnText:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle: 'Repayment Method Setup',
            modalSubTitle: 'Enter the details of the repayment method setup',
            uploadText: 'Evidence of Repayment Method Setup (Standing Order)',
            methodType: data?.repayment_method_setup?.type,
            methodSubscriptionCode:
                data?.repayment_method_setup?.type ===
                'PAYSTACK:PLAN_SUBSCRIPTION'
                    ? data?.repayment_method_setup?.subscription_code
                    : null,
            actionEvidence:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? null
                    : data?.repayment_method_setup?.secure_url,
            permission: {
                ...(Object.entries(data?.repayment_method_setup).length ===
                    0 && {
                    permission: canSetupRepaymentMethod,
                }),
                permissionKey:
                    Object.entries(data?.repayment_method_setup).length === 0
                        ? 'repayment_method_setup:can_upload'
                        : 'repayment_method_setup:can_view',
            },
        },
        {
            keyID: 'PROVIDER_PAYMENT',
            label: 'Provider Payment',
            moreActionsLabel: 'Payment Evidence',
            disabled:
                Object.entries(data?.evidence_of_provider_payment).length === 0
                    ? false
                    : true,
            statusBox:
                Object.entries(data?.evidence_of_provider_payment).length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                Object.entries(data?.evidence_of_provider_payment).length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                Object.entries(data?.evidence_of_provider_payment).length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.provider_received_payment_at,
            btnText:
                Object.entries(data?.evidence_of_provider_payment).length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle: 'Payment Evidence',
            modalSubTitle:
                'Enter the details of the payment received by the provider',
            uploadText: 'Evidence of Payment',
            actionEvidence: data?.evidence_of_provider_payment?.secure_url,
            permission: {
                ...(Object.entries(data?.evidence_of_provider_payment)
                    .length === 0 && {
                    permission: canUploadProviderPaymentEvidence,
                }),
                permissionKey:
                    Object.entries(data?.evidence_of_provider_payment)
                        .length === 0
                        ? 'provider_payment:can_upload'
                        : 'provider_payment:can_view',
            },
        },
        {
            keyID: 'SOLUTION_INSTALLED',
            label: 'Solution Installed',
            moreActionsLabel: 'Installation Evidence',
            disabled:
                data?.evidence_of_solution_installed.length === 0
                    ? false
                    : true,
            statusBox:
                data?.evidence_of_solution_installed.length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                data?.evidence_of_solution_installed.length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                data?.evidence_of_solution_installed.length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.solution_installed_at,
            btnText:
                data?.evidence_of_solution_installed.length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle: 'Installation Evidence',
            modalSubTitle: 'Enter the details of the installed solution',
            uploadText: 'Attach Evidence',
            actionEvidence: data?.evidence_of_solution_installed,
            permission: {
                ...(data?.evidence_of_solution_installed?.length === 0 && {
                    permission: canUploadInstallationEvidence,
                }),
                permissionKey:
                    data?.evidence_of_solution_installed.length === 0
                        ? 'solution_installed:can_upload'
                        : 'solution_installed:can_view',
            },
        },
    ]
    data?.repayment_schedule.filter(repayment => repayment.status === 'PAID')
        ?.length === 0 &&
        actionsData.splice(
            4,
            0,
            ...[
                {
                    keyID: 'UPDATE_PLAN_TENURE',
                    label: 'Update Plan Tenure',
                    moreActionsLabel: 'Update Plan Tenure',
                    modalTitle: 'Update Plan Tenure',
                    modalSubTitle: 'Select a new plan tenure below to continue',
                    statusMsg: 'Not Applicable',
                    uploadText: 'None',
                    buttonText: 'Update Plan Tenure',
                    permission: {
                        permission: canUpdatePlanTenure,
                        permissionKey: 'plan_tenure:can_update',
                    },
                },
                {
                    keyID: 'CLOSE_PLAN',
                    label: 'Close Payment Plan/Request',
                    moreActionsLabel: `Close ${
                        data?.survey_type === 'DFS' ? 'Payment Plan' : 'Request'
                    }`,
                    modalTitle: `Close ${
                        data?.survey_type === 'DFS' ? 'Payment Plan' : 'Request'
                    }`,
                    modalSubTitle:
                        'Enter the reason for closure below to continue',
                    statusMsg: 'Not Applicable',
                    uploadText: 'None',
                    buttonText: `Close ${
                        data?.survey_type === 'DFS' ? 'Plan' : 'Request'
                    }`,
                    permission: {
                        permission: canCloseRequest,
                        permissionKey: 'payment_plan:can_close',
                    },
                },
            ],
        )
    data?.repayment_method_setup?.is_active !== true &&
        actionsData.push({
            keyID: 'REQUEST_PAYMENT_SETUP',
            label: 'Request Repayment Method Setup',
            moreActionsLabel: 'Request Repayment Method Setup',
            modalTitle: 'Request Repayment Method Setup',
            modalSubTitle: 'Enter the details of the request below to continue',
            statusMsg: 'Not Applicable',
            uploadText: 'None',
            buttonText: 'Send Request',
            permission: {
                permission: canSetupRepaymentMethod,
                permissionKey: 'repayment_method_setup:can_request',
            },
        })
    Object.keys(data?.repayment_method_setup).length !== 0 &&
        data?.repayment_method_setup?.is_active === true &&
        actionsData.push({
            keyID: 'REQUEST_PAYMENT_SETUP',
            label: 'Cancel Repayment Method Setup',
            moreActionsLabel: 'Cancel Repayment Method Setup',
            modalTitle: 'Cancel Repayment Method Setup',
            modalSubTitle: 'Enter the details of the request below to continue',
            statusMsg: 'Not Applicable',
            uploadText: 'None',
            buttonText: 'Cancel Request',
            permission: {
                permissionKey: 'repayment_method_setup:can_request',
            },
        })

    Object.keys(data?.repayment_method_setup).length !== 0 &&
        actionsData.push({
            keyID: 'UPDATE_REPAYMENT_SETUP',
            label: 'Update Repayment Setup',
            moreActionsLabel: 'Update Repayment Setup',
            disabled:
                Object.entries(data?.repayment_method_setup).length >= 0
                    ? false
                    : true,
            statusBox:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? null
                    : data?.repayment_method_setup?.created_at,
            btnText:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? 'Upload'
                    : '',
            modalTitle: 'Update Repayment Method Setup',
            modalSubTitle:
                'Only update the field the consumer has requested an update on',
            uploadText: 'Evidence of Repayment Method Setup (Standing Order)',
            methodType: data?.repayment_method_setup?.type,
            methodSubscriptionCode:
                data?.repayment_method_setup?.type ===
                'PAYSTACK:PLAN_SUBSCRIPTION'
                    ? data?.repayment_method_setup?.subscription_code
                    : null,
            actionEvidence:
                Object.entries(data?.repayment_method_setup).length === 0
                    ? null
                    : data?.repayment_method_setup?.secure_url,
            permission: {
                ...(Object.entries(data?.repayment_method_setup).length ===
                    0 && {
                    permission: canSetupRepaymentMethod,
                }),
                permissionKey:
                    Object.entries(data?.repayment_method_setup).length === 0
                        ? 'repayment_method_setup:can_upload'
                        : 'repayment_method_setup:can_view',
            },
        })

    process.env.REACT_APP_FLAG_PAYMENT_COLLECTION_ENHANCEMENT_BUYOUT ===
        'true' &&
        data?.payment_plan === 'Lease to Own' &&
        data?.upfront_deposit_paid_at !== null &&
        data?.repayment_schedule.length > 0 &&
        actionsData.splice(2, 0, {
            keyID: 'REQUEST_BUY_OUT',
            label: 'Request Buy Out',
            moreActionsLabel: 'Request Buy Out',
            modalTitle: 'Request Lease-to-Own buyout',
            modalSubTitle: 'Enter the details of the request below to continue',
            statusMsg: 'Not Applicable',
            uploadText: 'None',
            buttonText: 'Send Request',
            permission: {
                permission: undefined,
                permissionKey: '',
            },
        })

    return actionsData
}

export const outrightSaleMoreActions = data => {
    const actionsData = [
        {
            keyID: 'SUNFI_AGREEMENT',
            label: 'SunFi Agreement',
            moreActionsLabel: 'Signed Agreement',
            disabled:
                Object.entries(data?.signed_agreement ?? {}).length === 0
                    ? false
                    : true,
            statusBox:
                Object.entries(data?.signed_agreement ?? {}).length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                Object.entries(data?.signed_agreement ?? {}).length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                Object.entries(data?.signed_agreement ?? {}).length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.agreement_signed_at,
            btnText:
                Object.entries(data?.signed_agreement ?? {}).length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle: 'Upload Signed Agreement',
            modalSubTitle: 'Enter the details of the signed agreement',
            uploadText: 'Signed Agreement',
            actionEvidence: data?.signed_agreement?.secure_url,
            permission: {
                permissionKey:
                    Object.entries(data?.signed_agreement ?? {}).length === 0
                        ? 'signed_agreement:can_upload'
                        : 'signed_agreement:can_view',
            },
            notAllowed:
                data.application_status === 'AWAITING_PAYMENT' ? true : false,
            notAllowedReason:
                'This consumer payment has not been completed. A signed agreement cannot be uploaded yet',
        },
        {
            keyID: 'MARK_A_PAYMENT',
            label: 'Record Payment',
            moreActionsLabel: 'Payment Evidence',
            disabled:
                data.application_status === 'AWAITING_PAYMENT' ? false : true,
            statusBox:
                data.application_status === 'AWAITING_PAYMENT'
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                data.application_status === 'AWAITING_PAYMENT'
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                data.application_status === 'AWAITING_PAYMENT'
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.outright_payment_evidence?.paid_at
                ? data?.outright_payment_evidence?.paid_at
                : null,
            btnText:
                data.application_status === 'AWAITING_PAYMENT'
                    ? 'Upload'
                    : 'View',
            methodOfPayment: data?.outright_payment_evidence?.payment_method,
            modalTitle: 'Record Payment',
            modalSubTitle:
                "Enter the details of the consumer's outright payment",
            uploadText: 'Evidence of Payment',
            actionEvidence:
                data?.outright_payment_evidence?.repayment_evidence_url,
            permission: {
                permissionKey:
                    data.application_status === 'AWAITING_PAYMENT'
                        ? 'record_payment:can_upload'
                        : 'record_payment:can_view',
            },
        },
        {
            keyID: 'SOLUTION_INSTALLED',
            label: 'Solution Installed',
            moreActionsLabel: 'Installation Evidence',
            disabled:
                data?.evidence_of_solution_installed?.length === 0
                    ? false
                    : true,
            statusBox:
                data?.evidence_of_solution_installed?.length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                data?.evidence_of_solution_installed?.length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                data?.evidence_of_solution_installed?.length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.solution_installed_at,
            btnText:
                data?.evidence_of_solution_installed?.length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle: 'Installation Evidence',
            modalSubTitle: 'Enter the details of the installed solution',
            uploadText: 'Attach Evidence',
            actionEvidence: data?.evidence_of_solution_installed,
            permission: {
                permissionKey:
                    data?.evidence_of_solution_installed?.length === 0
                        ? 'solution_installed:can_upload'
                        : 'solution_installed:can_view',
            },
            notAllowed:
                data.application_status === 'AWAITING_PAYMENT' ? true : false,
            notAllowedReason:
                'This consumer payment has not been completed. Installation evdience cannot be uploaded yet',
        },
    ]
    return actionsData
}

export const repaymentMethods = [
    { value: 'Paystack plan subscription' },
    {
        value: 'Bank transfer (Standing order)',
    },
    {
        value: 'Bank transfer (Admin manual entry)',
    },
]
export const repaymentMethodValues = {
    'Paystack plan subscription': 'PAYSTACK:PLAN_SUBSCRIPTION',
    'Bank transfer (Standing order)': 'BANK_TRANSFER:STANDING_ORDER',
    'Bank transfer (Admin manual entry)': 'BANK_TRANSFER:ADMIN_MANUAL_ENTRY',
}
export const whoToUpdateData = [
    { value: 'Update done by consumer' },
    {
        value: 'Update done by admin',
    },
]
export const repaymentActions = [
    { value: 'Change repayment day' },
    { value: 'Change repayment schedule' },
    { value: 'Select new repayment method' },
]

export const formatConsumerInfoTableData = data => {
    const arr = [
        {
            title: 'Payment plan type',
            key: 'payment-plan-type',
            value: <Pill text={data?.payment_model} /> || '--',
        },
        {
            title: 'Location',
            key: 'location',
            value: data?.state_of_installation || '--',
        },
        {
            title: 'Monthly spend on generator',
            key: 'monthly-spend-on-generator',
            value: data?.generator_maintenance_expense ? (
                <>
                    <NairaSymbol />{' '}
                    {Number(
                        data.generator_maintenance_expense,
                    ).toLocaleString()}
                </>
            ) : (
                '--'
            ),
        },
        {
            title: 'Reason for solar need',
            key: 'reason-for-solar-need',
            value: reasonForSolarFormatter[data?.solution_reason] || '--',
        },
        {
            title: 'Pay/Month',
            key: 'pay-month',
            value: data?.feasible_monthly_payment ? (
                <>
                    <NairaSymbol />{' '}
                    {Number(data.feasible_monthly_payment).toLocaleString()}
                </>
            ) : (
                '--'
            ),
        },
        {
            title: 'Electricity/Day',
            key: 'electricity-day',
            value: data?.gets_required_grid_hours + ' Hours' || '--',
        },
        {
            title: 'Authorized to mount solar panel',
            key: 'authorized-to-mount-solar-panel',
            value: data?.can_mount_solar ? 'Yes' : 'No',
        },
    ]
    return arr
}

export const formatInfoFromDep = data => {
    let formattedValues = []
    formattedValues.push(
        {
            id: 1,
            label: 'Full Name',
            value:
                data?.consumer?.user?.first_name +
                ' ' +
                data?.consumer?.user?.last_name,
        },
        { id: 2, label: 'Email Address', value: data?.consumer?.user?.email },
        {
            id: 3,
            label: 'Phone Number',
            value: data?.consumer?.user?.phone_number,
        },
        {
            id: 4,
            label: 'Energy Needs (Appliances)',
            value: data?.appliances?.length,
        },
        {
            id: 5,
            label: 'Customer Acquisition',
            value: data?.customer_acquisition,
        },
        { id: 6, label: 'Repayment Method', value: data?.repayment_method },
        {
            id: 7,
            label: 'Upfront Deposit',
            value:
                data?.upfront_deposit_percentage !== 0
                    ? data?.upfront_deposit_percentage + '%'
                    : 'Not Set',
        },

        {
            id: 8,
            label: 'Tenure of Payment',
            value: data?.financing_term
                ? data?.financing_term + ' ' + 'Months'
                : 'Not Set',
        },
        {
            id: 9,
            label: 'System’s Ease of Recovery',
            value: data?.system_ease_of_recovery
                ? data?.system_ease_of_recovery
                : 'Not Set',
        },
        {
            id: 10,
            label: 'Remote Monitoring Capabilities',
            value: data?.remote_monitoring_capabilities
                ? data?.remote_monitoring_capabilities
                : 'Not Set',
        },
        {
            id: 11,
            label: 'Payment Plan Type',
            value: data?.payment_plan ? data?.payment_plan : 'Not Set',
        },
        {
            id: 12,
            label: 'Provider Performed Credit Check',
            value: data?.ran_credit_check === false ? 'No' : 'Yes',
        },
        data?.ran_credit_check === true && {
            id: 13,
            label: 'Credit Check Proof',
            value: (
                <AppButton
                    btnTextColor={'#004AAD'}
                    btnBgColor={'#E2EEFF'}
                    type="buttonTwo"
                >
                    <a
                        href={data?.credit_check_proof}
                        rel="noreferrer"
                        target="_blank"
                    >
                        View
                    </a>
                </AppButton>
            ),
        },
    )

    return formattedValues.filter(Boolean)
}
