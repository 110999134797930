import PropTypes from 'prop-types'
import { heardAboutUsOptions, urgencyOptions } from './data'
import ButtonComponent from 'src/components/Button'
import s from './selectusetype.module.scss'
import { Link } from 'react-router-dom'
import { InlineLoader } from 'src/components/Loader'
import { MultipleSelectField, SelectField } from 'src/components/InputFields'
import { landingPageEvents } from 'src/utils/mp-event-tracker/landing-page'
import { appTracking } from 'src/utils/appTracker'
import { isMobile } from 'src/utils/mediaQueries'

const SelectUseType = ({
    handleGetStarted,
    errors,
    setErrors,
    useTypeFormInputs,
    setUseTypeFormInputs,
    handleCheckReturningCustomerStatus,
    allowReturningCustomers,
    getReturningCustomerStatusLoading,
    handleTrackEntryFormActivity,
}) => {
    const handleSelectChange = (name, value) => {
        setUseTypeFormInputs(prev => ({
            ...prev,
            [name]: value,
        }))

        handleTrackEntryFormActivity(
            'sseStartLandingPageEntryForm',
            name,
            value,
        )
    }

    // app tracker variables
    const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'View consumer page'

    const clearError = errName => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[errName]
            return newErrors
        })
    }

    return (
        <div>
            <SelectField
                name={'urgency'}
                initialOption="When do you need the solar installation?"
                selectWidth={'100%'}
                marginBottom={'20px'}
                values={urgencyOptions?.map(option => ({
                    value: option,
                }))}
                value={useTypeFormInputs.urgency}
                currentSelected={useTypeFormInputs.urgency}
                handleChange={(name, value) => {
                    handleSelectChange(name, value)
                    clearError('urgencyErr')
                    clearError('urgency')
                }}
                withCheckBox
                floatingLabel={
                    useTypeFormInputs?.urgency !== '' &&
                    'When do you need the solar installation?'
                }
                errorMessage={errors?.urgency}
                dropdownPositionRelative
            />
            <MultipleSelectField
                title="How did you hear about us?"
                selectWidth="100%"
                name="referral_source"
                width="100%"
                values={heardAboutUsOptions}
                value={useTypeFormInputs?.referral_source?.map(item => item)}
                inputValue={useTypeFormInputs?.referral_source?.map(
                    item => item,
                )}
                errorMessage={errors?.referral_source}
                dropDownWidth={isMobile ? '300px' : '380px'}
                dropdownMatchSelectWidth={false}
                handleMultipleSelectChange={value => {
                    setUseTypeFormInputs(prev => ({
                        ...prev,
                        referral_source: value,
                    }))
                    handleTrackEntryFormActivity(
                        'sseStartLandingPageEntryForm',
                        'referral_source',
                        value,
                    )
                    clearError('referralSourceErr')
                    clearError('referral_source')
                }}
                contentField="label"
                maxTagCount="responsive"
                floatingLabel={
                    !!useTypeFormInputs?.referral_source?.length &&
                    'How did you hear about us?'
                }
            />

            <div className={s.newform}>
                {errors?.useType && (
                    <p className={s.error}>{errors?.useType}</p>
                )}
                <ButtonComponent
                    btnBgColor={'#004AAD'}
                    btnTextColor={'#FFFFFF'}
                    btnWidth={'100%'}
                    btnHeight={'56px'}
                    marginTop={'10px'}
                    handleClick={() => {
                        if (allowReturningCustomers) {
                            handleCheckReturningCustomerStatus()
                        } else {
                            handleGetStarted()
                        }
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            landingPageEvents['getStarted'].action,
                            landingPageEvents['getStarted'].label,
                            landingPageEvents['getStarted'].category,
                            ['MP', 'GA'],
                            'event',
                        )
                    }}
                >
                    {getReturningCustomerStatusLoading ? (
                        <InlineLoader />
                    ) : (
                        'Get started'
                    )}
                </ButtonComponent>
            </div>

            <p className={s.disclaimer}>
                By clicking &quot;Get started&quot;, you agree
                <br />
                to SunFi’s{' '}
                <Link to="/terms-of-service" target="_blank">
                    Terms of use
                </Link>{' '}
                &{' '}
                <Link to="/privacy-policy" target="_blank">
                    Privacy policy
                </Link>
            </p>
        </div>
    )
}

SelectUseType.propTypes = {
    handleGetStarted: PropTypes.func,
    handleViewAllSystems: PropTypes.func,
    useType: PropTypes.string,
    setUseType: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    getStartedBtnLoading: PropTypes.bool,
    viewAllSolutionsBtnLoading: PropTypes.bool,
    useTypeFormInputs: PropTypes.object,
    setUseTypeFormInputs: PropTypes.func,
    handleCheckReturningCustomerStatus: PropTypes.func,
    allowReturningCustomers: PropTypes.bool,
    getReturningCustomerStatusLoading: PropTypes.bool,
    handleTrackEntryFormActivity: PropTypes.func,
}

export default SelectUseType
