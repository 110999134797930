import axios from '../../config/axios'

export const initiateSSESignup = async payload => {
    const request = axios.post(`/sse-auth/signup/initiate`, payload)
    return request
}

export const initiateReturningUserSignup = async (payload, v2) => {
    const request = axios.post(
        `/returning-consumers/signup/${v2 ? 'instigate' : 'initiate'}`,
        payload,
    )
    return request
}

export const validateSSESignupOTP = async payload => {
    const request = axios.post(`/sse-auth/validate-otp`, payload)
    return request
}

export const validateKYCAttempt = async payload => {
    const request = axios.post(
        `/sse-auth/validate-ab-testing-consumer`,
        payload,
    )
    return request
}

export const resendSSESignupOTP = async payload => {
    const request = axios.post(`/sse-auth/signup/resend-otp`, payload)
    return request
}

export const proceedKYCLater = async payload => {
    const request = axios.post(`/sse-auth/proceed-later`, payload)
    return request
}

export const instigateSSESignup = async payload => {
    const request = axios.post(`/sse-auth/signup/instigate`, payload)
    return request
}
