import helpIcon from 'src/assets/images/help-circle-black.svg'
import PropTypes from 'prop-types'
import { Tooltip, Skeleton } from 'antd'
import { useSSERetrieveBasicInfo } from 'src/api/shoppingExperience/kyc/queries'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { useEffect, useState } from 'react'
import failIcon from 'src/assets/images/circle-close-red.svg'
import passIcon from 'src/assets/images/check-green.svg'
import { useSSEAdminIdentityCompare } from 'src/api/shoppingExperience/identity-verification/queries'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import styles from './kycAnalysis.module.scss'

const KycAnalysisSummary = ({ title, handleOverride, page, inputs }) => {
    const { sseBasicInfoData, sseBasicInfoFetching } = useSSERetrieveBasicInfo({
        enabled: true,
    })
    const bvnInfo =
        sseBasicInfoData?.data?.data?.financial_verification?.bvn?.context
    const bvnID = sseBasicInfoData?.data?.data?.financial_verification?.bvn?.id
    const customerSelfie =
        sseBasicInfoData?.data?.data?.consumer?.user?.avatar_url
    const imageAnalysis =
        sseBasicInfoData?.data?.data?.consumer?.user?.avatar_meta_data
    const isFaceDetected =
        imageAnalysis?.facial_recognition?.single_face_detected
    const isFaceRecognized = bvnInfo?.comparison_data?.find(
        e => e.criteria === 'photo',
    )?.match
    const [showBvnImageModal, setShowBvnImageModal] = useState(false)
    const [showUserImageModal, setShowUserImageModal] = useState(false)
    const [showAnalysisModal, setShowAnalysisModal] = useState(false)
    const [showImageComparisonModal, setShowImageComparisonModal] =
        useState(false)
    const allChecksPassed = bvnInfo?.all_checks_passed
    const allFailedPropertyValues = [
        bvnInfo?.comparison_data?.find(rep => rep?.criteria === 'dob')?.match,
        bvnInfo?.comparison_data?.find(rep => rep?.criteria === 'gender')
            ?.match,
        isFaceRecognized,
        isFaceDetected,
    ].filter(value => !value)

    const { errorAlert } = useCustomToast()

    const { submitKycResidentialIdentityCompare } = useSSEAdminIdentityCompare({
        onSuccess: () => {},
        onError: error => {
            errorAlert(
                errorHandler(
                    error?.response?.data ||
                        'An error occurred, please try again later.',
                ),
            )
        },
    })

    useEffect(() => {
        if (!sseBasicInfoFetching) {
            if (
                bvnInfo?.comparison_data.length < 1 &&
                inputs?.selfieImageUrl !== ''
            ) {
                submitKycResidentialIdentityCompare(bvnID)
            }
        }
    }, [
        sseBasicInfoFetching,
        bvnID,
        bvnInfo,
        submitKycResidentialIdentityCompare,
        inputs?.selfieImageUrl,
    ])

    const PassedCheck = () => {
        return (
            <div className={styles.checkContainer}>
                <span>
                    <img src={passIcon} />
                </span>
                <p>Passed</p>
            </div>
        )
    }

    const NoMatchCheck = () => {
        return (
            <div className={styles.checkContainer}>
                <span>
                    <img src={failIcon} />
                </span>
                <p>No Match</p>
            </div>
        )
    }

    const handleOverrideClick = () => {
        handleOverride()
        setShowAnalysisModal(false)
        setShowImageComparisonModal(false)
    }

    return (
        <>
            <SecondaryModal
                modalWidth="500px"
                onCancel={() => setShowAnalysisModal(false)}
                showModal={showAnalysisModal}
                showFooter={true}
                title="KYC Analysis"
                v2
                content={
                    <div className={styles.override_analysis_container}>
                        <div className={styles.failed_properties_container}>
                            <div>
                                <p className={styles.failed_props}>
                                    <span className={styles.number}>
                                        {allFailedPropertyValues.length}
                                    </span>
                                    <span>
                                        Propert
                                        {allFailedPropertyValues.length > 1
                                            ? 'ies'
                                            : 'y'}{' '}
                                        failed
                                    </span>
                                </p>
                            </div>
                            <button
                                className={styles.override_all_btn}
                                onClick={handleOverrideClick}
                            >
                                Override failed propert
                                {allFailedPropertyValues.length > 1
                                    ? 'ies'
                                    : 'y'}{' '}
                            </button>
                        </div>
                        <div className={styles.property_details_container}>
                            <div className={styles.heading}>
                                <div className={styles.property}>PROPERTY</div>
                                <div className={styles.profile}>PROFILE</div>
                                <div className={styles.bvn}>BVN</div>
                                <div className={styles.status}>STATUS</div>
                            </div>
                            <div className={styles.flex}>
                                <div className={styles.property}>Age</div>
                                <div
                                    className={styles.profile}
                                >{`${imageAnalysis?.facial_recognition?.age_analysis?.value[0]} - ${imageAnalysis?.facial_recognition?.age_analysis?.value[1]}`}</div>
                                <div className={styles.bvn}>
                                    {new Date().getFullYear() -
                                        bvnInfo?.verified_data?.dob.slice(-4)}
                                </div>
                                <div className={styles.status}>
                                    {bvnInfo?.comparison_data?.find(
                                        rep => rep?.criteria === 'dob',
                                    )?.match === false &&
                                    page !== 'business-verification' ? (
                                        <div className="">
                                            <img src={failIcon} />
                                        </div>
                                    ) : (
                                        <div className="">
                                            <img src={passIcon} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.flex}>
                                <div className={styles.property}>Gender</div>
                                <div className={styles.profile}>
                                    {
                                        imageAnalysis?.facial_recognition
                                            ?.gender_analysis?.value
                                    }
                                </div>
                                <div className={styles.bvn}>
                                    {bvnInfo?.verified_data?.gender}
                                </div>
                                <div className={styles.status}>
                                    {bvnInfo?.comparison_data?.find(
                                        rep => rep?.criteria === 'gender',
                                    )?.match === false &&
                                    page !== 'business-verification' ? (
                                        <div className="">
                                            <img src={failIcon} />
                                        </div>
                                    ) : (
                                        <div className="">
                                            <img src={passIcon} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.flex}>
                                <div className={styles.property}>
                                    Face recognition
                                </div>
                                <div className={styles.profile}>-</div>
                                <div className={styles.bvn}>
                                    {isFaceRecognized ? 'Matched' : 'Failed'}
                                </div>
                                <div className={styles.status}>
                                    {isFaceRecognized ? (
                                        <div className="">
                                            <img src={passIcon} />
                                        </div>
                                    ) : (
                                        <div className="">
                                            <img src={failIcon} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.flex}>
                                <div className={styles.property}>
                                    Face detection
                                </div>
                                <div className={styles.profile}>
                                    {isFaceDetected ? 'Matched' : 'Failed'}
                                </div>
                                <div className={styles.bvn}>-</div>
                                <div className={styles.status}>
                                    {isFaceDetected ? (
                                        <div className="">
                                            <img src={passIcon} />
                                        </div>
                                    ) : (
                                        <div className="">
                                            <img src={failIcon} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.image_section}>
                            <div className={styles.pictures}>
                                <div className={styles.picture_wrapper}>
                                    <img
                                        src={customerSelfie}
                                        alt="Profile Image"
                                    />
                                    <p>Profile Image</p>
                                </div>
                                <div className={styles.picture_wrapper}>
                                    <img
                                        src={
                                            'data:image/png;base64,' +
                                            bvnInfo?.verified_data?.photoId
                                        }
                                        alt="Image returned from BVN"
                                    />
                                    <p>BVN Image</p>
                                </div>
                            </div>
                            <button
                                className={styles.override_all_btn}
                                onClick={() => {
                                    setShowImageComparisonModal(true)
                                    setShowAnalysisModal(false)
                                }}
                            >
                                Compare Images
                            </button>
                        </div>
                    </div>
                }
                footerLeftContent="button"
                secondaryBtnText="Cancel"
                handleSecondaryBtnClick={() => setShowAnalysisModal(false)}
                primaryBtnText="Continue"
                handlePrimaryBtnClick={handleOverrideClick}
            />
            <SecondaryModal
                modalWidth="478px"
                onCancel={() => {
                    setShowImageComparisonModal(false)
                    setShowAnalysisModal(true)
                }}
                showModal={showImageComparisonModal}
                showFooter={true}
                title="KYC Analysis"
                v2
                content={
                    <div className={styles.image_comparison}>
                        <div className={styles.photo_container}>
                            <img src={customerSelfie} alt="Profile Image" />
                            <p>Profile Image</p>
                        </div>
                        <div className={styles.photo_container}>
                            <img
                                src={
                                    'data:image/png;base64,' +
                                    bvnInfo?.verified_data?.photoId
                                }
                                alt="Image returned from BVN"
                            />
                            <p>BVN Image</p>
                        </div>
                    </div>
                }
                footerLeftContent="button"
                secondaryBtnText="Cancel"
                handleSecondaryBtnClick={() => {
                    setShowImageComparisonModal(false)
                    setShowAnalysisModal(true)
                }}
                primaryBtnText="Continue"
                handlePrimaryBtnClick={handleOverrideClick}
            />
            <SecondaryModal
                v2
                showModal={showBvnImageModal}
                onCancel={() => setShowBvnImageModal(false)}
                modalHeight="450px"
                modalWidth="550px"
                modalClass="adminModal"
                closable
                title="Image returned"
                content={
                    <>
                        <div className={styles.imageModalContainer}>
                            <div className={styles.imageModalContainer}>
                                <img
                                    src={
                                        'data:image/png;base64,' +
                                        bvnInfo?.verified_data?.photoId
                                    }
                                    alt="Image returned from BVN"
                                />
                            </div>
                        </div>
                    </>
                }
            />
            <SecondaryModal
                v2
                showModal={showUserImageModal}
                onCancel={() => setShowUserImageModal(false)}
                modalHeight="450px"
                modalWidth="550px"
                modalClass="adminModal"
                closable
                title="Image returned"
                content={
                    <>
                        <div className={styles.imageModalContainer}>
                            <img
                                src={customerSelfie}
                                alt="Image returned from BVN"
                            />
                        </div>
                    </>
                }
            />
            <div>
                {bvnInfo && (
                    <div
                        className={styles.bvnDataContainer}
                        style={{
                            margin:
                                page === 'business-verification'
                                    ? '10px 0'
                                    : '',
                            border:
                                page === 'business-verification'
                                    ? '1px solid #e2eeff'
                                    : '',
                        }}
                    >
                        <div className={styles.titleSection}>
                            <div className={styles.title}>
                                <h3>{title}</h3>
                                {page !== 'business-verification' && (
                                    <Tooltip
                                        overlayStyle={{
                                            padding: '9px 8px',
                                            maxWidth: '178px',
                                        }}
                                        overlayInnerStyle={{
                                            fontSize: '12px',
                                            fontFamily: 'SF-Pro-Display',
                                        }}
                                        color="#070C18"
                                        placement="bottom"
                                        title="This data  is being matched  with the data result from the selfie /photo the consumer added on their KYC"
                                    >
                                        <img src={helpIcon} />
                                    </Tooltip>
                                )}
                            </div>
                            {page !== 'business-verification' &&
                                allChecksPassed && (
                                    <div
                                        className={`${styles.tag} ${styles.success}`}
                                    >
                                        PASSED
                                    </div>
                                )}
                            {page !== 'business-verification' &&
                                !allChecksPassed &&
                                inputs?.selfieImageUrl !== '' && (
                                    <button
                                        className={styles.override_btn}
                                        onClick={() =>
                                            setShowAnalysisModal(true)
                                        }
                                    >
                                        Analyse
                                    </button>
                                )}
                        </div>
                        {sseBasicInfoFetching ? (
                            <Skeleton active size="small" />
                        ) : (
                            <>
                                <div className={styles.identityMetaData}>
                                    <div>
                                        {bvnInfo?.comparison_data?.find(
                                            rep => rep?.criteria === 'gender',
                                        )?.match === false &&
                                        page !== 'business-verification' ? (
                                            <div className="">
                                                <h4>Gender</h4> <NoMatchCheck />
                                            </div>
                                        ) : (
                                            <div className="">
                                                <h4>Gender</h4> <PassedCheck />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {bvnInfo?.comparison_data?.find(
                                            rep => rep?.criteria === 'dob',
                                        )?.match === false &&
                                        page !== 'business-verification' ? (
                                            <div className="">
                                                <h4>Age</h4> <NoMatchCheck />
                                            </div>
                                        ) : (
                                            <div className="">
                                                <h4>Age</h4> <PassedCheck />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {page !== 'business-verification' && (
                                            <div className="">
                                                <h4>Date of birth</h4>{' '}
                                                <p>
                                                    {bvnInfo?.verified_data
                                                        ?.dob || '-'}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {page !== 'business-verification' && (
                                    <div className={styles.identityMetaData}>
                                        <div>
                                            <h4>Face Detection</h4>
                                            <div>
                                                {isFaceDetected ? (
                                                    <div className="">
                                                        <PassedCheck />
                                                    </div>
                                                ) : (
                                                    <div className="">
                                                        <NoMatchCheck />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <h4>Face recognition</h4>
                                            <div>
                                                {isFaceRecognized ? (
                                                    <div className="">
                                                        <PassedCheck />
                                                    </div>
                                                ) : (
                                                    <div className="">
                                                        <NoMatchCheck />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

KycAnalysisSummary.propTypes = {
    title: PropTypes.string,
    status: PropTypes.string,
    page: PropTypes.string,
    handleOverride: PropTypes.func,
    inputs: PropTypes.object,
}

export default KycAnalysisSummary
