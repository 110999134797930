import SeoComponent from 'src/components/Seo'
import WelcomeModal from './components/WelcomeModal'
import useWorkspace from './hooks/useWorkspace'
import s from './styles.module.scss'
import ActionsPanel from './components/ActionsPanel'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import DashboardMetrics from './components/DashboardMetrics'
import MembersList from './components/MembersList'
import AddMembersModal from './components/AddMembersModal'
import ActionModal from 'src/components/ActionModalV2'
import { actionModalValues, uploadModalValues } from './utils'
import FilterDrawer from './components/FilterDrawer'
import SearchAndFilterPanel from './components/SearchAndFilterPanel'
import MemberDetailsModal from './components/MemberDetailsModal'
import Toast from 'src/components/Toast'
import { isMobile } from 'src/utils/mediaQueries'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import BankDetailsContent from './components/BankDetailsContent'
import UploadModal from 'src/components/UploadModal'
import BackNav from 'src/components/BackNav'
import { useHistory } from 'react-router-dom'

const RepresentativeWorkspace = () => {
    const {
        showWelcomeModal,
        closeWelcomeModal,
        showAddMembersModal,
        openAddMembersModal,
        closeAddMembersModal,
        actionModalOpen,
        modalAction,
        actionModalHandler,
        members,
        handleHoldNewMember,
        pageAction,
        handleChangePageAction,
        membersWithDuePayment,
        storeActionAndOpenModal,
        closeActionModal,
        filterDrawerVisible,
        handleFilterDrawerOpen,
        onFilterDrawerClose,
        membersListFiltered,
        applyFilter,
        resetFilter,
        showMemberDetailsModal,
        openMemberDetailsModal,
        closeMemberDetailsModal,
        secondaryActionModalHandler,
        memberDetails,
        userInfo,
        clusterSummary,
        toastError,
        errorMessage,
        actionLoading,
        totalMemberPages,
        filterStatus,
        setFilterStatus,
        profileSystemSizes,
        currentPage,
        updatePage,
        exportMembers,
        bankDetailsModal,
        successAlert,
        handlePaymentConfirmation,
        totalDuePaymentAmount,
        uploadFilesModal,
        setUploadFilesModal,
        fileSuccessUpload,
        setFileSuccessUpload,
        creditReportFiles,
        membersWithNoCreditReport,
        uploadType,
        handleSaveClusterMember,
        storeActionAndOpenUploadModal,
        handleUpload,
        newMembersList,
        membersLoading,
        disableActionBtn,
    } = useWorkspace()

    const {
        actionModalTitle,
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalPrimaryBtnText,
        actionModalFooterLeftContent,
        actionModalSecondaryBtnText,
    } = actionModalValues(
        modalAction,
        memberDetails,
        membersWithDuePayment,
        creditReportFiles,
    )

    const {
        uploadModalTitle,
        acceptableFileTypes,
        maxCount,
        acceptsMultiple,
        showUploadList,
    } = uploadModalValues(uploadType)
    const history = useHistory()
    const isFromOverviewPage = history?.location?.state?.comingFromOverview

    return (
        <>
            {/* modals */}
            {process.env
                .REACT_APP_FLAG_MANAGE_MULTIPLE_CLUSTERS_ON_REP_WORKSPACE !==
                'true' && (
                <WelcomeModal
                    showModal={showWelcomeModal}
                    onCancel={closeWelcomeModal}
                    firstName={userInfo?.firstName ?? ''}
                    companyLogo={userInfo?.clusterLogo ?? ''}
                />
            )}

            <AddMembersModal
                showModal={showAddMembersModal}
                onCancel={closeAddMembersModal}
                handleHoldNewMember={handleHoldNewMember}
                storeActionAndOpenUploadModal={storeActionAndOpenUploadModal}
            />
            <ActionModal
                actionModalOpen={actionModalOpen}
                title={actionModalTitle}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                primaryBtnText={actionModalPrimaryBtnText}
                handlePrimaryBtnClick={actionModalHandler}
                footerLeftContent={actionModalFooterLeftContent}
                secondaryBtnText={actionModalSecondaryBtnText}
                handleSecondaryBtnClick={secondaryActionModalHandler}
                closable
                noBackLink
                onCancel={closeActionModal}
                primaryBtnLoading={actionLoading}
                kycSecondaryButtonStopLoading={true}
            />
            <SecondaryModal
                showModal={bankDetailsModal}
                title="Cluster member payment"
                showFooter
                modalWidth={'475px'}
                primaryBtnText="Transfer done"
                handlePrimaryBtnClick={() => {
                    handlePaymentConfirmation()
                }}
                v2
                content={
                    <BankDetailsContent
                        successAlert={successAlert}
                        totalDuePaymentAmount={totalDuePaymentAmount}
                    />
                }
            />

            <UploadModal
                showModal={uploadFilesModal}
                title={uploadModalTitle}
                primaryBtnText={'Confirm'}
                successUpload={fileSuccessUpload}
                setSuccessUpload={setFileSuccessUpload}
                onCancel={() => {
                    setFileSuccessUpload([])
                    setUploadFilesModal(false)
                }}
                acceptableFileTypes={acceptableFileTypes}
                maxCount={maxCount}
                handlePrimaryBtnClick={handleUpload}
                showUploadList={showUploadList}
                multiple={acceptsMultiple}
            />
            {toastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                />
            )}
            {/* drawers */}
            <FilterDrawer
                visible={filterDrawerVisible}
                onDrawerClose={onFilterDrawerClose}
                applyFilter={applyFilter}
                resetFilter={resetFilter}
                setFilterStatus={setFilterStatus}
                profileSystemSizes={profileSystemSizes}
                filterStatus={filterStatus}
                page="workspace"
            />
            <MemberDetailsModal
                showModal={showMemberDetailsModal}
                onCancel={closeMemberDetailsModal}
                memberDetails={memberDetails}
            />
            {/* page */}
            <SeoComponent title="Representative Workspace | SunFi | Simplifying and Scaling Clean Energy" />
            <div className={s.page}>
                <div className={s.main}>
                    <div className={s.mainContent}>
                        {!isMobile && <VerticalSpacer top={56} />}
                        {isFromOverviewPage && (
                            <BackNav
                                v2
                                title="Go back"
                                onClick={() => history.push('/rep/overview')}
                            />
                        )}
                        <ActionsPanel
                            openAddMembersModal={openAddMembersModal}
                            pageAction={pageAction}
                            handleChangePageAction={handleChangePageAction}
                            storeActionAndOpenModal={storeActionAndOpenModal}
                            firstName={userInfo?.firstName ?? ''}
                            handleSaveClusterMembers={handleSaveClusterMember}
                            storeActionAndOpenUploadModal={
                                storeActionAndOpenUploadModal
                            }
                            disableActionBtn={disableActionBtn}
                        />
                        <VerticalSpacer top={24} />
                        {pageAction === 'view-dashboard' && (
                            <DashboardMetrics clusterSummary={clusterSummary} />
                        )}
                        <VerticalSpacer top={24} />

                        {pageAction === 'view-dashboard' &&
                            (members?.length > 0 || membersListFiltered) && (
                                <>
                                    <SearchAndFilterPanel
                                        handleFilterDrawerOpen={
                                            handleFilterDrawerOpen
                                        }
                                        membersListFiltered={
                                            membersListFiltered
                                        }
                                        resetFilter={resetFilter}
                                        handleExport={exportMembers}
                                        applyFilter={applyFilter}
                                        setFilterStatus={setFilterStatus}
                                        filterStatus={filterStatus}
                                        page="workspace"
                                    />
                                    <VerticalSpacer top={16} />
                                </>
                            )}

                        <MembersList
                            pageAction={pageAction}
                            members={members}
                            membersWithDuePayment={membersWithDuePayment}
                            openMemberDetailsModal={openMemberDetailsModal}
                            storeActionAndOpenModal={storeActionAndOpenModal}
                            totalPages={totalMemberPages}
                            currentPage={currentPage}
                            updatePage={updatePage}
                            membersListFiltered={membersListFiltered}
                            creditReportFiles={creditReportFiles}
                            membersWithNoCreditReport={
                                membersWithNoCreditReport
                            }
                            newMembersList={newMembersList}
                            storeActionAndOpenUploadModal={
                                storeActionAndOpenUploadModal
                            }
                            membersLoading={membersLoading}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RepresentativeWorkspace
