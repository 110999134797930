import axios from 'src/config/axios'

export const calculateBuyoutAmountApi = async ({
    estimation_id,
    user_id,
    user,
}) => {
    const request = await axios.post(
        `${
            user === 'consumer' ? '' : '/admin'
        }/payments/calculate-buyout-amount`,
        {
            estimation_id: estimation_id,
            user_id: user_id,
        },
    )
    return request
}

export const adminRequestBuyoutApi = async ({ estimation_id, user_id }) => {
    const request = await axios.post('/admin/payments/request-buyout', {
        estimation_id: estimation_id,
        user_id: user_id,
    })
    return request
}
