//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
// import { Badge } from 'antd'
import { Menu, Dropdown, Space, Drawer } from 'antd'
import { useHistory } from 'react-router-dom'
// import BellIcon from '../../assets/images/bell-icon.svg'
import DropdownArrow from '../../assets/images/dropdown.svg'
import './authenticatednavbar.scss'
import MyAccountIcon from '../../assets/images/account-user.svg'
import LogOutIcon from '../../assets/images/logout-icon.svg'
import { ReactComponent as MobileNavIcon } from '../../assets/images/mobileNavIcon.svg'
import { ReactComponent as CancelIcon } from '../../assets/images/MobileMenuCloseIcon.svg'
import logo from '../../assets/images/logo.svg'
import { getProfileInformationApi } from '../../api/profile'
import NavBar from './NavBar'
import { checkConsumerPages } from '../../utils/checkConsumerPages'
import { useCheckSelection, usePermittedRoutes } from './utils'

import { logoutApi } from '../../api/logout'

import { getToken, logsOutUser } from '../../utils/auth.js'
import Avatar from '../Avatar/Avatar'
import { checkRepWorkspacePages } from 'src/utils/checkClusterRepPages'
import { useClearPersistedFilter } from 'src/utils/clearPersistedFilter'

const AuthenticatedNavBar = ({ userInfo, path }) => {
    const [visible, setVisible] = useState(false)
    const [avatar, setAvatar] = useState(null)
    const [firstName, setFirstName] = useState(userInfo?.firstName)
    const [lastName, setLastName] = useState(userInfo?.lastName)
    const [companyName, setCompanyName] = useState(userInfo?.companyName)
    const [member, setMember] = useState(null)
    const isConsumerOrRepWorkspace =
        checkConsumerPages(path) || checkRepWorkspacePages(path)
    const history = useHistory()
    const currentUrl = history?.location?.pathname
    const { clearPersistedFilter } = useClearPersistedFilter()
    const logOut = () => {
        setTimeout(() => {
            refetch()
            clearPersistedFilter(window.location.pathname)
        }, 1000)
    }
    const { refetch } = useQuery(
        'logout',
        () => logoutApi({ access_token: getToken() }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                logsOutUser()
            },
        },
    )
    const { refetch: refetchUserProfile, status: getProfileStatus } = useQuery(
        'getUserProfile',
        () => getProfileInformationApi(),
        {
            enabled: true,
            retry: false,
            onSuccess: data => {
                setAvatar(data?.data?.data?.avatar_url)
                setCompanyName(
                    data?.data?.data?.provider.company_name === null
                        ? 'none'
                        : data?.data?.data?.provider.company_name,
                )
                setFirstName(data?.data?.data?.first_name)
                setLastName(data?.data?.data?.last_name)
                setMember(data?.data?.data?.provider?.member)
            },
        },
    )

    const permittedRoutes = usePermittedRoutes(getProfileStatus)
    const checkSelection = useCheckSelection({ isOnboardingActive: false })

    useEffect(() => {
        refetchUserProfile()
        //eslint-disable-next-line
    }, [])

    const showDrawer = () => {
        setVisible(true)
    }
    const hideDrawer = () => {
        setVisible(false)
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={() => (window.location.href = '/app/profile')}>
                <img src={MyAccountIcon} alt="arrow" />
                <span className="dropdownLinks">My Profile</span>
            </Menu.Item>
            <Menu.Item onClick={logOut}>
                <img src={LogOutIcon} alt="arrow" />
                <span className="dropdownLinks"> Log Out </span>
            </Menu.Item>
        </Menu>
    )

    const showUserName = () => {
        if (companyName === 'none') {
            return firstName + ' ' + lastName
        } else {
            return companyName
        }
    }

    const useDateWish = () => {
        const [today, setToday] = useState(new Date())

        useEffect(() => {
            const timer = setInterval(() => {
                setToday(new Date())
            }, 60 * 1000)
            return () => {
                clearInterval(timer)
            }
        }, [])

        const hour = today.getHours()
        const wish = `Good ${
            (hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'
        },`
        return wish
    }
    const wish = useDateWish()
    return (
        <>
            {isConsumerOrRepWorkspace && <NavBar />}

            <div className="NavBox">
                <button
                    className="MobileMenu"
                    onClick={showDrawer}
                    style={{ display: isConsumerOrRepWorkspace && 'none' }}
                >
                    <span>
                        <MobileNavIcon />
                    </span>
                </button>
                <Drawer
                    placement="left"
                    closable={false}
                    onClose={hideDrawer}
                    visible={visible}
                    width="55%"
                    bodyStyle={{ backgroundColor: '#011A3C' }}
                    className="MobileDrawer"
                >
                    <div className="AuthNavCancelContainer">
                        <button
                            className="MobileMenuCancelIcon"
                            onClick={hideDrawer}
                        >
                            <span>
                                <CancelIcon />
                            </span>
                        </button>
                    </div>
                    <div className="MobileLogoBox">
                        <img src={logo} alt="logo" />
                    </div>
                    <Menu
                        theme="light"
                        defaultSelectedKeys={checkSelection()}
                        selectedKeys={checkSelection()}
                        mode="inline"
                        className="MobileMenuItemBox"
                    >
                        <>
                            {permittedRoutes.map(navRoute => {
                                return (
                                    <Menu.Item
                                        key={navRoute?.key}
                                        icon={
                                            <div style={{ width: '30px' }}>
                                                <img
                                                    src={
                                                        parseInt(
                                                            checkSelection(),
                                                            10,
                                                        ) === navRoute?.key
                                                            ? navRoute?.onSelectIcon
                                                            : navRoute?.key ===
                                                              1
                                                            ? navRoute?.mobileIcon
                                                            : navRoute?.icon
                                                    }
                                                    alt="icon"
                                                />
                                            </div>
                                        }
                                        style={{ background: '#011A3C' }}
                                        disabled={
                                            navRoute?.key === 1 ||
                                            navRoute?.key === 2 ||
                                            navRoute?.key === 3 ||
                                            navRoute?.key === 4 ||
                                            navRoute?.key === 5 ||
                                            navRoute?.key === 6 ||
                                            navRoute?.key === 7 ||
                                            navRoute?.key === 8 ||
                                            navRoute?.key === 9 ||
                                            navRoute?.key === 10 ||
                                            navRoute?.key === 11 ||
                                            navRoute?.key === 12 ||
                                            navRoute?.key === 13
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            setVisible(false)
                                            clearPersistedFilter(navRoute.url)
                                            history.push(`${navRoute.url}`)
                                        }}
                                    >
                                        <span className="AuthNavMenuItemMarginLeft">
                                            {navRoute?.title}
                                        </span>
                                    </Menu.Item>
                                )
                            })}
                        </>
                    </Menu>
                </Drawer>
                <div
                    className="NavSectionOne"
                    style={{
                        margin:
                            currentUrl?.includes('overview') &&
                            !currentUrl.includes('rep')
                                ? ''
                                : 0,
                    }}
                >
                    {currentUrl?.includes('overview') &&
                        !currentUrl.includes('rep') && (
                            <div className="UserNameStyle">
                                {wish} &nbsp; <b>{showUserName()}</b>{' '}
                            </div>
                        )}
                </div>
                <div
                    className="NavSectionTwo"
                    style={{
                        display: isConsumerOrRepWorkspace && 'none',
                    }}
                >
                    {/* <div className='NotificationBox'>
          <div>
            <img src={BellIcon} alt='bell' />
          </div>
          <div className='NotificationNumberBox'>
            <Badge count={5} style={{ backgroundColor: '#004AAD' }} />
          </div>
        </div> */}
                    <div className="NavProfileWrapper">
                        <Avatar
                            avatar={avatar}
                            alt="profile avatar"
                            first_name={
                                companyName === 'none' || member === true
                                    ? firstName
                                    : companyName
                            }
                            last_name={
                                companyName === 'none' || member === true
                                    ? lastName
                                    : companyName?.substring(1, 2)
                            }
                            background_color="#004aad"
                            text_color="#ffffff"
                        />
                        <Space direction="vertical">
                            <Space wrap>
                                <Dropdown
                                    overlay={menu}
                                    placement="bottomCenter"
                                    trigger={['click']}
                                    overlayClassName="Navbar"
                                >
                                    <span className="AuthNavCursor">
                                        <img src={DropdownArrow} alt="arrow" />
                                    </span>
                                </Dropdown>
                            </Space>
                        </Space>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuthenticatedNavBar
