import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useConsumerKYCContext } from '../context'
import { useHistory } from 'react-router-dom'
import { actionModalValues } from '../data'
import SectionContainer from './SectionContainer'
import SelectedSolutionSection from './SelectedSolution'
import SwitchUseType from '../../AccountSetupOverview/components/SwitchUseType'
import LoginModal from 'src/components/ConsumerLandingPageBody/LoginModal'
import ActionModal from 'src/components/ActionModalV2'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ReactPlayer from 'react-player'
import { sseAuthValidateOtp } from 'src/api/shoppingExperience/auth/api'
import EmailSent from 'src/components/GettingStarted/components/EmailSent'
import '../consumerkyc.scss'
import WelcomeModal from '../../AccountSetupOverview/components/WelcomeModal'
import { customerTypeRemapping } from '../../QuickViewSystem/data'
import PackageDrawer from '../../AccountSetupOverview/components/PackageDrawer'
import React, { useEffect, useRef } from 'react'
import DisputePackageModal from '../../AccountSetupOverview/components/DisputePackageModal'
import { ReactComponent as NewPackageIcon } from '../../../../../assets/images/new-package.svg'
import { SectionLoader } from './Loaders'
import ErrorHandlerTour from './ErrorHandler/ErrorHandlerTour'

const MainContent = () => {
    const {
        sections,
        estimation,
        goBackToSystem,
        handleShowVideo,
        handleChangePackage,
        handleEditInfo,
        handleUseTypeChange,
        handleToggle,
        isOpen,
        showSecondaryModal,
        setShowSecondaryModal,
        action,
        setAction,
        actionModalOpen,
        handlePrimaryBtnClick,
        gettingStartedModalOpen,
        setGettingStartedModalOpen,
        fetchEstimation,
        useType,
        contact_type,
        contact_details,
        showLoginModal,
        setShowLoginModal,
        closeLoginModal,
        loginModalContent,
        setLoginModalContent,
        otpMethod,
        setOtpMethod,
        loginInputs,
        initiateOTPVerificationMutation,
        initiateOTPVerificationLoading,
        onVerifySuccess,
        setActionModalOpen,
        setSubmit,
        showDEPConsumerWelcomeModal,
        setShowDEPConsumerWelcomeModal,
        submitKYC,
        isSubmitting,
        setShowPackageDetailsDrawer,
        inputs,
        goToSSEHome,
        showPackageDetailsDrawer,
        showDisputePackageModal,
        setShowDisputePackageModal,
        nudgeProvider,
        nudgeProviderMutationLoading,
        kycFetching,
        kycLoadingOrRefetchError,
        actionModalPrimaryBtnLoading,
        redirectIfNeeded,
        estimationFetching,
        estimationLoadingOrRefetchError,
        disablePageButtons,
        isCustomerApproved,
        upfrontDepositPaid,
        kycInfoFormik,
        handleSaveProgress,
        isAdminUser,
    } = useConsumerKYCContext()

    const {
        actionModalActionType,
        actionModalFooterLeftContent,
        actionModalHeaderText,
        actionModalPrimaryBtnText,
        actionModalSecondaryBtnText,
        actionModalSubtitle,
        actionModalTitle,
    } = actionModalValues(
        action,
        useType,
        estimation?.notify_provider_of_reestimation,
    )
    const { isMobile } = useMediaQueries()
    const history = useHistory()
    const backAction = useRef('')

    const AppliancesFromProvider = () => (
        <p className="ASO_AppliancesText">
            {estimation?.appliances_from_provider?.map(
                (appliance, index, array) => (
                    <React.Fragment key={index}>
                        <span>{appliance?.quantity}</span> {appliance?.name}
                        {index < array.length - 1 && ', '}
                    </React.Fragment>
                ),
            )}
        </p>
    )

    useEffect(() => {
        const handleSaveBeforeUnload = event => {
            if (kycInfoFormik.dirty && !isAdminUser) {
                handleSaveProgress()
                event.preventDefault()
                event.returnValue = ''
            }
        }
        window.addEventListener('beforeunload', handleSaveBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleSaveBeforeUnload)
        }
        // eslint-disable-next-line
    }, [kycInfoFormik.dirty])

    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (action === 'POP') {
                if (
                    kycInfoFormik.dirty &&
                    backAction.current !== 'discard-changes'
                ) {
                    backAction.current = 'unsaved-changes-prompt'
                    handleSaveProgress()
                    return false
                }

                backAction.current = ''
                return true
            }
            return true
        })
        return unblock
    }, [handleSaveProgress, history, kycInfoFormik.dirty])

    return (
        <>
            <div className="SectionsContainer">
                {sections?.map((section, index) => (
                    <SectionContainer
                        key={section.title}
                        title={section.title}
                        isOpen={isOpen[index]}
                        onToggle={() => handleToggle(index)}
                        info={section.info}
                    >
                        {kycFetching || kycLoadingOrRefetchError ? (
                            <SectionLoader index={index} />
                        ) : (
                            section?.component
                        )}
                    </SectionContainer>
                ))}
                {isMobile && (
                    <SelectedSolutionSection
                        estimation={estimation}
                        goBackToSystem={goBackToSystem}
                        useType={estimation?.use_type}
                        handleShowVideo={handleShowVideo}
                        handlePackageChange={handleChangePackage}
                        handleEditInfo={handleEditInfo}
                        handleUseTypeChange={handleUseTypeChange}
                        showPageHeading={false}
                        inputs={inputs}
                        estimationFetching={estimationFetching}
                        estimationLoadingOrRefetchError={
                            estimationLoadingOrRefetchError
                        }
                        disablePageButtons={disablePageButtons}
                        isCustomerApproved={isCustomerApproved}
                        upfrontDepositPaid={upfrontDepositPaid}
                    />
                )}
                <ErrorHandlerTour />
            </div>

            <SecondaryModal
                showModal={showSecondaryModal}
                modalWidth={action === 'show-video' ? '1000px' : '450px'}
                modalHeight="100%"
                onCancel={() => {
                    setShowSecondaryModal(false)
                    if (action === 'progress-saved') {
                        setActionModalOpen(true)
                    } else if (action === 'new-package') {
                        setShowPackageDetailsDrawer(true)
                    }
                }}
                noPadding
                title={
                    action === 'progress-saved'
                        ? 'Getting Started on Sunfi'
                        : action === 'new-package'
                        ? 'You have a new package'
                        : ''
                } // TODO: handle with a function instead
                v2={action === 'show-video' ? false : true}
                modalClass={action === 'show-video' ? 'videoModal' : ''}
                content={
                    <>
                        {action === 'show-video' && (
                            <div>
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=ABdhW8N1rPk"
                                    width="100%"
                                    height={isMobile ? '250px' : '643px'}
                                    playing={showSecondaryModal}
                                    controls={true}
                                    loop={true}
                                />
                            </div>
                        )}

                        {action === 'progress-saved' && <EmailSent />}
                        {action === 'new-package' && (
                            <div className="NewPackageModal">
                                <NewPackageIcon />
                                <h3>New Package</h3>
                                <p>
                                    A new package has been suggested to you by
                                    your provider. Kindly look through it.
                                </p>
                            </div>
                        )}
                    </>
                }
                showFooter={
                    action === 'progress-saved' || action === 'new-package'
                        ? true
                        : false
                }
                footerLeftContent="chat"
                primaryBtnText={
                    action === 'progress-saved'
                        ? 'Great, Thank You'
                        : action === 'new-package'
                        ? 'Proceed'
                        : ''
                }
                handlePrimaryBtnClick={() => {
                    if (action === 'progress-saved') {
                        setShowSecondaryModal(false)
                        setActionModalOpen(true)
                    } else if (action === 'new-package') {
                        setShowSecondaryModal(false)
                        setShowPackageDetailsDrawer(true)
                    }
                }}
                secondaryBtnLoading={
                    action === 'package-change-request-in-progress' &&
                    nudgeProviderMutationLoading
                }
            />

            <ActionModal
                actionModalOpen={actionModalOpen}
                headerText={actionModalHeaderText}
                actionType={actionModalActionType}
                footerLeftContent={actionModalFooterLeftContent}
                primaryBtnText={actionModalPrimaryBtnText}
                subTitle={actionModalSubtitle}
                title={actionModalTitle}
                secondaryBtnText={actionModalSecondaryBtnText}
                handlePrimaryBtnClick={handlePrimaryBtnClick}
                handleSecondaryBtnClick={() => {
                    if (action === 'package-change-request-in-progress') {
                        nudgeProvider()
                    } else {
                        setAction('')
                        setActionModalOpen(false)
                    }
                }}
                countdownTimerSeconds={10}
                onTimeEnd={handlePrimaryBtnClick}
                closable
                onCancel={() => {
                    setAction('')
                    setLoginModalContent('')
                    setActionModalOpen(false)
                    action === 'progress-saved-for-dep-consumer' &&
                        goToSSEHome()
                    setOtpMethod('')
                    setSubmit(false)

                    if (backAction.current === 'unsaved-changes-prompt') {
                        backAction.current = 'discard-changes'
                        history.goBack()
                    }
                    localStorage.removeItem('showBankStatementModal')
                }}
                primaryBtnLoading={actionModalPrimaryBtnLoading}
                kycSecondaryButtonStopLoading
            />

            <SwitchUseType
                showModal={gettingStartedModalOpen}
                setShowModal={setGettingStartedModalOpen}
                useType={
                    useType === 'residential'
                        ? 'Business use'
                        : 'Residential use'
                }
                id={estimation?.id}
                contact={contact_type}
                contact_details={contact_details}
                fetchEstimation={fetchEstimation}
                redirectIfNeeded={() => redirectIfNeeded(estimation?.use_type)}
            />

            <LoginModal
                showModal={showLoginModal}
                setShowModal={setShowLoginModal}
                onCancel={closeLoginModal}
                loginModalContent={loginModalContent}
                setLoginModalContent={setLoginModalContent}
                inputs={loginInputs}
                otpMethod={otpMethod}
                setOtpMethod={setOtpMethod}
                initiateOTP={initiateOTPVerificationMutation.mutate}
                initiateOTPVerificationLoading={initiateOTPVerificationLoading}
                verifyApi={sseAuthValidateOtp}
                onVerifySuccess={onVerifySuccess}
                modalTitle={'Getting started on SunFi'}
                modalHeading={
                    'Where would you like to receive your verification code?'
                }
                modalSubHeading={
                    'Providing the code to your email or phone number helps us verify your account and get started with your KYC'
                }
            />

            <WelcomeModal
                showModal={showDEPConsumerWelcomeModal}
                useType={customerTypeRemapping[useType?.toLocaleUpperCase()]}
                firstName={inputs?.firstName}
                onCancel={() => {
                    submitKYC()
                }}
                onClick={() => {
                    setShowDEPConsumerWelcomeModal(false)
                    setShowPackageDetailsDrawer(true)
                }}
                secondaryLoading={isSubmitting}
            />

            <PackageDrawer
                open={showPackageDetailsDrawer}
                estimation={estimation}
                goBackToSystem={goBackToSystem}
                handleAcceptPackage={() => {
                    setShowPackageDetailsDrawer(false)
                    fetchEstimation()
                }}
                handleDisputePackage={() => {
                    setAction(
                        process.env
                            .REACT_APP_FLAG_SHOW_REESTIMATE_DEP_CONSUMER_PACKAGE ===
                            'true' &&
                            estimation?.consumer_status === null &&
                            estimation?.application_status ===
                                'AWAITING_RE_ESTIMATION'
                            ? 'reject-new-package'
                            : 'initiate-dispute',
                    )
                    setActionModalOpen(true)
                    setShowPackageDetailsDrawer(false)
                }}
                AppliancesFromProvider={AppliancesFromProvider}
            />

            <DisputePackageModal
                showModal={showDisputePackageModal}
                onCancel={() => {
                    setShowDisputePackageModal(false)
                    setShowPackageDetailsDrawer(true)
                }}
                onClick={() => {
                    const _action =
                        estimation.consumer_status === null
                            ? estimation.application_status ===
                              'AWAITING_RE_ESTIMATION'
                                ? 'new-package-rejected-successfully'
                                : 'dispute-submitted'
                            : 'package-change-requested-successfully'
                    setAction(_action)
                    setShowDisputePackageModal(false)
                    setActionModalOpen(true)
                    fetchEstimation()
                }}
                estimation_id={estimation?.id}
                showDEPConsumerChangePackageOption={
                    process.env
                        .REACT_APP_FLAG_SHOW_REESTIMATE_DEP_CONSUMER_PACKAGE ===
                        'true' &&
                    estimation.application_status !== 'IN_PROGRESS'
                }
            />
        </>
    )
}

export default MainContent
