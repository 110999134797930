import PropTypes from 'prop-types'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import { formatMemberData } from '../../utils'
import s from './styles.module.scss'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'

const renderSection = (data, title) => (
    <div className={s.section}>
        <h4>{title}</h4>
        <hr />
        <div className={s.sectionGrid}>
            {data.map((item, index) => (
                <div key={index} className={s.sectionInfo}>
                    <h5>{item.label}</h5>
                    <p>{item.value}</p>
                </div>
            ))}
        </div>
    </div>
)

const MemberDetailsModal = ({ showModal, onCancel, memberDetails }) => {
    const memberData = formatMemberData(memberDetails)
    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={onCancel}
            standardWidth={477}
            standardHeight={484}
            title={'Member details'}
            primaryBtnText={'Got it'}
            primaryBtnWidth={'160px'}
            handlePrimaryBtnClick={onCancel}
        >
            <div className={s.wrapper}>
                {renderSection(memberData.basicInfo, 'Basic Info')}
                <VerticalSpacer top={24} />
            </div>
        </ConsumerModalBase>
    )
}

MemberDetailsModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    memberDetails: PropTypes.object,
}

export default MemberDetailsModal
