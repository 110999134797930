import { createContext, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
    extractFileNameFromUrl,
    formatInputValue,
    formatPhoneNumber,
    removeCountryCodefromNGNNum,
} from 'src/utils/formatting'
import { businessKYC, residentialKYC } from './data'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { useKYCCompletionStatus } from 'src/utils/Hooks/useKYCCompletionStatus'
import { useHistory, useParams } from 'react-router-dom'
import { decodeUserInfo } from 'src/utils/auth'
import { useMutation, useQuery } from 'react-query'
import {
    validateBusinessOnePageKyc,
    validateResidentialOnePageKyc,
} from 'src/utils/validationSchema'
import {
    getKYCInfo,
    nudgeProviderApi,
    retrieveEstimationApi,
    retrieveEstimationForAdminApi,
    getConsumerKYCInfoForAdmin,
} from 'src/api/shoppingExperience/account-overview'
import { formatAccountOverviewData } from '../AccountSetupOverview/data'
import { intiateOnePageVerification } from 'src/api/consumer'
import { errorHandler } from 'src/utils/errorHandler'
import {
    onePageSubmitAdminInfo,
    onePageSubmitConsumerInfo,
} from 'src/api/shoppingExperience/kyc/api'
import {
    formatConsumerKYC,
    formatKYCPayload,
    getPrimaryStatementType,
    handleMixPanelTracking,
    mapValuesToLabelsString,
} from './utils'
import { useAppContext } from 'src/appContext/AppContext'
import {
    SET_AUDIT_FILE,
    SET_AUDIT_REPORT_ERROR,
    SET_BANK_STATEMENT_ERROR,
} from 'src/appContext/appReducer'
import useLogOut from 'src/utils/Hooks/useLogOut'
import { decrypt, encrypt } from 'src/utils/cryptography'
import { heardAboutUsOptions } from '../Home/SelectUseType/data'
import { formatBusinessType } from 'src/utils/business'
import { useCreditDecisionStatus } from '../AccountSetupOverview/hooks/useCreditDecisionStatus'
import { useCustomFormik } from 'src/utils/Hooks/useCustomFormik'

const contextDefaultValues = {
    inputs: {},
    handleInputChange: () => {},
    setInputs: () => {},
    useType: '',
    errors: {},
    handleBlur: () => {},
    handleSubmit: () => {},
    showBVNModal: false,
    handleBvnVerification: () => {},
    closeBVNModal: () => {},
    verificationInProgress: false,
    setVerificationInProgress: () => {},
    handleBvnVerifyLater: () => {},
    bvnVerifiedSuccessfully: () => {},
    cacVerificationInProgress: false,
    setCacVerificationInProgress: () => {},
    handleCacVerifyModal: () => {},
    showCacVerifyModal: false,
    cacHelpPayload: {},
    handleSelectChange: () => {},
    handleSelectOnBlur: () => {},
    estimation: {},
    kycData: {},
    removeErrors: () => {},
    setShowSimulateCreditDecision: () => {},
    showSimulateCreditDecision: false,
    goBackToSystem: () => {},
    handleChangePackage: () => {},
    handleShowVideo: () => {},
    handleUseTypeChange: () => {},
    sections: [],
    handleToggle: () => {},
    isOpen: false,
    isLoading: false,
    estimationFetching: false,
    estimationLoadingOrRefetchError: false,
    showSecondaryModal: false,
    setShowSecondaryModal: () => {},
    action: '',
    setAction: () => {},
    actionModalOpen: false,
    setActionModalOpen: () => {},
    handleSaveProgress: () => {},
    handlePrimaryBtnClick: () => {},
    gettingStartedModalOpen: false,
    setGettingStartedModalOpen: () => {},
    fetchEstimation: () => {},
    errorMessage: '',
    showToastError: false,
    contact_type: '',
    contact_details: {},
    kycFetching: false,
    kycLoadingOrRefetchError: false,
    toastError: false,
    kycStatus: {},
    showLoginModal: false,
    setShowLoginModal: () => {},
    closeLoginModal: () => {},
    loginModalContent: '',
    setLoginModalContent: () => {},
    otpMethod: '',
    setOtpMethod: () => {},
    loginInputs: {},
    canSubmitKYC: false,
    initiateOTPVerificationMutation: () => {},
    initiateOTPVerificationLoading: false,
    onVerifySuccess: () => {},
    setSubmit: () => {},
    kycRefetch: () => {},
    isSaving: false,
    isSubmitting: false,
    showDEPConsumerWelcomeModal: false,
    setShowDEPConsumerWelcomeModal: () => {},
    submitKYC: () => {},
    showPackageDetailsDrawer: false,
    setShowPackageDetailsDrawer: () => {},
    isDEPConsumer: false,
    goToSSEHome: () => {},
    showDisputePackageModal: false,
    setShowDisputePackageModal: () => {},
    nudgeProvider: () => {},
    nudgeProviderMutationLoading: false,
    disablePageButtons: false,
    startMonoLoading: () => {},
    stopMonoLoading: () => {},
    connectToMonoLoading: false,
    actionModalPrimaryBtnLoading: false,
    isCustomerApproved: false,
    redirectIfNeeded: () => {},
    statusRefetch: () => {},
    ranCreditDecision: false,
    upfrontDepositPaid: false,
    kycInfoFormik: {},
    isAdminUser: false,
    showAdminSaveProgressModal: false,
    setShowAdminSaveProgressModal: () => {},
    bankStatementRef: null,
    onBankStatementUploadSuccess: () => {},
    openErrorTour: false,
    setOpenErrorTour: () => {},
    errorTourSteps: [],
    inputFieldRefs: {},
}

export const ConsumerKYCContext = createContext(contextDefaultValues)

const ConsumerKYCContextProvider = ({ children, useType }) => {
    const [sections] = useState(
        useType === 'business' ? businessKYC : residentialKYC,
    )
    const [showAdminSaveProgressModal, setShowAdminSaveProgressModal] =
        useState(false)
    const [isOpen, setIsOpen] = useState(Array(sections.length).fill(true))
    const [estimation, setEstimation] = useState({})
    const [showSecondaryModal, setShowSecondaryModal] = useState(false)
    const [action, setAction] = useState('') // save-progress | show-video | progress-saved
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [kycData, setKycData] = useState({})
    const [showToastError, setShowToastError] = useState(false)
    const [isPromo, setIsPromo] = useState(false)
    const [clusterCode, setClusterCode] = useState('')
    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(null)
    const [showSimulateCreditDecision, setShowSimulateCreditDecision] =
        useState(false)
    const [showPackageDetailsDrawer, setShowPackageDetailsDrawer] =
        useState(false)
    const [showDEPConsumerWelcomeModal, setShowDEPConsumerWelcomeModal] =
        useState(false)
    const [hasFilledAllFields, setHasFilledAllFields] = useState(false)
    const [showBVNModal, setShowBVNModal] = useState(false)
    const [verificationInProgress, setVerificationInProgress] = useState(false)
    const [cacVerificationInProgress, setCacVerificationInProgress] =
        useState(false)
    const [showCacVerifyModal, setShowCacVerifyModal] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [otpMethod, setOtpMethod] = useState('') // email | phone
    const [loginModalContent, setLoginModalContent] =
        useState('select-otp-method') // select-otp-method | enter-otp
    const { state, dispatch } = useAppContext()
    const [submit, setSubmit] = useState(false)
    const [connectToMonoLoading, setConnectToMonoLoading] = useState(false)
    const [isDEPConsumer, setIsDEPConsumer] = useState(false)
    const [openErrorTour, setOpenErrorTour] = useState(false)
    const [errorTourSteps, setErrorTourSteps] = useState([])

    const { id } = useParams()
    const history = useHistory()
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        contactAddress: '',
        contactCountry: '',
        contactState: '',
        contactCity: '',
        confirmAddress: 'true',
        installationAddress: '',
        installationCity: '',
        installationState: '',
        installationCountry: '',
        employmentType: '',
        companyName: '',
        jobTitle: '',
        isBusinessRegistered: null,
        pensionManager: '',
        retirementDate: '',
        selfieImageUrl: '',
        bank_statement_id: '',
        businessType: '',
        businessIndustry: '',
        businessName: '',
        businessDescription: '',
        bvn: '',
        bvnVerified: false,
        directorEmail: '',
        directorFirstName: '',
        directorLastName: '',
        directorPhone: '',
        phoneVerified: false,
        emailVerified: false,
        bankStatements: [],
        moa_url: '',
        audited_financial_report: '',
    })
    const [initialValues, setInitialValues] = useState(inputs)

    const kycInfoFormik = useCustomFormik({
        initialValues: initialValues,
        validationSchema:
            useType === 'business'
                ? validateBusinessOnePageKyc
                : validateResidentialOnePageKyc,
    })

    const { errorAlert, successAlert } = useCustomToast()
    const canSubmitKYC = inputs?.emailVerified || inputs?.phoneVerified
    const [showDisputePackageModal, setShowDisputePackageModal] =
        useState(false)
    const [showStatementReviewInProgress, setShowStatementReviewInProgress] =
        useState(false)

    const contact_type =
        kycData?.consumer?.user?.email !== '' ? 'email' : 'phone'
    const contact_details =
        kycData?.consumer?.user?.email !== ''
            ? kycData?.consumer?.user?.email
            : removeCountryCodefromNGNNum(kycData?.consumer?.user?.phone_number)

    const isCustomerApproved = estimation?.approvalStatus === 'APPROVED'

    const [errorMessage, setErrorMessage] = useState('')
    const validateField = useValidateOnBlur()
    const bankStatementRef = useRef(null)

    const [gettingStartedModalOpen, setGettingStartedModalOpen] =
        useState(false)
    const newCustomerKycStartCapturedRef = useRef(false)

    const { kycStatus, statusRefetch } = useKYCCompletionStatus(
        estimation?.id || history?.location?.state?.estimationId,
        estimation?.use_type,
        estimation?.isDynamicKycUser,
        true,
    )
    const bankStatementNotReviewed =
        kycStatus?.residential_financial_information?.includes(
            'bank_statement_analysis',
        ) ||
        kycStatus?.business_financial_information?.includes(
            'bank_statement_analysis',
        )
    const closeLoginModal = () => {
        setShowLoginModal(false)
        setLoginModalContent('')
    }

    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const abTestInBoundRequest =
        history?.location?.state?.abTestInBoundRequest ?? false
    const convertedOnboardingType =
        estimation?.onboardingType === 'ALL_SOLUTIONS' ||
        estimation?.onboardingType === 'ENERGY_PROFILE'
            ? 'profiles-or-all-systems'
            : 'custom-build'
    const { BVNAlert } = useCustomToast()
    const { logOut } = useLogOut('/')

    const onboardingType =
        history?.location?.state?.onboardingType ?? convertedOnboardingType

    const { ranCreditDecision, upfrontDepositPaid } = useCreditDecisionStatus(
        estimation?.id,
    )
    const showSimulateCreditEngineDecision =
        process.env.REACT_APP_SHOW_SIMULATED_CREDIT_DECISION_ENGINE === 'true'

    const allowKYCSubmission =
        process.env.REACT_APP_FLAG_ALLOW_KYC_WITH_BANK_STATEMENT_REVIEW ===
        'true'

    const goBackToSystem = () => {
        if (kycInfoFormik.dirty) {
            handleSaveProgress()
        } else {
            history.push({
                pathname: `/consumer/view-system/${estimation?.solution_id}`,
                state: {
                    isAuthenticatedUser: true,
                    estimation_id: estimation?.id,
                    action: 'view_from_account_setup',
                    auth_user_id: kycData?.consumer?.user?.id,
                    page: isPromo && 'promo',
                    clusterCode: clusterCode,
                    payment_model: estimation.paymentType,
                    onboardingType,
                    profile_id:
                        kyc_data?.data?.data?.consumer_form_questions
                            ?.energy_profile_id,
                    returnToKyc: estimation?.isDynamicKycUser,
                    solution_id: estimation?.solution_id,
                    previous_payment_model: estimation.paymentType,
                    returnToWorkspace: history.location?.state?.from?.includes(
                        '/consumer/workspace',
                    ),
                    abTestInBoundRequest,
                },
            })
        }
    }

    const handleBvnVerification = () => {
        setShowBVNModal(true)
    }

    const closeBVNModal = () => {
        setShowBVNModal(false)
    }

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleShowVideo = () => {
        setAction('show-video')
        setShowSecondaryModal(true)
    }

    const useTypeMapping = {
        RESIDENTIAL: 'Residential',
        BUSINESS: 'Business',
    }

    const handleInputChange = e => {
        const { name, value } = e.target
        setInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
        removeErrors(name)
        kycInfoFormik.handleChange(e)

        if (
            estimation?.application_status === 'KYC_NOT_STARTED' &&
            !newCustomerKycStartCapturedRef.current
        ) {
            const mixpanelPayload = {
                'Use type': useTypeMapping[estimation?.use_type],
                ...(estimation?.referralSource && {
                    Source: mapValuesToLabelsString(
                        estimation?.referralSource,
                        heardAboutUsOptions,
                    ),
                }),
                'Plan Type': estimation?.payment_plan,
                'Customer Channel':
                    estimation?.survey_type === 'DFS' ? 'DEP' : 'DTC',
                'Package Cost': estimation?.total_price,
                'No of Appliance': estimation?.appliances_count,
                'Package Size': estimation?.capacityValue,
            }
            handleMixPanelTracking({
                eventActionAndCategory: 'Started KYC',
                event: mixpanelPayload,
            })
            newCustomerKycStartCapturedRef.current = true
        }
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)
    }

    const submitKYCMutation = useMutation({
        mutationFn: () =>
            isAdminUser
                ? onePageSubmitAdminInfo({
                      data: {
                          ...formatKYCPayload(
                              {
                                  ...inputs,
                                  audited_financial_report: state.auditFile.url,
                              },
                              estimation,
                              useType,
                          ),
                          submit,
                      },
                      type: useType,
                      estimationID: estimation.id,
                  })
                : onePageSubmitConsumerInfo({
                      type: useType,
                      data: {
                          ...formatKYCPayload(
                              {
                                  ...inputs,
                                  audited_financial_report: state.auditFile.url,
                              },
                              estimation,
                              useType,
                          ),
                          submit,
                      },
                  }),

        onSuccess: () => {
            const mixpanelSubmitPayload = {
                'Use type': useTypeMapping[estimation?.use_type],
                ...(inputs?.employmentType && {
                    'Employment type': inputs?.employmentType,
                }),
                ...(inputs?.businessType && {
                    'Business type': formatBusinessType(inputs?.businessType),
                }),
                'Self employed registration status':
                    inputs?.employmentType === 'Self Employed' ? 'Yes' : 'No',
                'Multiple address state': JSON.parse(inputs?.confirmAddress),
                'Plan Type': estimation?.payment_plan,
                'Customer Channel':
                    estimation?.survey_type === 'DFS' ? 'DEP' : 'DTC',
                'Package Cost': estimation?.total_price,
                'No of Appliance': estimation?.appliances_count,
                'Package Size': estimation?.capacity,
                'Monthly Repayment': estimation?.price_per_month,
                'Bank statement type': getPrimaryStatementType(
                    inputs?.bankStatements,
                ),
            }
            const mixpanelSavePayload = {
                'Use type': useTypeMapping[estimation?.use_type],
                'Package Size': estimation?.capacity,
                'Plan Type': estimation?.payment_plan,
                'No of Appliance': estimation?.appliances_count,
                'Monthly Repayment': estimation?.price_per_month,
                'Customer Channel':
                    estimation?.survey_type === 'DFS' ? 'DEP' : 'DTC',
            }
            const eventActionAndCategory = submit
                ? 'Submitted KYC'
                : 'Saved KYC Data'

            handleMixPanelTracking({
                eventActionAndCategory,
                ...(isAdminUser && {
                    estimation,
                    inputs,
                }),
                ...(!isAdminUser && {
                    event: submit ? mixpanelSubmitPayload : mixpanelSavePayload,
                }),
            })

            if (!submit) {
                if (isDEPConsumer) {
                    setShowDEPConsumerWelcomeModal(false)
                    setAction('progress-saved-for-dep-consumer')
                    setActionModalOpen(true)
                }
                if (
                    action === 'save-and-continue-later' ||
                    action === 'bank-statement-in-review'
                ) {
                    kycRefetch()
                    statusRefetch()
                    setAction('progress-saved')
                    setShowSecondaryModal(false)
                    setActionModalOpen(true)
                    setShowStatementReviewInProgress(false)
                }
            } else if (submit) {
                kycRefetch()
                statusRefetch()
                successAlert('KYC submitted successfully')
                if (allowKYCSubmission && !bankStatementNotReviewed) {
                    if (showSimulateCreditEngineDecision) {
                        setShowSimulateCreditDecision(true)
                    } else {
                        history.push({
                            pathname: '/consumer/account-setup/credit-decision',
                            state: {
                                estimationId: estimation?.id,
                                paymentPlanType: estimation?.paymentType,
                            },
                        })
                    }
                }
            }
            setSubmit(false)
        },
        onError: () => {
            setToastError(true)
            errorAlert(`Could not ${submit ? 'submit' : 'save'} your info`)
            setSubmit(false)
            kycRefetch()
            statusRefetch()
        },
    })

    const handleKYCSubmission = () => {
        setErrors({})
        const bank_statements = kycData?.financial_verification?.bank_statements
        checkValidation({
            onSuccess: () => {
                if (canSubmitKYC) {
                    if (bankStatementNotReviewed) {
                        if (allowKYCSubmission && bank_statements.length > 0) {
                            submitKYCMutation.mutate()
                        } else {
                            setSubmit(false)
                            setAction('bank-statement-in-review')
                            setActionModalOpen(true)
                        }
                    } else {
                        submitKYCMutation.mutate()
                    }
                } else {
                    setShowStatementReviewInProgress(true)
                    setLoginModalContent('select-otp-method')
                    setShowLoginModal(true)
                }
            },
            onError: err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList[e.path] = e.message
                })
                const emptyFields = Object.keys(errList)
                if (emptyFields.length > 0) {
                    const generatedSteps = emptyFields.map(field => ({
                        description: `${errList[field]} to proceed. This is required to complete the KYC process.`,
                        field: field,
                    }))
                    const sortedGeneratedSteps = generatedSteps.sort((a, b) => {
                        if (a.field === 'bvn' && b.field === 'bvnVerified')
                            return -1
                        if (a.field === 'bvnVerified' && b.field === 'bvn')
                            return 1
                        return 0
                    })
                    setErrorTourSteps(sortedGeneratedSteps)
                    setOpenErrorTour(true)
                }
                setErrors(prev => ({
                    ...prev,
                    ...errList,
                }))
                dispatch({
                    type: SET_AUDIT_REPORT_ERROR,
                    payload: errList?.audit_url,
                })

                dispatch({
                    type: SET_BANK_STATEMENT_ERROR,
                    payload: errList?.bankStatements,
                })
                errorAlert('You omitted some required fields.')
                setSubmit(false)
            },
        })
    }

    const redirectIfNeeded = customerType => {
        const path = customerType?.toLowerCase()
        if (!history.location?.pathname?.includes(path)) {
            history.replace({
                pathname: `/consumer/account-setup/${path}`,
                state: {
                    ...history.location?.state,
                    abTestInBoundRequest,
                },
            })
            fetchEstimation()
            kycRefetch()
            statusRefetch()
        }
    }

    const {
        isLoading,
        refetch: fetchEstimation,
        data: kyc_data,
        isFetching: estimationFetching,
        status: estimationFetchStatus,
    } = useQuery(
        'fetch-estimation',
        () => {
            const historyState = history.location.state
            let estimationId = ''

            if (isAdminUser) {
                return retrieveEstimationForAdminApi(id)
            }

            if (
                process.env
                    .REACT_APP_FLAG_SHOW_KYC_DETAILS_FOR_ASSOCIATED_ACTIVE_PLAN ===
                    'true' &&
                historyState &&
                historyState.from === '/consumer/workspace' &&
                historyState.estimationId
            ) {
                estimationId = historyState.estimationId
            }

            return retrieveEstimationApi(estimationId)
        },
        {
            enabled: true,
            retry: false,
            refetchOnMount: true,
            onSuccess: res => {
                const data = res?.data?.data
                const isDepConsumer = data?.survey_type === 'DFS'
                setEstimation(formatAccountOverviewData(data, isAdminUser))
                setClusterCode(data?.consumer_form_questions?.cluster_code)
                setIsPromo(data?.is_promo_estimation)
                setIsDEPConsumer(isDepConsumer)
                if (
                    isDepConsumer &&
                    data?.consumer_status === null &&
                    Object.keys(data).length !== 0
                ) {
                    if (data?.application_status === 'AWAITING_RE_ESTIMATION') {
                        setAction('new-package')
                        setShowSecondaryModal(true)
                    } else {
                        setShowDEPConsumerWelcomeModal(true)
                    }
                } else if (!gettingStartedModalOpen) {
                    redirectIfNeeded(data?.customer_type)
                }
            },
        },
    )

    const handleSubmit = () => {
        setSubmit(true)
        handleKYCSubmission()
    }

    const onVerifySuccess = () => {
        setShowLoginModal(false)
        if (bankStatementNotReviewed && showStatementReviewInProgress) {
            setSubmit(false)
            setAction('bank-statement-in-review')
            setActionModalOpen(true)
        } else {
            submitKYCMutation.mutate()
        }
    }

    const goToSSEHome = () => {
        setActionModalOpen(false)
        logOut()
    }

    const nudgeProviderMutation = useMutation({
        mutationKey: ['nudge_provider'],
        mutationFn: () => nudgeProviderApi(estimation.id),
        onSuccess: () => {
            setAction('provider-nudged-successfully')
            fetchEstimation()
        },
        onError: err => {
            setShowToastError(true)
            setErrorMessage(errorHandler(err?.response?.data))
        },
    })

    const actionModalPrimaryBtnLoading = submitKYCMutation.isLoading

    const handlePrimaryBtnClick = () => {
        localStorage.removeItem('showBankStatementModal')
        switch (action) {
            case 'save-and-continue-later':
                if (canSubmitKYC) {
                    setActionModalOpen(false)
                    submitKYCMutation.mutate()
                } else {
                    setActionModalOpen(false)
                    setLoginModalContent('select-otp-method')
                    setShowLoginModal(true)
                }
                break
            case 'progress-saved':
            case 'progress-saved-for-dep-consumer':
                goToSSEHome()
                break
            case 'change-use-type':
                setActionModalOpen(false)
                setAction('')
                setGettingStartedModalOpen(true)
                break
            case 'dispute-submitted':
            case 'provider-nudged-successfully':
            case 'new-package-rejected-successfully':
            case 'package-change-request-in-progress':
            case 'package-change-requested-successfully':
            case 'bank-statement-review-in-progress':
                setAction('')
                setActionModalOpen(false)
                break
            case 'confirm-package-change-request':
            case 'initiate-dispute':
            case 'reject-new-package':
                setActionModalOpen(false)
                setShowDisputePackageModal(true)
                break
            case 'data-fetch-failed':
                if (kycFetchStatus === 'error') {
                    kycRefetch()
                }
                if (estimationFetchStatus === 'error') {
                    fetchEstimation()
                }
                setActionModalOpen(false)
                setAction('')
                break
            case 'bank-statement-in-review':
                submitKYCMutation.mutate()
                break
            case 'bank-statement-review-failed':
                setActionModalOpen(false)
                setAction('')
                bankStatementRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                })
                break
            case 'bank-statement-review-in-progress-and-user-can-submit-kyc':
                setActionModalOpen(false)
                setAction('')
                if (canSubmitKYC) {
                    submitKYCMutation.mutate()
                } else {
                    setLoginModalContent('select-otp-method')
                    setShowLoginModal(true)
                }
                break
        }
    }

    const {
        refetch: kycRefetch,
        isFetching: kycFetching,
        status: kycFetchStatus,
    } = useQuery(
        'fetch_one_page_kyc',
        () => {
            const historyState = history.location.state
            let estimationId = ''

            if (isAdminUser) {
                return getConsumerKYCInfoForAdmin(id)
            }

            if (
                process.env
                    .REACT_APP_FLAG_SHOW_KYC_DETAILS_FOR_ASSOCIATED_ACTIVE_PLAN ===
                    'true' &&
                historyState &&
                historyState.estimationId
            ) {
                estimationId = historyState.estimationId
            }

            return getKYCInfo(estimationId)
        },
        {
            enabled: true,
            retry: false,
            refetchOnMount: true,
            onSuccess: res => {
                const data = res?.data?.data
                const formattedInput = formatConsumerKYC(data, inputs)
                setKycData(data)
                setInputs(prev => ({
                    ...prev,
                    ...formattedInput,
                    audited_financial_report: state?.auditFile?.url,
                }))
                setInitialValues(prev => ({
                    ...prev,
                    ...formattedInput,
                    audited_financial_report: state?.auditFile?.url,
                }))
                if (data?.consumer?.audited_financial_reports?.length > 0) {
                    dispatch({
                        type: SET_AUDIT_FILE,
                        payload: {
                            url: res?.data?.data?.consumer
                                ?.audited_financial_reports[0]?.url,
                            name: extractFileNameFromUrl(
                                res?.data?.data?.consumer
                                    ?.audited_financial_reports[0]?.url,
                            ),
                        },
                    })
                }
                setSubmit(false)
            },
            onError: error => {
                setShowToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const initiateOTPVerificationMutation = useMutation({
        mutationFn: () =>
            intiateOnePageVerification({
                ...(otpMethod === 'phone'
                    ? { phone_number: formatPhoneNumber(inputs?.phone) }
                    : { email: inputs?.email }),
            }),
        onSuccess: () => {
            setLoginModalContent('enter-otp')
            setErrors({})
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const handleChangePackage = () => {
        if (isDEPConsumer) {
            setAction(
                estimation?.consumer_status === 'DECLINED_AND_RE_ESTIMATE'
                    ? 'package-change-request-in-progress'
                    : 'confirm-package-change-request',
            )
            setActionModalOpen(true)
        } else {
            const state = {
                isAuthenticatedUser: true,
                estimation_id: estimation?.id,
                auth_user_id: kycData?.consumer?.user?.id,
                action: 'view_from_one_page_kyc_form',
                solution_id: estimation?.solution_id,
                payment_type: estimation?.payment_plan,
                phone_number: inputs?.phone,
                email: inputs?.email,
            }
            const allSolutions =
                kyc_data?.data?.data?.onboarding_type === 'ALL_SOLUTIONS'
            const profile =
                kyc_data?.data?.data?.onboarding_type === 'ENERGY_PROFILE'
            const profile_id =
                kyc_data?.data?.data?.consumer_form_questions?.energy_profile_id
            localStorage.setItem(
                'sunfiUserSSEConsumerKYC',
                encrypt(JSON.stringify(inputs)),
            )
            if (allSolutions) {
                history.push({
                    pathname: '/consumer/all-systems',
                    state,
                })
            } else if (profile) {
                history.push({
                    pathname: `/consumer/profile-details/${profile_id}`,
                    state,
                })
            } else {
                history.push({
                    pathname: isPromo
                        ? '/consumer/promos'
                        : '/consumer/systems',
                    state,
                })
            }
        }
    }

    const handleToggle = index => {
        const updatedOpenState = isOpen.map((open, i) =>
            i === index ? !open : open,
        )
        setIsOpen(updatedOpenState)
    }

    const handleSaveProgress = () => {
        setAction('save-and-continue-later')
        setActionModalOpen(true)
    }

    const handleUseTypeChange = () => {
        setAction('change-use-type')
        setActionModalOpen(true)
    }

    const checkValidation = ({ onSuccess, onError }) => {
        const conditionalSchema =
            useType === 'business'
                ? validateBusinessOnePageKyc
                : validateResidentialOnePageKyc
        conditionalSchema
            .validate(
                {
                    button: 'Submit',
                    ...inputs,
                    audit_url: state?.auditFile?.url,
                    bankStatements: inputs?.bankStatements,
                },
                {
                    context: {
                        isEmailVerifed: inputs?.emailVerified,
                        isPhoneVerified: inputs?.phoneVerified,
                    },
                    abortEarly: false,
                },
            )
            .then(() => {
                setErrors({})
                onSuccess?.()
            })
            .catch(err => {
                onError?.(err)
            })
    }

    const handleBlur = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }

    const loginInputs = {
        email: inputs?.email,
        phone: formatPhoneNumber(inputs?.phone),
    }

    const handleBvnVerifyLater = () => {
        closeBVNModal()
        BVNAlert('Please, go ahead to provide your other details', '511px')
    }

    const handleSelectOnBlur = async name => {
        await validateField({
            name,
            value: inputs[`${name}`],
            schema:
                useType === 'business'
                    ? validateBusinessOnePageKyc
                    : validateResidentialOnePageKyc,
            setErrors,
            context: {
                sameAddress: inputs.sameAddress?.value,
                useType,
                isEmailVerifed: inputs?.emailVerified,
                isPhoneVerified: inputs?.phoneVerified,
            },
        })
    }

    const bvnVerifiedSuccessfully = () => {
        successAlert('Your BVN has been verified successfully')
        setShowBVNModal(false)
        kycRefetch()
        statusRefetch()
    }

    const handleCacVerifyModal = () => {
        setShowCacVerifyModal(!showCacVerifyModal)
    }

    const cacHelpPayload = {
        email: inputs?.email || 'support@sunfi.co',
        name: inputs?.businessName,
        message: 'CAC Verification failed and consumer is requesting for help',
        phone_number: formatPhoneNumber(inputs?.phone) || '+2347080639920',
    }

    const disablePageButtons =
        estimationFetching ||
        kycFetching ||
        kycFetchStatus === 'error' ||
        estimationFetchStatus === 'error'

    const startMonoLoading = () => {
        setConnectToMonoLoading(true)
    }

    const stopMonoLoading = () => {
        setConnectToMonoLoading(false)
    }

    useEffect(() => {
        const fetchError =
            kycFetchStatus === 'error' || estimationFetchStatus === 'error'
        const fetchIsSuccessful =
            kycFetchStatus === 'success' && estimationFetchStatus === 'success'

        if (fetchError) {
            setAction('data-fetch-failed')
        }
        if (fetchError && !actionModalOpen) {
            setActionModalOpen(true)
        }
        if (fetchIsSuccessful && action === 'data-fetch-failed') {
            setActionModalOpen(false)
            setAction('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycFetchStatus, estimationFetchStatus])

    useEffect(() => {
        const encryptedData = localStorage?.getItem('sunfiUserSSEConsumerKYC')
        if (encryptedData) {
            let decryptedData = JSON.parse(decrypt(encryptedData))
            setInputs(decryptedData)
            localStorage?.removeItem('sunfiUserSSEConsumerKYC')
        }
    }, [])

    const handleBankStatement = ({ submittedStatement }) => {
        const bank_statements = kycData?.financial_verification?.bank_statements
        const primary_statement = bank_statements?.filter(statement => {
            return statement.primary_statement
        })[0]
        if (
            allowKYCSubmission &&
            (bank_statements?.length > 0 || submittedStatement)
        ) {
            if (
                primary_statement?.analysis_context?.status !== 'DONE' ||
                submittedStatement
            ) {
                if (primary_statement?.analysis_context?.status === 'FAILED') {
                    setAction('bank-statement-review-failed')
                } else if (
                    primary_statement?.analysis_context?.status ===
                        'IN_PROGRESS' ||
                    primary_statement?.analysis_context?.status === 'EMPTY' ||
                    primary_statement?.analysis_context?.status ===
                        'RETRYING' ||
                    submittedStatement
                ) {
                    if (hasFilledAllFields) {
                        setAction(
                            'bank-statement-review-in-progress-and-user-can-submit-kyc',
                        )
                    } else {
                        setAction('bank-statement-review-in-progress')
                    }
                }
                setActionModalOpen(true)
            }
        }
    }

    useEffect(() => {
        checkValidation({
            onSuccess: () => {
                setHasFilledAllFields(true)
                setSubmit(true)
            },
            onError: () => setHasFilledAllFields(false),
        })
        // eslint-disable-next-line
    }, [inputs])

    useEffect(() => {
        if (localStorage.getItem('showBankStatementModal')) {
            handleBankStatement({
                submittedStatement: false,
            })
        }
        return
        // eslint-disable-next-line
    }, [kycData])

    return (
        <ConsumerKYCContext.Provider
            value={{
                cacHelpPayload,
                sections,
                handleToggle,
                isOpen,
                estimation,
                isLoading,
                estimationFetching,
                estimationLoadingOrRefetchError:
                    estimationFetchStatus === 'error',
                isAdminUser,
                goBackToSystem,
                handleSubmit,
                showSecondaryModal,
                setShowSecondaryModal,
                handleChangePackage,
                action,
                setAction,
                actionModalOpen,
                setActionModalOpen,
                handleSaveProgress,
                handlePrimaryBtnClick,
                handleShowVideo,
                handleUseTypeChange,
                gettingStartedModalOpen,
                setGettingStartedModalOpen,
                fetchEstimation,
                inputs,
                setInputs,
                handleInputChange,
                errorMessage,
                showToastError,
                useType,
                contact_type,
                contact_details,
                kycFetching,
                kycLoadingOrRefetchError: kycFetchStatus === 'error',
                errors,
                kycData,
                showSimulateCreditDecision,
                setShowSimulateCreditDecision,
                handleBlur,
                toastError,
                showBVNModal,
                handleBvnVerification,
                closeBVNModal,
                verificationInProgress,
                setVerificationInProgress,
                handleBvnVerifyLater,
                bvnVerifiedSuccessfully,
                cacVerificationInProgress,
                setCacVerificationInProgress,
                handleCacVerifyModal,
                showCacVerifyModal,
                handleSelectChange,
                handleSelectOnBlur,
                kycStatus,
                showLoginModal,
                setShowLoginModal,
                closeLoginModal,
                loginModalContent,
                setLoginModalContent,
                otpMethod,
                setOtpMethod,
                loginInputs,
                canSubmitKYC,
                removeErrors,
                initiateOTPVerificationMutation,
                initiateOTPVerificationLoading:
                    initiateOTPVerificationMutation.isLoading,
                onVerifySuccess,
                setSubmit,
                kycRefetch,
                isSaving:
                    !submit && !actionModalOpen && submitKYCMutation.isLoading,
                isSubmitting: submitKYCMutation.isLoading,
                submit,
                paymentPlanType: kyc_data?.data?.data?.payment_plan,
                showDEPConsumerWelcomeModal,
                setShowDEPConsumerWelcomeModal,
                submitKYC: submitKYCMutation.mutate,
                showPackageDetailsDrawer,
                setShowPackageDetailsDrawer,
                isDEPConsumer,
                goToSSEHome,
                showDisputePackageModal,
                setShowDisputePackageModal,
                nudgeProvider: nudgeProviderMutation.mutate,
                nudgeProviderMutationLoading: nudgeProviderMutation.isLoading,
                disablePageButtons,
                startMonoLoading,
                stopMonoLoading,
                connectToMonoLoading,
                actionModalPrimaryBtnLoading,
                isCustomerApproved,
                redirectIfNeeded,
                ranCreditDecision,
                upfrontDepositPaid,
                statusRefetch,
                kycInfoFormik,
                showAdminSaveProgressModal,
                setShowAdminSaveProgressModal,
                bankStatementRef,
                onBankStatementUploadSuccess: handleBankStatement,
                setErrors,
                openErrorTour,
                setOpenErrorTour,
                errorTourSteps,
            }}
        >
            {children}
        </ConsumerKYCContext.Provider>
    )
}

ConsumerKYCContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    useType: PropTypes.string,
}

export const useConsumerKYCContext = () => useContext(ConsumerKYCContext)
export default ConsumerKYCContextProvider
