import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { message, Upload } from 'antd'
import { SelectField, TextArea } from 'src/components/InputFields'
import { ReactComponent as UploadIcon } from 'src/assets/images/upload.svg'
import { ReactComponent as SuccessUploadIcon } from 'src/assets/images/successUpload.svg'
import { zipSupportedbankStatementFileTypes } from 'src/utils/supportedFileTypes'
import { documentType } from './data'

import styles from './CreditReviewTab.module.scss'

const UploadDocuments = ({
    inputs,
    handleInputChange,
    handleSelectChange,
    setFileValid,
    successUpload,
    setSuccessUpload,
    errors,
}) => {
    const { Dragger } = Upload
    const { DCDUploadBankStatementStyle, DCDUploadModalContent } = styles

    const [uploading, setUploading] = useState(false)
    const [width, setWidth] = useState(40)
    // handle upload bank statement
    const props = {
        name: 'file',
        onChange({ fileList }) {
            setUploading(true)
            setSuccessUpload(fileList)
            setUploading(false)
        },
        onDrop() {
            setUploading(false)
        },
    }

    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) {
                setWidth(prev => prev + 20)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading])

    const beforeStatementUpload = file => {
        const isLt2M = file.size / 1000 / 1000 <= 10

        if (
            !Object.keys(zipSupportedbankStatementFileTypes)?.includes(
                file.type,
            )
        ) {
            message.error(
                `${
                    file.name
                } is not one of the supported file types (${Object.values(
                    zipSupportedbankStatementFileTypes,
                )?.join(',')})`,
            )
            setFileValid(false)
            return false
        } else if (!isLt2M) {
            message.error('File should be less than 10mb')
            setFileValid(false)
            return false
        } else {
            setFileValid(true)
            return true
        }
    }

    return (
        <>
            <div className={'UploadModalWrapper'}>
                <SelectField
                    name="documentType"
                    initialOption="Select document type"
                    selectWidth="100%"
                    handleChange={handleSelectChange}
                    withCheckBox={true}
                    dropdownPositionRelative
                    selectedValue={inputs?.documentType}
                    currentSelected={inputs?.documentType}
                    values={documentType?.map(type => ({
                        value: type,
                    }))}
                    value={inputs?.documentType}
                    marginBottom="16px"
                    showSearch
                    errorMessage={errors.documentType}
                    floatingLabel={
                        inputs.documentType === '' ? '' : 'Select document type'
                    }
                />
                <div
                    style={{
                        marginBottom: '16px',
                    }}
                >
                    <Dragger
                        className={DCDUploadBankStatementStyle}
                        fileList={successUpload}
                        maxCount={1}
                        showUploadList={true}
                        accept={Object.values(
                            zipSupportedbankStatementFileTypes,
                        )?.join(',')}
                        beforeUpload={beforeStatementUpload}
                        customRequest={() => {}}
                        style={{
                            border: errors?.document && '1px solid #da1e28',
                        }}
                        {...props}
                    >
                        {uploading ? (
                            <div className="SlideContainer">
                                <p
                                    className="Slider"
                                    style={{
                                        width: `${width}%`,
                                    }}
                                ></p>
                                <p
                                    className="UploadText"
                                    style={{ marginTop: '5px' }}
                                >
                                    Uploading document...{' '}
                                </p>
                            </div>
                        ) : successUpload?.length > 0 ? (
                            <div className={DCDUploadModalContent}>
                                <SuccessUploadIcon />
                                <p>{successUpload?.[0]?.name}</p>
                            </div>
                        ) : (
                            <div className={DCDUploadModalContent}>
                                <UploadIcon />
                                <p>
                                    Upload bank statement here. Drag and drop or
                                    &nbsp;
                                    <span>browse</span>
                                </p>
                                <p style={{ fontSize: '13px' }}>
                                    If more than one bank statement, please zip
                                    and upload file <br />
                                    Supported format : pdf or zip
                                </p>
                                <p>The maximum accepted file size is 10mb</p>
                            </div>
                        )}
                    </Dragger>
                    <p className="ApprovedModalUploadError">
                        {errors?.document}
                    </p>
                </div>

                <TextArea
                    title="What insights are you looking out for in this added document "
                    rows={10}
                    height="146px"
                    name="note"
                    value={inputs.note}
                    handleChange={handleInputChange}
                    errorMessage={errors.note}
                />
            </div>
        </>
    )
}

export default UploadDocuments

UploadDocuments.propTypes = {
    handleInputChange: PropTypes.func,
    inputs: PropTypes.object,
    handleSelectChange: PropTypes.func,
    setFileValid: PropTypes.func,
    successUpload: PropTypes.array,
    setSuccessUpload: PropTypes.func,
    errors: PropTypes.object,
}
