import { parsePhoneNumber } from 'libphonenumber-js'
import { appTracking } from 'src/utils/appTracker'
import {
    formatBusinessType,
    formatBusinessTypeMapping,
} from 'src/utils/business'
import {
    formatPhoneNumber,
    removeCountryCodefromNGNNum,
} from 'src/utils/formatting'
import { useTypeMapping } from './data'

const removeEmptyFields = obj => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (key === 'identity_information' && value['selfie_url'] === '') {
            // Preserve identity_information as an empty object if selfie_url is empty
            acc[key] = {}
        } else if (
            value &&
            typeof value === 'object' &&
            !Array.isArray(value)
        ) {
            const nestedObj = removeEmptyFields(value)
            if (Object.keys(nestedObj).length > 0) {
                acc[key] = nestedObj
            }
        } else if (value !== '' && value !== null && value !== undefined) {
            acc[key] = value
        }
        return acc
    }, {})
}

export const formatConsumerKYC = (data, inputs) => {
    let generalInfo = {
        firstName: data?.consumer?.user?.first_name ?? inputs?.firstNname,
        lastName: data?.consumer?.user?.last_name ?? inputs?.lastName,
        phone: data?.consumer?.user?.phone_number
            ? parsePhoneNumber(data?.consumer?.user?.phone_number || '', 'NG')
                  ?.nationalNumber
            : inputs?.phone,
        phoneVerified: data?.consumer?.user?.phone_verified,
        email: data?.consumer?.user?.email ?? inputs?.email,
        emailVerified: data?.consumer?.user?.email_verified,
        contactAddress:
            data?.consumer?.user?.street_address ?? inputs?.contactAddress,
        contactState:
            data?.consumer?.user?.state_of_residence ?? inputs?.contactState,
        contactCity: data?.consumer?.user?.city ?? inputs?.contactCity,
        contactCountry: data?.consumer?.user?.country ?? inputs?.contactCountry,
        confirmAddress:
            data?.consumer?.user?.street_address ===
                data?.consumer?.installation_details?.street_address &&
            data?.consumer?.user?.state_of_residence ===
                data?.consumer?.installation_details?.state &&
            data?.consumer?.user?.city ===
                data?.consumer?.installation_details?.city
                ? 'true'
                : data?.consumer?.user?.street_address !==
                      data?.consumer?.installation_details?.street_address &&
                  data?.consumer?.installation_details?.street_address?.length >
                      0
                ? 'false'
                : data?.consumer?.user?.state_of_residence !==
                      data?.consumer?.installation_details?.state &&
                  data?.consumer?.installation_details?.state?.length > 0
                ? 'false'
                : data?.consumer?.user?.city !==
                      data?.consumer?.installation_details?.city &&
                  data?.consumer?.installation_details?.city?.length > 0
                ? 'false'
                : 'true',
        installationAddress:
            data?.consumer?.installation_details?.street_address ??
            inputs?.installationAddress,
        installationState:
            data?.consumer?.installation_details?.state ??
            inputs?.installationState,
        installationCity:
            data?.consumer?.installation_details?.city ??
            inputs?.installationCity,
        installationCountry:
            data?.consumer?.installation_details?.country ??
            inputs?.installationCountry,
        employmentType:
            data?.consumer?.employment_status ?? inputs?.employmentType,
        companyName:
            data?.consumer?.employment_details?.company_name ??
            inputs?.companyName,
        jobTitle:
            data?.consumer?.employment_details?.job_title ?? inputs?.jobTitle,
        isBusinessRegistered:
            data?.consumer?.business?.is_business_registered ??
            inputs?.isBusinessRegistered,
        businessName:
            data?.consumer?.business?.business_name ?? inputs?.businessName,
        businessIndustry:
            data?.consumer?.business?.business_industry ??
            inputs?.businessIndustry,
        cacNumber:
            data?.consumer?.business?.business_registration_number ??
            inputs?.cacNumber,
        cacNumberVerified:
            data?.consumer?.business?.business_registration_number_verified,
        businessWebsite:
            data?.consumer?.business?.business_website ??
            inputs?.businessWebsite,
        businessDescription:
            data?.consumer?.business?.business_description ??
            inputs?.businessDescription,
        pensionManager:
            data?.consumer?.pension_details?.pension_manager ??
            inputs?.pensionManager,
        retirementDate:
            data?.consumer?.pension_details?.retirement_date ??
            inputs?.retirementDate,
        selfieImageUrl: data?.consumer?.user?.avatar_url ?? '',
        bvn: data?.financial_verification?.bvn?.number ?? inputs?.bvn,
        bvnVerified:
            data?.financial_verification?.bvn?.verified ?? inputs?.bvnVerified,
        bankStatements:
            data?.financial_verification?.bank_statements ??
            inputs?.bankStatements,
        business: data?.consumer?.business,
        businessType:
            formatBusinessTypeMapping[
                data?.consumer?.business?.business_type
            ] ?? inputs?.businessType,
        directorEmail:
            data?.consumer?.director_details?.email ?? inputs?.directorEmail,
        directorFirstName:
            data?.consumer?.director_details?.first_name ??
            inputs?.directorFirstName,
        directorLastName:
            data?.consumer?.director_details?.last_name ??
            inputs?.directorLastName,
        directorPhone:
            removeCountryCodefromNGNNum(
                data?.consumer?.director_details?.phone_number,
            ) ?? inputs?.directorPhone,
        bank_statement_id:
            data?.financial_verification?.bank_statements?.find(
                item => item?.primary_statement,
            )?.id ?? inputs?.bank_statement_id,
        moa_url:
            data?.consumer?.business?.memorandum_of_association?.url ??
            inputs?.moa_url,
        audited_financial_report:
            data?.consumer?.audited_financial_reports[0]?.url ??
            inputs?.audited_financial_report,
    }
    return generalInfo
}

export const formatKYCPayload = (
    inputs,
    estimation,
    type,
    canRemoveEmptyFields = true,
) => {
    const residentialPayload = {
        basic_information: {
            personal_information: {
                first_name: inputs?.firstName,
                last_name: inputs?.lastName,
                phone_number: formatPhoneNumber(inputs?.phone),
                email: inputs?.email,
                contact_address: {
                    street_address: inputs?.contactAddress,
                    city: inputs?.contactCity,
                    state_of_residence: inputs?.contactState,
                    country: inputs?.contactCountry,
                },
                installation_address: {
                    street_address: inputs?.confirmAddress
                        ? inputs?.contactAddress
                        : inputs?.installationAddress,
                    city: inputs?.confirmAddress
                        ? inputs?.contactCity
                        : inputs?.installationCity,
                    state: inputs?.confirmAddress
                        ? inputs?.contactState
                        : inputs?.installationState,
                    country: inputs?.confirmAddress
                        ? inputs?.contactCountry
                        : inputs?.installationCountry,
                },
            },
            employment_information: {
                employment_type: inputs?.employmentType,
                ...(inputs?.employmentType !== '' && {
                    ...(inputs?.employmentType !== 'Retired' && {
                        company_name: inputs?.companyName,
                        job_title: inputs?.jobTitle,
                    }),
                    ...(inputs?.employmentType === 'Retired' && {
                        pension_manager: inputs?.pensionManager,
                        retirement_date: inputs?.retirementDate,
                    }),
                    ...(inputs?.employmentType === 'Business' && {
                        is_business_registered: inputs?.isBusinessRegistered,
                    }),
                    ...(inputs?.employmentType === 'Self Employed' && {
                        is_business_registered: inputs?.isBusinessRegistered,
                    }),
                    ...(inputs?.employmentType === 'Self Employed' && {
                        business_details: {
                            ...(!inputs?.isBusinessRegistered && {
                                business_industry: inputs?.businessIndustry,
                                business_description:
                                    inputs?.businessDescription,
                            }),
                            business_name: inputs?.businessName,
                        },
                    }),
                }),
            },
        },
        identity_information: {
            selfie_url: inputs?.selfieImageUrl,
        },
        financial_information: {
            estimation_id: estimation.id,
            select_primary: {
                bank_statement_id: inputs?.bank_statement_id,
            },
        },
    }

    const businessPayload = {
        business_information: {
            business_type: formatBusinessType(inputs?.businessType),
            business_industry: inputs?.businessIndustry,
            business_description: inputs?.businessDescription,
            business_name: inputs?.businessName,
            contact_address: {
                street_address: inputs?.contactAddress,
                city: inputs?.contactCity,
                state_of_residence: inputs?.contactState,
                country: inputs?.contactCountry,
            },
            installation_address: {
                street_address: inputs?.confirmAddress
                    ? inputs?.contactAddress
                    : inputs?.installationAddress,
                city: inputs?.confirmAddress
                    ? inputs?.contactCity
                    : inputs?.installationCity,
                state: inputs?.confirmAddress
                    ? inputs?.contactState
                    : inputs?.installationState,
                country: inputs?.confirmAddress
                    ? inputs?.contactCountry
                    : inputs?.installationCountry,
            },
            memorandum_of_association_url: inputs?.moa_url,
        },
        director_information: {
            first_name: inputs?.directorFirstName,
            last_name: inputs?.directorLastName,
            phone_number: inputs?.directorPhone?.length
                ? formatPhoneNumber(inputs?.directorPhone)
                : '',
            email: inputs?.directorEmail,
        },
        contact_information: {
            first_name: inputs?.firstName,
            last_name: inputs?.lastName,
            phone_number: formatPhoneNumber(inputs?.phone),
            email: inputs?.email,
        },
        financial_information: {
            estimation_id: estimation.id,
            select_primary: {
                bank_statement_id: inputs?.bank_statement_id,
            },
            audited_financial_report: inputs?.audited_financial_report,
            password: '',
        },
    }

    let payload =
        type?.toLowerCase() === 'business'
            ? businessPayload
            : residentialPayload

    return canRemoveEmptyFields ? removeEmptyFields(payload) : payload
}

const calculateCompletedFieldsFromObject = (obj, extraFields = {}) => {
    let completed = 0
    let total = 0

    const traverse = value => {
        if (value && typeof value === 'object') {
            Object.values(value).forEach(traverse)
        } else if (value) {
            completed += 1
            total += 1
        } else {
            total += 1
        }
    }
    traverse(obj)

    Object.values(extraFields).forEach(value => {
        total += 1
        if (value) completed += 1
    })

    return { completed, total }
}

export const getSectionFields = (useType, title, inputs, estimation, type) => {
    const sectionMapping = {
        residential: {
            'Basic Information': 'basic_information',
            'Identity Information': 'identity_information',
        },
        business: {
            'Business Information': 'business_information',
            "Director's Information": 'director_information',
            'Contact Information': 'contact_information',
        },
    }

    const extraFields = {
        ...(title === 'Financial Information' &&
            useType === 'business' && {
                bank_statement_id: inputs?.bank_statement_id,
                audited_financial_report: inputs?.audited_financial_report,
            }),
        ...(title === 'Financial Information' &&
            useType === 'residential' && {
                bvn: inputs?.bvn,
                bank_statement_id: inputs?.bank_statement_id,
            }),
        ...(title === "Director's Information" &&
            useType === 'business' && {
                bvn: inputs?.bvn,
            }),
        ...(title === 'Basic Innformation' &&
            useType === 'residential' && {
                cacNumber: inputs?.cacNumber,
            }),
    }

    // Handle "Financial Information" separately
    if (title === 'Financial Information') {
        const { completed, total } =
            calculateCompletedFieldsFromObject(extraFields)
        return { completed_fields: completed, required_fields: total }
    }

    const sectionKey = sectionMapping[useType]?.[title]
    const payload = formatKYCPayload(inputs, estimation, type, false)

    if (inputs?.confirmAddress === 'true') {
        if (
            payload.basic_information?.personal_information
                ?.installation_address
        ) {
            delete payload.basic_information.personal_information
                .installation_address
        }
        if (payload.business_information?.installation_address) {
            delete payload.business_information.installation_address
        }
    }

    let sectionData = sectionKey ? payload[sectionKey] || {} : {}

    // Append extraFields for "Director's Information"
    if (title === "Director's Information" && useType === 'business') {
        sectionData = { ...sectionData, ...extraFields }
    }

    const { completed, total } = calculateCompletedFieldsFromObject(sectionData)

    return { completed_fields: completed, required_fields: total }
}

export const getRequiredFieldsForSection = (useType, title, inputs) => {
    let result = []

    if (useType === 'residential') {
        if (title === 'Basic Information') {
            result = [
                'firstName',
                'lastName',
                'email',
                'phone',
                'contactAddress',
                'contactCountry',
                'contactState',
                'contactCity',
            ]

            if (inputs?.confirmAddress !== 'true') {
                result = [
                    ...result,
                    ...[
                        'installationAddress',
                        'installationCountry',
                        'installationState',
                        'installationCity',
                    ],
                ]
            }

            result.push('employmentType')

            if (inputs?.employmentType) {
                if (
                    ['Full Time', 'Part Time', 'Contract'].includes(
                        inputs.employmentType,
                    )
                ) {
                    result = [...result, ...['companyName', 'jobTitle']]
                } else if (inputs?.employmentType === 'Retired') {
                    result = [
                        ...result,
                        ...['pensionManager', 'retirementDate'],
                    ]
                } else if (inputs?.employmentType === 'Self Employed') {
                    result.push('isBusinessRegistered')

                    if (inputs?.isBusinessRegistered === true) {
                        result = [...result, ...['businessName', 'cacNumber']]
                    } else if (inputs?.isBusinessRegistered === false) {
                        result = [
                            ...result,
                            ...['businessName', 'businessIndustry'],
                        ]
                    }
                }
            }
        } else if (title === 'Identity Information') {
            result = ['selfieImageUrl']
        } else if (title === 'Financial Information') {
            result = ['bvn', 'bank_statement_id']
        }
    } else if (useType === 'business') {
        if (title === 'Business Information') {
            result = [
                'businessName',
                'contactAddress',
                'contactCountry',
                'contactState',
                'contactCity',
            ]

            if (inputs?.confirmAddress !== 'true') {
                result = [
                    ...result,
                    ...[
                        'installationAddress',
                        'installationCountry',
                        'installationState',
                        'installationCity',
                    ],
                ]
            }

            result.push('businessType')

            if (inputs?.businessType) {
                if (
                    [
                        'Sole Proprietorship',
                        'Limited Liability Company',
                        'Self-Employed',
                    ].includes(inputs.businessType)
                ) {
                    result = [
                        ...result,
                        ...[
                            'businessIndustry',
                            'cacNumber',
                            'moa_url',
                            'businessDescription',
                        ],
                    ]
                } else if (
                    inputs.businessType === 'Non-governmental Organisation'
                ) {
                    result = [
                        ...result,
                        ...[
                            'businessIndustry',
                            'cacNumber',
                            'businessDescription',
                        ],
                    ]
                }
            }
        } else if (title === "Director's Information") {
            result = [
                'directorFirstName',
                'directorLastName',
                'directorEmail',
                'directorPhone',
                'bvn',
            ]
        } else if (title === 'Contact Information') {
            result = ['firstName', 'lastName', 'email', 'phone']
        } else if (title === 'Financial Information') {
            result = ['audited_financial_report', 'bank_statement_id']
        }
    }

    return result
}

export const getSectionCompletionData = (
    useType,
    title,
    inputs,
    errors,
    fields = [],
) => {
    const requiredFields = fields?.length
        ? fields
        : getRequiredFieldsForSection(useType, title, inputs)

    const completeBankStatementsAnalysis = inputs?.bankStatements?.find(
        statement =>
            statement?.analysis_context?.status === 'DONE' &&
            statement?.name_match &&
            statement?.analysis_context?.statement_complete,
    )

    const completedFields = []
    const errorFields = []
    const incompleteFields = []

    const requiredCount = requiredFields.length

    for (const fieldName of requiredFields) {
        if (
            errors[fieldName] ||
            (!completeBankStatementsAnalysis &&
                title === 'Financial Information' &&
                ['bankStatementId', 'bank_statement_id'].includes(fieldName) &&
                inputs?.[fieldName])
        ) {
            errorFields.push({ name: fieldName, status: 'error' })
        } else if ([undefined, '', null].includes(inputs?.[fieldName])) {
            incompleteFields.push({ name: fieldName, status: 'incomplete' })
        } else {
            completedFields.push({ name: fieldName, status: 'complete' })
        }
    }

    const hasError = !!errorFields.length
    const isComplete = !hasError && requiredCount === completedFields.length
    const completedCount = completedFields.length

    return {
        isComplete,
        hasError,
        requiredCount,
        completedCount,
        completedFields,
        fields: [...completedFields, ...errorFields, ...incompleteFields],
    }
}

export const getPrimaryStatementType = statements => {
    const primaryStatement = statements?.find(
        statement => statement?.primary_statement,
    )

    if (primaryStatement) {
        if (primaryStatement?.source === 'MONO') {
            return 'Mono'
        } else if (primaryStatement?.source === 'MANUAL_UPLOAD') {
            return 'Manual'
        }
    }

    return ''
}

export const mapValuesToLabelsString = (values, options) => {
    if (!Array.isArray(values) || !Array.isArray(options)) {
        return ''
    }
    const valueToLabelMap = options?.reduce((map, option) => {
        if (option.value !== undefined && option.label !== undefined) {
            map[option.value] = option.label
        }
        return map
    }, {})
    const mappedLabels = values?.map(value => valueToLabelMap[value] || value)
    return mappedLabels.join(', ')
}

export const handleMixPanelTracking = ({
    eventActionAndCategory,
    estimation,
    inputs,
    event,
    isDynamicKYC = false,
}) => {
    const pageTitle =
        'Customer KYC | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    let mixpanelPayload = {}

    if (estimation && inputs) {
        mixpanelPayload = {
            'Use type': useTypeMapping[estimation?.use_type],
            ...(inputs?.employmentType && {
                'Employment type': inputs?.employmentType,
            }),
            ...(inputs?.businessType && {
                'Business type': formatBusinessType(inputs?.businessType),
            }),
            'Self employed registration status':
                inputs?.employmentType === 'Self Employed' ? 'Yes' : 'No',
            'Multiple address state': isDynamicKYC
                ? inputs?.sameAddress?.value
                : JSON.parse(inputs?.confirmAddress),
            'Plan Type': estimation?.payment_plan,
            'Customer Channel':
                estimation?.survey_type === 'DFS' ? 'DEP' : 'DTC',
            'Package Cost': estimation?.total_price,
            'No of Appliance': estimation?.appliances_count,
            'Package Size': estimation?.capacity,
            'Monthly Repayment': estimation?.price_per_month,
            'Bank statement type': getPrimaryStatementType(
                inputs?.bankStatements,
            ),
        }
    }

    appTracking(
        pageUrlName,
        pageTracker,
        pageTitle,
        eventActionAndCategory,
        '1-page KYC',
        eventActionAndCategory,
        ['MP'],
        'event',
        {
            event: event ? event : mixpanelPayload,
        },
    )
    appTracking(
        pageUrlName,
        pageTracker,
        pageTitle,
        eventActionAndCategory,
        '1-page KYC',
        eventActionAndCategory,
        ['MP'],
        'people_set',
        {
            people_set: event ? event : mixpanelPayload,
        },
    )
}
