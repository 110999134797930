import Button, { ConsumerButton } from 'src/components/Button'
import PropType from 'prop-types'
import { Collapse, Dropdown, Menu } from 'antd'
import { ReactComponent as ArrowDown } from 'src/assets/images/arrowDown.svg'
import { ReactComponent as VerifiedIcon } from 'src/assets/images/circle-check-green.svg'
import { ReactComponent as CaretUp } from 'src/assets/images/caret-up.svg'
import { ReactComponent as CaretDown } from 'src/assets/images/caret-down.svg'
import { ReactComponent as CancelIcon } from 'src/assets/images/close-circle-red.svg'
import styles from './AsideDetails.module.scss'
import { AsideLoaders } from '../Loaders'
import moment from 'moment'
import { denyPermission } from 'src/utils/permissionFramework'
import { useEffect, useRef, useState } from 'react'
import { usePlanContext } from '../../hooks/planProvider'
import { isMobile } from 'src/utils/mediaQueries'
import {
    MoreActions,
    canUploadUpfrontDeposit,
    handlePermission,
    outrightSaleMoreActions,
} from '../../utils/data'
import UpdateRepaymentModal from '../UpdatedRepayment'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import Toast from 'src/components/Toast'
import { CostField } from 'src/components/InputFields/CostField'
import { TextArea } from 'src/components/InputFields'
import { useQuery } from 'react-query'
import { InlineLoader } from 'src/components/Loader'
import {
    requestCancelRepaymentSetupApi,
    requestRepaymentSetupApi,
    requestUpfrontDepositApi,
    getConsumerCheckReport,
    closePaymentPlan,
} from 'src/api/admin/payment-plans/ssePlan'
import { useParams } from 'react-router-dom'
import { errorHandler } from 'src/utils/errorHandler'
import { RequestUpfrontDepositValidationSchema } from 'src/utils/validationSchema'
import ActionModal from 'src/components/ActionModalV2'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import ButtonComponent from 'src/components/Button'
import {
    avatarParams,
    checkParams,
    consumerTypeMap,
    handlePositioning,
} from '../../utils/utils'
import ApprovePaymentPlan from 'src/pages/Admin/PaymentPlans/Details/components/ApprovedPaymentPlan/ApprovedPaymentPlan'
import ClosureActionModal from 'src/pages/Admin/PaymentPlans/Details/components/ClosureActionModal'
import GetModal from 'src/components/Modal'
import RequestBankStatementModal from '../RequestBankStatement'
import StatusBreakdown from 'src/components/StatusBreakdown'
import CustomToolTip from 'src/components/CustomToolTip'
import { permissionsControl } from 'src/utils/permissionsControl'
import Buyout from 'src/components/Buyout/Buyout'
import { useCalculateBuyoutAmount } from 'src/api/buyout/queries'

const { Panel } = Collapse

const AsideDetails = ({
    isPaymentPlanLoading,
    paymentPlanData,
    paymentPlanDataRefetch,
    recommendationType,
    isOutrightSale,
}) => {
    const [showRequestDepositModal, setShowRequestDepositModal] =
        useState(false)
    const [showBuyoutModal, setShowBuyoutModal] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [showUpdateRepaymentModal, setShowUpdateRepaymentModal] =
        useState(false)
    const [notes, setNotes] = useState('')
    const [requestDepositToastError, setRequestDepositToastError] =
        useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [requestErrors, setRequestErrors] = useState('')
    const [depositRequestSuccessful, setDepositRequestSuccessful] =
        useState(false)
    const [checkReportData, setCheckReportData] = useState({})

    const [selected, setSelected] = useState('')
    const [openApprovedModal, setOpenApprovedModal] = useState(false)
    const [closureModal, setClosureModal] = useState(false)
    const [inputs, setInputs] = useState({
        declineReason: '',
    })
    const [requestBankStatementModal, setRequestBankStatementModal] =
        useState(false)
    const [errors, setErrors] = useState('')

    const { errorAlert, successAlert } = useCustomToast()

    const params = useParams()

    const {
        setMoreActionsContent,
        setRecordDepositContent,
        setModalDisplayValues,
        openModal,
        setOpenModal,
    } = usePlanContext()

    const moreActions = isOutrightSale ? outrightSaleMoreActions : MoreActions

    useQuery({
        queryKey: ['get-check-report', paymentPlanData?.consumer?.user.id],
        enabled:
            paymentPlanData?.consumer?.user.id !== (null || undefined) &&
            recommendationType === 'auto',
        retry: false,
        queryFn: () =>
            getConsumerCheckReport(paymentPlanData?.consumer?.user.id),
        onSuccess: data => {
            setCheckReportData(data?.data?.data)
        },
        onError: error => {
            errorAlert(
                errorHandler(error?.response?.data) ||
                    'Unable to fetch check report',
            )
        },
    })

    // request upfront deposit endpoints integration
    const {
        isFetching: requestUpfrontDepositLoading,
        refetch: requestUpfrontDepositRefetch,
    } = useQuery(
        'postUpfrontDepositRequest',
        () => requestUpfrontDepositApi(params?.id, { notes: notes }),
        {
            retry: false,
            enabled: false,
            onSuccess: () => {
                setShowRequestDepositModal(false)
                setDepositRequestSuccessful(true)
                setNotes('')
            },
            onError: error => {
                setErrorMessage(errorHandler(error))
                setRequestDepositToastError(true)
            },
        },
    )

    // request repayment setup
    const {
        isFetching: requestRepaymentSetupLoading,
        refetch: requestRepaymentSetupRefetch,
    } = useQuery(
        'postRepaymentSetupRequest',
        () => requestRepaymentSetupApi(params?.id, notes),
        {
            retry: false,
            enabled: false,
            onSuccess: () => {
                setShowRequestDepositModal(false)
                setDepositRequestSuccessful(true)
                setNotes('')
            },
            onError: error => {
                setErrorMessage(errorHandler(error))
                setRequestDepositToastError(true)
            },
        },
    )

    // close plan
    const { refetch: closePlanRefetch, isLoading: closePlanLoading } = useQuery(
        'close-payment-plan-awaiting-approval',
        () =>
            closePaymentPlan(params?.id, {
                action: 'CLOSE_APPLICATION',
                reason: inputs?.declineReason,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setClosureModal(false)
                successAlert('Plan closed successfully')
                paymentPlanDataRefetch()
            },
            onError: () => {
                setClosureModal(false)
                errorAlert('Unable to close plan')
            },
        },
    )

    // cancle repayment method setup endpoints integration
    const {
        isFetching: cancelRepaymentMethodLoading,
        refetch: cancelRepaymentSetupRefetch,
    } = useQuery(
        'postCancelRepaymentMethodRequest',
        () => requestCancelRepaymentSetupApi(params?.id, { reason: notes }),
        {
            retry: false,
            enabled: false,
            onSuccess: () => {
                setShowRequestDepositModal(false)
                setDepositRequestSuccessful(true)
                setNotes('')
            },
            onError: error => {
                setErrorMessage(errorHandler(error))
                setRequestDepositToastError(true)
            },
        },
    )

    const handleSubmit = () => {
        if (showUpfrontDeposit) {
            RequestUpfrontDepositValidationSchema.validate(
                { notes: notes },
                {
                    abortEarly: false,
                },
            )
                .then(() => {
                    setRequestErrors('')
                    requestUpfrontDepositRefetch()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setRequestErrors(errList)
                })
        } else if (showCancelRepaymentMethodSetup) {
            RequestUpfrontDepositValidationSchema.validate(
                { notes: notes },
                {
                    abortEarly: false,
                },
            )
                .then(() => {
                    setRequestErrors('')
                    cancelRepaymentSetupRefetch()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setRequestErrors(errList)
                })
        } else {
            requestRepaymentSetupRefetch()
        }
    }

    // display modal under more actions
    const handleMoreActionsModal = info => {
        setMoreActionsContent([info])
        setModalDisplayValues('More Actions')
        setOpenModal(!openModal)
    }

    const handleShowDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    const handleInputChange = event => {
        const { value } = event.target
        setNotes(value)
    }

    // display modal using the 'Record Deposit' button
    const handleRecordDepositModal = () => {
        setRecordDepositContent([
            {
                keyID: 'UPFRONT_DEPOSIT',
                label: 'Upfront Deposit',
                statusBox:
                    paymentPlanData?.upfront_deposit_paid_at == null
                        ? '#fffaea'
                        : '#daf9ea',
                statusText:
                    paymentPlanData?.upfront_deposit_paid_at == null
                        ? '#ffc222'
                        : '#32cc79',
                statusMsg:
                    paymentPlanData?.upfront_deposit_paid_at == null
                        ? 'PENDING'
                        : 'COMPLETED',
                updatedTime: paymentPlanData?.upfront_deposit_paid_at,
                btnText:
                    paymentPlanData?.upfront_deposit_paid_at == null
                        ? 'Upload'
                        : 'View',
                modalTitle:
                    paymentPlanData?.upfront_deposit_paid_at == null
                        ? 'Record Deposit'
                        : 'View Deposit',
                modalSubTitle:
                    'Enter the details of the payment received by the provider',
                uploadText: 'Evidence of Payment',
                actionEvidence:
                    paymentPlanData?.upfront_deposit_evidence.secure_url,
                permission:
                    paymentPlanData?.upfront_deposit_paid_at === null
                        ? 'upfront_deposit:can_upload'
                        : 'upfront_deposit:can_view',
            },
        ])
        setModalDisplayValues('Record Deposit')
        setOpenModal(!openModal)
    }

    const upfront_deposit_not_paid =
        paymentPlanData?.upfront_deposit_paid_at === null

    const upfront_deposit_is_required =
        paymentPlanData?.upfront_deposit_percentage > 0

    const showUpfrontDeposit =
        upfront_deposit_not_paid && upfront_deposit_is_required

    const showCancelRepaymentMethodSetup =
        JSON.stringify(paymentPlanData?.repayment_method_setup) !== '{}' &&
        paymentPlanData?.repayment_method_setup?.is_active === true

    const canClosePaymentPlan = permissionsControl(['can_close_estimation'])

    // detect click outside dropdown
    const ref = useRef(null)
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowDropdown(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [showDropdown])

    useEffect(() => {
        // Call the positioning function on load and when the window is resized
        handlePositioning()

        window.addEventListener('resize', handlePositioning)

        return () => {
            window.removeEventListener('resize', handlePositioning)
        }
    }, [])

    const { calculateBuyoutAmountMutate, calculateBuyoutAmountData } =
        useCalculateBuyoutAmount({
            onSuccess: () => {
                setShowDropdown(false)
                setShowBuyoutModal(true)
            },
            user: 'admin',
        })

    const menu = recommendationType === 'auto' && (
        <Menu>
            {Object.keys(paymentPlanData).length > 0 &&
                moreActions(paymentPlanData)?.map((info, i) => {
                    return (
                        <Menu.Item
                            key={i}
                            onClick={
                                !info.notAllowed &&
                                (info.keyID === 'REQUEST_PAYMENT_SETUP'
                                    ? () => {
                                          setShowRequestDepositModal(true)
                                      }
                                    : info.keyID === 'UPDATE_REPAYMENT_SETUP'
                                    ? () => setShowUpdateRepaymentModal(true)
                                    : info.keyID === 'REQUEST_BUY_OUT'
                                    ? () => {
                                          calculateBuyoutAmountMutate({
                                              estimation_id: params?.id,
                                              user_id:
                                                  paymentPlanData?.consumer
                                                      ?.user?.id,
                                          })
                                      }
                                    : () => {
                                          handleMoreActionsModal(info)
                                          setShowDropdown(false)
                                      })
                            }
                            disabled={
                                handlePermission(info?.permission) ||
                                (info.keyID === 'REQUEST_PAYMENT_SETUP' &&
                                    showUpfrontDeposit)
                            }
                        >
                            <CustomToolTip
                                showTooltip={
                                    !!info.notAllowed ||
                                    handlePermission(info?.permission) ||
                                    (info.keyID === 'REQUEST_PAYMENT_SETUP' &&
                                        showUpfrontDeposit)
                                }
                                text={
                                    info.notAllowedReason ||
                                    'You are not authorised to perform this action'
                                }
                            >
                                <span
                                    className={`dropdownLinks ${
                                        info.notAllowed
                                            ? styles.disabledLink
                                            : ''
                                    }`}
                                >
                                    {info.uploadText !== 'None'
                                        ? info.disabled
                                            ? 'View'
                                            : info.keyID ===
                                              'UPDATE_REPAYMENT_SETUP'
                                            ? ''
                                            : 'Upload'
                                        : ''}{' '}
                                    {info.moreActionsLabel}
                                </span>
                            </CustomToolTip>
                        </Menu.Item>
                    )
                })}
        </Menu>
    )

    function statusButton(status) {
        if (status === 'APPROVED') {
            return (
                <>
                    {paymentPlanData.payment_plan !== 'Outright Sale' && (
                        <Button
                            btnWidth={'120px'}
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnHeight={isMobile ? '56px' : '44px'}
                            handleClick={
                                upfront_deposit_not_paid
                                    ? () => setShowRequestDepositModal(true)
                                    : handleRecordDepositModal
                            }
                            disabled={
                                handlePermission({
                                    ...(upfront_deposit_not_paid && {
                                        permission: canUploadUpfrontDeposit,
                                    }),
                                    permissionKey: upfront_deposit_not_paid
                                        ? 'upfront_deposit:can_request'
                                        : 'upfront_deposit:can_view',
                                }) || !upfront_deposit_is_required
                            }
                        >
                            {upfront_deposit_not_paid ? 'Request ' : 'View '}{' '}
                            Deposit
                        </Button>
                    )}
                    <div ref={ref}>
                        <Dropdown
                            overlay={menu}
                            placement="bottomLeft"
                            trigger={['click']}
                            visible={showDropdown}
                            overlayClassName="aside-details-dropdown"
                        >
                            <Button
                                btnBgColor="var(--purple-light)"
                                btnTextColor="#004AAD"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnHeight={isMobile ? '56px' : '44px'}
                                btnWidth={isMobile ? '160px' : '120px'}
                                handleClick={handleShowDropdown}
                            >
                                More Actions
                                <ArrowDown
                                    style={{
                                        marginLeft: 12,
                                    }}
                                />
                            </Button>
                        </Dropdown>
                    </div>
                </>
            )
        } else if (
            status === 'IN_PROGRESS' ||
            paymentPlanData?.approval_status === 'AWAITING_APPROVAL' ||
            paymentPlanData?.approval_status === 'AWAITING_UPDATE'
        ) {
            return (
                <>
                    <ButtonComponent
                        btnWidth={isMobile ? '160px' : '136px'}
                        btnHeight={isMobile ? '56px' : '44px'}
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        handleClick={() => {
                            setSelected('Close Payment Plan')
                            setOpenApprovedModal(true)
                        }}
                        disabled={
                            process.env
                                .REACT_APP_FLAG_ENFORCE_ADMIN_PERMISSION ===
                            'true'
                                ? !canClosePaymentPlan
                                : denyPermission(
                                      'admin',
                                      'inprogress_requests',
                                      'request:can_close',
                                  )
                        }
                    >
                        Close Payment Plan
                    </ButtonComponent>
                    {isOutrightSale &&
                        paymentPlanData.payment_plan === 'Outright Sale' &&
                        paymentPlanData.application_status ===
                            'AWAITING_PAYMENT' && (
                            <div ref={ref}>
                                <Dropdown
                                    overlay={menu}
                                    placement="bottomLeft"
                                    trigger={['click']}
                                    visible={showDropdown}
                                    overlayClassName="aside-details-dropdown"
                                >
                                    <Button
                                        btnBgColor="var(--purple-light)"
                                        btnTextColor="#004AAD"
                                        btnTextColorOutline="var(--blue)"
                                        btnOutlineColor="var(--purple-light)"
                                        btnBgColorOutline="#E2EEFF"
                                        type="outline"
                                        btnHeight={isMobile ? '56px' : '44px'}
                                        btnWidth={isMobile ? '160px' : '120px'}
                                        handleClick={handleShowDropdown}
                                    >
                                        More Actions
                                        <ArrowDown
                                            style={{
                                                marginLeft: 12,
                                            }}
                                        />
                                    </Button>
                                </Dropdown>
                            </div>
                        )}
                </>
            )
        }
    }

    return (
        <>
            {/* request bank statement modal */}
            <GetModal
                app="adminsolution"
                showModal={requestBankStatementModal}
                modalWidth="624px"
                modalHeight="475px"
                closable={false}
                content={
                    <RequestBankStatementModal
                        closeModal={() => setRequestBankStatementModal(false)}
                        estimationId={params?.id}
                        sseUse
                    />
                }
            />

            {/* Payment collection enhancement - buyout */}
            <Buyout
                details={calculateBuyoutAmountData}
                showBuyoutModal={showBuyoutModal}
                setShowBuyoutModal={setShowBuyoutModal}
            />

            {/* IN PROGRESS, AWAITING APPROVAL, AWAITING UPDATE */}
            {(paymentPlanData?.approval_status === 'IN_PROGRESS' ||
                paymentPlanData?.approval_status === 'AWAITING_APPROVAL' ||
                paymentPlanData?.approval_status === 'AWAITING_UPDATE') && (
                <SecondaryModal
                    showModal={openApprovedModal}
                    modalHeight="450px"
                    modalWidth="580px"
                    modalClass="adminModal"
                    closable={false}
                    content={
                        <ApprovePaymentPlan
                            closeModal={() => setOpenApprovedModal(false)}
                            selected={selected}
                            estimationId={params?.id}
                            inputs={inputs}
                            setInputs={setInputs}
                            errors={errors}
                            setErrors={setErrors}
                            iconOnClick={() => {
                                setOpenApprovedModal(false)
                                setInputs({
                                    declineReason: '',
                                })
                                setErrors('')
                            }}
                            headerText={
                                selected === 'Close Payment Plan' &&
                                'Close Payment Plan'
                            }
                            btnText={
                                selected === 'Close Payment Plan' &&
                                'Close Plan'
                            }
                            setClosureModal={setClosureModal}
                        />
                    }
                />
            )}

            <ClosureActionModal
                estimationType="Payment Plan"
                actionModalOpen={closureModal}
                closeModal={() => setClosureModal(false)}
                onCancel={() => setClosureModal(false)}
                actionHandler={() => closePlanRefetch()}
                loading={closePlanLoading}
            />

            {/* APPROVED */}
            <UpdateRepaymentModal
                showUpdateRepaymentModal={showUpdateRepaymentModal}
                setShowUpdateRepaymentModal={setShowUpdateRepaymentModal}
                paymentPlanDataRefetch={paymentPlanDataRefetch}
                paymentPlanData={paymentPlanData}
            />

            {/* request successfully sent */}
            <ActionModal
                actionType="success"
                headerText="Request Sent Successfully"
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText="Okay, Got it"
                actionModalOpen={depositRequestSuccessful}
                actionHandler={() => setDepositRequestSuccessful(false)}
                onCancel={() => setDepositRequestSuccessful(false)}
                noCancelBtn
                closable
                noBackLink
            />

            {/* request deposit and setup modals */}
            <SecondaryModal
                modalWidth="624px"
                modalHeight="506px"
                modalClass="RecommendedEnergy"
                showModal={showRequestDepositModal}
                onCancel={() => {
                    setShowRequestDepositModal(false)
                    setNotes('')
                }}
                closable={true}
                content={
                    <div className={styles.repaymentMethodModal}>
                        {requestDepositToastError && (
                            <Toast messageType="error" message={errorMessage} />
                        )}
                        <h2>
                            {showUpfrontDeposit
                                ? 'Request Upfront Deposit'
                                : showCancelRepaymentMethodSetup
                                ? 'Cancel Repayment Method Setup'
                                : 'Request Repayment Method Setup'}
                        </h2>
                        <p
                            style={{
                                marginBottom:
                                    !upfront_deposit_not_paid && '50px',
                            }}
                        >
                            Enter the details of the request below to continue
                        </p>
                        <div className="USUpfrontDepositInputsWrapper">
                            {showUpfrontDeposit && (
                                <CostField
                                    title="Upfront Deposit"
                                    name="upfrontDeposit"
                                    value={paymentPlanData?.upfront_deposit}
                                    inputWidth="100%"
                                    width="200px"
                                    disabled={true}
                                    noFloat
                                    cssStyle={{
                                        position: 'relative',
                                        top: '6px',
                                        marginLeft: '-14px',
                                    }}
                                    prefilled
                                    floatTitlePositionLeft="90px"
                                    marginBottom="20px"
                                    selectWidth="100px"
                                    currencyCode={'NGN'}
                                    defaultValue={'NGN'}
                                />
                            )}
                            <TextArea
                                title={`Add Short Note ${
                                    !showUpfrontDeposit &&
                                    !showCancelRepaymentMethodSetup
                                        ? '(optional)'
                                        : ''
                                }`}
                                height="170px"
                                resize
                                name="notes"
                                inputValue={notes}
                                value={notes}
                                rows={10}
                                handleChange={handleInputChange}
                                errorMessage={requestErrors?.notes}
                            />
                        </div>
                        <ConsumerButton
                            btnWidth="160px"
                            btnHeight="56px"
                            btnTextColor="#FFFFFF"
                            btnBgColor="#004AAD"
                            btnFontSize="14px"
                            marginTop="48px"
                            handleClick={() => {
                                handleSubmit()
                            }}
                        >
                            {requestUpfrontDepositLoading ||
                            requestRepaymentSetupLoading ||
                            cancelRepaymentMethodLoading ? (
                                <InlineLoader />
                            ) : showCancelRepaymentMethodSetup ? (
                                'Cancel request'
                            ) : (
                                'Send Request'
                            )}
                        </ConsumerButton>
                    </div>
                }
            />

            {isPaymentPlanLoading ? (
                <AsideLoaders />
            ) : (
                <aside className={styles.asidePlanContainer}>
                    <h3>OFFER DETAILS</h3>

                    <div className={styles.asidePlanContainer__details}>
                        <div>
                            <h6>Creation date</h6>
                            <p>
                                {moment(paymentPlanData?.created_at).format(
                                    'DD MMM, YYYY, HH:mm',
                                )}
                            </p>
                        </div>
                        {paymentPlanData?.survey_type === 'DFS' ? (
                            <div>
                                <h6>Created by</h6>
                                <p>
                                    {paymentPlanData?.created_by?.display_name}
                                </p>
                            </div>
                        ) : null}
                        <div>
                            <h6>Consumer type</h6>
                            <p>
                                {recommendationType === 'alternative'
                                    ? 'DTC'
                                    : paymentPlanData?.survey_type === 'CFS'
                                    ? 'DTC'
                                    : paymentPlanData?.survey_type === 'DFS'
                                    ? 'DEP'
                                    : ''}
                            </p>
                        </div>
                        <div>
                            <h6>Last updated</h6>
                            <p>
                                {moment(paymentPlanData?.updated_at).format(
                                    'DD MMM, YYYY, HH:mm',
                                )}
                            </p>
                        </div>
                        <div>
                            <h6>Solution type</h6>
                            <p>
                                {consumerTypeMap[
                                    paymentPlanData?.customer_type
                                ] || '--'}
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles.asidePlanContainer__btns}
                        id="asideButtons"
                    >
                        {recommendationType === 'auto' &&
                            statusButton(paymentPlanData?.approval_status)}
                    </div>
                    {paymentPlanData?.customer_type === 'RESIDENTIAL' &&
                        !isOutrightSale && (
                            <div
                                className={
                                    styles.asidePlanContainer__checkReport
                                }
                            >
                                <h3>CONSUMER CHECK REPORT</h3>

                                <div
                                    className={
                                        styles.asidePlanContainer__checkReport__wrapper
                                    }
                                >
                                    <div
                                        className={
                                            styles.asidePlanContainer__checkReport__item
                                        }
                                    >
                                        <Collapse
                                            accordion
                                            bordered={false}
                                            expandIconPosition="right"
                                            ghost
                                            size="small"
                                            className={
                                                styles.paymentPlanDetails__collapse
                                            }
                                            expandIcon={({ isActive }) => {
                                                if (isActive) {
                                                    return (
                                                        <CaretUp
                                                            role={
                                                                'presentation'
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <CaretDown
                                                            role={
                                                                'presentation'
                                                            }
                                                        />
                                                    )
                                                }
                                            }}
                                        >
                                            <Panel
                                                header="AVATAR CHECK"
                                                className={
                                                    styles.paymentPlanDetails__panel
                                                }
                                                style={{ padding: 0 }}
                                                key="1"
                                            >
                                                {avatarParams.map(
                                                    (param, i) => (
                                                        <div
                                                            key={'id_check' + i}
                                                            className={
                                                                styles.paymentPlanDetails__panel__item
                                                            }
                                                        >
                                                            <p>{param.label}</p>
                                                            {checkReportData
                                                                ?.avatar_check?.[
                                                                param.key
                                                            ] ? (
                                                                <VerifiedIcon />
                                                            ) : (
                                                                <CancelIcon />
                                                            )}
                                                        </div>
                                                    ),
                                                )}
                                            </Panel>
                                        </Collapse>
                                    </div>

                                    <div
                                        className={
                                            styles.asidePlanContainer__checkReport__item
                                        }
                                    >
                                        <Collapse
                                            accordion
                                            bordered={false}
                                            expandIconPosition="right"
                                            ghost
                                            size="small"
                                            className={
                                                styles.paymentPlanDetails__collapse
                                            }
                                            expandIcon={({ isActive }) => {
                                                if (isActive) {
                                                    return (
                                                        <CaretUp
                                                            role={
                                                                'presentation'
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <CaretDown
                                                            role={
                                                                'presentation'
                                                            }
                                                        />
                                                    )
                                                }
                                            }}
                                        >
                                            <Panel
                                                header="ID CARD CHECK"
                                                className={
                                                    styles.paymentPlanDetails__panel
                                                }
                                                style={{ padding: 0 }}
                                                key="2"
                                            >
                                                {checkParams.map((param, i) => (
                                                    <div
                                                        key={'id_check' + i}
                                                        className={
                                                            styles.paymentPlanDetails__panel__item
                                                        }
                                                    >
                                                        <p>{param.label}</p>
                                                        {checkReportData
                                                            ?.id_card_check?.[
                                                            param.key
                                                        ] ? (
                                                            <VerifiedIcon />
                                                        ) : (
                                                            <CancelIcon />
                                                        )}
                                                    </div>
                                                ))}
                                            </Panel>
                                        </Collapse>
                                    </div>
                                    <div
                                        className={
                                            styles.asidePlanContainer__checkReport__item
                                        }
                                    >
                                        <Collapse
                                            accordion
                                            bordered={false}
                                            expandIconPosition="right"
                                            ghost
                                            size="small"
                                            className={
                                                styles.paymentPlanDetails__collapse
                                            }
                                            expandIcon={({ isActive }) => {
                                                if (isActive) {
                                                    return (
                                                        <CaretUp
                                                            role={
                                                                'presentation'
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <CaretDown
                                                            role={
                                                                'presentation'
                                                            }
                                                        />
                                                    )
                                                }
                                            }}
                                        >
                                            <Panel
                                                header="BVN CHECK"
                                                className={
                                                    styles.paymentPlanDetails__panel
                                                }
                                                style={{ padding: 0 }}
                                                key="3"
                                            >
                                                {checkParams.map((param, i) => (
                                                    <div
                                                        key={'bvn_check' + i}
                                                        className={
                                                            styles.paymentPlanDetails__panel__item
                                                        }
                                                    >
                                                        <p>{param.label}</p>
                                                        {checkReportData
                                                            ?.bvn_check?.[
                                                            param.key
                                                        ] ? (
                                                            <VerifiedIcon />
                                                        ) : (
                                                            <CancelIcon />
                                                        )}
                                                    </div>
                                                ))}
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        )}
                    {recommendationType === 'auto' && (
                        <StatusBreakdown
                            margin="32px 0 0 0"
                            status={paymentPlanData?.application_status}
                            consumerType={
                                paymentPlanData?.survey_type === 'CFS' ||
                                paymentPlanData?.survey_type === 'UFS'
                                    ? 'DTC'
                                    : 'DEP'
                            }
                            plan={paymentPlanData?.payment_plan}
                        />
                    )}
                </aside>
            )}
        </>
    )
}

export default AsideDetails

AsideDetails.propTypes = {
    isPaymentPlanLoading: PropType.bool,
    paymentPlanData: PropType.object,
    paymentPlanDataRefetch: PropType.func,
    recommendationType: PropType.string,
    isOutrightSale: PropType.bool,
}
