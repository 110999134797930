import { employmentTypes, businessTypes } from '../../data'
import { InputFields } from 'src/components/InputFields'
import { useConsumerKYCContext } from '../../context'
import { useState } from 'react'
import VerifyCacModal from '../../../CreateAccount/DynamicInformation/components/VerifyCacModal'
import PensionDetails from '../../../CreateAccount/DynamicInformation/components/Form/components/PensionDetails'
import BusinessDetails from './BusinessDetails'
import UploadModal from 'src/components/UploadModal'
import RemoveFileModal from '../../../CreateAccount/_components/RemoveFileModal'
import { memorandumOfAssociationFileTypes } from 'src/utils/supportedFileTypes'
import { uploadToS3 } from 'src/api/utils/file-upload'
import { useMutation } from 'react-query'
import { InlineLoader } from 'src/components/Loader'

const DetailsSection = () => {
    const {
        useType,
        inputs,
        setInputs,
        handleInputChange,
        errors,
        handleSelectOnBlur,
        handleSelectChange,
        handleBlur,
        cacVerificationInProgress,
        handleCacVerifyModal,
        showCacVerifyModal,
        setCacVerificationInProgress,
        cacHelpPayload,
        kycRefetch,
        removeErrors,
        isCustomerApproved,
    } = useConsumerKYCContext()

    const isBusiness = useType === 'business'
    const isResidential = useType === 'residential'
    const types = isBusiness ? businessTypes : employmentTypes
    const [showCalendar, setShowCalendar] = useState(false)
    const [moa, setMoa] = useState([])
    const isEmployed = ['Full Time', 'Part Time', 'Contract'].includes(
        inputs?.employmentType,
    )
    const [successMOAUpload, setSuccessMOAUpload] = useState([])
    const [showRemoveFileModal, setShowRemoveFileModal] = useState(false)
    const [fileToBeRemoved, setFileToBeRemoved] = useState({
        title: '',
        url: '',
        fileType: '',
    })
    const [documentType, setDocumentType] = useState('')
    const [showUploadModal, setShowUploadModal] = useState(false)

    const handleTypeSelection = item => {
        const name = useType === 'business' ? 'businessType' : 'employmentType'
        setInputs(prev => {
            if (prev[name] === item) {
                return { ...prev, [name]: null }
            } else {
                return {
                    ...prev,
                    ...(isResidential &&
                        item !== 'Self Employed' && {
                            isBusinessRegistered: null,
                            businessType: '',
                        }),
                    [name]: item,
                }
            }
        })
        removeErrors(name)
    }

    const handleBusinessRegistrationToggle = isRegistered => {
        setInputs(prev => ({
            ...prev,
            isBusinessRegistered: isRegistered,
            businessType: inputs?.employmentType,
        }))
        removeErrors('isBusinessRegistered')
    }

    const handleUploadClick = type => {
        setDocumentType(type)
        setShowUploadModal(true)
    }

    const handleRemoveFile = (title, url) => {
        setFileToBeRemoved({
            title: title,
            url: url,
            fileType: 'MEMORANDUM_OF_ASSOCIATION',
        })
        setShowRemoveFileModal(true)
    }

    const handleCloseUploadModal = () => {
        setShowUploadModal(false)
        if (documentType === 'memorandum_of_association') {
            setSuccessMOAUpload([])
        }
    }

    const closeRemoveFileModal = () => {
        setFileToBeRemoved({
            title: '',
            url: '',
            fileType: '',
        })
        setShowRemoveFileModal(false)
    }

    const closeRemoveFileModalAfterSuccess = () => {
        if (fileToBeRemoved.title === 'MOA') {
            setMoa([])
            setInputs(prev => ({
                ...prev,
                moa_url: undefined,
            }))
        }
        setShowRemoveFileModal(false)
    }

    const uploadFileMutation = useMutation({
        mutationFn: ({ file, category }) => uploadToS3(category, file[0]),
        onSuccess: res => {
            setInputs(prev => ({
                ...prev,
                moa_url: res?.url + res?.fields?.key,
            }))
            setShowUploadModal(false)
        },
        onError: () => {},
    })

    const handleUploadToS3 = () => {
        if (documentType === 'memorandum_of_association') {
            uploadFileMutation.mutate({
                file: successMOAUpload,
                category: 'MEMORANDUM_OF_ASSOCIATION',
            })
        }
    }

    return (
        <>
            <UploadModal
                showModal={showUploadModal}
                onCancel={
                    !uploadFileMutation.isLoading && handleCloseUploadModal
                }
                title={'Memorandum of association'}
                primaryBtnText={
                    uploadFileMutation.isLoading ? <InlineLoader /> : 'Confirm'
                }
                successUpload={successMOAUpload}
                setSuccessUpload={setSuccessMOAUpload}
                isLoading={uploadFileMutation.isLoading}
                acceptableFileTypes={memorandumOfAssociationFileTypes}
                setFileToState={setMoa}
                handlePrimaryBtnClick={() => handleUploadToS3()}
            />

            <RemoveFileModal
                showModal={showRemoveFileModal}
                fileToBeRemoved={fileToBeRemoved}
                closeRemoveFileModal={closeRemoveFileModal}
                closeRemoveFileModalAfterSuccess={
                    closeRemoveFileModalAfterSuccess
                }
            />

            <div className="DetailsContainer">
                <p>
                    Select Your{' '}
                    {isBusiness ? 'Business type' : 'Employment Type'}
                </p>
                <div className="GroupItems Types" id="employmentType">
                    {types?.map(item => (
                        <span
                            key={item}
                            onClick={() =>
                                !isCustomerApproved && handleTypeSelection(item)
                            }
                            className={`Badge${
                                inputs?.employmentType === item ||
                                inputs?.businessType === item
                                    ? ' ActiveType'
                                    : ''
                            }`}
                        >
                            {item}
                        </span>
                    ))}
                </div>

                {(inputs?.employmentType !== '' ||
                    inputs?.businessType !== '') && (
                    <>
                        {isResidential &&
                            inputs?.employmentType === 'Self Employed' && (
                                <div className="isBusiness">
                                    <p>Is your business registered?</p>
                                    <div
                                        className="GroupItems"
                                        id="isBusinessRegistered"
                                    >
                                        <span
                                            onClick={() =>
                                                handleBusinessRegistrationToggle(
                                                    true,
                                                )
                                            }
                                            className={`Badge ${
                                                inputs?.isBusinessRegistered ===
                                                true
                                                    ? 'ActiveType'
                                                    : ''
                                            }`}
                                        >
                                            Yes
                                        </span>
                                        <span
                                            onClick={() =>
                                                handleBusinessRegistrationToggle(
                                                    false,
                                                )
                                            }
                                            className={`Badge ${
                                                inputs?.isBusinessRegistered ===
                                                false
                                                    ? 'ActiveType'
                                                    : ''
                                            }`}
                                        >
                                            No
                                        </span>
                                    </div>
                                </div>
                            )}
                        <BusinessDetails
                            inputs={inputs}
                            isBusiness={isBusiness}
                            isResidential={isResidential}
                            errors={errors}
                            cacVerificationInProgress={
                                cacVerificationInProgress
                            }
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            handleSelectOnBlur={handleSelectOnBlur}
                            handleBlur={handleBlur}
                            handleCacVerifyModal={handleCacVerifyModal}
                            moa={moa}
                            setMoa={setMoa}
                            removeFromErrObj={removeErrors}
                            handleRemoveFile={handleRemoveFile}
                            handleUploadClick={handleUploadClick}
                            setInputs={setInputs}
                            isCustomerApproved={isCustomerApproved}
                        />

                        <div>
                            {inputs?.employmentType === 'Retired' && (
                                <PensionDetails
                                    inputs={inputs}
                                    errors={errors}
                                    handleChange={handleInputChange}
                                    handleOnBlur={handleBlur}
                                    showCalendar={showCalendar}
                                    setShowCalendar={setShowCalendar}
                                    handleSelectChange={handleSelectChange}
                                    handleSelectOnBlur={handleSelectOnBlur}
                                    isCustomerApproved={isCustomerApproved}
                                    v2
                                    onePageKyc
                                />
                            )}
                            {!isBusiness && isEmployed && (
                                <div className="GroupItems flex-column">
                                    <InputFields
                                        title="Where do you work?"
                                        name="companyName"
                                        value={inputs?.companyName}
                                        handleChange={handleInputChange}
                                        onBlur={handleBlur}
                                        marginBottom={0}
                                        disabled={isCustomerApproved}
                                    />
                                    <InputFields
                                        title="Job Title"
                                        name="jobTitle"
                                        value={inputs?.jobTitle}
                                        handleChange={handleInputChange}
                                        onBlur={handleBlur}
                                        marginBottom={0}
                                        disabled={isCustomerApproved}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            <VerifyCacModal
                showModal={showCacVerifyModal}
                onCancel={() => {
                    handleCacVerifyModal()
                    kycRefetch()
                }}
                cacHelpPayload={cacHelpPayload}
                setVerificationInProgress={setCacVerificationInProgress}
                inputs={inputs}
            />
        </>
    )
}

export default DetailsSection
