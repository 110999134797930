import { LogoLoader } from 'src/components/Loader'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'
import panelsAndTurbineImg from 'src/assets/images/panel-and-turbine.png'
import s from './manageproviderrequest.module.scss'

export const pageValues = status => {
    switch (status) {
        case 'approving-request':
            return {
                icon: <LogoLoader />,
                title: 'Approving Provider’s Request',
                description:
                    "Your decision to approve the provider's request to discard the previous payment plan and create a new one is being processed. Sit back and relax, this will only take a moment.",
                showChatCta: false,
            }
        case 'request-approved':
            return {
                icon: <SuccessIcon />,
                title: 'Provider’s Request Approved',
                description:
                    'Your previous plan has been discarded, and your provider can now proceed to create a new payment plan.',
                showChatCta: true,
            }
        case 'decline-request':
            return {
                icon: <LogoLoader />,
                title: 'Declining Provider’s Request',
                description:
                    "Your decision to decline the provider's request to discard the previous payment plan is being processed. This will only take a moment.",
                showChatCta: false,
            }
        case 'request-declined':
            return {
                icon: (
                    <img
                        src={panelsAndTurbineImg}
                        alt="solar panel and turbine"
                        className={s.requestDeclinedImg}
                    />
                ),
                title: 'Provider’s Request Declined ',
                description:
                    "Your decision to decline the provider's request to discard the previous payment plan has been recorded. No further action will be taken.",
                showChatCta: true,
            }
        default:
            return {
                icon: '',
                title: '',
                description: '',
                showChatCta: false,
            }
    }
}

export const statusMap = {
    approve: 'approving-request',
    decline: 'decline-request',
}

export const onSuccessStatusMap = {
    'approving-request': 'request-approved',
    'decline-request': 'request-declined',
}
