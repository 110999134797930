import { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { useMutation } from 'react-query'
import { retrieveUserDetailsApi } from 'src/api/estimations/estimations'

import { isMobile } from 'src/utils/mediaQueries'
import CustomerType from '../CustomerType.jsx'
import {
    formatPhoneNumber,
    removeCountryCodefromNGNNum,
} from 'src/utils/formatting.js'
import { newDEPCreatePaymentPlanUseTypeSchema } from 'src/utils/validationSchema'
import { errorHandler } from 'src/utils/errorHandler'
import ActionModalV2 from 'src/components/ActionModalV2'
import { getActionModalData } from '../data.js'

const NewCustomerModal = ({
    useType,
    setUseType,
    useTypeError,
    setUseTypeError,
    customerType,
    setShowModal,
    setToastError,
    setErrorMessage,
    showModal,
    onClose,
}) => {
    const isRetrieveUserDetailsFlow =
        process.env.REACT_APP_FLAG_RETURNING_CUSTOMERS_V2 === 'true'
    const history = useHistory()

    const [customerInfo, setCustomerInfo] = useState({
        email: '',
        phone: '',
        customer_type: '',
    })

    const [customerInfoErrors, setCustomerInfoErrors] = useState({})
    const [showActionModal, setShowActionModal] = useState(false)
    const [actionModalContent, setActionModalContent] = useState('')

    // Retrieve existing user details
    const retrieveUserDetailsMutation = useMutation({
        mutationKey: ['retrieveUserDetailsApi'],
        mutationFn: payload => retrieveUserDetailsApi(payload),
        onSuccess: res => {
            const data = res?.data?.data
            setShowModal(false)

            if (
                data?.status === 'INCORRECT_EMAIL' ||
                data?.status === 'INCORRECT_PHONE_NUMBER'
            ) {
                setActionModalContent('wrong-credentials')
                setShowActionModal(true)
            } else if (data?.user) {
                setActionModalContent('prefilled-info')
                setShowActionModal(true)

                setCustomerInfo(prev => ({
                    ...prev,
                    email: data?.user?.email,
                    phoneNumber: data?.user?.phone_number
                        ? removeCountryCodefromNGNNum(data?.user?.phone_number)
                        : '',
                    firstName: data?.user?.first_name,
                    lastName: data?.user?.last_name,
                    businessName: data?.business?.name,
                    businessAddress: data?.business?.address,
                    businessCity: data?.business?.city,
                    businessState: data?.business?.state,
                }))
            } else {
                history.push({
                    pathname: '/app/payment-plans/create/one',
                    state: {
                        newCustomerType: customerInfo.customer_type,
                        isExistingUser: false,
                        customerInfo: {
                            email: customerInfo?.email,
                            phoneNumber: customerInfo.phone,
                        },
                    },
                })
            }
        },
        onError: error => {
            setToastError?.(true)
            setErrorMessage?.(errorHandler(error?.response.data))
        },
    })

    const handleValidateCustomerInfoAndRetrieveDetails = () => {
        newDEPCreatePaymentPlanUseTypeSchema
            .validate(customerInfo, {
                abortEarly: false,
            })
            .then(() => {
                setCustomerInfoErrors({})
                retrieveUserDetailsMutation.mutate({
                    email: customerInfo.email,
                    phone_number: formatPhoneNumber(customerInfo.phone),
                })
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setCustomerInfoErrors(errList)
            })
    }

    const actionModalData = getActionModalData(actionModalContent)

    const actionModalHandler = () => {
        switch (actionModalContent) {
            case 'wrong-credentials':
                setShowActionModal(false)
                setShowModal(true)
                setActionModalContent('')
                break
            case 'prefilled-info':
                setShowActionModal(false)
                setActionModalContent('')

                history.push({
                    pathname: '/app/payment-plans/create/one',
                    state: {
                        newCustomerType: customerInfo.customer_type,
                        isExistingUser: true,
                        customerInfo,
                    },
                })
                break
            default:
                setShowActionModal(false)
                setActionModalContent('')
        }
    }

    const onActionModalClose = () => {
        setShowActionModal(false)
        setActionModalContent('')
        setCustomerInfo({})
    }

    const handleModalClose = () => {
        onClose?.()
        setCustomerInfo({ email: '', phone: '', customer_type: '' })
    }

    return (
        <>
            <SecondaryModal
                showModal={showModal}
                onCancel={handleModalClose}
                modalWidth={'475px'}
                title={
                    isRetrieveUserDetailsFlow
                        ? 'Getting started on SunFi'
                        : 'Select the customer use type'
                }
                v2
                content={
                    <CustomerType
                        useType={useType}
                        setUseType={setUseType}
                        useTypeError={useTypeError}
                        setUseTypeError={setUseTypeError}
                        inputs={customerInfo}
                        setInputs={setCustomerInfo}
                        errors={customerInfoErrors}
                        setErrors={setCustomerInfoErrors}
                    />
                }
                showFooter
                footerLeftContent={'chat'}
                primaryBtnText={'Confirm'}
                primaryBtnLoading={retrieveUserDetailsMutation.isLoading}
                handlePrimaryBtnClick={() => {
                    if (isRetrieveUserDetailsFlow) {
                        handleValidateCustomerInfoAndRetrieveDetails()
                    } else {
                        if (
                            useType?.useType?.length === undefined ||
                            useType?.useType?.length === 0
                        ) {
                            setUseTypeError({
                                useType:
                                    'Please select your use type to continue',
                            })
                        } else {
                            if (customerType === 'new') {
                                history.push({
                                    pathname: '/app/payment-plans/create/one',
                                    state: {
                                        newCustomerType: useType?.useType,
                                    },
                                })
                            } else {
                                history.push({
                                    pathname: '/app/payment-plans/create',
                                    state: { customerType },
                                })
                            }
                        }
                    }
                }}
                primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            />

            <ActionModalV2
                title={actionModalData.title}
                actionModalOpen={showActionModal}
                actionType={actionModalData.actionType}
                headerText={actionModalData.headerText}
                subTitle={actionModalData.subTitle}
                primaryBtnText={actionModalData.primaryBtnText}
                secondaryBtnText={actionModalData.secondaryBtnText}
                handlePrimaryBtnClick={actionModalHandler}
                v2
                closable
                onCancel={onActionModalClose}
                footerLeftContent={actionModalData.footerLeftContent}
            />
        </>
    )
}

NewCustomerModal.propTypes = {
    useType: PropTypes.object,
    setUseType: PropTypes.func,
    useTypeError: PropTypes.object,
    setUseTypeError: PropTypes.func,
    customerType: PropTypes.string,
    setShowModal: PropTypes.func,
    setToastError: PropTypes.func,
    setErrorMessage: PropTypes.func,
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
}

export default NewCustomerModal
