import ChatCTA from 'src/components/ChatCTA'
import s from './manageproviderrequest.module.scss'
import { pageValues } from './utils'
import AccountSetupFooter from 'src/components/ConsumerFooter/AccountSetupFooter'
import useManageProviderRequest from './hook'

const ManageProviderRequest = () => {
    const { status } = useManageProviderRequest()
    const { icon, title, description, showChatCta } = pageValues(status)
    return (
        <div className={s.pageWrapper}>
            <div className={s.pageContentWrapper}>
                <div className={s.pageContent}>
                    {icon}
                    <h1>{title}</h1>
                    <p>{description}</p>
                    {showChatCta && (
                        <ChatCTA
                            margin="8px auto 0 auto"
                            width={'100%'}
                            justifyContent="center"
                            textOneFontSize={'14px'}
                        />
                    )}
                </div>
            </div>
            <AccountSetupFooter />
        </div>
    )
}

export default ManageProviderRequest
