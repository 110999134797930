import { ReactComponent as PagaIcon } from 'src/assets/images/paga.svg'
import { ReactComponent as CardDebitIcon } from 'src/assets/images/card-debit.svg'
import { ReactComponent as DirectDebitIcon } from 'src/assets/images/direct-debit.svg'
import { ReactComponent as PaystackLogo } from 'src/assets/images/paystack-icon-2.svg'
import { ReactComponent as RemitaLogo } from 'src/assets/images/remita-logo.svg'
import { ReactComponent as RecovaLogo } from 'src/assets/images/recova-logo.svg'

export const paymentTypeList = repaymentMethodType => {
    let type = repaymentMethodType
    if (Array.isArray(repaymentMethodType)) {
        type = repaymentMethodType.join(' ')?.toLowerCase()
    }

    const isDirectDebit = type.includes('direct')
    const isPaga =
        process.env.REACT_APP_ENABLE_PAGA_FOR_REPAYMENT_METHOD_SETUP ===
            'true' && type.includes('paga')
    const isCard = type.includes('card')

    const list = [
        {
            id: 1,
            icon: <DirectDebitIcon />,
            hoverIcon: '',
            displayed_title: 'Direct debit',
            title: 'Direct Debit',
            subTitle: 'Pay with your credit approved bank account',
            enabled: isDirectDebit,
        },
        {
            id: 2,
            icon: <CardDebitIcon />,
            hoverIcon: '',
            displayed_title: 'Card Debit',
            title: 'Card Debit',
            subTitle: 'Pay with your credit approved debit card',
            enabled: isCard,
        },
        {
            id: 3,
            icon: <PagaIcon />,
            hoverIcon: '',
            displayed_title: 'Pay with Paga',
            title: 'Pay with Paga',
            subTitle: 'Setup your SunFi repayment using Paga',
            enabled: isPaga,
        },
    ]

    return list
}

export const directDebitTypeList = directDebitTypes => {
    return directDebitTypes
        .map((type, index) => {
            let icon
            let title
            let subTitle
            let value

            switch (type) {
                case 'PAYSTACK:DIRECT_DEBIT':
                    if (
                        process.env.REACT_APP_SHOW_PAYSTACK_DIRECT_DEBIT ===
                        'true'
                    ) {
                        icon = <PaystackLogo />
                        title = 'Pay using Paystack'
                        value = 'paystack'
                        subTitle =
                            'Pay with your credit approved bank account using Paystack'
                    }

                    break
                case 'REMITA:DIRECT_DEBIT':
                    if (
                        process.env.REACT_APP_ENABLE_REMITTA_DIRECT_DEBIT ===
                        'true'
                    ) {
                        icon = <RemitaLogo style={{ width: '36px' }} />
                        title = 'Pay using Remita'
                        value = 'remita'
                        subTitle =
                            'Pay with your credit approved bank account using Remitta'
                    }
                    break
                case 'RECOVA:DIRECT_DEBIT':
                    if (
                        process.env.REACT_APP_ENABLE_RECOVA_DIRECT_DEBIT ===
                        'true'
                    ) {
                        icon = <RecovaLogo style={{ width: '36px' }} />
                        title = 'Pay using Recova'
                        value = 'recova'
                        subTitle =
                            'Pay with your credit approved bank account using Recova'
                    }

                    break
                // Add more cases for other direct debit types as needed
                default:
                    icon = null
                    title = ''
                    subTitle = ''
            }

            if (icon && title && subTitle) {
                return {
                    id: index + 1,
                    icon,
                    title,
                    value,
                    subTitle,
                }
            }
            return null
        })
        .filter(Boolean)
}

export const selectDirectDebitCopyOptions = [
    {
        title: 'How would you like to make payment?',
        description: 'Choose a preferred payment type from the options below.',
    },
    {
        title: 'Experiencing trouble with Recova? Select another option',
        description:
            "We noticed you're experiencing a challenge setting up your repayment through Recova. No worries, you can try using any of the options below.",
    },
]

export const getCardSetupFeedbackData = type => {
    switch (type) {
        case 'KYC_NAME_MISMATCH':
            return {
                title: "Name on card doesn't match",
                headerText: 'Card name doesn’t match name on KYC',
                subtitle:
                    'We noticed that the name on the card does not match the name on your application. Please check and try again.',
                icon: 'error',
                leftContent: 'chat',
                leftButtonText: '',
                rightButtonText: 'Continue',
            }
        case 'BANK_STATEMENT_MISMATCH':
            return {
                title: "Bank statement doesn't match card",
                headerText:
                    "Bank statement analysis submitted doesn't match Bank's card",
                subtitle:
                    'The card you provided does not match the details on the bank statement you submitted during your KYC',
                icon: 'error',
                leftContent: 'chat',
                leftButtonText: '',
                rightButtonText: 'Continue',
            }
        case 'CARD_EXPIRY_SOON':
            return {
                title: 'Card Expiration Notice',
                headerText: 'The card provided will expire soon',
                subtitle:
                    'Please be advised that we currently don’t accept any card expiring within the next six months. This means that the card must be valid for at least six months. Please use another card or let us notify you when you can continue with your repayment setup.',
                icon: 'error',
                leftContent: 'button',
                leftButtonText: 'Notify Me',
                rightButtonText: 'Use Another Card',
            }
        case 'CARD_EXPIRED':
            return {
                title: 'Card Expiration Notice',
                headerText: 'The card provided has expired',
                subtitle:
                    'The card you entered has expired. Please provide a valid card to proceed.',
                icon: 'error',
                leftContent: 'button',
                leftButtonText: 'Cancel',
                rightButtonText: 'Use Another Card',
            }
        case 'INVALID_CARD':
            return {
                title: 'Card setup was unsuccessful',
                headerText: 'We couldn’t setup your repayment',
                subtitle:
                    'We couldn’t set up your repayment because the card you provided is invalid. Please double-check the card details or use a valid card to proceed.',
                icon: 'error',
                leftContent: 'button',
                leftButtonText: 'Cancel',
                rightButtonText: 'Try Again',
            }
        case 'VALIDATION SUCCESS':
            return {
                title: 'Card Setup Successful',
                headerText: 'Your card has been setup successfully',
                subtitle:
                    'Your card is all set and ready to go! Automatic repayments will now process smoothly.',
                icon: 'success',
                leftContent: 'chat',
                leftButtonText: '',
                rightButtonText: 'Continue',
            }
        default:
            return {
                title: 'Card Setup',
                headerText: '',
                subtitle: '',
                icon: 'warning',
                leftContent: 'chat',
                leftButtonText: '',
                rightButtonText: 'Continue',
            }
    }
}
