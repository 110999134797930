//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Menu } from 'antd'
import { format, parseISO } from 'date-fns'
import ActionModal from 'src/components/ActionModal'
import CompanyInfoTable from 'src/pages/Admin/Providers/Active/CompanyInfoTable'
import ProviderBankDetailsTable from 'src/pages/Admin/Providers/Active/BankDetailsTable'
import ProviderUserTable from 'src/pages/Admin/Providers/Active/UserTable'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import EditUserDetails from 'src/pages/Admin/Providers/Active/EditUserDetails'
import PendingProviderModal from 'src/pages/Admin/Providers/Pending/PendingProviderModal'
import ProviderInstallerInfo from 'src/pages/Admin/Providers/Active/InstallerInfoTable'
import WorkExperience from 'src/pages/Admin/Providers/Active/WorkExperience'
import DetailsPage from 'src/components/DetailsPage'
import ProviderResourcesTable from 'src/pages/Admin/Providers/Active/ResourcesTable'
import Toast from 'src/components/Toast'
import { errorHandler } from 'src/utils/errorHandler'
import {
    getAdminProviderUsersApi,
    getSingleAdminProviderApi,
    updateProviderStatusApi,
    getActiveProviderGroupsApi,
} from 'src/api/admin/provider'
import SeoComponent from '../../../../components/Seo'
import { denyPermission } from 'src/utils/permissionFramework'
import ComponentBrands from '../Active/ComponentBrands'
import { canViewProviderUserList } from '../Active/data'
import {
    applyDynamicPermissionsToAdminUser,
    permissionsControl,
} from 'src/utils/permissionsControl'

const AdminPendingProviders = () => {
    const { id } = useParams()
    const [showModal, setShowModal] = useState(false)
    const [activateProvider, setActivateProvider] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [selected, setSelected] = useState('')
    const [openPendingModal, setOpenPendingModal] = useState(false)
    const [providerData, setProviderData] = useState([])
    const [providerUsersData, setProviderUsersData] = useState([])
    const [triggerRefetch, setTriggerRefetch] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [groups, setGroups] = useState([])
    const newAsiOnboardingFlow =
        process.env.REACT_APP_FLAG_SHOW_NEW_ASI_ONBOARDING_FLOW === 'true'
    const history = useHistory()
    const providerIsAnAsi = history?.location?.search?.includes('asi')

    const handleModalAction = () => {
        setShowModal(false)
    }

    const handleActivateProvider = () => {
        approveProviderRefetch()
    }

    const handleEditModal = () => {
        setEditModal(false)
    }

    const menuList = [
        {
            key: 'change-type',
            label: 'Change Type',
        },
        {
            key: 'decline-provider',
            label: 'Decline Provider',
        },
    ]
    const canApproveOrDeclineProvider = permissionsControl([
        'can_update_provider_status',
    ])

    const canUpdateProviderType = permissionsControl([
        'can_update_provider_type',
    ])

    //get single provider
    const {
        refetch: singleProviderRefetch,
        isLoading: singleProviderLoading,
        isFetching: singleProviderFetching,
    } = useQuery(
        ['get-pending-single-provider'],
        () => getSingleAdminProviderApi(id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setProviderData(data?.data?.data)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't fetch details. Refresh page`)
            },
        },
    )

    const approveProviderPayload = {
        status: 'APPROVED',
    }

    // approve a provider
    const {
        refetch: approveProviderRefetch,
        isLoading: approveProviderLoading,
    } = useQuery(
        ['approve-admin-provider'],
        () => updateProviderStatusApi(id, approveProviderPayload),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setShowModal(false)
                setActivateProvider(true)
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    //get all users under the provider
    const { refetch: providerUsersRefetch, isFetching: providerUsersFetching } =
        useQuery(
            ['get-pending-provider-users'],
            () => getAdminProviderUsersApi(id),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setProviderUsersData(data?.data?.data)
                },
                onError: () => {
                    setToastError(true)
                    setErrorMessage(`Couldn't fetch details. Refresh page`)
                },
            },
        )

    // get list of groups (permission roles)
    const { refetch: getGroupsRefetch } = useQuery(
        ['get-provider-groups'],
        () => getActiveProviderGroupsApi(id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const groupData = data?.data?.data
                for (let i = 0; i < groupData?.length; i++) {
                    setGroups(prev => [
                        ...prev,
                        {
                            label: groupData?.[i]?.name,
                            value: groupData?.[i]?.name,
                            id: groupData?.[i]?.id,
                        },
                    ])
                }
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        getGroupsRefetch()
    }, [])

    useEffect(() => {
        singleProviderRefetch()
        providerUsersRefetch()
        setTriggerRefetch(false)
    }, [triggerRefetch])

    const menu = (
        <Menu>
            {menuList.map((option, index) => {
                return (
                    <Menu.Item
                        key={index}
                        onClick={() => {
                            setSelected(option?.label)
                            setOpenPendingModal(true)
                        }}
                        disabled={
                            (option?.key === 'decline-provider' &&
                            applyDynamicPermissionsToAdminUser
                                ? !canApproveOrDeclineProvider
                                : denyPermission(
                                      'admin',
                                      'unapproved_providers',
                                      'providers:can_decline',
                                  )) ||
                            (option?.key === 'change-type' &&
                                !canUpdateProviderType)
                        }
                    >
                        {option?.label}
                    </Menu.Item>
                )
            })}
        </Menu>
    )

    return (
        <>
            <SeoComponent
                title="Unapproved Provider - Provider Management | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Admin-Provider-Management"
            />
            <ActionModal
                actionModalOpen={showModal}
                headerText="Approve Provider?"
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                closeModal={handleModalAction}
                actionHandler={handleActivateProvider}
                actionType="warning"
                actionText="Approve"
                loading={approveProviderLoading}
                cancelText="Cancel"
                noBackLink
            />
            <ActionModal
                actionModalOpen={activateProvider}
                headerText="Provider Successfully Approved"
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionHandler={() => history.push('/admin/providers')}
                actionType="success"
                actionText="Okay, Got it"
                loading={''}
                noCancelBtn={true}
                noBackLink
            />
            {/*edit user details modal*/}
            <SecondaryModal
                showModal={editModal}
                modalWidth="624px"
                modalHeight={'fit-content'}
                noPadding="48px 80px"
                modalClass="RecommendedEnergy"
                onCancel={handleEditModal}
                content={<EditUserDetails />}
            />
            <SecondaryModal
                showModal={openPendingModal}
                modalHeight="450px"
                modalWidth="580px"
                modalClass="adminModal"
                closable={false}
                content={
                    <PendingProviderModal
                        selected={selected}
                        headerText={
                            selected === 'Change Type'
                                ? 'Change Provider'
                                : 'Decline Provider '
                        }
                        btnText={
                            selected === 'Change Type'
                                ? 'Change Type'
                                : 'Decline'
                        }
                        closeModal={() => setOpenPendingModal(false)}
                        providerId={id}
                    />
                }
            />
            {toastError && <Toast messageType="error" message={errorMessage} />}
            <div className="APPWrapper">
                <DetailsPage
                    backNavTitle="Back to Providers"
                    backNavOnClick={() => {
                        history.push('/admin/providers')
                    }}
                    name={providerData?.display_name}
                    status={providerData?.status}
                    infoSubhead={
                        providerIsAnAsi ? 'INSTALLER BIO' : 'BUSINESS INFO'
                    }
                    infoParagraph={
                        providerIsAnAsi
                            ? providerData?.user?.bio
                                ? providerData?.user?.bio
                                : 'Not available'
                            : providerData?.business_info
                            ? providerData?.business_info
                            : 'Not available'
                    }
                    tabPaneOneTab={
                        providerIsAnAsi ? 'Installer Info' : 'Company Info'
                    }
                    tabPaneOneContent={
                        providerIsAnAsi ? (
                            <ProviderInstallerInfo data={providerData} />
                        ) : (
                            <CompanyInfoTable data={providerData} />
                        )
                    }
                    tabPaneTwo={
                        providerIsAnAsi && newAsiOnboardingFlow
                            ? false
                            : providerIsAnAsi
                            ? true
                            : canViewProviderUserList
                    }
                    tabPaneTwoTab={
                        providerIsAnAsi ? 'Work Experience' : 'Users'
                    }
                    tabPaneTwoContent={
                        providerIsAnAsi ? (
                            <WorkExperience providerId={id} />
                        ) : (
                            <ProviderUserTable
                                data={providerUsersData}
                                setTriggerRefetch={setTriggerRefetch}
                                groups={groups}
                            />
                        )
                    }
                    tabPaneThree={
                        providerIsAnAsi && newAsiOnboardingFlow ? false : true
                    }
                    tabPaneThreeTab="Bank Details"
                    tabPaneThreeContent={
                        <ProviderBankDetailsTable data={providerData} />
                    }
                    tabPaneFour={
                        providerIsAnAsi && newAsiOnboardingFlow ? false : true
                    }
                    tabPaneFourTab="Resources"
                    tabPaneFourContent={
                        <ProviderResourcesTable
                            resources={providerData?.resources}
                            providerId={id}
                            setTriggerRefetch={setTriggerRefetch}
                            pending
                        />
                    }
                    {...(process.env.REACT_APP_FLAG_SHOW_COMPONENT_BRANDS ===
                    'true'
                        ? {
                              tabPaneFive:
                                  providerIsAnAsi && newAsiOnboardingFlow
                                      ? false
                                      : true,
                              tabPaneFiveTab: 'Component Brands',
                              tabPaneFiveContent: (
                                  <ComponentBrands providerId={id} />
                              ),
                          }
                        : {})}
                    rowCount={2}
                    rightSectionHeading="Provider Details"
                    gridItemOneHeading="CREATION DATE"
                    gridItemOneContent={
                        providerData?.created_at !== undefined &&
                        format(
                            parseISO(providerData?.created_at),
                            'dd MMM, yyyy, hh:mm',
                        )
                    }
                    gridItemTwoHeading="CREATED BY"
                    gridItemTwoContent="Samuel SunFi"
                    gridItemThreeHeading="PROVIDER TYPE"
                    gridItemThreeContent={`${providerData?.user?.provider?.type?.name} (${providerData?.user?.provider?.type?.identifier})`}
                    gridItemFourHeading={
                        providerIsAnAsi ? 'QUALIFICATION' : 'NO. USERS'
                    }
                    gridItemFourContent={
                        providerIsAnAsi
                            ? providerData?.user?.education_level ?? '-'
                            : providerUsersData?.length
                    }
                    actionButton
                    buttonOneText="Approve"
                    buttonOneHandleClick={() => setShowModal(true)}
                    buttonOneDisabled={!canApproveOrDeclineProvider}
                    buttonOneTooltipText={
                        !canApproveOrDeclineProvider
                            ? 'You are not authorised to perform this action'
                            : null
                    }
                    secondButton
                    secondButtonType="moreActions"
                    dropdownMenu={menu}
                    isLoading={
                        singleProviderLoading ||
                        providerUsersFetching ||
                        singleProviderFetching
                    }
                />
            </div>
        </>
    )
}

export default AdminPendingProviders
