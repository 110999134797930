import axios from '../../../config/axios'
import { useQuery } from 'react-query'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'

export const getAlternateRecommendations = async dropOffId => {
    const res = await axios.get(
        `/admin/sse-consumers/drop-offs/${dropOffId}/recommend-solutions`,
    )
    return res.data
}

export const useGetAlternateRecommendations = dropOffId => {
    const { errorAlert } = useCustomToast()
    return useQuery({
        queryKey: ['alternate-recommendations', dropOffId],
        queryFn: () => getAlternateRecommendations(dropOffId),
        retry: false,
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })
}

export const sendRecommendationsToConsumerApi = dropOffId => {
    const request = axios.post(
        `/admin/sse-consumers/drop-offs/${dropOffId}/send-recommendations`,
    )
    return request
}
