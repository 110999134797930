import { useEffect, useState } from 'react'
import { useConsumerKYCContext } from '../../context'
import styles from './errorHandler.module.scss'
import errorTip from 'src/assets/images/error-pointer.svg'

const ErrorHandlerTour = () => {
    const { errorTourSteps, openErrorTour, setOpenErrorTour, inputs } =
        useConsumerKYCContext()
    const [currentStep, setCurrentStep] = useState(1)
    const [position, setPosition] = useState({ top: 0, left: 0 })
    const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false)

    const handleNextStep = () => {
        if (currentStep < errorTourSteps.length) {
            setCurrentStep(currentStep + 1)
        } else {
            setCurrentStep(1)
            setOpenErrorTour(false)
        }
    }

    const currentField = document.querySelector(
        `#${errorTourSteps[currentStep - 1]?.field}`,
    )
    const formContainer = document.querySelector('.SectionsContainer')

    currentField?.addEventListener('input', () => {
        setTimeout(() => {
            setIsAutoCompleteOpen(false)
        }, 200)
    })

    currentField?.addEventListener('focus', () => {
        setIsAutoCompleteOpen(true)
    })

    currentField?.addEventListener('blur', () => {
        setTimeout(() => {
            setIsAutoCompleteOpen(false)
        }, 200)
    })

    useEffect(() => {
        if (currentField) {
            currentField.scrollIntoView({ behavior: 'smooth', block: 'center' })
            const fieldRect = currentField.getBoundingClientRect()
            const containerRect = formContainer.getBoundingClientRect()
            const top =
                fieldRect.top - containerRect.top + fieldRect.height + 15
            const left =
                currentField?.id === 'bvnVerified' && window.innerWidth <= 600
                    ? containerRect.left + 30
                    : fieldRect.left - containerRect.left
            setPosition({ top, left })
        }
    }, [currentStep, currentField, formContainer])

    return (
        <div>
            {openErrorTour && !isAutoCompleteOpen && (
                <div
                    style={{
                        top: `${position.top}px`,
                        left: `${position.left}px`,
                    }}
                    className={`${styles.errorHandlerContainer}`}
                >
                    <div className={styles.imgWrapper}>
                        <img
                            className={styles.errorTip}
                            src={errorTip}
                            alt="tooltip"
                            draggable={false}
                            style={{
                                top: '-26px',
                                left:
                                    currentField?.id === 'bvnVerified' &&
                                    window.innerWidth <= 600
                                        ? '260px'
                                        : '30px',
                            }}
                        />
                    </div>
                    <p className={styles.errorText}>
                        {errorTourSteps[currentStep - 1]?.description}
                    </p>
                    <div className={styles.action}>
                        <button
                            disabled={
                                !inputs[errorTourSteps[currentStep - 1]?.field]
                            }
                            onClick={handleNextStep}
                        >
                            {currentStep === errorTourSteps.length
                                ? 'Done'
                                : 'Next'}
                        </button>
                        <p>
                            {currentStep}/{errorTourSteps.length}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ErrorHandlerTour
