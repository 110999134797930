import PropTypes from 'prop-types'
import styles from 'src/components/LoginMethodsModal/loginmethodsmodal.module.scss'

const SelectOtpMethods = ({
    modalHeading,
    modalSubHeading,
    otpMethods,
    handleSelectOtpMethod,
    errors,
    otpMethod,
}) => {
    const {
        methodDiv,
        methodDivActive,
        methodIcon,
        methodText,
        customStrokeAndFillStyle,
        customStrokeAndFillStyleIcon,
    } = styles
    return (
        <>
            <div
                className={'GettingStartedHeadingAndText'}
                style={{ marginBottom: 15 }}
            >
                <h3>{modalHeading}</h3>
                <p>{modalSubHeading}</p>
            </div>
            {otpMethods?.map((each, i) => (
                <div
                    key={i}
                    role="button"
                    className={`${methodDiv} ${
                        otpMethod === each?.key ? methodDivActive : ''
                    } ${each?.key === 'phone' ? customStrokeAndFillStyle : ''}`}
                    onClick={() =>
                        !each.disabled && handleSelectOtpMethod(each?.key)
                    }
                    style={{
                        cursor: each.disabled ? 'not-allowed' : '',
                        border: each.disabled ? '0.5px solid #e5e5e5' : '',
                    }}
                >
                    <div
                        className={`${methodIcon} ${
                            each?.key === 'phone'
                                ? customStrokeAndFillStyleIcon
                                : ''
                        }`}
                    >
                        {each?.icon}
                    </div>
                    <div className={methodText}>
                        <h4>{each?.title}</h4>
                        <p>{each?.description}</p>
                    </div>
                </div>
            ))}
            <p className="GettingStartedOTPErrorMsg" style={{ marginTop: 0 }}>
                {errors?.otpMethod}
            </p>
        </>
    )
}

SelectOtpMethods.propTypes = {
    modalHeading: PropTypes.string,
    modalSubHeading: PropTypes.string,
    otpMethods: PropTypes.array,
    handleSelectOtpMethod: PropTypes.func,
    errors: PropTypes.object,
    otpMethod: PropTypes.string,
}

export default SelectOtpMethods
