import './filterdrawer.scss'
import { Drawer } from 'antd'
import PropTypes from 'prop-types'
import BackNav from 'src/components/BackNav'
import { ConsumerButton } from 'src/components/Button'
import { SelectField } from 'src/components/InputFields'
import {
    overviewFilterOptions,
    paymentTypeOptions,
    profileType,
    statusOptions,
} from './data'
import Checkbox from 'src/components/Checkbox'
import { useEffect, useState } from 'react'
import RadioButton from 'src/components/RadioButton'

const FilterDrawer = ({
    visible,
    onDrawerClose,
    applyFilter,
    resetFilter,
    filterStatus,
    setFilterStatus,
    profileSystemSizes,
    page,
}) => {
    const workspaceFilterState = {
        member_status: [],
        due_payment: false,
        system_size: '',
        payment_type: '',
        page: 1,
        name: '',
        payment_amount: '',
    }
    const overviewFilterState = {
        profile_type: '',
        max_cluster_members: '',
        recently_updated: '',
        recently_created: '',
        name: '',
        page: 1,
    }
    const initialState =
        page === 'workspace' ? workspaceFilterState : overviewFilterState
    const [localFilter, setLocalFilter] = useState(initialState)
    const [clearSelected, setClearSelected] = useState(false)

    useEffect(() => {
        setLocalFilter(filterStatus)
        if (localFilter?.payment_type && !filterStatus?.payment_type) {
            setClearSelected(true)
        }
        setTimeout(() => setClearSelected(false), 0)
        // eslint-disable-next-line
    }, [filterStatus])

    const handleStatusSelection = (filterType, status) => {
        setLocalFilter(prevLocalFilter => ({
            ...prevLocalFilter,
            [filterType]: statusSelection(filterType, status, prevLocalFilter),
        }))
    }

    const statusSelection = (filterType, status, prevLocalFilter) => {
        let statusResult
        switch (filterType) {
            case 'max_cluster_members':
            case 'due_payment':
            case 'recently_updated':
            case 'recently_created':
                statusResult = !localFilter[filterType]
                break
            case 'member_status':
                statusResult = localFilter[filterType]?.includes(status)
                    ? prevLocalFilter[filterType]?.filter(
                          each => each !== status,
                      )
                    : [...prevLocalFilter[filterType], status]
                break
            case 'profile_type':
                statusResult =
                    localFilter[filterType] === profileType[status]
                        ? ''
                        : profileType[status]
                break
            default:
                statusResult = status
        }
        return statusResult
    }

    const isChecked = (status, filterType) => {
        return filterType === 'profile_type'
            ? localFilter[filterType] === profileType[status]
            : localFilter[filterType] === true
    }

    const handleApplyFilter = () => {
        setFilterStatus(prevState => ({
            ...prevState,
            ...localFilter,
            page: 1,
        }))
        applyFilter()
    }

    const handleResetFilter = () => {
        setLocalFilter({ ...initialState })
        setClearSelected(true)
        resetFilter()
    }

    return (
        <Drawer
            placement="right"
            visible={visible}
            closable={false}
            width={366}
            onClose={onDrawerClose}
            className="RepWorkspace_FilterDrawer"
        >
            <div className="RepWorkspace_FilterDrawer_Content">
                <BackNav
                    v2
                    title="Go back"
                    marginBottom={16}
                    onClick={onDrawerClose}
                />
                <h2 className="RepWorkspace_FilterDrawer_Heading">
                    Filter {page === 'workspace' ? 'Members' : 'Requests'}
                </h2>
                <>
                    {page === 'overview' && (
                        <div>
                            <p className="RepWorkspace_FilterDrawer_SectionHeading">
                                Filter cluster by
                            </p>
                            <div className="RepWorkspace_FilterDrawer_Checkboxes">
                                {overviewFilterOptions?.map(
                                    ({ status, filterType }, i) => (
                                        <Checkbox
                                            key={i}
                                            isChecked={isChecked(
                                                status,
                                                filterType,
                                            )}
                                            lightBlueUncheckedBg
                                            handleChecked={() =>
                                                handleStatusSelection(
                                                    filterType,
                                                    status,
                                                )
                                            }
                                        >
                                            {status}
                                        </Checkbox>
                                    ),
                                )}
                            </div>
                        </div>
                    )}
                    {page === 'workspace' && (
                        <>
                            <h4 className="RepWorkspace_FilterDrawer_SectionHeading">
                                Filter by system size
                            </h4>
                            <SelectField
                                name="system_size"
                                initialOption={'Select system size'}
                                value={localFilter?.system_size}
                                marginBottom={32}
                                selectWidth={'100%'}
                                values={profileSystemSizes.map(option => ({
                                    value: `${option.energy_capacity}${option.unit_of_measurement}`,
                                }))}
                                dropdownPositionRelative
                                handleChange={(name, value) =>
                                    handleStatusSelection(name, value)
                                }
                            />
                            <div>
                                <h4 className="RepWorkspace_FilterDrawer_SectionHeading">
                                    Filter by status
                                </h4>
                                <div className="RepWorkspace_FilterDrawer_Checkboxes">
                                    {statusOptions?.map(
                                        ({ status, filterType }, i) => (
                                            <Checkbox
                                                key={i}
                                                isChecked={
                                                    filterType ===
                                                    'member_status'
                                                        ? localFilter[
                                                              filterType
                                                          ]?.includes(status)
                                                        : localFilter[
                                                              filterType
                                                          ] === true
                                                }
                                                lightBlueUncheckedBg
                                                handleChecked={() =>
                                                    handleStatusSelection(
                                                        filterType,
                                                        status,
                                                    )
                                                }
                                            >
                                                {status}
                                            </Checkbox>
                                        ),
                                    )}
                                </div>
                            </div>
                            <div style={{ marginTop: '32px' }}>
                                <h4 className="RepWorkspace_FilterDrawer_SectionHeading">
                                    Filter by payment type
                                </h4>

                                <div className="RepWorkspace_FilterDrawer_Checkboxes">
                                    <RadioButton
                                        list={paymentTypeOptions}
                                        onCheck={v =>
                                            setLocalFilter(prev => ({
                                                ...prev,
                                                payment_type: v,
                                            }))
                                        }
                                        clearSelected={clearSelected}
                                        defaultChecked={
                                            localFilter?.payment_type
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </>
            </div>
            <div className="RepWorkspace_FilterDrawer_Buttons">
                <ConsumerButton
                    btnBgColor={'#F9FCFF'}
                    btnTextColor={'#004AAD'}
                    btnWidth={'143px'}
                    btnHeight={'44px'}
                    border={'0.5px solid #004AAD'}
                    handleClick={handleResetFilter}
                >
                    Reset
                </ConsumerButton>
                <ConsumerButton
                    btnBgColor={'#004AAD'}
                    btnTextColor={'#FFFFFF'}
                    btnWidth={'143px'}
                    btnHeight={'44px'}
                    handleClick={handleApplyFilter}
                >
                    Apply
                </ConsumerButton>
            </div>
        </Drawer>
    )
}

FilterDrawer.propTypes = {
    visible: PropTypes.bool,
    onDrawerClose: PropTypes.func,
    filterState: PropTypes.object,
    applyFilter: PropTypes.func,
    resetFilter: PropTypes.func,
    filterStatus: PropTypes.object,
    setFilterStatus: PropTypes.func,
    profileSystemSizes: PropTypes.array,
    page: PropTypes.string.isRequired,
}

export default FilterDrawer
