import PropTypes from 'prop-types'
import styles from 'src/components/LoginMethodsModal/loginmethodsmodal.module.scss'
import { InputFields } from '../../InputFields'
import { useTypeDetails } from '../data'
import '../gettingstarted.scss'
import { SelectCountryCode } from '../../InputFields/SelectCountryCode'
import Toast from 'src/components/Toast'
import checked from 'src/assets/images/circle_check_blue.svg'
import { formatOtpVerifyMethods } from '../utils'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import SystemSelected from './SystemSelected'
import { formatInputValue } from 'src/utils/formatting'

const UseType = ({
    contact,
    inputs,
    setInputs,
    errors,
    setErrors,
    showToastError,
    errorMessage,
    showUseTypeField = true,
    showUseTypeFieldWithoutContact,
    isPromoCluster,
    useTypeContent,
    otpMethod,
    handleSelectOtpMethod,
    isNotSharedPromoCluster,
    isClosedCluster,
    signUpModalToast,
    handleTrackEntryFormActivity,
}) => {
    const { isMobile } = useMediaQueries()
    const otpMethods = formatOtpVerifyMethods(inputs)

    const {
        methodDiv,
        methodDivActive,
        methodIcon,
        methodText,
        customStrokeAndFillStyle,
        customStrokeAndFillStyleIcon,
    } = styles

    const handleSelectChange = (name, value) => {
        setErrors(prev => ({ ...prev, useType: '', customer_type: '' }))
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }
    const handleInputChange = event => {
        const { name, value } = event.target
        setErrors(prev => ({ ...prev, [name]: '' }))

        if (name === 'email') {
            setInputs(prev => ({ ...prev, [name]: value.replaceAll(' ', '') }))
        } else if (name === 'phone' || name === 'firstName') {
            setInputs(prev => ({
                ...prev,
                [name]: formatInputValue(name, value),
            }))
        } else {
            setInputs(preState => ({
                ...preState,
                [name]: value,
            }))
        }
    }

    const handleInputBlur = e => {
        const { name, value } = e.target

        handleTrackEntryFormActivity(
            'sseStartPromoProfileEntryForm',
            name,
            value,
        )
    }

    return (
        <div
            className={'GettingStartedWrapper UseType'}
            data-testid="use-type-wrapper"
        >
            {(showToastError || signUpModalToast?.showToast) && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage || signUpModalToast?.toastMessage}
                    closable={true}
                    autoHide={false}
                />
            )}
            {useTypeContent === 'select-otp-method' && (
                <>
                    <div className={'GettingStartedHeadingAndText'}>
                        <h3 data-testid="otp-selection-heading">
                            Where would you like to receive your verification
                            code?
                        </h3>
                        <p>
                            Providing the code to your email or phone number
                            helps us verify your account and get started with
                            your KYC.
                        </p>
                    </div>
                    {otpMethods?.map((each, i) => (
                        <div
                            key={i}
                            role="button"
                            className={`${methodDiv} ${
                                otpMethod === each?.key ? methodDivActive : ''
                            } ${
                                each?.key === 'phone'
                                    ? customStrokeAndFillStyle
                                    : ''
                            }`}
                            onClick={() => handleSelectOtpMethod(each?.key)}
                            data-testid={`otp-method-option-${each.key}`}
                        >
                            <div
                                className={`${methodIcon} ${
                                    each?.key === 'phone'
                                        ? customStrokeAndFillStyleIcon
                                        : ''
                                }`}
                            >
                                {each?.icon}
                            </div>
                            <div className={methodText}>
                                <h4>{each?.title}</h4>
                                <p>{each?.description}</p>
                            </div>
                        </div>
                    ))}
                    <p
                        className="GettingStartedOTPErrorMsg"
                        style={{ marginTop: 0 }}
                    >
                        {errors?.otpMethod}
                    </p>
                    {!isMobile && <VerticalSpacer top={80} />}
                </>
            )}
            {(useTypeContent === 'confirm-otp-method' ||
                useTypeContent === 'promo-cluster-form') && (
                <>
                    {showUseTypeField && (
                        <div className={'GettingStartedHeadingAndText'}>
                            <h3>What are you getting this system for?</h3>
                            <p>
                                Will the solar system be used for residential,
                                small business or other business purposes?
                            </p>
                            <div
                                className={'GettingStartedUseTypes'}
                                data-testid="use-types"
                            >
                                {useTypeDetails?.map((each, i) => (
                                    <div
                                        key={i}
                                        className={'GettingStartedUseType'}
                                        role={'button'}
                                        onClick={() => {
                                            handleSelectChange(
                                                'useType',
                                                each.value,
                                            )
                                            setErrors(prev => ({
                                                ...prev,
                                                useType: '',
                                            }))
                                        }}
                                        style={{
                                            backgroundColor:
                                                inputs.useType === each.value
                                                    ? '#EBF3FF'
                                                    : '#F9FCFF',
                                            border:
                                                inputs.useType === each.value
                                                    ? '0.5px solid #004AAD'
                                                    : '0.5px solid #FFFFFF',
                                        }}
                                        data-testid={`use-type-option-${i}`}
                                    >
                                        <img
                                            src={each.icon}
                                            alt={each.title}
                                            className={
                                                'GettingStartedUseType_Img'
                                            }
                                        />
                                        <div>
                                            <h4>{each.title}</h4>
                                            <p>{each.description}</p>
                                        </div>
                                        {inputs.useType === each.value && (
                                            <img
                                                src={checked}
                                                alt={'checked'}
                                                className={
                                                    'GettingStartedUseType_CheckedIcon'
                                                }
                                            />
                                        )}
                                    </div>
                                ))}
                                {(errors?.useType || errors?.customer_type) && (
                                    <p className={'GettingStartedUseTypes_Err'}>
                                        {errors?.useType ||
                                            errors?.customer_type}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    {showUseTypeFieldWithoutContact ? null : (
                        <div
                            className={
                                'GettingStartedHeadingAndText UseTypeContact'
                            }
                            data-testid="contact-info"
                        >
                            {isNotSharedPromoCluster && (
                                <InputFields
                                    name="firstName"
                                    value={inputs.firstName}
                                    handleChange={handleInputChange}
                                    onBlur={handleInputBlur}
                                    paddingBottom="18px"
                                    title="Enter your first name"
                                    errorMessage={errors['firstName']}
                                    exemptMouseOut
                                />
                            )}
                            {isPromoCluster ? (
                                <h3>
                                    Add your{' '}
                                    {contact === 'email'
                                        ? 'email address'
                                        : 'phone number'}
                                </h3>
                            ) : (
                                <h3>
                                    Your added{' '}
                                    {contact === 'email'
                                        ? 'email address'
                                        : 'phone number'}
                                </h3>
                            )}
                            {isPromoCluster ? (
                                <p>
                                    Kindly add your{' '}
                                    {contact === 'email'
                                        ? 'email address'
                                        : 'phone number'}{' '}
                                    to get started
                                </p>
                            ) : (
                                <p>
                                    {contact === 'email'
                                        ? 'Email'
                                        : 'Phone number'}{' '}
                                    submitted when filling in your appliances
                                </p>
                            )}

                            {isNotSharedPromoCluster ? (
                                <>
                                    <InputFields
                                        title={'Email Address'}
                                        name={'email'}
                                        value={inputs?.email}
                                        errorMessage={errors?.email}
                                        handleChange={handleInputChange}
                                        onBlur={handleInputBlur}
                                        marginBottom={0}
                                    />
                                    <SelectCountryCode
                                        title="Phone Number"
                                        name="phone"
                                        inputValue={inputs?.phone}
                                        errorMessage={errors?.phone}
                                        handleChange={handleInputChange}
                                        onBlur={handleInputBlur}
                                        marginBottom={0}
                                        sseUse={true}
                                    />
                                </>
                            ) : contact === 'email' ? (
                                <InputFields
                                    title={'Email Address'}
                                    name={'email'}
                                    value={inputs?.email}
                                    errorMessage={errors?.email}
                                    handleChange={handleInputChange}
                                    onBlur={handleInputBlur}
                                    marginBottom={0}
                                />
                            ) : (
                                <SelectCountryCode
                                    title="Phone Number"
                                    name="phone"
                                    inputValue={inputs?.phone}
                                    errorMessage={errors?.phone}
                                    handleChange={handleInputChange}
                                    onBlur={handleInputBlur}
                                    marginBottom={0}
                                    sseUse={true}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
            {useTypeContent === 'confirm-or-reselect' && isClosedCluster && (
                <SystemSelected
                    showToastError={showToastError}
                    errorMessage={errorMessage}
                />
            )}
        </div>
    )
}
UseType.propTypes = {
    contact: PropTypes.string,
    inputs: PropTypes.object,
    setInputs: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    showToastError: PropTypes.bool,
    errorMessage: PropTypes.string,
    showUseTypeField: PropTypes.bool,
    showUseTypeFieldWithoutContact: PropTypes.bool,
    isPromoCluster: PropTypes.bool,
    useTypeContent: PropTypes.string,
    otpMethod: PropTypes.string,
    handleSelectOtpMethod: PropTypes.func,
    isNotSharedPromoCluster: PropTypes.bool,
    isClosedCluster: PropTypes.bool,
    signUpModalToast: PropTypes.object,
    handleTrackEntryFormActivity: PropTypes.func,
}

export default UseType
