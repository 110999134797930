import { useParams } from 'react-router-dom'
import styles from './buyout.module.scss'
import PropTypes from 'prop-types'
import ActionModal from 'src/components/ActionModalV2'
import { useBuyout } from './hook'
import { actionBuyoutModalData } from './data'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import { ReactComponent as WarningIcon } from 'src/assets/images/warning-info.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import { useAdminRequestBuyout } from 'src/api/buyout/queries'

const Buyout = ({
    details,
    showBuyoutModal,
    setShowBuyoutModal,
    user,
    handlePrimaryBtn,
}) => {
    const { id } = useParams()
    const { actionModal, closeActionModal, openActionModal } = useBuyout()
    const { Wrapper } = styles
    const { adminRequestBuyoutMutate, adminRequestBuyoutLoading } =
        useAdminRequestBuyout({
            onSuccess: () => {
                openActionModal()
            },
        })
    const sendRequest = () => {
        adminRequestBuyoutMutate({
            estimation_id: id,
        })
    }

    return (
        <div>
            <ConsumerModalBase
                showModal={showBuyoutModal}
                title={
                    user === 'consumer'
                        ? 'Buy Out payment breakdown'
                        : 'Request Lease-to-Own Buyout'
                }
                primaryBtnText="Continue"
                handlePrimaryBtnClick={() => {
                    user === 'consumer' ? handlePrimaryBtn() : openActionModal()
                    setShowBuyoutModal(false)
                }}
                onCancel={() => {
                    setShowBuyoutModal(false)
                }}
            >
                <div className={Wrapper}>
                    <div>
                        <h3>TOTAL AMOUNT DUE</h3>
                        <p>₦ {details?.buyout_amount}</p>
                    </div>
                    <p>
                        {user === 'consumer'
                            ? 'You are to pay this remaining amount for your monthly lease-to-own repayment, which covers any additional fees. It does not include past repayments or overpayments.'
                            : 'This is the outstanding amount the customer is required to pay to completely buy out their remaining lease-to-own repayment. It covers any applicable fees but does not include past repayments or overpayments.'}
                    </p>
                    <div>
                        <div>
                            <div>
                                <h2>MONTHLY PAYMENT</h2>
                                <p>₦ {details?.monthly_payment}</p>
                            </div>
                            <div>
                                <h2>REMAINING MONTHS</h2>
                                <p style={{ textAlign: 'right' }}>
                                    {details?.remaining_months} Months
                                </p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <h2>ADDITIONAL FEES</h2>
                                    <div>
                                        <CustomToolTip
                                            text={
                                                'This is the calculated sum of any additional fees and penalties.'
                                            }
                                            showTooltip={true}
                                            toolTipFontSize={'12px'}
                                            toolTipPadding={
                                                '10px 6px 14px 11px'
                                            }
                                            toolTipPopupWidth={'174px'}
                                            toolTipMarginLeft={'-151px'}
                                            tooltipZIndex="100"
                                            arrowPositionRight={true}
                                        >
                                            <WarningIcon />
                                        </CustomToolTip>
                                    </div>
                                </div>
                                <p>
                                    ₦{' '}
                                    {details?.fee.length
                                        ? details?.fee
                                        : '0.00'}
                                </p>
                            </div>
                            <div>
                                <div>
                                    <h2>PREPAYMENTS</h2>
                                    <div>
                                        <CustomToolTip
                                            text={`This is the calculated sum of any prepayment or overpayment that occurred during the${
                                                user === 'consumer'
                                                    ? ''
                                                    : ' customer’s'
                                            } Lease-to-Own plan.`}
                                            showTooltip={true}
                                            toolTipFontSize={'12px'}
                                            toolTipPadding={
                                                '10px 6px 14px 11px'
                                            }
                                            toolTipPopupWidth={'190px'}
                                            toolTipMarginLeft={'-161px'}
                                            arrowPositionRight={true}
                                            tooltipZIndex="100"
                                        >
                                            <WarningIcon />
                                        </CustomToolTip>
                                    </div>
                                </div>
                                <p style={{ textAlign: 'right' }}>
                                    ₦ {details?.total_amount_paid}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ConsumerModalBase>

            <ActionModal
                actionModalOpen={actionModal?.open}
                v2
                title={actionBuyoutModalData[actionModal?.type]?.title}
                onCancel={closeActionModal}
                headerText={actionBuyoutModalData[actionModal?.type]?.header}
                subTitle={actionBuyoutModalData[actionModal?.type]?.subTitle}
                actionType={
                    actionBuyoutModalData[actionModal?.type]?.actionType
                }
                primaryBtnText={
                    actionBuyoutModalData[actionModal?.type]?.btnText
                }
                primaryBtnLoading={adminRequestBuyoutLoading}
                handlePrimaryBtnClick={
                    actionModal?.type === 'confirm'
                        ? sendRequest
                        : openActionModal
                }
            />
        </div>
    )
}

Buyout.propTypes = {
    details: PropTypes.object,
    showBuyoutModal: PropTypes.bool,
    setShowBuyoutModal: PropTypes.func,
    user: PropTypes.string,
    handlePrimaryBtn: PropTypes.func,
}

export default Buyout
