import SecondaryModal from 'src/components/Modal/SecondaryModal'
import warning from 'src/assets/images/warning-icon.svg'
import success from 'src/assets/images/successImage.svg'
import PropTypes from 'prop-types'
import './override-check.scss'
import { TextArea } from 'src/components/InputFields'
import { useState } from 'react'
import { useOverrideVerification } from '../../IdentityInformation/components/api'
import { useParams, useHistory } from 'react-router-dom'
import { useSSERetrieveBasicInfo } from 'src/api/shoppingExperience/kyc/queries'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { InlineLoader } from 'src/components/Loader'
const OverrideCheckModal = ({ onCancel, showOverrideModal, type }) => {
    const { sseBasicInfoData, sseBasicInfoRefetch } = useSSERetrieveBasicInfo({
        enabled: true,
    })
    const bvnId = sseBasicInfoData?.data?.data?.financial_verification?.bvn?.id
    const identity_id = sseBasicInfoData?.data?.data?.identity_verification?.id
    const objectId = {
        BVN: bvnId,
        ID: identity_id,
    }
    const { id: estimation_id } = useParams()
    const { mutate: mutateOverride, isLoading: isOverrideLoading } =
        useOverrideVerification()
    const [reason, setReason] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showReasonModal, setShowReasonModal] = useState(false)
    const { successAlert, errorAlert } = useCustomToast()
    const history = useHistory()
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const isDynamicKycUser = history?.location?.state?.isDynamicKycUser
    const estimationId = history?.location?.state?.estimationId
    const handleChange = e => {
        setReason(e.target.value)
    }
    const handleSubmitReason = () => {
        if (!reason) {
            setErrorMessage('Reason is required')
            return
        }
        mutateOverride(
            {
                payload: {
                    object_id: objectId[type],
                    reason,
                },
                estimation_id: isDynamicKycUser ? estimationId : estimation_id,
            },
            {
                onSuccess: () => {
                    successAlert('User data has been overridden')
                    setShowSuccessModal(true)
                    setShowReasonModal(false)
                    sseBasicInfoRefetch()
                },
                onError: err => {
                    errorAlert(errorHandler(err?.response.data))
                },
            },
        )
    }
    return (
        <>
            <SecondaryModal
                onCancel={onCancel}
                showModal={showOverrideModal}
                showFooter={true}
                dataTestId={'override-check-modal'}
                title="Override check result"
                v2
                content={
                    <div className="override-container">
                        <img src={warning} />
                        <h2>Pass this customer</h2>
                        <p>
                            Changing this user {type}{' '}
                            {type === 'ID' ? 'card' : ''} result might affect
                            the initial credit decision result for this consumer
                        </p>
                    </div>
                }
                footerLeftContent="button"
                secondaryBtnText="Cancel"
                handleSecondaryBtnClick={onCancel}
                primaryBtnText="Continue"
                handlePrimaryBtnClick={() => {
                    onCancel()
                    setShowReasonModal(true)
                }}
            />
            <SecondaryModal
                modalWidth="550px"
                onCancel={() => {
                    setShowReasonModal(false)
                    setReason('')
                }}
                showModal={showReasonModal}
                showFooter={true}
                title="Override check result"
                v2
                content={
                    <div className="override-check-result">
                        <h2>Give a reason</h2>
                        <p>Enter a reason for overriding check result</p>
                        <TextArea
                            placeholder="Enter reason"
                            rows={10}
                            height="146px"
                            name="reason"
                            value={reason}
                            handleChange={handleChange}
                            errorMessage={errorMessage}
                        />
                    </div>
                }
                footerLeftContent="button"
                secondaryBtnText="Cancel"
                handleSecondaryBtnClick={() => {
                    setShowReasonModal(false)
                    setReason('')
                }}
                primaryBtnText={
                    isOverrideLoading ? <InlineLoader /> : 'Confirm'
                }
                handlePrimaryBtnClick={handleSubmitReason}
            />
            <SecondaryModal
                modalWidth="550px"
                onCancel={() => setShowSuccessModal(false)}
                showModal={showSuccessModal}
                showFooter={true}
                title="Override check result"
                v2
                content={
                    <div className="override-container">
                        <img src={success} />
                        <h2>Override Successful</h2>
                        <p>
                            Great work! Consumer data override was successful,
                            we’ve updated the check result status.
                        </p>
                    </div>
                }
                footerLeftContent="chat"
                primaryBtnText={'Continue'}
                handlePrimaryBtnClick={() => setShowSuccessModal(false)}
            />
        </>
    )
}
OverrideCheckModal.propTypes = {
    onCancel: PropTypes.func,
    showOverrideModal: PropTypes.bool,
    type: PropTypes.string,
}
export default OverrideCheckModal
