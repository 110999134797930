import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import SolarCity from 'src/assets/images/solar-city-bg.svg'
import ButtonComponent from 'src/components/Button'
import ChatCTA from 'src/components/ChatCTA'
import s from './styles.module.scss'
import { ReactComponent as BigWhiteCloseIcon } from 'src/assets/images/close-icon-white-big.svg'
import { isMobile } from 'src/utils/mediaQueries'

const WelcomeModal = ({ showModal, onCancel, firstName, companyLogo }) => {
    const canManageMultipleClusters =
        process.env.REACT_APP_FLAG_MANAGE_MULTIPLE_CLUSTERS_ON_REP_WORKSPACE ===
        'true'

    return (
        <SecondaryModal
            v2={canManageMultipleClusters ? true : false}
            showModal={showModal}
            modalHeight={isMobile ? '475px' : '436px'}
            modalWidth={canManageMultipleClusters ? '475px' : '100% !important'}
            noPadding={isMobile ? '15px' : '0px'}
            onCancel={onCancel}
            closable={isMobile}
            modalClass={isMobile ? s.modalClass : undefined}
            showFooter={canManageMultipleClusters ? true : false}
            primaryBtnText="Continue"
            primaryBtnWidth="110px"
            handlePrimaryBtnClick={onCancel}
            content={
                <>
                    {canManageMultipleClusters ? (
                        <div>
                            <div className={s.modalHeader}>
                                <div>
                                    <h6>Welcome to SunFi</h6>
                                    <p>
                                        We are an energy financial technology
                                        platform that connects people who want
                                        solar energy access to payment plans
                                        that match their needs.
                                    </p>
                                </div>
                            </div>

                            <div className={s.modalContent}>
                                <h2>
                                    Hey {firstName}, welcome to your cluster
                                    workspace!
                                </h2>
                                <p>
                                    Welcome back to the Cluster Management
                                    Workspace! This workspace allows you to view
                                    all the clusters you manage, seamlessly
                                    switch between clusters, add or remove
                                    members, and make bulk payments for all
                                    members. If you have any questions, please
                                    don’t hesitate to reach out to us.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className={s.wrapper}>
                            <div className={s.left}>
                                <img src={companyLogo} alt="company logo" />
                                <h2>
                                    Hey {firstName}, welcome to your workspace!
                                </h2>
                                <p>
                                    We are excited about this partnership. This
                                    space is for you to manage all your sales
                                    and monitor your progress easily
                                </p>
                                <div className={s.btnAndCta}>
                                    <ButtonComponent
                                        btnBgColor={'#004aad'}
                                        btnTextColor={'#ffffff'}
                                        btnWidth={isMobile ? '260px' : '166px'}
                                        btnHeight={isMobile ? '50px' : '56px'}
                                        handleClick={onCancel}
                                    >
                                        Get Started
                                    </ButtonComponent>
                                    <ChatCTA />
                                </div>
                            </div>
                            <div className={s.cityImgWrapper}>
                                <img
                                    src={SolarCity}
                                    alt="solar city"
                                    className={s.solarCityImg}
                                />
                                <BigWhiteCloseIcon
                                    className={s.closeIcon}
                                    role="button"
                                    onClick={onCancel}
                                />
                            </div>
                        </div>
                    )}
                </>
            }
            title={
                canManageMultipleClusters ? 'Getting started on SunFi' : null
            }
        />
    )
}

WelcomeModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    firstName: PropTypes.string,
    companyLogo: PropTypes.string,
}

export default WelcomeModal
