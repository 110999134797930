import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    survey_type: 'CFS,UFS',
    page: '1',
    name: null,
    start_date: null,
    end_date: null,
    payment_plan: 'Lease to Own,Subscription',
    provider_type: null,
    campaign: null,
    bank_statement_source: null,
    csse_offers: 'true',
    application_status: null,
    statuses: null,
    current_tab: '1',
    onboarding_type: null,
    customer_type: 'BUSINESS, RESIDENTIAL',
}

const adminPaymentPlanListing = createSlice({
    name: 'adminPaymentPlanListingFilter',
    initialState,
    reducers: {
        updateAdminPaymentPlanFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetAdminPaymentPlanFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateAdminPaymentPlanFilters, resetAdminPaymentPlanFilters } =
    adminPaymentPlanListing.actions

export const updatedAdminPaymentPlanFilters = state =>
    state.adminPaymentPlanListingFilter

const persistConfig = {
    key: 'adminPaymentPlanListingFilter',
    storage,
}

export const persistedAdminPaymentPlanListingReducer = persistReducer(
    persistConfig,
    adminPaymentPlanListing.reducer,
)

export default adminPaymentPlanListing.reducer
