import { useEffect, useState } from 'react'
import { useGetEnergyProfiles } from 'src/api/shoppingExperience/energy-profile/queries'
import { appTracking } from 'src/utils/appTracker'
import { decrypt } from 'src/utils/cryptography'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'
import { getOnboardingInfo } from 'src/utils/userOnboardingInfoManager'
import { visitCount } from 'src/utils/visitCount'

export const useProfilelistings = ({ isBusiness }) => {
    const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = `View ${isBusiness ? 'BP' : 'RP'} listing`
    const [currentPage, setCurrentPage] = useState(1)
    const [showModal, setShowModal] = useState(false)
    const { getEnergyProfilesData, getEnergyProfilesFetching } =
        useGetEnergyProfiles({
            enabled: true,
            params: {
                customer_type: isBusiness ? 'BUSINESS' : 'RESIDENTIAL',
                page: currentPage,
            },
        })

    const toggleModal = () => {
        setShowModal(prev => !prev)
        !showModal &&
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents(!isBusiness ? 'RP' : 'BP')[
                    'selectBuildSytem'
                ].action,
                consumerProfileEvents(!isBusiness ? 'RP' : 'BP').label,
                consumerProfileEvents(!isBusiness ? 'RP' : 'BP').category,
                ['MP', 'GA'],
                'event',
            )
    }

    useEffect(() => {
        const event = consumerProfileEvents()['viewProfileList']

        const prevUseType = sessionStorage.getItem('prevUseType')

        const onboardingInfo = getOnboardingInfo()
        const onboardingType = onboardingInfo?.type

        const prevUseTypeDecoded = prevUseType ? decrypt(prevUseType) : ''
        const useType = isBusiness ? 'Business' : 'Residential'

        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            event.action,
            event.label,
            event.category,
            ['MP', 'GA'],
            'event',
            {
                event: {
                    'Number of Profile lists viewed': visitCount(
                        currentPage,
                        'profile_lists_viewed',
                        true,
                    ),
                    'User type': useType,
                    'Switch profile': prevUseTypeDecoded,
                    'Profile type': onboardingType,
                },
            },
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    return {
        getEnergyProfilesData,
        getEnergyProfilesFetching,
        currentPage,
        setCurrentPage,
        showModal,
        setShowModal,
        toggleModal,
    }
}
