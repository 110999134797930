import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { onSuccessStatusMap, statusMap } from './utils'
import { useMutation } from 'react-query'
import { manageProviderRequestApi } from 'src/api/consumer/manage-provider-request'

const useManageProviderRequest = () => {
    const [status, setStatus] = useState('')
    const location = useLocation()
    const history = useHistory()
    const queryParams = new URLSearchParams(location.search)
    const action = queryParams.get('action')
    const estimationId = queryParams.get('estimation_id')

    const { mutate: submitActionMutate } = useMutation({
        mutationFn: () =>
            manageProviderRequestApi(estimationId, {
                action: action,
            }),
        onSuccess: () => {
            setStatus(prev => onSuccessStatusMap[prev])
            const params = new URLSearchParams(location.search)
            params.delete('action')
            params.delete('estimation_id')
            history.replace({
                pathname: location.pathname,
                search: params.toString(),
            })
        },
        onError: () => {
            history.push('/not-found')
        },
    })

    useEffect(() => {
        if (action === null || estimationId === null) {
            history.push('/not-found')
        } else {
            setStatus(statusMap[action])
            submitActionMutate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        status,
    }
}

export default useManageProviderRequest
