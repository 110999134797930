import Card from 'src/components/Card'
import styles from './styles.module.scss'
import SearchAndFilterPanel from '../Workspace/components/SearchAndFilterPanel'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { useHistory } from 'react-router-dom'
import { overviewTableHeaders, overviewMetricsData } from './utils'
import {
    convertQueryParamsToObject,
    numberWithCommas,
} from 'src/utils/formatting'
import { useQuery } from 'react-query'
import {
    getAllClusters,
    getClusterSummary,
} from 'src/api/representative/clusters'
import { useEffect, useState } from 'react'
import moment from 'moment'
import FilterDrawer from '../Workspace/components/FilterDrawer'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateClusterFilter,
    updatedClusterFilters,
} from 'src/redux/reducers/representative/clusters/listing'
import { decodeUserInfo } from 'src/utils/auth'
import { getGreetingTime } from 'src/utils/formatting'
import { Pagination } from 'antd'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import ButtonComponent from 'src/components/Button'
import WelcomeModal from '../Workspace/components/WelcomeModal'

const RepresentativeOverview = () => {
    const [clusterInfo, setClusterInfo] = useState([])
    const [filterDrawerVisible, setFilterDrawerVisible] = useState(false)
    const defaultQueries = useSelector(updatedClusterFilters)
    const [showFiltered, setShowFiltered] = useState(false)
    const [clustersSummary, setClustersSummary] = useState()
    const [currentPage, setCurrentPage] = useState(Number(defaultQueries?.page))
    const [totalPages, setTotalPages] = useState(0)
    const dispatch = useDispatch()
    const [toastError, setToastError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const history = useHistory()
    const [showWelcomeModal, setShowWelcomeModal] = useState(false)

    const initialState = {
        profile_type: defaultQueries?.profile_type ?? '',
        max_cluster_members: defaultQueries?.max_cluster_members ?? '',
        recently_updated: defaultQueries?.recently_updated ?? '',
        recently_created: defaultQueries?.recently_created ?? '',
        name: defaultQueries?.name ?? '',
    }
    const [filterStatus, setFilterStatus] = useState(initialState)
    const userInfo = decodeUserInfo()

    const handleFilterDrawerOpen = () => {
        setFilterDrawerVisible(true)
    }

    const onFilterDrawerClose = () => {
        setFilterDrawerVisible(false)
    }

    const updatePage = page => {
        setCurrentPage(page)
    }

    const closeWelcomeModal = () => {
        setShowWelcomeModal(false)
    }

    const queryKey = [
        'clusterListing',
        filterStatus?.profile_type,
        filterStatus?.max_cluster_members,
        filterStatus?.recently_updated,
        filterStatus?.recently_created,
        filterStatus?.name,
        filterStatus?.page,
        currentPage,
    ]

    const { isLoading, refetch: refetchClusters } = useQuery(
        queryKey,
        () =>
            getAllClusters(
                filterStatus?.profile_type || '',
                filterStatus?.max_cluster_members || '',
                filterStatus?.recently_updated || '',
                filterStatus?.recently_created || '',
                filterStatus?.name || '',
                currentPage || 1,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const queries = res?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateClusterFilter(
                        convertQueryParamsToObject([...queries]),
                    ),
                )
                const data = res?.data?.data
                setClusterInfo(data)
                setTotalPages(res?.data?.total_pages)
            },
            onError: err => {
                setToastError(true)
                setErrorMessage(errorHandler(err?.response?.data))
            },
        },
    )

    useEffect(() => {
        refetchClusters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, filterStatus])

    useQuery('clusterOverview', () => getClusterSummary(), {
        enabled: true,
        retry: false,
        refetchOnMount: true,
        onSuccess: res => {
            const data = res?.data?.data
            setClustersSummary(data)
        },
        onError: err => {
            setToastError(true)
            setErrorMessage(errorHandler(err?.response?.data))
        },
    })

    const applyFilter = () => {
        setShowFiltered(true)
        setFilterDrawerVisible(false)
    }

    const resetFilter = () => {
        setShowFiltered(true)
        setFilterStatus({
            profile_type: '',
            max_cluster_members: '',
            recently_updated: '',
            recently_created: '',
            name: '',
            page: 1,
        })
        setFilterDrawerVisible(false)
    }

    useEffect(() => {
        if (userInfo.isFirstTimeLogin === 'true') {
            setShowWelcomeModal(true)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className={styles.page}>
                <div className={styles.header}>
                    <h5>Cluster overview</h5>
                    <h4>
                        Good {getGreetingTime()}, {userInfo?.firstName ?? ''}
                    </h4>
                </div>

                <div className={styles.overviewInfo}>
                    {overviewMetricsData(
                        clustersSummary,
                        clusterInfo?.length,
                    )?.map((summary, index) => (
                        <Card
                            key={index}
                            topLeftHeading={
                                <div className={styles.iconDiv}>
                                    {summary?.icon}
                                </div>
                            }
                            bottomLeftContent={
                                summary?.symbol === 'naira'
                                    ? `₦${numberWithCommas(summary?.value)}.00`
                                    : numberWithCommas(summary?.value)
                            }
                            bottomLeftHeading={summary?.title}
                            showButton={false}
                        />
                    ))}
                </div>

                <div className={styles.mainContent}>
                    <SearchAndFilterPanel
                        page="overview"
                        handleFilterDrawerOpen={handleFilterDrawerOpen}
                        applyFilter={applyFilter}
                        resetFilter={resetFilter}
                        setFilterStatus={setFilterStatus}
                        filterStatus={filterStatus}
                        membersListFiltered={showFiltered}
                    />
                    <VerticalSpacer top={16} />

                    {!isLoading && (
                        <div className={styles.overviewTable}>
                            <div className={styles.overviewTableHeaders}>
                                {overviewTableHeaders.map(header => (
                                    <span key={header}>{header}</span>
                                ))}
                            </div>

                            <div className={styles.overviewTableBody}>
                                {clusterInfo?.map(cluster => (
                                    <div
                                        className={styles.overviewTableRow}
                                        key={cluster?.id}
                                    >
                                        <div
                                            className={
                                                styles.clusterNameAndLogo
                                            }
                                        >
                                            <img src={cluster?.logo} />
                                            <p>{cluster?.name}</p>
                                        </div>
                                        <span className={styles.profileType}>
                                            {cluster?.profile_type}
                                        </span>
                                        <span>
                                            {cluster?.no_of_cluster_members}{' '}
                                            Members
                                        </span>
                                        <span>
                                            {moment(cluster?.updated_at).format(
                                                'lll',
                                            )}
                                        </span>
                                        <span>
                                            <ButtonComponent
                                                btnTextColor="#004aad"
                                                btnTextColorOutline="#004aad"
                                                handleClick={() =>
                                                    history.push({
                                                        pathname: `/rep/workspace/${cluster?.id}`,
                                                        state: {
                                                            comingFromOverview: true,
                                                        },
                                                    })
                                                }
                                            >
                                                Manage Cluster
                                            </ButtonComponent>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {totalPages > 1 && (
                        <div className={styles.paginationContainer}>
                            <Pagination
                                defaultCurrent={1}
                                current={currentPage}
                                total={parseInt(totalPages, 10)}
                                defaultPageSize={1}
                                showSizeChanger={false}
                                showTitle={false}
                                showLessItems
                                onChange={updatePage}
                            />
                        </div>
                    )}
                </div>
            </div>

            {toastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                />
            )}

            <FilterDrawer
                visible={filterDrawerVisible}
                onDrawerClose={onFilterDrawerClose}
                page="overview"
                applyFilter={applyFilter}
                resetFilter={resetFilter}
                setFilterStatus={setFilterStatus}
                filterStatus={filterStatus}
            />

            <WelcomeModal
                showModal={showWelcomeModal}
                onCancel={closeWelcomeModal}
                firstName={userInfo?.firstName ?? ''}
            />
        </>
    )
}

export default RepresentativeOverview
