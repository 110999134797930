import { appTracking } from 'src/utils/appTracker'
import { consumerKYCEvents } from 'src/utils/mp-event-tracker/consumer-kyc'
import { ReactComponent as Email } from 'src/assets/images/email-no-stroke.svg'
import { ReactComponent as Phone } from 'src/assets/images/phone-with-chat-no-stroke.svg'
import { shortenEmail } from 'src/utils/shortenEmail'

const pageUrlName = window.location.pathname

export const handleAppTrackingOnSignup = event => {
    appTracking(
        pageUrlName,
        '',
        '',
        consumerKYCEvents[event].action,
        consumerKYCEvents[event].label,
        consumerKYCEvents[event].category,
        ['MP', 'GA'],
        'event',
    )
}

export const formatOtpVerifyMethods = data => {
    const phone =
        data &&
        (data.phone ||
            data.phonenumber ||
            data.phoneNumber ||
            data.phone_number)

    return [
        {
            title: 'Send OTP to your email address',
            description: `A code will be sent to ${shortenEmail(data?.email)}`,
            key: 'email',
            icon: <Email />,
            disabled: data?.email?.length === 0,
        },
        {
            title: 'Send OTP to your phone number',
            description: `A code will be sent to +234********${phone?.slice(
                -2,
            )}`,
            key: 'phone',
            icon: <Phone />,
            disabled: data?.phone?.length === 4,
        },
    ]
}

export const otpMethodsNoInitialDetails = [
    {
        title: 'Use your Email',
        description: 'A code will be sent to your email address',
        key: 'email',
        icon: <Email />,
    },
    {
        title: 'Use your Phone number',
        description: 'A code will be sent to your phone number',
        key: 'phone',
        icon: <Phone />,
    },
]

export const kycPageTypes = [
    {
        title: 'Use One Page KYC',
        description:
            'Complete your KYC verification on a single page for a streamlined and efficient experience.',
        key: 'one-page-kyc',
    },
    {
        title: 'Use Multi-Page KYC',
        description:
            'Complete your KYC where the verification process is split across multiple steps or pages.',
        key: 'multi-page-kyc',
    },
]

export const formatEntryFormDataForMixpanel = (data = {}) => {
    const payload = {
        'First name': data.firstName || '',
        'Email address': data.email || '',
        'Phone number': data.phone || '',
        'Appliance count': data.applianceCount || '',
        'Promo type': data.customerType || '',
        'Promo name': data.profileName || '',
        'Package name': data.packageName || '',
        'Package size': data.packageSize || '',
        'Package cost': data.packageCost || '',
    }

    return payload
}

export const actionModalValues = action => {
    switch (action) {
        case 'customer-exists':
            return {
                actionModalTitle: 'You already have an account',
                actionModalHeaderText:
                    'Welcome back, you have previously created an account',
                actionModalSubtitle:
                    "Sign in into your account to continue to view this system, once you're signed you’ll be able to view this system.",
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Sign in',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'confirm-continue-to-package':
            return {
                actionModalTitle: 'You already have a selected package',
                actionModalHeaderText: (
                    <>
                        Welcome back, would you like
                        <br />
                        to continue with your selected package?
                    </>
                ),
                actionModalSubtitle:
                    'You have previously selected a package with your created account. Your old package will be discarded if you will like to change to this new package, please confirm.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm new package',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Use old package',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}
