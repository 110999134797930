import { decrypt, encrypt } from './cryptography'

export const setOnboardingInfo = (data = {}) => {
    localStorage.setItem(
        'sunfiUserSSEOnboardingInfo',
        encrypt(JSON.stringify(data)),
    )
}

export const getOnboardingInfo = () => {
    let onboardingInfo = null

    try {
        const onboardingInfoEncrypted = localStorage.getItem(
            'sunfiUserSSEOnboardingInfo',
        )

        const onboardingInfoDecrypted =
            onboardingInfoEncrypted && decrypt(onboardingInfoEncrypted)
        onboardingInfo = onboardingInfoDecrypted
            ? JSON.parse(onboardingInfoDecrypted)
            : null
    } catch (err) {
        if (process.env.REACT_APP_NODE_ENV === 'development') {
            console.error(err)
        }
    }

    return onboardingInfo
}
