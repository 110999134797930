import PropTypes from 'prop-types'
import { useTypeDetails } from './data'
import './estimations.scss'
import checked from 'src/assets/images/circle_check_blue.svg'
import unchecked from 'src/assets/images/circle_check_white.svg'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { formatInputValue } from 'src/utils/formatting'

const CustomerType = ({
    useType,
    setUseType,
    useTypeError,
    setUseTypeError,
    inputs,
    setInputs,
    errors,
    setErrors,
}) => {
    const isRetrieveUserDetailsFlow =
        process.env.REACT_APP_FLAG_RETURNING_CUSTOMERS_V2 === 'true'

    const handleSelectChange = (name, value) => {
        if (isRetrieveUserDetailsFlow) {
            setErrors(prev => ({ ...prev, [name]: '' }))

            setInputs(preState => ({
                ...preState,
                [name]: value,
            }))
        } else {
            setUseType(preState => ({
                ...preState,
                [name]: value,
            }))
        }
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setErrors(prev => ({ ...prev, [name]: '' }))

        if (name === 'email') {
            setInputs(prev => ({
                ...prev,
                [name]: value.replaceAll(' ', ''),
            }))
        } else if (name === 'phone') {
            setInputs(prev => ({
                ...prev,
                [name]: formatInputValue(name, value),
            }))
        } else {
            setInputs(preState => ({
                ...preState,
                [name]: value,
            }))
        }
    }

    if (isRetrieveUserDetailsFlow) {
        return (
            <div className={'CTWrapper UseType'}>
                <div className={'CTHeadingAndText'}>
                    <h3>What is this customer getting this system for?</h3>
                    <p>
                        Will the solar system be used for residential, SME or
                        other business purposes?
                    </p>
                    <div className={'CTUseTypes_V2'}>
                        {useTypeDetails?.map((each, i) => (
                            <div
                                key={i}
                                className={'CTUseType_V2'}
                                role={'button'}
                                onClick={() => {
                                    handleSelectChange(
                                        'customer_type',
                                        each.value,
                                    )
                                    setErrors(prev => ({
                                        ...prev,
                                        useType: '',
                                    }))
                                }}
                                style={{
                                    backgroundColor:
                                        inputs?.customer_type === each.value
                                            ? '#EBF3FF'
                                            : '#F9FCFF',
                                    border:
                                        inputs?.customer_type === each.value
                                            ? '0.5px solid #004AAD'
                                            : '0.5px solid #FFFFFF',
                                }}
                            >
                                <img
                                    src={each.icon}
                                    alt={each.title}
                                    className={'CTUseType_Img'}
                                />
                                <div>
                                    <h4>{each.title}</h4>
                                    <p>{each.description}</p>
                                </div>
                                <img
                                    src={
                                        inputs?.customer_type === each.value
                                            ? checked
                                            : unchecked
                                    }
                                    alt={'checked'}
                                    className={'CTUseType_CheckedIcon'}
                                />
                            </div>
                        ))}
                        {(errors?.customer_type || errors?.customer_type) && (
                            <p className={'CTUseTypes_Err_V2'}>
                                {errors?.customer_type || errors?.customer_type}
                            </p>
                        )}
                    </div>

                    <div className="CTInputsWrapper ">
                        <InputFields
                            title={'Customer’s Email Address'}
                            name={'email'}
                            value={inputs?.email}
                            errorMessage={errors?.email}
                            handleChange={handleInputChange}
                            marginBottom={0}
                        />
                        <SelectCountryCode
                            title="Customer’s Phone Number"
                            name="phone"
                            inputValue={inputs?.phone}
                            errorMessage={errors?.phone}
                            handleChange={handleInputChange}
                            marginBottom={0}
                            sseUse={true}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={'CTWrapper UseType'}>
            <div className={'CTHeadingAndText'}>
                <h3>What is this customer getting this system for?</h3>
                <p>
                    Will the solar system be used for residential, small
                    business or other business purposes?
                </p>
                <div className={'CTUseTypes'}>
                    {useTypeDetails?.map((each, i) => (
                        <div
                            key={i}
                            className={'CTUseType'}
                            role={'button'}
                            onClick={() => {
                                handleSelectChange('useType', each.value)
                                setUseTypeError(prev => ({
                                    ...prev,
                                    useType: '',
                                }))
                            }}
                            style={{
                                backgroundColor:
                                    useType?.useType === each.value
                                        ? '#EBF3FF'
                                        : '#F9FCFF',
                                border:
                                    useType?.useType === each.value
                                        ? '0.5px solid #004AAD'
                                        : '0.5px solid #FFFFFF',
                            }}
                        >
                            <img
                                src={each.icon}
                                alt={each.title}
                                className={'CTUseType_Img'}
                            />
                            <div>
                                <h4>{each.title}</h4>
                                <p>{each.description}</p>
                            </div>
                            {useType?.useType === each.value && (
                                <img
                                    src={checked}
                                    alt={'checked'}
                                    className={'CTUseType_CheckedIcon'}
                                />
                            )}
                        </div>
                    ))}
                    {useTypeError?.useType && (
                        <p className={'CTUseTypes_Err'}>
                            {useTypeError?.useType}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

CustomerType.propTypes = {
    useType: PropTypes.object,
    setUseType: PropTypes.func,
    useTypeError: PropTypes.object,
    setUseTypeError: PropTypes.func,
    inputs: PropTypes.object,
    setInputs: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
}

export default CustomerType
