import PropTypes from 'prop-types'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import Options from './components/Options'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import useAddMembersModal from './useAddMembersModal'
import PersonalInfo from './components/PersonalInfo'
import ShowCSVFile from './components/ShowCSVFile'
import IdInfo from './components/IdInfo'
import React from 'react'
import { consumerModalValues } from './utils'

const AddMembersModal = ({
    showModal,
    onCancel,
    handleHoldNewMember,
    storeActionAndOpenUploadModal,
}) => {
    const {
        method,
        action,
        handleSelectMethod,
        handleClick,
        standardHeight,
        closeModal,
        modalTitle,
        inputs,
        errors,
        handleChange,
        handleOnBlur,
        handleSelectChange,
        handleSelectOnBlur,
        sampleCSVFile,
        handleSampleCSVdownload,
    } = useAddMembersModal(
        onCancel,
        handleHoldNewMember,
        storeActionAndOpenUploadModal,
    )
    const { isMobile } = useMediaQueries()
    const { primaryBtnText } = consumerModalValues(action)

    return (
        <>
            <ConsumerModalBase
                showModal={showModal}
                onCancel={closeModal}
                standardWidth={477}
                standardHeight={standardHeight}
                title={modalTitle}
                primaryBtnText={primaryBtnText}
                primaryBtnDisabled={
                    method === '' ||
                    (action === 'show-multiple-members-csv-file' &&
                        !sampleCSVFile)
                }
                primaryBtnWidth={isMobile ? '127px' : '160px'}
                handlePrimaryBtnClick={handleClick}
            >
                <React.Fragment id="Rep_AddMembersContainer">
                    {action === 'select-method' ? (
                        <Options
                            method={method}
                            handleSelectMethod={handleSelectMethod}
                        />
                    ) : action === 'single-member-personal-info' ? (
                        <PersonalInfo
                            inputs={inputs}
                            errors={errors}
                            handleChange={handleChange}
                            handleOnBlur={handleOnBlur}
                        />
                    ) : action === 'single-member-id-info' ? (
                        <IdInfo
                            inputs={inputs}
                            errors={errors}
                            handleChange={handleChange}
                            handleOnBlur={handleOnBlur}
                            handleSelectChange={handleSelectChange}
                            handleSelectOnBlur={handleSelectOnBlur}
                        />
                    ) : action === 'show-multiple-members-csv-file' ? (
                        <ShowCSVFile
                            handleSampleCSVdownload={handleSampleCSVdownload}
                            sampleCSVFile={sampleCSVFile}
                        />
                    ) : (
                        <div>not set yet</div>
                    )}
                </React.Fragment>
            </ConsumerModalBase>
        </>
    )
}

AddMembersModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    handleHoldNewMember: PropTypes.func,
    storeActionAndOpenModal: PropTypes.func,
    storeActionAndOpenUploadModal: PropTypes.func,
}

export default AddMembersModal
