import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getCreditVariableCount } from 'src/api/estimations/estimations-step-one'
import { eventTrackers } from 'src/utils/eventTrackers.js'
import { appTracking } from 'src/utils/appTracker.js'

export const useCreatePaymentPlans = ({
    allEstimationsCount,
    isRetrieveUserDetailsFlow,
    page = '',
    pageTitle,
}) => {
    const history = useHistory()
    const [showNoCreditVariableModal, setShowNoCreditVariableModal] =
        useState(false)
    const [showCustomerTypeModal, setShowCustomerTypeModal] = useState(false)
    const [showCustomerType, setShowCustomerType] = useState(false)

    const [customerType, setCustomerType] = useState('')
    const [creditVariableCheck, setCreditVariableCheck] = useState({
        hasSubscription: false,
        hasLeaseToOwn: false,
        hasNoCreditVariable: false,
    })

    const pageUrlName = window.location.pathname
    const pageTracker = 'CREATE_PAYMENT_PLAN_TRACKER'

    useQuery(
        `get-credit-variable-count-${page}`,
        () => getCreditVariableCount(),
        {
            enabled: true,
            retry: false,
            cacheTime: 0,
            onSuccess: data => {
                const creditVariableData = data?.data?.data

                creditVariableData.forEach(data => {
                    if (
                        data?.payment_model === 'SUBSCRIPTION' &&
                        data.count > 0
                    ) {
                        setCreditVariableCheck(prev => ({
                            ...prev,
                            hasSubscription: true,
                        }))
                    }

                    if (
                        data?.payment_model === 'LEASE_TO_OWN' &&
                        data.count > 0
                    ) {
                        setCreditVariableCheck(prev => ({
                            ...prev,
                            hasLeaseToOwn: true,
                        }))
                    }
                })

                setCreditVariableCheck(prev => ({
                    ...prev,
                    hasNoCreditVariable:
                        !prev.hasSubscription && !prev.hasLeaseToOwn,
                }))
            },
        },
    )

    const handleCreatePaymentPlansClick = () => {
        if (creditVariableCheck?.hasNoCreditVariable) {
            setShowNoCreditVariableModal(true)
            return
        }

        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['createPaymentPlan'].action,
            eventTrackers['createPaymentPlan'].label,
            eventTrackers['createPaymentPlan'].category,
        )

        if (allEstimationsCount > 0) {
            setShowCustomerTypeModal(true)
            return
        }

        if (isRetrieveUserDetailsFlow) {
            setCustomerType('new')
            setShowCustomerType(true)
            return
        }

        history.push({
            pathname: '/app/payment-plans/create',
            state: {
                customerType: 'new',
            },
        })
    }

    return {
        creditVariableCheck,
        setCreditVariableCheck,
        showActionModal: showNoCreditVariableModal,
        setShowActionModal: setShowNoCreditVariableModal,
        showCustomerTypeModal,
        customerType,
        setCustomerType,
        showCustomerType,
        setShowCustomerType,
        setShowCustomerTypeModal,
        handleCreatePaymentPlansClick,
    }
}
