//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { encodeUserInfo, setToken } from '../../utils/auth'
import { useQuery } from 'react-query'
import { emailVerificationApi } from '../../api/signup/emailVerification'
import { verifyConsumerEmail } from '../../api/consumer'
import Confirmation from '../../components/Confirmation'
import errorImage from '../../assets/images/deletemodal-icon.svg'
import successImage from '../../assets/images/successImage.svg'
import SeoComponent from 'src/components/Seo'

const VerifyEmail = props => {
    const parsed = queryString.parse(props.location.search)
    const type = props?.location?.pathname?.startsWith('/consumer/')
        ? 'consumer'
        : ''

    const formattedParsed = {
        ...parsed,
        email: parsed?.email?.split(' ').join('+'),
        module: type === 'consumer' ? 2 : 1,
    }

    const [verified, setVerified] = useState(false)
    const [width, setwidth] = useState(50)

    useEffect(() => {
        const handleSlider = () => {
            if (verified && width < 100) {
                setwidth(prev => prev + 50)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 2000)
        return () => clearTimeout(sliderWidth)
    }, [width, verified])

    const { refetch, error, data } = useQuery(
        'email verification',
        () =>
            type === 'consumer'
                ? verifyConsumerEmail(formattedParsed)
                : emailVerificationApi(formattedParsed),

        {
            enabled: false,
            retry: false,
            onSuccess: () => setVerified(true),
        },
    )

    const processType =
        parsed?.event === 'offer_update'
            ? 'updateApplication'
            : parsed?.event === 'repayment_schedule' && 'repaymentSchedule'

    const ConsumerProcessType = localStorage.getItem('processType')

    useEffect(() => {
        ConsumerProcessType
        refetch()
    }, [refetch])

    const history = useHistory()

    const onButtonClick = () => {
        if (type === 'consumer') {
            history.push('/consumer/login')
        } else {
            history.push('/signup')
        }
    }

    if (verified && width === 100) {
        if (type === 'consumer') {
            encodeUserInfo(data)
            localStorage.setItem('sunfiToken', data?.data?.data?.token?.access)
            localStorage.setItem('processType', processType)

            history.push('/consumer/workspace')
        } else {
            setToken(data?.data?.data?.token?.access)
            history.push({
                pathname: '/create-account',
                state: {
                    partnerDetails: data?.data?.data?.user,
                    from: 'intialSignup',
                },
            })
        }
    }

    return (
        <>
            <SeoComponent
                title="SunFi - Verify Email"
                tracker="VerifyEmailTracker"
            />
            {verified ? (
                <Confirmation
                    imgUrl={successImage}
                    header={`${
                        type === 'consumer'
                            ? 'Sign In Successful'
                            : 'Verification Successful'
                    }`}
                    subHeader={`${
                        type === 'consumer'
                            ? ConsumerProcessType === 'repaymentSchedule'
                                ? 'Give us a second to get your repayment breakdown ready'
                                : 'Give us a second to get your application details ready.'
                            : 'Your details have been verified. Now you can proceed to create your account.'
                    }`}
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    scrollIndicator
                    scrollWidth={width}
                />
            ) : (
                error && (
                    <Confirmation
                        imgUrl={errorImage}
                        header="Verification Unsuccessful"
                        subHeader="Oops, we have some trouble verifying your account. Please try again."
                        subHeaderStyle="48px"
                        boxPadding="45px"
                        onButtonClick={onButtonClick}
                        btnText="Retry"
                    />
                )
            )}
        </>
    )
}

export default VerifyEmail
