import axios from '../../../config/axios'

export const getAllClusterRepMember = async (
    clusterId,
    memberStatus,
    paymentType,
    systemSize,
    paymentAmount,
    duePayment,
    currentPage,
    name,
    has_credit_report,
    paginate = true,
) => {
    const request = await axios.get(
        `/cluster-origination/reps/${clusterId}/members?member_status=${memberStatus}&payment_type=${paymentType}&system_size=${systemSize}&payment_amount=${paymentAmount}&due_payment=${duePayment}&page=${currentPage}&name=${name}&has_credit_report=${has_credit_report}&paginate=${paginate}`,
    )
    return request
}

export const addClusterMember = async (data, clusterId) => {
    const request = await axios.post(
        `/cluster-origination/reps/${clusterId}/members/add`,
        data,
    )
    return request
}

export const resendSystemSelectionLink = async (id, clusterId) => {
    const request = await axios.post(
        `/cluster-origination/reps/${clusterId}/members/${id}/selection-link`,
    )
    return request
}

export const removeClusterMember = async (id, clusterId) => {
    const request = await axios.post(
        `/cluster-origination/reps/${clusterId}/members/${id}/remove`,
    )
    return request
}

export const retrieveClusterSummary = async clusterId => {
    const request = await axios.get(
        `/cluster-origination/reps/${clusterId}/members/summary`,
    )
    return request
}

export const exportMembers = async (
    clusterId,
    memberStatus,
    paymentType,
    systemSize,
    paymentAmount,
    duePayment,
    currentPage,
) => {
    const request = await axios.get(
        `/cluster-origination/reps/${clusterId}/members/export?member_status=${memberStatus}&payment_type=${paymentType}&system_size=${systemSize}&payment_amount=${paymentAmount}&due_payment=${duePayment}&page=${currentPage}&response_type=csv`,
    )
    return request
}

export const uploadCreditReport = async (data, clusterId) => {
    const request = await axios.post(
        `/cluster-origination/reps/${clusterId}/members/upload-credit-report`,
        data,
    )
    return request
}

export const validateMemberUpload = async (data, clusterId) => {
    const request = await axios.post(
        `/cluster-origination/reps/${clusterId}/members/validate-members`,
        data,
    )
    return request
}

export const downloadCSVSample = async clusterId => {
    const request = await axios.post(
        `/cluster-origination/reps/${clusterId}/download-csv-sample`,
    )
    return request
}

export const addMultipleClusterMembers = async (data, clusterId) => {
    const request = await axios.post(
        `/cluster-origination/reps/${clusterId}/members/add-multiple`,
        data,
    )
    return request
}
