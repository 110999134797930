import PropTypes from 'prop-types'
import ActionModal from 'src/components/ActionModal/index.jsx'

const EstimationsActionModal = ({ showModal, setShowModal }) => {
    const handleClose = () => {
        setShowModal?.(false)
    }

    return (
        <ActionModal
            actionType="warning"
            actionModalOpen={showModal}
            headerText={`You can't create and simulate a plan yet`}
            subTitle="To start creating and simulating a plan, a credit variable needs to be created for you."
            actionText="Okay, Got it"
            closable
            onCancel={handleClose}
            actionHandler={handleClose}
            noBackLink
            noCancelBtn
        />
    )
}

EstimationsActionModal.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
}

export default EstimationsActionModal
