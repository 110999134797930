export const clearVisitCount = () => {
    localStorage.removeItem('profile_listing_visits')
    localStorage.removeItem('system_details_visits')
    localStorage.removeItem('profile_lists_viewed')
}

export const visitCount = (
    item_id,
    storage_key,
    return_actual_visits = false,
) => {
    let visits = Number(localStorage.getItem(storage_key))
    const prevItemId = sessionStorage.getItem(`${storage_key}_id`)
    if (item_id !== prevItemId) {
        visits++
    }

    localStorage.setItem(storage_key, visits)
    sessionStorage.setItem(`${storage_key}_id`, item_id)

    if (return_actual_visits) {
        return visits
    }

    return visits <= 2 && visits > 0
        ? 2
        : visits <= 4 && visits > 2
        ? 4
        : visits <= 6 && visits > 4
        ? 6
        : visits <= 8 && visits > 6
        ? 8
        : visits <= 10 && visits > 8
        ? 10
        : visits > 10
        ? '> 10'
        : 0
}
