//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import './adminprovidersactive.scss'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import CompanyInfoTable from './CompanyInfoTable'
import ProviderBankDetailsTable from './BankDetailsTable'
import ProviderUserTable from './UserTable'
import {
    actionMappings,
    canInviteProviderUser,
    canViewProviderUserList,
    createLibraryUserPermissions,
} from './data'
import ActionModal from 'src/components/ActionModal'
import ProviderResourcesTable from './ResourcesTable'
import AdminProviderMoreActions from './MoreActionsModal'
import InviteAdminUser from './InviteUserModal'
import { v4 as uuidv4 } from 'uuid'

import ProviderInstallerInfo from './InstallerInfoTable'
import WorkExperience from './WorkExperience'
import DetailsPage from 'src/components/DetailsPage'
import { useQuery } from 'react-query'
import {
    getActiveProviderGroupsApi,
    getAdminProviderUsersApi,
    getNonAssignedResourcesApi,
    getSingleAdminProviderApi,
} from 'src/api/admin/provider'
import Toast from 'src/components/Toast'
import { format, parseISO } from 'date-fns'
import { errorHandler } from 'src/utils/errorHandler'
import SeoComponent from 'src/components/Seo'
import MoreActionsMenu from './MoreActionsMenu'
import ComponentBrands from '../Active/ComponentBrands'

const AdminApprovedProviders = () => {
    const history = useHistory()
    const [successModal, setSuccessModal] = useState(false)
    const [action, setAction] = useState('')
    const [moreActionsModal, setMoreActionsModal] = useState(false)
    const [inputs, setInputs] = useState({
        resource: null,
        provider: null,
        reason: null,
    })
    const [errors, setErrors] = useState('')
    const [showInviteModal, setShowInviteModal] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([
        { id: uuidv4(), email: '', roles: [] },
    ])
    const [inviteErrors, setInviteErrors] = useState({})
    const [providerData, setProviderData] = useState([])
    const [providerUsersData, setProviderUsersData] = useState([])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [triggerRefetch, setTriggerRefetch] = useState(false)
    const [triggerResourceRefetch, setTriggerResourceRefetch] = useState(false)
    const [availableResources, setAvailableResources] = useState([])
    const [groups, setGroups] = useState([])
    const providerIsAnAsi = history?.location?.search?.includes('asi')
    const params = useParams()
    const currentActionContent =
        actionMappings[action] || actionMappings['default']

    //get single provider
    const {
        refetch: singleProviderRefetch,
        isLoading: singleProviderLoading,
        isFetching: singleProviderFetching,
    } = useQuery(
        ['get-active-single-provider'],
        () => getSingleAdminProviderApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const fetchedData = data?.data?.data
                setProviderData(fetchedData)
                if (fetchedData?.status !== 'APPROVED') {
                    history.goBack()
                }
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't fetch details. Refresh page`)
            },
        },
    )

    //get all users under the provider
    const { refetch: providerUsersRefetch, isFetching: providerUsersFetching } =
        useQuery(
            ['get-active-provider-users'],
            () => getAdminProviderUsersApi(params?.id),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setProviderUsersData(data?.data?.data)
                },
                onError: () => {
                    setToastError(true)
                    setErrorMessage(`Couldn't fetch details. Refresh page`)
                },
            },
        )

    //get list of resources not yet assigned to the provider
    const { refetch: getAvailableResourcesRefetch } = useQuery(
        ['get-available-provider-resources'],
        () => getNonAssignedResourcesApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                let resources = data?.data?.data
                resources = resources?.filter(
                    resource => createLibraryUserPermissions[resource],
                )
                setAvailableResources(resources)
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    // get list of groups (permission roles)
    const { refetch: getGroupsRefetch } = useQuery(
        ['get-provider-groups'],
        () => getActiveProviderGroupsApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const groupData = data?.data?.data
                for (let i = 0; i < groupData?.length; i++) {
                    setGroups(prev => [
                        ...prev,
                        {
                            label: groupData?.[i]?.name,
                            value: groupData?.[i]?.name,
                            id: groupData?.[i]?.id,
                        },
                    ])
                }
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        singleProviderRefetch()
        providerUsersRefetch()
        setTriggerRefetch(false)
    }, [triggerRefetch])

    useEffect(() => {
        getGroupsRefetch()
    }, [])

    useEffect(() => {
        getAvailableResourcesRefetch()
        setTriggerResourceRefetch(false)
    }, [triggerResourceRefetch])

    const handleMoreActions = action => {
        if (action === 'deactivate provider') {
            setAction('deactivate-provider')
        }

        if (action === 'assign to resource') {
            setAction('assign-resource')
        }

        setMoreActionsModal(true)
    }

    const handleAddUser = () => {
        setAction('add-user')
        setShowInviteModal(true)
    }

    const closeInviteModal = () => {
        setInviteErrors({})
        setSelectedUsers([
            {
                id: uuidv4(),
                email: '',
                roles: [],
            },
        ])
        setAction('')
        setShowInviteModal(false)
    }

    const handleSuccessModal = () => {
        setSuccessModal(false)

        if (action === 'assign-resource') {
            setInputs({
                resource: null,
                provider: null,
            })
            setErrors('')
        }
    }

    return (
        <>
            <SeoComponent
                title="Approved Provider - Provider Management | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Admin-Provider-Management"
            />
            {/*more actions*/}
            <AdminProviderMoreActions
                ownerId={providerData?.user?.id}
                providerId={params?.id}
                showModal={moreActionsModal}
                setMoreActionsModal={setMoreActionsModal}
                action={action}
                inputs={inputs}
                setInputs={setInputs}
                setSuccessModal={setSuccessModal}
                errors={errors}
                setErrors={setErrors}
                setTriggerRefetch={setTriggerRefetch}
                setTriggerResourceRefetch={setTriggerResourceRefetch}
                availableResources={availableResources}
            />
            {/*invite user*/}
            <InviteAdminUser
                providerId={params?.id}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                inviteErrors={inviteErrors}
                setInviteErrors={setInviteErrors}
                closeInviteModal={closeInviteModal}
                showInviteModal={showInviteModal}
                setSuccessModal={setSuccessModal}
                setTriggerRefetch={setTriggerRefetch}
                groups={groups}
                setAction={setAction}
            />
            <ActionModal
                actionType="success"
                actionModalOpen={successModal}
                headerText={currentActionContent.headerText}
                subTitle={currentActionContent.subTitle}
                actionText="Okay, Got it"
                closable
                onCancel={() => handleSuccessModal()}
                actionHandler={() => handleSuccessModal()}
                noBackLink
                noCancelBtn
            />
            {toastError && <Toast messageType="error" message={errorMessage} />}
            <DetailsPage
                backNavTitle="Back to Providers"
                backNavOnClick={() => {
                    history.push('/admin/providers')
                }}
                name={
                    providerIsAnAsi
                        ? providerData?.display_name
                        : providerData?.company_name
                }
                status={providerData?.status}
                infoSubhead={
                    providerIsAnAsi ? 'INSTALLER BIO' : 'BUSINESS INFO'
                }
                infoParagraph={
                    providerIsAnAsi
                        ? providerData?.user?.bio
                            ? providerData?.user?.bio
                            : 'Not available'
                        : providerData?.business_info
                        ? providerData?.business_info
                        : 'Not available'
                }
                tabPaneOneTab={
                    providerIsAnAsi ? 'Installer Info' : 'Company Info'
                }
                tabPaneOneContent={
                    providerIsAnAsi ? (
                        <ProviderInstallerInfo data={providerData} />
                    ) : (
                        <CompanyInfoTable data={providerData} />
                    )
                }
                tabPaneTwo={providerIsAnAsi ? true : canViewProviderUserList}
                tabPaneTwoTab={providerIsAnAsi ? 'Work Experience' : 'Users'}
                tabPaneTwoContent={
                    providerIsAnAsi ? (
                        <WorkExperience providerId={params?.id} />
                    ) : (
                        <ProviderUserTable
                            data={providerUsersData}
                            setTriggerRefetch={setTriggerRefetch}
                            groups={groups}
                            isFetching={providerUsersFetching}
                        />
                    )
                }
                tabPaneThree
                tabPaneThreeTab="Bank Details"
                tabPaneThreeContent={
                    <ProviderBankDetailsTable data={providerData} />
                }
                tabPaneFour
                tabPaneFourTab="Resources"
                tabPaneFourContent={
                    <ProviderResourcesTable
                        resources={providerData?.resources}
                        providerId={params?.id}
                        setTriggerRefetch={setTriggerRefetch}
                        setTriggerResourceRefetch={setTriggerResourceRefetch}
                    />
                }
                {...(process.env.REACT_APP_FLAG_SHOW_COMPONENT_BRANDS === 'true'
                    ? {
                          tabPaneFive: true,
                          tabPaneFiveTab: 'Component Brands',
                          tabPaneFiveContent: (
                              <ComponentBrands providerId={params?.id} />
                          ),
                      }
                    : {})}
                rowCount={2}
                rightSectionHeading="Provider Details"
                gridItemOneHeading="CREATION DATE"
                gridItemOneContent={
                    providerData?.created_at !== undefined &&
                    format(
                        parseISO(providerData?.created_at),
                        'dd MMM, yyyy, hh:mm',
                    )
                }
                gridItemTwoHeading="CREATED BY"
                gridItemTwoContent={providerData?.created_by ?? '-'}
                gridItemThreeHeading="PROVIDER TYPE"
                gridItemThreeContent={`${providerData?.user?.provider?.type?.name} (${providerData?.user?.provider?.type?.identifier})`}
                gridItemFourHeading={
                    providerIsAnAsi ? 'Qualification' : 'NO. USERS'
                }
                gridItemFourContent={
                    providerIsAnAsi
                        ? providerData?.user?.education_level ?? '-'
                        : providerUsersData?.length
                }
                actionButton
                buttonOneText={providerIsAnAsi ? 'Assign Resource' : 'Add User'}
                buttonOneHandleClick={() =>
                    providerIsAnAsi
                        ? handleMoreActions('assign to resource')
                        : handleAddUser()
                }
                buttonOneDisabled={!providerIsAnAsi && !canInviteProviderUser}
                buttonOneTooltipText={
                    !providerIsAnAsi && !canInviteProviderUser
                        ? 'You are not authorised to perform this action'
                        : null
                }
                secondButton
                secondButtonType="moreActions"
                dropdownMenu={
                    <MoreActionsMenu
                        providerIsAnAsi={providerIsAnAsi}
                        handleMoreActions={handleMoreActions}
                    />
                }
                isLoading={
                    singleProviderLoading ||
                    providerUsersFetching ||
                    singleProviderFetching
                }
            />
        </>
    )
}

export default AdminApprovedProviders
