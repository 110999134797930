export const consumerProfileEvents = type => {
    const events = {
        viewListing: {
            action: `View ${type} Listing`,
            category: 'Consumer Profile',
            label: `View ${type} Listing`,
        },
        viewDetail: {
            action: `View ${type} Details`,
            category: 'Consumer Profile',
            label: `View ${type} Details`,
        },
        viewProfileCount: {
            action: `Viewed ${type}`,
            category: 'Consumer Profile',
            label: `Viewed ${type}`,
        },
        viewPackageDetails: {
            action: `View ${type}`,
            category: 'Consumer Profile',
            label: `View ${type}`,
        },
        getSystem: {
            action: `Wants ${type}`,
            category: 'Consumer Profile',
            label: `Wants ${type}`,
        },
        selectBuildSytem: {
            action: `Selected BCS on ${type}`,
            category: 'Consumer Profile',
            label: `Selected BCS on ${type}`,
        },
        useFilter: {
            action: `Use Filter ${type}`,
            category: 'Consumer Profile',
            label: `Use Filter - ${type}`,
        },
        useSort: {
            action: `Use Sort ${type}`,
            category: 'Consumer Profile',
            label: `Use Sort - ${type}`,
        },
        shareProfile: {
            action: `Share ${type}`,
            category: 'Consumer Profile',
            label: `Share ${type}`,
        },
        viewedSharedProfile: {
            action: `View shared ${type}`,
            category: 'Consumer Profile',
            label: `View shared ${type}`,
        },
        qvClick: {
            action: `Clicked quick view`,
            category: 'Quick view',
            label: `Clicked quick view`,
        },
        qvPackageType: {
            action: `QV Viewed ${type}`,
            category: `Quick view`,
            label: `QV Viewed ${type}`,
        },
        qvViewMultiplePlans: {
            action: `Viewed multiple Pymt. plan`,
            category: 'Quick View',
            label: `Viewed multiple Pymt. plan`,
        },
        qvGetSystem: {
            action: `Clicked "Get this sys." w/o full det.`,
            category: 'Quick view',
            label: `Clicked "Get this sys." w/o full det.`,
        },
        viewProfileList: {
            action: 'View Profile list',
            category: 'Profile management',
            label: 'View Profile list',
        },
        viewProfileDetails: {
            action: 'View Profile details',
            category: 'Profile management',
            label: 'View Profile details',
        },
        viewPackageList: {
            action: 'View Package list',
            category: 'Package management',
            label: 'View Package list',
        },
        viewPackageQuickView: {
            action: 'View Package quick view',
            category: 'Package quick view',
            label: 'View Package quick view',
        },
        viewPackage: {
            action: 'View Package details',
            category: 'Package details view',
            label: 'View Package details',
        },
    }
    return events
}
