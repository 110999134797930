/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Layout, Menu, Drawer } from 'antd'
import { Widget } from 'react-typeform-embed'

import ButtonComponent from '../Button/index'
import GetModal from '../Modal/index'
import styles from './navigation.module.scss'
import logo from '../../assets/images/logo.svg'
import logo2 from '../../assets/images/logo2.svg'

import { ReactComponent as CancelIcon } from '../../assets/images/CancelIcon.svg'
import { ReactComponent as HamburgerIconWhite } from '../../assets/images/HamburgerIconWhite.svg'
import { ReactComponent as HamburgerIconBlack } from '../../assets/images/HamburgerIconBlack.svg'
import useHandleSectionScroll from 'src/utils/Hooks/useHandleSectionScroll'
import { useInView } from 'src/utils/Hooks/useInView'

const Navigation = ({
    bgColor,
    textColor,
    btnBgColor,
    btnTextColor,
    btnOutlineColor,
    btnTextColorOutline,
    page,
    btnType,
    className,
    fontSize,
}) => {
    const {
        NavLayout,
        NavHeader,
        MenuWrapper,
        NavLogoWrapper,
        NavItem,
        BarsMenu,
        BtnCancel,
        NavText,
        TextElement,
        BtnSecondary,
        MobileDrawer,
        ListWrapper,
        ListStyle,
        ConsumerAppHomeLinks,
        BlueNavWrappper,
        WhiteNavWrapper,
        ActiveNavLink,
    } = styles

    const [visible, setVisible] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()
    const { handleSectionScroll } = useHandleSectionScroll()
    const inView = useInView({
        '/': ['solar-savings-calculator', 'landing-page-form'],
        '/home': ['solar-savings-calculator', 'landing-page-form'],
    })

    const showDrawer = () => {
        setVisible(true)
    }

    const hideDrawer = () => {
        setVisible(false)
    }

    const onClose = () => {
        setVisible(false)
    }
    const getOfferTypeForm = (
        <Widget
            id={`${process.env.REACT_APP_GET_OFFER_FORM_URL}`}
            height={700}
        />
    )

    const getStarted = () => {
        history.push(
            window.location.pathname === '/partners'
                ? '/login'
                : '/consumer/welcome',
        )
    }

    const handleCancel = () => {
        setShowModal(false)
    }

    const isBlueBg = bgColor === 'var(--blue)'

    return (
        <Layout
            className={NavLayout}
            style={{
                position:
                    (page === 'appHome' ||
                        page === 'consumerConversationalFormActive' ||
                        page === 'ConsumerAppHome') &&
                    'relative',
            }}
        >
            <header
                className={className ? className : NavHeader}
                style={{ backgroundColor: `${bgColor}` }}
            >
                <div className={NavLogoWrapper}>
                    {page === 'home' ||
                    page === 'partner' ||
                    page === 'consumerConversationalFormActive' ? (
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    ) : (
                        <Link to="/">
                            <img src={logo2} alt="logo" />
                        </Link>
                    )}
                </div>
                {page !== 'appHome' &&
                page !== 'ConsumerAppHome' &&
                page !== 'consumerConversationalFormInactive' &&
                page !== 'consumerConversationalFormActive' ? (
                    <Menu
                        mode="horizontal"
                        className={`${MenuWrapper} ${
                            isBlueBg ? BlueNavWrappper : WhiteNavWrapper
                        }`}
                        style={{ backgroundColor: `${bgColor}`, width: '45%' }}
                    >
                        <li className={NavItem}>
                            <Link
                                className={
                                    history.location?.pathname === '/'
                                        ? ActiveNavLink
                                        : ''
                                }
                                to="/"
                                style={{
                                    color: textColor,
                                }}
                            >
                                Home
                            </Link>
                        </li>
                        {/* <li className={NavItem}>
                            <Link
                                to="/projects"
                                style={{
                                    color: textColor,

                                    fontWeight: page === 'projects' && '600',
                                }}
                            >
                                Projects
                            </Link>
                        </li> */}
                        <li className={NavItem}>
                            <Link
                                className={
                                    history.location?.pathname === '/learn'
                                        ? ActiveNavLink
                                        : ''
                                }
                                to="/learn"
                                onClick={e => {
                                    e.preventDefault()
                                    window.location.href = '/learn'
                                }}
                                style={{
                                    ...(history.location.pathname === '/learn'
                                        ? {
                                              color: '#004AAD',
                                          }
                                        : { color: textColor }),
                                }}
                            >
                                Learn
                            </Link>
                        </li>
                        <li className={NavItem}>
                            <Link
                                className={
                                    history.location?.pathname === '/partners'
                                        ? ActiveNavLink
                                        : ''
                                }
                                to="/partners"
                                style={{
                                    color: textColor,
                                }}
                            >
                                Become a partner
                            </Link>
                        </li>
                        <li className={NavItem}>
                            <Link
                                className={
                                    inView['solar-savings-calculator']
                                        ? ActiveNavLink
                                        : ''
                                }
                                to="#solar-savings-calculator"
                                onClick={e => {
                                    e.preventDefault()
                                    handleSectionScroll(
                                        'solar-savings-calculator',
                                        '',
                                        -150,
                                    )
                                }}
                            >
                                Energy Calculator
                            </Link>
                        </li>
                    </Menu>
                ) : page === 'ConsumerAppHome' ? (
                    <div className={ConsumerAppHomeLinks}>
                        <li className={NavItem}>
                            <Link
                                to="/privacy-policy"
                                style={{
                                    color: `${textColor}`,
                                    fontSize: `${fontSize}`,
                                }}
                            >
                                Privacy Policy
                            </Link>
                        </li>
                        <li className={NavItem}>
                            <Link
                                to="/terms-of-service"
                                style={{
                                    color: `${textColor}`,
                                    fontSize: `${fontSize}`,
                                }}
                            >
                                Terms of Service
                            </Link>
                        </li>
                    </div>
                ) : null}
                {page !== 'appHome' &&
                page !== 'ConsumerAppHome' &&
                page !== 'consumerConversationalFormInactive' &&
                page !== 'consumerConversationalFormActive' ? (
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                        className={MenuWrapper}
                    >
                        <ButtonComponent
                            btnTextColorOutline={btnTextColorOutline}
                            btnOutlineColor={btnOutlineColor}
                            btnTextColor={btnTextColor}
                            btnBgColor={btnBgColor}
                            btnWidth="161px"
                            btnHeight="54px"
                            handleClick={() => getStarted()}
                            type={btnType ? btnType : 'default'}
                        >
                            Sign in
                        </ButtonComponent>
                    </div>
                ) : null}
                {page === 'home' || page === 'partner' ? (
                    <button
                        className={BarsMenu}
                        onClick={showDrawer}
                        aria-label="Open menu"
                    >
                        <span>
                            <HamburgerIconWhite role={'presentation'} />
                        </span>
                    </button>
                ) : null}
                {page === 'learn' ||
                page === 'projects' ||
                page === 'termsofservice' ||
                page === 'privacyPolicy' ||
                page === 'notFound' ? (
                    <button className={BarsMenu} onClick={showDrawer}>
                        <span>
                            <HamburgerIconBlack />
                        </span>
                    </button>
                ) : null}
                <Drawer
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                    width="100%"
                >
                    <div className={MobileDrawer}>
                        <button className={BtnCancel} onClick={hideDrawer}>
                            <span>
                                <CancelIcon />
                            </span>
                        </button>
                    </div>
                    <div className={ListWrapper}>
                        <li className={ListStyle}>
                            <Link to="/home" className={NavText}>
                                Home
                            </Link>
                        </li>
                        {/* <li className={ListStyle}>
                            <Link
                                to="/projects"
                                className={NavText}
                                style={{
                                    fontWeight: page === 'projects' && '600',
                                }}
                            >
                                Projects
                            </Link>
                        </li> */}
                        <li className={ListStyle}>
                            <Link
                                to="/learn"
                                onClick={e => {
                                    e.preventDefault()
                                    window.location.href = '/learn'
                                }}
                                className={NavText}
                            >
                                Learn
                            </Link>
                        </li>
                        <li style={{ marginBottom: '30px' }}>
                            <Link to="/partners" className={NavText}>
                                Become a partner
                            </Link>
                        </li>
                        <li style={{ marginBottom: '50px' }}>
                            <p
                                onClick={() =>
                                    handleSectionScroll(
                                        'solar-savings-calculator',
                                    )
                                }
                                className={`${NavText} ${TextElement}`}
                            >
                                Energy Calculator
                            </p>
                        </li>
                        <button
                            className={BtnSecondary}
                            onClick={() => getStarted()}
                        >
                            Sign in
                        </button>
                    </div>
                </Drawer>
            </header>

            <GetModal
                showModal={showModal}
                onCancel={handleCancel}
                content={getOfferTypeForm}
            />
        </Layout>
    )
}

export default Navigation
