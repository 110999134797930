import { useMutation } from 'react-query'
import {
    sseAuthInitiateOtp,
    sseAuthValidateOtp,
    sseAuthCheckExistingUser,
} from './api'
import { errorHandler } from 'src/utils/errorHandler'

export const useSSEAuthValidateOtp = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: payload => sseAuthValidateOtp(payload),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        sseValidateAuthOtp: mutate,
        sseValidateAuthOtpLoading: isLoading,
        sseValidateAuthOtpData: data,
        sseValidateAuthOtpError: error,
        sseValidateAuthOtpSuccess: isSuccess,
    }
}

export const useSSEAuthInitiateOtp = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: payload => sseAuthInitiateOtp(payload.data),
        onSuccess: (data, variables) => {
            if (onSuccess) {
                onSuccess(data?.data?.data, variables?.type)
            }
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        sseInitiateAuthOtp: mutate,
        sseInitiateAuthOtpLoading: isLoading,
        sseInitiateAuthOtpData: data,
        sseInitiateAuthOtpError: error,
        sseInitiateAuthOtpSuccess: isSuccess,
    }
}

export const useSSEAuthCheckExistingUser = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: payload => sseAuthCheckExistingUser(payload),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        sseAuthCheckExistingUser: mutate,
        sseAuthCheckExistingUserLoading: isLoading,
        sseAuthCheckExistingUserData: data,
        sseAuthCheckExistingUserError: error,
        sseAuthCheckExistingUserSuccess: isSuccess,
    }
}
