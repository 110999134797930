//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { SelectField, TextArea, InputFields } from 'src/components/InputFields'
import ActionModal from 'src/components/ActionModal'
import { InlineLoader } from 'src/components/Loader'
import Toast from 'src/components/Toast'
import {
    declinePaymentPlanApi,
    declineReEstimatePaymentPlanApi,
    resendPiiRequestApi,
    approvePaymentPlanApi,
} from 'src/api/admin/payment-plans/ssePlan'
import useErrorHandler from 'src/utils/Hooks/useErrorHandler'
import CloseIcon from 'src/assets/images/black-close-icon.svg'
import SeoComponent from 'src/components/Seo'

import styles from './approvedpaymentplan.module.scss'

const ApprovePaymentPlan = ({
    closeModal,
    selected,
    headerText,
    btnText,
    estimationId,
    userEmail,
    awaitingApprovalData,
    inputs,
    setInputs,
    errors,
    setErrors,
    iconOnClick,
    setClosureModal,
    sseUse = false,
}) => {
    const {
        ABtnWrapper,
        AButton,
        AWrapper,
        SelectStyle,
        header,
        AWCloseIconWrapper,
    } = styles

    const [successModal, setSuccessModal] = useState(false)
    const [requestSuccess, setRequestSuccess] = useState(false)
    const [errs, setErrs] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [planDurationOption, setPlanDurationOption] = useState([])

    let history = useHistory()
    useEffect(() => {
        setInputs({ ...inputs, email: userEmail, savedEmail: userEmail })
    }, [])
    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    //set values for approving a payment plan
    useEffect(() => {
        const availablePlans = awaitingApprovalData?.repayment_plans?.filter(
            plan => plan?.admin_approved === false,
        )

        for (let i = 0; i < availablePlans?.length; i++) {
            setPlanDurationOption(prev => [
                ...prev,
                {
                    id: availablePlans[i].id,
                    label: ` Plan ${i + 1} (${
                        availablePlans[i].tenure
                    } Months, ${availablePlans[i].currency.symbol} ${
                        availablePlans[i].monthly_payment
                    }/mo)`,
                },
            ])
        }
    }, [])
    const errorValue = useErrorHandler(errs)

    const handleInputChange = event => {
        const { name, value } = event.target
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleUpdateEmail = () => {
        setSuccessModal(true)
        closeModal()
    }

    const handleSendPiiRequest = () => {
        resendPiiRequestRefetch()
    }

    const handleApprovePlan = () => {
        if (inputs.planDuration === '') {
            setErrors({ planDuration: 'This field is required' })
        } else {
            approvePaymentPlanRefetch()
        }
    }

    const handleDeclinePlan = () => {
        if (inputs.declineReason === '') {
            setErrors({ declineReason: 'This field is required' })
        } else {
            declinePaymentPlanRefetch()
        }
    }

    const handleDeclineReEstimatePlan = () => {
        if (inputs.declineReason === '') {
            setErrors({ declineReason: 'This field is required' })
        } else {
            declineReEstimatePaymentPlanRefetch()
        }
    }

    const handlePiiRequest = () => {
        if (inputs.email === '') {
            setErrors({ email: 'This field is required' })
        } else {
            inputs.savedEmail !== inputs.email
                ? handleUpdateEmail()
                : handleSendPiiRequest()
        }
    }

    const handleClosePlan = () => {
        if (inputs.declineReason === '') {
            setErrors({ declineReason: 'This field is required' })
        } else {
            closeModal()
            setClosureModal(true)
        }
    }

    const handleApprove = () => {
        selected === 'Re-estimate'
            ? handleDeclineReEstimatePlan()
            : selected === 'Decline'
            ? handleDeclinePlan()
            : selected === 'Resend PII Request'
            ? handlePiiRequest()
            : selected === 'Close Payment Plan' || selected === 'Close Request'
            ? handleClosePlan()
            : handleApprovePlan()
    }

    const {
        refetch: declinePaymentPlanRefetch,
        isLoading: declinePaymentPlanLoading,
    } = useQuery(
        ['fetchSinglePackage'],
        () =>
            declinePaymentPlanApi(estimationId, {
                reason: inputs?.declineReason,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeModal()
                setSuccessModal(true)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't send request. Try again`)
            },
        },
    )

    const {
        refetch: declineReEstimatePaymentPlanRefetch,
        isLoading: declineReEstimateLoading,
    } = useQuery(
        ['fetchSinglePackage'],
        () =>
            declineReEstimatePaymentPlanApi(estimationId, {
                reason: inputs?.declineReason,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeModal()
                setSuccessModal(true)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't send request. Try again`)
            },
        },
    )

    const {
        refetch: approvePaymentPlanRefetch,
        isLoading: approvePaymentPlanLoading,
    } = useQuery(
        ['fetchSinglePackage'],
        () =>
            approvePaymentPlanApi(estimationId, {
                repayment_choice: inputs?.planDuration,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeModal()
                setSuccessModal(true)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't approve plan. Try again`)
            },
        },
    )

    const {
        refetch: resendPiiRequestRefetch,
        isLoading: resendPiiRequestLoading,
    } = useQuery(
        ['resendPiiRequestApi'],
        () =>
            resendPiiRequestApi(
                estimationId,
                inputs.email !== inputs.savedEmail
                    ? {
                          email: inputs.email,
                      }
                    : {},
            ),
        {
            enabled: false,
            retry: false,
            onError: error => {
                setErrs(error)
            },
            onSuccess: () => {
                setRequestSuccess(true)
                closeModal()
            },
        },
    )

    const loadingState =
        approvePaymentPlanLoading ||
        declinePaymentPlanLoading ||
        declineReEstimateLoading ||
        resendPiiRequestLoading

    const hasError = errorValue['email']?.length > 0

    return (
        <>
            <SeoComponent
                title="SunFi - Admin Payment Plans | Awaiting Approval - Approve Payment Plan | Awaiting Approval - Decline Payment Plan | Re-estimate Payment Plan | Resend PII Request | Request Bank Statement"
                tracker="AdminPaymentPlansTracker"
            />
            <ActionModal
                actionType={
                    selected === 'Resend PII Request' ? 'warning' : 'success'
                }
                headerText={
                    selected === 'Resend PII Request'
                        ? 'Update Email Address'
                        : selected === 'Decline'
                        ? 'Plan Declined Successfully'
                        : selected === 'Re-estimate'
                        ? 'Plan Re-estimated Successfully'
                        : 'Plan Approved Successfully'
                }
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText={
                    selected === 'Resend PII Request'
                        ? 'Update'
                        : 'Okay, Got it'
                }
                actionModalOpen={successModal}
                actionHandler={() => {
                    if (selected === 'Resend PII Request') {
                        handleSendPiiRequest()
                        setRequestSuccess(true)
                    }

                    if (selected === 'Decline') {
                        sseUse
                            ? window.location.reload()
                            : history.push({
                                  pathname: `/admin/payment-plans/declined/${estimationId}`,
                                  state: `/admin/payment-plans/awaiting-approval/${estimationId}`,
                              })
                    }
                    if (selected === 'Re-estimate') {
                        sseUse
                            ? window.location.reload()
                            : history.push({
                                  pathname: `/admin/payment-plans/in-progress/${estimationId}`,
                                  state: `/admin/payment-plans/awaiting-approval/${estimationId}`,
                              })
                    }
                    if (selected === 'approved') {
                        sseUse
                            ? window.location.reload()
                            : history.push({
                                  pathname: `/admin/payment-plans/approved/${estimationId}`,
                                  state: `/admin/payment-plans/awaiting-approval/${estimationId}`,
                              })
                    }
                    setSuccessModal(false)
                }}
                closeModal={() => setSuccessModal(false)}
                noCancelBtn={selected === 'Resend PII Request' ? false : true}
                closable
                noBackLink
            />

            <ActionModal
                actionType={hasError ? 'delete' : 'success'}
                headerText={
                    hasError
                        ? 'Existing Email Address'
                        : 'Request Sent Successfully'
                }
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText="Okay, Got it"
                actionModalOpen={requestSuccess}
                actionHandler={() => {
                    setRequestSuccess(false)
                }}
                onCancel={() => setSuccessModal(false)}
                noCancelBtn
                closable
                noBackLink
            />
            <div className={AWrapper}>
                <header className={header}>
                    <div style={{ position: 'relative' }}>
                        <h1>{headerText || 'Approve Payment Plan'}</h1>
                        <span className={AWCloseIconWrapper}>
                            <img
                                src={CloseIcon}
                                alt="close icon"
                                onClick={iconOnClick}
                                style={{ width: '27px', marginTop: -73 }}
                            />
                        </span>
                    </div>
                    <p>Enter the details of the request below to continue</p>
                </header>
                {toastError && (
                    <Toast messageType="error" message={errorMessage} />
                )}
                <section className={SelectStyle}>
                    {selected === 'approved' ? (
                        <SelectField
                            values={planDurationOption.map(option => ({
                                ...option,
                                value: option.label,
                            }))}
                            optionStyle={{
                                width: '100%',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                            }}
                            selectedValue={inputs.planDuration}
                            currentSelected={inputs.planDuration}
                            name="planDuration"
                            selectWidth="100%"
                            initialOption="Select Plan Duration"
                            handleChange={handleSelectChange}
                            withCheckBox={true}
                            dropdownPositionRelative
                            type="DropdownClassName"
                            errorMessage={errors?.planDuration}
                            selectedField="id"
                        />
                    ) : selected === 'Resend PII Request' ? (
                        <InputFields
                            inputWidth="100%"
                            title="Email Address"
                            name="email"
                            value={inputs.email}
                            handleChange={handleInputChange}
                            errorMessage={errors?.email}
                        />
                    ) : (
                        <TextArea
                            width="464px"
                            height="146px"
                            title={'Enter Reason'}
                            name="declineReason"
                            value={inputs.declineReason}
                            handleChange={handleInputChange}
                            errorMessage={errors.declineReason}
                            padding="10px 20px"
                        />
                    )}
                </section>
                <div className={ABtnWrapper}>
                    <button
                        className={AButton}
                        onClick={handleApprove}
                        style={{
                            width: selected === 'Re-estimate' && '191px',
                        }}
                    >
                        {loadingState ? (
                            <InlineLoader />
                        ) : (
                            btnText || 'Approve Plan'
                        )}
                    </button>
                </div>
            </div>
        </>
    )
}

ApprovePaymentPlan.propTypes = {
    closeModal: PropTypes.func.isRequired,
    selected: PropTypes.string,
    headerText: PropTypes.string,
    btnText: PropTypes.string,
    estimationId: PropTypes.string,
    userEmail: PropTypes.string,
    inputs: PropTypes.any,
    setInputs: PropTypes.func,
    errors: PropTypes.any,
    setErrors: PropTypes.func,
    iconOnClick: PropTypes.func,
    setClosureModal: PropTypes.func,
}

export default ApprovePaymentPlan
