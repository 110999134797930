import { useState, useEffect } from 'react'
import { Drawer, Skeleton } from 'antd'
import { useQuery } from 'react-query'
import { createRepaymentPlansApi } from '../../api/estimations/estimations-step-three'
import { validateExistingAwaitingApprovalPaymentPlansApi } from '../../api/estimations/estimations'
import style from './estimationcard.module.scss'
import { format, parseISO } from 'date-fns'
import { convertToSentenceCase } from '../../utils/formatting'
import { useHistory } from 'react-router-dom'
import { currencyFormatter } from '../../utils/currencyFormatter'
import ActionModal from '../ActionModal'
import Toast from '../Toast'
import { errorHandler } from '../../utils/errorHandler'
import PropTypes from 'prop-types'
import { InlineLoader } from '../Loader'
import { eventTrackers } from '../../utils/eventTrackers.js'
import { appTracking } from '../../utils/appTracker.js'
import { permissionsControl } from 'src/utils/permissionsControl'
import CustomToolTip from '../CustomToolTip'
import StatusIndicator from '../StatusIndicator'
import { ReactComponent as ShieldIcon } from 'src/assets/images/shield-warning.svg'
import BackNav from '../BackNav'
import NairaSymbol from '../NairaSymbol'

const EstimationCard = ({ products, loading, refetch }) => {
    const {
        Card,
        CardChildLayout,
        CardNameTitle,
        CardButton,
        CardDateAmountHeader,
        CardDateAmountValue,
        FlexContainer,
        Divider,
        DrawerAmountText,
        DrawerNameText,
        TextContent,
        TextHeader,
        SubHeader,
        DrawerBtnPrimary,
        DrawerBtnTertiary,
        TextContentBold,
        ProductStatus,
        TextContentLeft,
    } = style

    const [isView, setIsView] = useState({
        id: '',
        consumer_id: '',
        view: false,
    })
    const history = useHistory()

    const pageTitle = 'SunFi - Create A Payment Plan | Payment Plans'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CREATE_PAYMENT_PLAN_TRACKER'

    const consumerInProgressPaymentPlans =
        products?.filter(product => product.id === isView.id)[0]
            ?.selected_repayment_plans.length > 0

    let repayment_plan_ids = products
        ?.filter(product => product.id === isView.id)[0]
        ?.selected_repayment_plans.map(item => item?.id)

    const [successModal, setSuccessModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isAwaitingApproval, setIsAwaitingApproval] = useState(false)
    const subTitleMessage = `Awesome, you’ve created a Payment Plan for your customer. We’ve sent your customer an email and text for them to complete their Personal Information. After that, the next step is approval. This happens pretty fast`
    const newReEstimationFlow =
        process.env.REACT_APP_FLAG_SHOW_REESTIMATE_DEP_CONSUMER_PACKAGE ===
        'true'

    const handleModalAction = () => {
        refetch()
        setSuccessModal(false)
        setIsView({ view: false })
    }

    const { refetch: validateExistingAwaitingApprovalPaymentPlans } = useQuery(
        'validateExistingAwaitingApprovalPaymentPlans',
        () =>
            validateExistingAwaitingApprovalPaymentPlansApi({
                consumer_id: isView.consumer_id,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                if (data?.data?.data) {
                    setErrorMessage([
                        'A payment plan awaiting approval exists for this customer',
                    ])
                    setToastError(true)
                }
                setIsAwaitingApproval(data?.data?.data)
            },
        },
    )

    useEffect(() => {
        isView?.id?.length > 0 &&
            isView?.status === 'IN_PROGRESS' &&
            validateExistingAwaitingApprovalPaymentPlans()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isView])

    const { refetch: createPaymentPlansRefetch } = useQuery(
        'createPaymentPlans',
        () =>
            createRepaymentPlansApi(isView.id, {
                repayment_plan_ids: repayment_plan_ids,
                send_to: 'admin',
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setIsLoading(false)
                setSuccessModal(true)
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    'PAYMENT_PLAN_SUCCESSFULLY_SENT_TO_ADMIN',
                    eventTrackers['createPaymentPlan'].label,
                    eventTrackers['createPaymentPlan'].category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {
                            payment_plan_step_three_info: {
                                repayment_plan_ids: repayment_plan_ids,
                                send_to: 'admin',
                            },
                        },
                    },
                )
            },
            onError: data => {
                if (data?.response?.data?.errors) {
                    setErrorMessage(errorHandler(data?.response?.data))
                } else if (data?.message.includes('409')) {
                    setErrorMessage([
                        'An active payment plan exists for this customer',
                    ])
                } else {
                    setErrorMessage([
                        'Failed to submit payment plan for approval. Refresh/Try Again',
                    ])
                }
                setIsLoading(false)
                setToastError(true)
            },
        },
    )

    const submitForApproval = () => {
        setIsLoading(true)
        createPaymentPlansRefetch()
    }

    const closeDrawer = () => {
        setIsView({ view: false })
        setToastError(false)
    }

    const canViewPaymentPlansDetails = permissionsControl([
        'can_view_estimation_detail',
    ])

    return (
        <>
            {loading ? (
                <>
                    <Skeleton className={Card} />
                    <Skeleton className={Card} />
                    <Skeleton className={Card} />
                    <Skeleton className={Card} />
                    <Skeleton className={Card} />
                    <Skeleton className={Card} />{' '}
                </>
            ) : (
                <>
                    {products.map((product, index) => (
                        <div className={Card} key={index}>
                            <div className={CardChildLayout}>
                                <div>
                                    <StatusIndicator
                                        status={product?.application_status}
                                        applyMaxWidthStyles
                                    />
                                    <p
                                        className={CardNameTitle}
                                    >{`${convertToSentenceCase(
                                        product.consumer.user.first_name,
                                    )} ${convertToSentenceCase(
                                        product.consumer.user.last_name,
                                    )}`}</p>
                                </div>
                                <CustomToolTip
                                    text={
                                        !canViewPaymentPlansDetails &&
                                        'You are not authorised to perform this action'
                                    }
                                    showTooltip={
                                        !canViewPaymentPlansDetails && true
                                    }
                                    toolTipFontSize={'12px'}
                                    toolTipPadding={'10px 6px 14px 11px'}
                                    toolTipPopupWidth={'140px'}
                                    toolTipMarginLeft={'-40px'}
                                    arrowPositionCenter={true}
                                >
                                    <button
                                        onClick={e => {
                                            setIsView({
                                                view: true,
                                                id: product?.id,
                                                consumer_id:
                                                    product?.consumer?.id,
                                                status: product?.approval_status,
                                            })
                                            e.preventDefault()
                                            appTracking(
                                                pageUrlName,
                                                'VIEW_PAYMENT_PLAN_TRACKER',
                                                pageTitle,
                                                eventTrackers['viewPaymentPlan']
                                                    .action,
                                                eventTrackers['viewPaymentPlan']
                                                    .label,
                                                eventTrackers['viewPaymentPlan']
                                                    .category,
                                                ['MP', 'GA'],
                                                'event',
                                                {
                                                    event: {
                                                        ...product,
                                                    },
                                                },
                                            )
                                        }}
                                        className={CardButton}
                                        disabled={!canViewPaymentPlansDetails}
                                    >
                                        View
                                    </button>
                                </CustomToolTip>
                            </div>
                            <div className={CardChildLayout}>
                                <div>
                                    <p className={CardDateAmountHeader}>
                                        DATE OF CREATION
                                    </p>
                                    <p className={CardDateAmountValue}>
                                        {format(
                                            parseISO(product.created_at),
                                            'dd MMM, yyyy',
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <p className={CardDateAmountHeader}>
                                        TOTAL AMOUNT
                                    </p>
                                    <p className={CardDateAmountValue}>
                                        &#8358;{' '}
                                        {`${currencyFormatter(
                                            product.recommended_solution
                                                .total_cost,
                                        )}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                    {isView.id?.length > 0 &&
                        isView.view &&
                        products.map(
                            (product, index) =>
                                product.id === isView.id && (
                                    <Drawer
                                        key={index}
                                        placement="right"
                                        visible={true}
                                        closable={false}
                                        bodyStyle={{
                                            backgroundColor: newReEstimationFlow
                                                ? '#ffffff'
                                                : '#f7f7f7',
                                        }}
                                        width="480px"
                                        onClose={closeDrawer}
                                    >
                                        <ActionModal
                                            actionModalOpen={successModal}
                                            headerText="Payment Plan Successfully Created"
                                            subTitle={subTitleMessage}
                                            closeModal={handleModalAction}
                                            actionHandler={handleModalAction}
                                            actionType="success"
                                            actionText="Okay, Got it"
                                            loading={''}
                                            noCancelBtn={true}
                                        />
                                        {toastError && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '60px',
                                                    minWidth: '300px',
                                                }}
                                            >
                                                <Toast
                                                    messageType="error"
                                                    message={errorMessage}
                                                />
                                            </div>
                                        )}
                                        <BackNav
                                            onClick={() =>
                                                setIsView({ view: false })
                                            }
                                            title={'Back to Payment Plans'}
                                            marginBottom="49px"
                                        />
                                        <div className={FlexContainer}>
                                            <div>
                                                <StatusIndicator
                                                    status={
                                                        product?.application_status
                                                    }
                                                    arrowPositionLeft
                                                    toolTipMarginLeft="-18px"
                                                />
                                                <p
                                                    className={DrawerNameText}
                                                    style={{
                                                        marginTop: '12px',
                                                    }}
                                                >
                                                    {`${convertToSentenceCase(
                                                        product.consumer.user
                                                            .first_name,
                                                    )} ${convertToSentenceCase(
                                                        product.consumer.user
                                                            .last_name,
                                                    )}`}
                                                </p>
                                            </div>
                                            <div
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                <p className={DrawerAmountText}>
                                                    <NairaSymbol />{' '}
                                                    {currencyFormatter(
                                                        product
                                                            .recommended_solution
                                                            .total_cost,
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        {newReEstimationFlow &&
                                            product?.application_status ===
                                                'AWAITING_RE_ESTIMATION' && (
                                                <div
                                                    className={
                                                        style.reEstimationCard
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            style.reEstimationCardTitle
                                                        }
                                                    >
                                                        <div>
                                                            <ShieldIcon />
                                                        </div>
                                                        <h4>
                                                            Important
                                                            information to note
                                                        </h4>
                                                    </div>
                                                    <p
                                                        className={
                                                            style.reEstimationCardReason
                                                        }
                                                    >
                                                        This customer has made a
                                                        request to change their
                                                        package because;
                                                        <br />
                                                        <ul>
                                                            <li>
                                                                [reason -{' '}
                                                                {
                                                                    product
                                                                        ?.reasons?.[
                                                                        product
                                                                            ?.reasons
                                                                            ?.length -
                                                                            1
                                                                    ]?.category
                                                                }
                                                                :{' '}
                                                                {
                                                                    product
                                                                        ?.reasons?.[
                                                                        product
                                                                            ?.reasons
                                                                            ?.length -
                                                                            1
                                                                    ]?.reason
                                                                }
                                                                ].
                                                            </li>
                                                        </ul>
                                                        Please send a new
                                                        package to them as soon
                                                        as possible.
                                                    </p>
                                                </div>
                                            )}
                                        <div
                                            className={FlexContainer}
                                            style={{ marginTop: '62px' }}
                                        >
                                            <p className={TextHeader}>
                                                DATE OF CREATION
                                            </p>
                                            <p className={TextContent}>
                                                {/**parseISO to parse the string date to actual date */}
                                                {format(
                                                    parseISO(
                                                        product.created_at,
                                                    ),
                                                    'dd MMM, yyyy',
                                                )}
                                            </p>
                                        </div>
                                        <div className={Divider}></div>
                                        <div className={FlexContainer}>
                                            <p className={TextHeader}>
                                                PHONE NUMBER
                                            </p>
                                            <p className={TextContent}>
                                                {
                                                    product.consumer.user
                                                        .phone_number
                                                }
                                            </p>
                                        </div>
                                        <div className={Divider}></div>
                                        <div className={FlexContainer}>
                                            <p className={TextHeader}>
                                                EMAIL ADDRESS
                                            </p>
                                            <p className={TextContent}>
                                                {product.consumer.user.email}
                                            </p>
                                        </div>
                                        <div className={Divider}></div>
                                        <h2 className={SubHeader}>
                                            Energy Profile
                                        </h2>
                                        <div className={FlexContainer}>
                                            <p className={TextHeader}>
                                                TENURE OF PAYMENT
                                            </p>
                                            <p className={TextContent}>
                                                {product.financing_term !==
                                                    null || undefined
                                                    ? `${product.financing_term} MONTHS`
                                                    : 'Not Selected'}
                                            </p>
                                        </div>
                                        <div className={Divider}></div>
                                        <div className={FlexContainer}>
                                            <p className={TextHeader}>
                                                PAYMENT PLAN TYPE
                                            </p>
                                            <p className={TextContent}>
                                                {product.payment_plan !==
                                                    null || undefined
                                                    ? product.payment_plan
                                                    : 'Not Selected'}
                                            </p>
                                        </div>
                                        <div className={Divider}></div>
                                        <div className={FlexContainer}>
                                            <p className={TextHeader}>
                                                UPFRONT DEPOSIT
                                            </p>
                                            {product.recommended_solution
                                                .total_cost > 0 ? (
                                                <p
                                                    className={`${TextContent} ${TextContentBold}`}
                                                >
                                                    {
                                                        product.upfront_deposit_percentage
                                                    }{' '}
                                                    % (&#8358;
                                                    {currencyFormatter(
                                                        product.upfront_deposit,
                                                    )}
                                                    )
                                                </p>
                                            ) : (
                                                <p
                                                    className={`${TextContent} ${TextContentBold}`}
                                                >
                                                    {
                                                        product.upfront_deposit_percentage
                                                    }{' '}
                                                    %
                                                </p>
                                            )}
                                        </div>
                                        <div className={Divider}></div>
                                        {product.selected_repayment_plans
                                            .length > 0 && (
                                            <>
                                                <h2 className={SubHeader}>
                                                    Selected Plans
                                                </h2>
                                                {product.selected_repayment_plans.map(
                                                    (selected_plans, i) => (
                                                        <div key={i}>
                                                            <div
                                                                className={
                                                                    FlexContainer
                                                                }
                                                            >
                                                                <p
                                                                    className={
                                                                        TextHeader
                                                                    }
                                                                >
                                                                    PLAN {i + 1}
                                                                    (
                                                                    {
                                                                        selected_plans?.tenure
                                                                    }{' '}
                                                                    MONTHS)
                                                                </p>
                                                                <p
                                                                    className={
                                                                        TextContent
                                                                    }
                                                                >
                                                                    {'₦ '}
                                                                    {
                                                                        selected_plans?.monthly_payment
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div
                                                                className={
                                                                    Divider
                                                                }
                                                            ></div>
                                                        </div>
                                                    ),
                                                )}
                                            </>
                                        )}
                                        {product.approval_status !==
                                            'APPROVED' &&
                                            product.reasons.length > 0 &&
                                            product.reasons.map(
                                                (reason, index) =>
                                                    reason.type ===
                                                        product.approval_status && (
                                                        <>
                                                            {reason.reason
                                                                .length > 0 && (
                                                                <div
                                                                    style={{
                                                                        marginTop:
                                                                            '50px',
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <p
                                                                        className={
                                                                            TextHeader
                                                                        }
                                                                    >
                                                                        REASON
                                                                        FOR
                                                                        DECLINE
                                                                    </p>
                                                                    <p
                                                                        className={`${TextContent} ${TextContentLeft}`}
                                                                    >
                                                                        {
                                                                            reason.reason
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </>
                                                    ),
                                            )}
                                        {consumerInProgressPaymentPlans &&
                                        product.approval_status ===
                                            'IN_PROGRESS' &&
                                        product.consumer_status !==
                                            'CONSUMER_ACCEPT_PACKAGE' ? (
                                            <div
                                                className={ProductStatus}
                                                style={{ gridColumnGap: '8px' }}
                                            >
                                                <button
                                                    className={
                                                        DrawerBtnTertiary
                                                    }
                                                    disabled={
                                                        isLoading ||
                                                        isAwaitingApproval
                                                    }
                                                    onClick={submitForApproval}
                                                >
                                                    {isLoading ? (
                                                        <InlineLoader />
                                                    ) : (
                                                        'Submit for Approval'
                                                    )}
                                                </button>

                                                <button
                                                    className={DrawerBtnPrimary}
                                                    onClick={() => {
                                                        history.push(
                                                            `/app/payment-plans/edit/one/${product.id}`,
                                                            {
                                                                params: product.id,
                                                                newCustomerType:
                                                                    product?.customer_type ===
                                                                    'RESIDENTIAL'
                                                                        ? 'individual'
                                                                        : 'business',
                                                            },
                                                        )
                                                        appTracking(
                                                            pageUrlName,
                                                            'UPDATE_PAYMENT_PLAN_TRACKER',
                                                            pageTitle,
                                                            eventTrackers[
                                                                'updatePaymentPlan'
                                                            ].action,
                                                            eventTrackers[
                                                                'updatePaymentPlan'
                                                            ].label,
                                                            eventTrackers[
                                                                'updatePaymentPlan'
                                                            ].category,
                                                        )
                                                    }}
                                                >
                                                    Update
                                                </button>
                                            </div>
                                        ) : [
                                              'IN_PROGRESS',
                                              'DECLINED_AND_RE_ESTIMATE',
                                          ].includes(product.approval_status) &&
                                          product.consumer_status !==
                                              'CONSUMER_ACCEPT_PACKAGE' ? (
                                            <div className={ProductStatus}>
                                                <button
                                                    className={DrawerBtnPrimary}
                                                    onClick={() =>
                                                        history.push(
                                                            `/app/payment-plans/edit/one/${product.id}`,
                                                            {
                                                                params: product.id,
                                                                newCustomerType:
                                                                    product?.customer_type ===
                                                                    'RESIDENTIAL'
                                                                        ? 'individual'
                                                                        : 'business',
                                                            },
                                                        )
                                                    }
                                                >
                                                    {product.approval_status ===
                                                    'IN_PROGRESS'
                                                        ? 'Continue'
                                                        : 'Re-Estimate'}
                                                </button>
                                            </div>
                                        ) : null}
                                    </Drawer>
                                ),
                        )}
                </>
            )}
        </>
    )
}

EstimationCard.propTypes = {
    products: PropTypes.any,
    loading: PropTypes.bool,
    refetch: PropTypes.func,
}

export default EstimationCard
