import { appTracking } from 'src/utils/appTracker'
import { consumerBuildCustomSystemEvents } from 'src/utils/mp-event-tracker/consumer-build-custom-system'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'
import {
    emailRegex,
    nameRegex,
    phoneNumberRegex,
} from 'src/utils/validationSchema'
import { formatProfileManagementDataForMixpanel } from '../SolarSystemsListingsV2/utils'
import { currencyFormatter } from 'src/utils/currencyFormatter'

const pageUrlName = window.location.pathname
const pageTitle =
    'Recommended Systems | SunFi | Simplifying and Scaling Clean Energy'
const pageTracker = 'View PCR listing'

export const sortByData = [
    'Price: low to high',
    'Price: high to low',
    'Popularity',
    'All',
]

export const modalType = {
    edit: 'edit',
    manual: 'manual',
}

export const regex = {
    email: emailRegex,
    phone: phoneNumberRegex,
    customEmail: emailRegex,
    name: nameRegex,
}

export const manualRecommReason = {
    noRecommendation: 'Zero Recommendations',
    unsatisfiedRecommendation: 'Unsatisfied with Recommendations',
}

export const handleAppTracking = (event, system) => {
    if (event === 'viewPackage') {
        const viewPackageEvent = consumerProfileEvents()[event]

        const payload = formatProfileManagementDataForMixpanel(
            {
                packageName: system?.name,
                planType: system?.payment_model,
                canLastUpTo: system?.full_appliance_inverter_longevity,
                monthlyPayment: system?.least_payment_tenure,
                applianceCount: system?.fit_appliances?.length,
                availablePlansCount: system?.number_of_plans,
                upfrontDeposit:
                    system?.upfront_deposit && `N${system?.upfront_deposit}`,
                totalAmount:
                    system?.total_cost &&
                    `N${currencyFormatter(system.total_cost)}`,
                packageSource: 'Build custom solution',
            },
            'package-details-view',
        )

        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            viewPackageEvent.action,
            viewPackageEvent.label,
            viewPackageEvent.category,
            ['MP', 'GA'],
            'event',
            {
                event: payload,
            },
        )
        return
    }

    appTracking(
        pageUrlName,
        pageTracker,
        pageTitle,
        consumerBuildCustomSystemEvents[event].action,
        consumerBuildCustomSystemEvents[event].label,
        consumerBuildCustomSystemEvents[event].category,
        ['MP', 'GA'],
        'event',
    )
}
