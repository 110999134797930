import { overviewFilterOptions, statusOptions } from '../FilterDrawer/data'

export const handleFilterDisplay = filter => {
    const filterText = []

    ;[...overviewFilterOptions, ...statusOptions].forEach(
        ({ status, filterType }) => {
            const filterValue = filter?.[filterType]
            if (filterValue) {
                if (
                    filterType === 'profile_type' &&
                    !status.toLowerCase().includes(filterValue.toLowerCase())
                ) {
                    return
                }
                filterText.push(status)
            }
        },
    )

    if (filter?.member_status?.length) {
        filterText.push(`${filter.member_status} status`)
    }
    if (filter?.system_size) {
        filterText.push(`${filter.system_size} system size`)
    }
    if (filter?.payment_type) {
        filterText.push(`${filter.payment_type} payment plan`)
    }

    return filterText
}
