import PropTypes from 'prop-types'
import SecondaryModal from '../Modal/SecondaryModal'
import './gettingstarted.scss'
import { isMobile } from 'src/utils/mediaQueries'
import useGettingStarted from './hooks/useGettingStarted'

const GettingStarted = ({
    showModal,
    setShowModal,
    context = '',
    handleSwitchUseType,
    useType = '',
    selectedSolutionId,
    isPromo,
    handlePromoDropoff,
    initiatePayload,
    showUseTypeField,
    showUseTypeFieldWithoutContact = false,
    useV2SaveUseTypeFn,
    selectedDifferentPaymentPlan,
    activePaymentModel,
    handleChangePaymentModel,
    undoPaymentOptionSelection,
    isClosedCluster,
    isPromoCluster,
    isSharedCluster,
    paymentModel,
    isAllSystems,
    signUpPayload,
    consumerKYCHome,
    hasDiscardedDeal,
    isPromotional,
    profileName,
    systemDetails,
}) => {
    const {
        closeModal,
        step,
        payment_model,
        stepMapping,
        btnTextMapping,
        initialSSESignupLoading,
        validateSSESignupOTPLoading,
        handlePrimaryBtnClick,
        handleSecondaryBtnClick,
        publicPromoClusterLoading,
        getReturningCustomerStatusLoading,
        onePageKYCSignupLoading,
        actionModalTitle,
        actionModalFooterLeftContent,
        actionModalSecondaryBtnText,
        initiateLoginLoading,
        verifyLoginMutation,
        verifyLoginResendOTPMutation,
        discardDealLoading,
    } = useGettingStarted(
        useType,
        selectedSolutionId,
        isPromo,
        handlePromoDropoff,
        initiatePayload,
        showUseTypeField,
        showUseTypeFieldWithoutContact,
        setShowModal,
        context,
        handleSwitchUseType,
        useV2SaveUseTypeFn,
        activePaymentModel,
        handleChangePaymentModel,
        selectedDifferentPaymentPlan,
        undoPaymentOptionSelection,
        isClosedCluster,
        isPromoCluster,
        isSharedCluster,
        paymentModel,
        isAllSystems,
        signUpPayload,
        consumerKYCHome,
        hasDiscardedDeal,
        isPromotional,
        profileName,
        systemDetails,
    )

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={() => closeModal()}
            modalWidth={'475px'}
            title={
                step === 1 && isClosedCluster
                    ? 'Your selection has been confirmed'
                    : step === 3 && payment_model === 'Outright Sale'
                    ? 'Your deal is approved!'
                    : [1, 2, 3].includes(step)
                    ? 'Getting Started on SunFi'
                    : step === 'action-modal'
                    ? actionModalTitle
                    : 'Save to continue later'
            }
            v2
            content={stepMapping[step]}
            showFooter
            footerLeftContent={
                selectedDifferentPaymentPlan ||
                (step === 3 && payment_model !== 'Outright Sale') ||
                (step === 1 && isClosedCluster)
                    ? 'button'
                    : (step === 3 && payment_model === 'Outright Sale') ||
                      step === 5 ||
                      step === 1 ||
                      (step === 2 && isClosedCluster) ||
                      step === 'select-otp-method-for-login'
                    ? 'chat'
                    : step === 'action-modal'
                    ? actionModalFooterLeftContent
                    : ''
            }
            primaryBtnText={btnTextMapping[step]}
            primaryBtnLoading={
                initialSSESignupLoading ||
                validateSSESignupOTPLoading ||
                publicPromoClusterLoading ||
                getReturningCustomerStatusLoading ||
                onePageKYCSignupLoading ||
                initiateLoginLoading ||
                verifyLoginMutation.isLoading ||
                verifyLoginResendOTPMutation.isLoading ||
                discardDealLoading
            }
            handlePrimaryBtnClick={handlePrimaryBtnClick}
            primaryBtnWidth={isMobile ? '127.15px' : '160px'}
            primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            secondaryBtnWidth={isMobile ? '140px' : '160px'}
            secondaryBtnHeight={isMobile ? '44.5px' : '53px'}
            secondaryBtnText={
                step === 'action-modal'
                    ? actionModalSecondaryBtnText
                    : isClosedCluster
                    ? 'Reselect'
                    : selectedDifferentPaymentPlan
                    ? 'Undo'
                    : "I don't have these"
            }
            handleSecondaryBtnClick={handleSecondaryBtnClick}
            dataTestId="getting-started-modal"
            primaryBtnDataTestId={'getting-started-primary-btn'}
            secondaryBtnDataTestId={'getting-started-secondary-btn'}
            kycSecondaryButtonStopLoading={true}
        />
    )
}

GettingStarted.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    context: PropTypes.oneOf(['switch_use_type', '']),
    handleSwitchUseType: PropTypes.func,
    useType: PropTypes.string,
    selectedSolutionId: PropTypes.string,
    isPromo: PropTypes.bool,
    handlePromoDropoff: PropTypes.func,
    initiatePayload: PropTypes.any,
    showUseTypeField: PropTypes.bool,
    showUseTypeFieldWithoutContact: PropTypes.bool,
    useV2SaveUseTypeFn: PropTypes.bool,
    selectedDifferentPaymentPlan: PropTypes.bool,
    activePaymentModel: PropTypes.string,
    handleChangePaymentModel: PropTypes.func,
    undoPaymentOptionSelection: PropTypes.func,
    isClosedCluster: PropTypes.func,
    isPromoCluster: PropTypes.bool,
    isSharedCluster: PropTypes.bool,
    paymentModel: PropTypes.string,
    isAllSystems: PropTypes.bool,
    signUpPayload: PropTypes.object,
    consumerKYCHome: PropTypes.string,
    hasDiscardedDeal: PropTypes.bool,
    isPromotional: PropTypes.bool,
    profileName: PropTypes.string,
    systemDetails: PropTypes.object,
}

export default GettingStarted
